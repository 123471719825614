import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonCard from '../../../Components/Ui/CommonCard';
import OfferTime from './OfferTime';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import SEO from '../../../Components/Helmet/SEO';
import { ROUTES } from '../../../Constant/Routes.Constant';
import {
	LimitedEditionState,
	getAllLimitedEditions,
} from '../../../store/LimitedEditionSlice';
import { LIMITED_EDITION_NOTES } from '../../../Constant/Seo.Constant';
import { LimitedEditionData } from '../../../Interface/Index';
import moment from 'moment';
import { LimitedTitle } from '../../../Constant/Constant';
import {
	BLACK,
	GRAY10,
	GRAY300,
	WHITE,
} from '../../../Constant/Color.Constant';

const LimitedEditionNotes = () => {
	const [shouldShowLimitedEdition, setShouldShowLimitedEdition] =
		useState<boolean>(false);
	const [dataLimit, setDataLimit] = useState(4);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const date = new Date();

	const limitedEditionData = useAppSelector(
		(state: { limited: LimitedEditionState }) => state?.limited?.limitedList
	);

	const limitedEditionDate = useAppSelector(
		(state: any) => state?.countdown?.endDate?.data?.limitedEditionDate
	);

	const handleLoadMore = () => {
		const newLimit = dataLimit + 8;
		const maxLimit = limitedEditionData?.length;

		if (newLimit <= maxLimit) {
			setDataLimit(newLimit);
		} else {
			setDataLimit(maxLimit);
		}
	};

	useEffect(() => {
		const offerDate = moment(limitedEditionDate);
		const todaysDate = moment(date);

		if (offerDate < todaysDate) {
			setShouldShowLimitedEdition(false);
			navigate(ROUTES.HOME_PAGE);
		} else {
			setShouldShowLimitedEdition(true);
		}
	}, [limitedEditionDate]);

	const showLoadMoreButton = dataLimit < limitedEditionData?.length;

	useEffect(() => {
		const fetchData = async () => {
			try {
				await dispatch(getAllLimitedEditions({ forSell: true }));
			} catch (error) {
				console.error('Error fetching limited edition data:', error);
			}
		};

		fetchData();
	}, [dispatch]);

	const handleNavigate = () => {
		navigate(ROUTES.LIMITED_EDITION);
	};

	useEffect(() => {
		if (location.pathname === ROUTES.LIMITED_EDITION) {
			setDataLimit(dataLimit + 4);
		}
	}, []);

	return (
		<>
			<>
				<SEO title="Limited Edition" description={LIMITED_EDITION_NOTES} />
				{shouldShowLimitedEdition && (
					<>
						<div
							className="xlg:px-24 lg:px-12 sm:gap-8 gap-2 sm:py-24 xs:py-8 xs:px-4 h-full flex justify-center items-center flex-col"
							id="limitedId"
							style={{
								background:
									window.location.pathname === ROUTES.LIMITED_EDITION
										? WHITE
										: GRAY300,
							}}
						>
							<div className="flex justify-center items-center flex-col sm:gap-4 gap-2 w-full ">
								<p
									className="font-bold sm:text-[32px] text-2xl sm:leading-10 leading-8 uppercase text-center"
									style={{ color: BLACK }}
								>
									{LimitedTitle?.title}
								</p>

								<p
									className="sm:text-xl sm:leading-8 font-medium leading-6 text-sm text-center sm:max-w-[1184px] max-w-[343px]"
									style={{ color: GRAY10 }}
								>
									{LimitedTitle?.description}
								</p>
							</div>
							<div className="flex flex-col sm:gap-8 gap-6">
								<OfferTime />
								<div className="flex flex-col w-auto justify-center items-center sm:gap-8 gap-4">
									<div
										className={`xs:gap-4 lg:gap-x-8 lg:gap-y-12 sm:gap-4 grid ${
											limitedEditionData?.length === 3
												? 'sm:grid-cols-2 md:grid-cols-3'
												: limitedEditionData?.length === 2
												  ? 'xs:grid-cols-2 md:grid-cols-2'
												  : limitedEditionData?.length === 1
												    ? 'xs:grid-cols-1'
												    : 'xs:grid-cols-1 nd:grid-cols-2 xlg:grid-cols-4 md:grid-cols-3'
										}`}
									>
										{limitedEditionData
											?.slice(0, dataLimit)
											?.map((item: LimitedEditionData, index: number) => {
												return (
													<CommonCard
														key={index}
														title={item.name}
														description={item.description}
														image={item.images[0]}
														id={item.id}
														to={item.to}
														collectionID={item.id}
														isSold={item.isSold}
													/>
												);
											})}
									</div>

									{location.pathname === ROUTES.LIMITED_EDITION &&
										showLoadMoreButton && (
											<div className="w-full flex justify-center">
												<CommonButton
													padding="12px 32px"
													label="Load More"
													fontSize="16px"
													onClick={handleLoadMore}
												/>
											</div>
										)}

									<>
										{location.pathname === ROUTES.HOME_PAGE && (
											<CommonButton
												padding="12px 32px"
												label="VIEW ALL"
												fontSize="16px"
												onClick={() => handleNavigate()}
											/>
										)}
									</>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		</>
	);
};

export default LimitedEditionNotes;
