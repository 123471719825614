import React, { useState } from 'react';
import ErrorMessage from '../../../../Components/Typography/ErrorText';
import CommonLabel from '../../../../Components/InputLable/lable';
import {
	BORDERCOLOR,
	TEXTCOLOR,
	WHITE,
} from '../../../../Constant/Color.Constant';
import DeleteCrossIcon from '../../../../Components/Svg/DeleteCrossIcon';
import {
	ACCEPT_FILES_EXTENSSIONS,
	CLASSES,
	SET_OF_FOUR,
} from '../../../../Constant/Constant';
import { USER_IMAGE_MALE } from '../../../../Configuration/InitialData';
import {
	setOfFour,
	SetOfFourData,
} from '../../../../Interface/NewGiftInterface';
import { SetState } from '../../../../Interface/Index';
import Image from '../../../../Components/Image/Image';

const ImageUpload = ({
	setOfFour,
	setSetOfFour,
	handleImageChange,
	uploadedFileJson,
	setSetOfFourData,
	setOfFourData,
	formEl,
	index,
	id,
}: {
	setOfFour: any;
	setSetOfFour: SetState<setOfFour>;
	handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	uploadedFileJson: { name: string } | null;
	setSetOfFourData: SetState<SetOfFourData>;
	setOfFourData: SetOfFourData;
	formEl: any;
	index: number;
	id?: string;
}) => {
	const { register, errors, setError, setValue, clearErrors } = formEl;
	const [isDefaultImage, setIsDefaultImage] = useState(true);

	return (
		<div>
			<div>
				<CommonLabel labelText="Images" required />
				<div>
					<div className="w-full text-gray-500 lg:mt-3 mt-1 text-base rounded-lg flex flex-col gap-4">
						<div className="text-lg w-full h-full flex">
							{setOfFour?.image ? (
								<div
									className="w-full p-2 pr-4 rounded-lg flex justify-between"
									style={{
										border: `1px solid ${BORDERCOLOR}`,
										background: WHITE,
									}}
								>
									<div className="flex gap-3 items-center w-[calc(100%-24px)]">
										<div className="h-12 w-12 overflow-hidden relative shadow-[rgba(149,157,165,0.2)_0px_8px_24px]">
											<Image
												image={setOfFour?.image}
												altName="user-image"
												className="h-full w-full object-cover"
											/>
										</div>
										<div className="w-[calc(100%-60px)]">
											<p
												className="uppercase text-base leading-8 w-full whitespace-nowrap overflow-hidden text-ellipsis"
												style={{ color: TEXTCOLOR }}
											>
												{uploadedFileJson?.name ?? `IMG45x${24 + index}.PNG`}
											</p>
										</div>
									</div>
									<div className="flex items-center">
										<button
											type="button"
											onClick={() => {
												if (id === CLASSES) {
													setSetOfFour((values: setOfFour) => ({
														...values,
														image: {},
													}));
												} else {
													setError('image', { message: 'Image is required' });
													setSetOfFourData({
														...setOfFourData,
														[`set${index + 1}`]: {
															...setOfFourData[`set${index + 1}`],
															image: '',
														},
													});
												}
											}}
										>
											<DeleteCrossIcon />
										</button>
									</div>
								</div>
							) : id === CLASSES ? (
								<div
									className="w-full p-2 pr-4 rounded-lg flex justify-between"
									style={{
										border: `1px solid ${BORDERCOLOR}`,
										background: WHITE,
									}}
								>
									<div className="flex gap-3 items-center w-[calc(100%-24px)]">
										<div className="h-12 w-12 overflow-hidden relative shadow-[rgba(149,157,165,0.2)_0px_8px_24px]">
											<Image
												image={USER_IMAGE_MALE}
												altName="user-image"
												className="h-full w-full object-cover"
											/>
										</div>
										<div className="w-[calc(100%-60px)]">
											<p
												className="uppercase text-base leading-8 w-full whitespace-nowrap overflow-hidden text-ellipsis"
												style={{ color: TEXTCOLOR }}
											>
												IMG45x24.PNG
											</p>
										</div>
									</div>
									<div className="flex items-center">
										<button
											type="button"
											onClick={() => {
												setIsDefaultImage(false);
												setValue('imageString', 'something');
												clearErrors('imageString');
											}}
										>
											<DeleteCrossIcon />
										</button>
									</div>
								</div>
							) : (
								<button className="w-full h-16 border-2 border-dashed border-gray-200 rounded-lg relative">
									<div className="flex flex-row justify-center gap-5 cursor-pointer">
										{id === SET_OF_FOUR && (
											<div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
												<p className="capitalize text-center text-lg leading-8">
													Upload image
												</p>
											</div>
										)}

										<div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 opacity-0 w-full h-full">
											<input
												type="file"
												{...register('image')}
												className="h-full w-full cursor-pointer"
												onChange={(e) => {
													handleImageChange(e);
													register('image').onChange(e);
												}}
												accept={ACCEPT_FILES_EXTENSSIONS}
											/>
										</div>
									</div>
								</button>
							)}
						</div>
						{!setOfFour?.image && !isDefaultImage && (
							<button className="w-full h-16 border-2 border-dashed border-gray-200 rounded-lg relative">
								<div className="flex flex-row justify-center gap-5 cursor-pointer">
									{id === CLASSES && (
										<div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
											<p className="capitalize text-center text-lg leading-8">
												Upload image
											</p>
										</div>
									)}

									<div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 opacity-0 w-full h-full">
										<input
											type="file"
											className="h-full w-full cursor-pointer"
											onChange={handleImageChange}
											accept={ACCEPT_FILES_EXTENSSIONS}
										/>
									</div>
								</div>
							</button>
						)}
					</div>
				</div>
				{errors?.imageString && (
					<ErrorMessage message={errors?.imageString.message} />
				)}
				{errors?.imageString ? (
					<ErrorMessage message={errors?.imageString.message} />
				) : (
					errors?.image && <ErrorMessage message={errors?.image.message} />
				)}
			</div>
		</div>
	);
};

export default ImageUpload;
