import React from 'react';

import { CONTACT_NUMBER, EMAIL } from '../../Constant/KeyWord.Constant';
import Call from '../Svg/Call';
import Email from '../Svg/Email';
import { BLACK, BLACK200, WHITE, WHITE20 } from '../../Constant/Color.Constant';
import { makeCall, sendEmail } from '../../utils/comon.functions';

const ContactusDetails = () => {
	return (
		<div
			className="flex w-full flex-col h-full xs:py-16 xs:px-4 lg:p-12 relative overflow-hidden"
			style={{
				background: BLACK,
			}}
		>
			<div
				className="absolute lg:top-[64%] xs:top-[72%] right-0 h-full lg:w-[200px] xs:w-[150px] skew-x-0 skew-y-[-38deg]"
				style={{ backgroundColor: BLACK200 }}
			></div>
			<div className="flex flex-col z-[2]">
				<div className="flex flex-col gap-8">
					<div className="flex flex-col gap-2 md:gap-6">
						<p
							className="lg:text-xl xs:text-[32px] font-medium uppercase"
							style={{
								color: WHITE,
							}}
						>
							contact information
						</p>
						<div className="flex flex-col gap-8">
							<p
								className="text-sm leading-6 text-justify"
								style={{ color: WHITE20 }}
							>
								We value your feedback and are here to assist you. Whether you
								have questions about our products, need support, or just want to
								say hello, we are always happy to hear from you. Please feel
								free to reach out through any of the following channels
							</p>
						</div>
					</div>
					<div className="flex flex-col gap-6 text-base leading-6 font-normal">
						<div
							className="flex gap-3 cursor-pointer"
							onClick={() => makeCall(CONTACT_NUMBER)}
						>
							<Call />
							<p style={{ color: WHITE20 }}>{CONTACT_NUMBER}</p>
						</div>
						<div
							className="flex gap-3 cursor-pointer"
							onClick={() => sendEmail(EMAIL)}
						>
							<Email />
							<p style={{ color: WHITE20 }}>{EMAIL}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactusDetails;
