import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LeftSlider from '../../../Components/Svg/LeftSlider';
import RightSlider from '../../../Components/Svg/RightSlider';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	getAllAlbumBanner,
	getAllSetOfAllBanner,
} from '../../../store/BannerSlice';
import Image from '../../../Components/Image/Image';

interface SampleNextArrowProps extends React.HTMLProps<HTMLDivElement> {
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const OrderSlider: React.FC<{ bannerType?: string }> = ({
	bannerType = 'album',
}) => {
	const settings = {
		speed: 500,
		slidesToScroll: 1,
		dots: true,
		autoplaySpeed: 2000,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};

	function SamplePrevArrow(props: SampleNextArrowProps) {
		const { onClick } = props;
		return (
			<div onClick={onClick} className="SamplePrevArrow-Album">
				<div
					style={{
						borderRadius: '8px',
					}}
				>
					<LeftSlider strokeColor="black" />
				</div>
			</div>
		);
	}

	function SampleNextArrow(props: SampleNextArrowProps) {
		const { onClick } = props;
		return (
			<div onClick={onClick} className="SampleNextArrow-Album ">
				<div
					style={{
						borderRadius: '8px',
					}}
				>
					<RightSlider strokeColor="black" />
				</div>
			</div>
		);
	}

	const AlbumBannerList = useAppSelector(
		(state) => state?.banner?.albumBannerList
	);
	const SetOfAllBannerList = useAppSelector(
		(state) => state.banner.setOfAllBannerList
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (bannerType === 'album') {
			dispatch(getAllAlbumBanner({ limit: 10, type: bannerType }));
		} else {
			dispatch(getAllSetOfAllBanner({ limit: 10, type: bannerType }));
		}
	}, [bannerType]);

	return (
		<div className="w-full z-10 relative order-slider">
			<Slider
				{...settings}
				className="w-full sm:h-[280px] !xs:h-[168px] flex flex-col gap-4 object-cover"
			>
				{bannerType === 'album' && AlbumBannerList?.length > 0 ? (
					AlbumBannerList?.map((banner, index) => (
						<div
							key={index}
							className="!sm:h-[256px] !sm:w-[624px] !h-[168px] w-[319px]"
						>
							<div className="rounded-lg !sm:h-[256px] !sm:w-[624px] !h-[168px] w-[319px]">
								<Image image={banner.image} altName="banners" />
							</div>
						</div>
					))
				) : bannerType === 'setOfAll' && SetOfAllBannerList?.length > 0 ? (
					SetOfAllBannerList?.map((banner, index) => (
						<div
							key={index}
							className="!sm:h-[256px] !sm:w-[624px] !h-[168px] w-[319px]"
						>
							<div className="rounded-lg !sm:h-[256px] !sm:w-[624px] !h-[168px] w-[319px]">
								<Image image={banner.image} altName="banners" />
							</div>
						</div>
					))
				) : (
					<div>No banners available</div>
				)}
			</Slider>
		</div>
	);
};

export default OrderSlider;
