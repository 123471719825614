import React from 'react';
import { stackItems } from '../../../Constant/Constant';
import { stackItems2 } from '../../../Constant/Constant';
import {
	GRAY900,
	TEXTCOLORGRAY,
	WHITE,
} from '../../../Constant/Color.Constant';
import StackItems from './StackItems';

const KeyValues = () => {
	return (
		<div
			className="w-full flex items-center justify-center lg:max-w-[2500px] lg:px-24 mx-auto"
			style={{ backgroundColor: WHITE }}
		>
			<div className="h-full w-full lg:px-10 lg:py-24 xs:py-8 xs:px-4">
				<div className=" w-full h-full grid lg:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 sm:gap-8 gap-6">
					<div className="flex flex-col sm:gap-y-4 gap-y-2">
						<p
							className="sm:text-3xl text-2xl uppercase font-bold leading-9"
							style={{ color: GRAY900 }}
						>
							key values
						</p>
						<p
							className="sm:text-lg xs:text-base sm:leading-7 xs:leading-6 font-normal"
							style={{ color: TEXTCOLORGRAY }}
						>
							The fundamental principles and beliefs that guide our actions,
							decisions, and interactions, shaping our culture and defining our
							identity
						</p>
					</div>
					<StackItems stackItems={[...stackItems, ...stackItems2]} />
				</div>
			</div>
		</div>
	);
};

export default KeyValues;
