import React from 'react';

const Frame = () => {
	return (
		<div>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="Frame">
					<rect width="20" height="20" rx="4" fill="#EB5757" />
					<path
						id="Vector"
						d="M5.63184 14.3692L10.001 10L14.3702 14.3692M14.3702 5.63086L10.0002 10L5.63184 5.63086"
						stroke="white"
					/>
				</g>
			</svg>
		</div>
	);
};

export default Frame;
