import { menuItems, socialMediaLinks } from '../../Constant/Constant';
import { useNavigate } from 'react-router-dom';
import { THE_RUPI } from '../../Constant/KeyWord.Constant';
import '../../index.css';
import {
	BORDERCOLOR,
	TEXTCOLORGRAY,
	WHITE,
} from '../../Constant/Color.Constant';

const Footer = () => {
	const navigate = useNavigate();
	return (
		<div
			className="max-w-[2500px] w-full mx-auto justify-center h-full items-center lg:py-12 xs:py-8 border-t"
			style={{ background: WHITE, borderColor: BORDERCOLOR }}
		>
			<div>
				<div className="items-center justify-center flex flex-col gap-8">
					<div
						className="flex justify-center  items-center lg:flex-row md:flex-row sm:flex-col gap-3 md:gap-0 xs:flex-col w-full"
						style={{ color: TEXTCOLORGRAY }}
					>
						{menuItems.map((menuItem, index) => (
							<p
								key={index}
								className="cursor-pointer lg:text-xl text-sm leading-4 font-medium lg:leading-6 px-5 py-2"
								onClick={() => {
									navigate(menuItem.link);
								}}
							>
								{menuItem.label}
							</p>
						))}
					</div>

					<div className="flex gap-6 items-center" style={{ color: '#374151' }}>
						{socialMediaLinks.map((link) => (
							<a
								key={link.name}
								href={link.url}
								target="_blank"
								rel="noopener noreferrer"
							>
								{link.icon}
							</a>
						))}
					</div>
					<div className="flex justify-center items-center">
						<p
							className="xs:text-xs sm:text-sm w-full md:text-base"
							style={{ color: '#9CA3AF' }}
						>
							@2023 {THE_RUPI}. All rights reserved
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
