import React from 'react';
import { ExclusiveProps } from '../../Interface/Index';
import Image from '../Image/Image';
import RightColloection from '../Svg/RightColloection';
import classNames from 'classnames';
import '../../index.css';
import useMobileMediaQuery from '../../utils/useMobileMediaQuery';
import { GRAY900, WHITE_SMOKE } from '../../Constant/Color.Constant';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Constant/Routes.Constant';

const ExclusiveCard = ({
	image,
	title,
	id,
	collectionID,
}: ExclusiveProps = {}) => {
	const navigate = useNavigate();
	const { isMobile } = useMobileMediaQuery();
	const handleClick = () => {
		navigate(
			`${ROUTES.EXCLUSIVE}/${ROUTES.EXCLUSIVE_DETAILES}/${collectionID}`
		);
	};
	return (
		<>
			<div
				className={classNames(
					'sm:max-w-[288px] rounded-lg w-full flex flex-col justify-start items-start cursor-pointer',
					id,
					'visible-card'
				)}
				onClick={handleClick}
			>
				<div
					className="flex  w-full rounded-t-[10px]"
					style={{ background: WHITE_SMOKE }}
				>
					<Image
						image={image}
						className="!rounded-t-[10px] sm:min-w-full nd:min-w-[164px]    xs:w-60 sm:w-[288px] nd:w-[164px] sm:h-[200px] nd:h-[96px] xs:h-[200px]"
					/>
				</div>
				<div
					className="flex flex-col  justify-between border-2 border-t-0 rounded-b-lg  xs:min-w-[164px] xs:w-60 nd:w-[164px] sm:min-w-full nd:min-w-[164px] "
					style={{ background: WHITE_SMOKE }}
				>
					<div className="gap-5 flex items-center justify-center  w-full p-4 uppercase rounded-b-lg ">
						<p
							className="nd:text-sm xs:text-lg leading-8 xs:leading-6  font-bold  sm:w-[calc(100%-40px)] truncate"
							style={{ color: GRAY900 }}
						>
							{title}
						</p>
						{!isMobile && <RightColloection />}
					</div>
				</div>
			</div>
		</>
	);
};

export default ExclusiveCard;
