import React, { useEffect } from 'react';
import '../../../index.css';
import { getAllFaq, Faq } from '../../../store/FaqSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { GRAY900 } from '../../../Constant/Color.Constant';

interface HomeFaqsProps {}
const HomeFaqs: React.FC<HomeFaqsProps> = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(getAllFaq());
	}, []);
	const faqs = useAppSelector((state) => state.faq.faqList);

	return (
		<div className="w-full h-full lg:py-20 lg:px-24 md:py-20 md:px-24 sm:py-5 sm:px-12 xs:py-8 xs:px-4 bg-white flex justify-center">
			<div className="w-full">
				<div className="flex w-full sm:gap-8 xs:gap-6 lg:flex-row md:flex-row sm:flex-col xs:flex-col">
					<div className="md:w-2/4 sm:w-full xs:w-full h-full">
						<div>
							<p
								className="font-bold text-start xs:text-[24px] sm:text-[44px] max-w-[608px] uppercase flex flex-col"
								style={{ color: GRAY900 }}
							>
								<span className="sm:leading-[64px] leading-8">
									Frequently asked
								</span>
								<span className="sm:leading-[64px] leading-8">question</span>{' '}
							</p>
						</div>
					</div>
					<div className="md:w-2/4 sm:w-full xs:w-full h-full lg:gap-[48px] xs:gap-[24px] flex flex-col justify-center">
						{faqs?.map((faq: Faq, index: number) => (
							<div key={index} className="flex flex-col gap-2">
								<p
									className="font-medium sm:text-lg text-base leading-6"
									style={{ color: GRAY900 }}
								>
									{faq.question}
								</p>
								<p
									className="font-normal sm:text-base text-sm lg:max-w-[608px]"
									style={{ color: '#6B7280' }}
								>
									{faq.answer}
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeFaqs;
