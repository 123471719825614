import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { getPriceByDenomination } from '../../../../Api/UserListApi';
import Frame from '../ui/Frame';
import BackGround from '../ui/BackGround';
import AddToBag from '../frame/components/AddToBag';
import CommonLabel from '../../../../Components/InputLable/lable';
import ErrorMessage from '../../../../Components/Typography/ErrorText';
import {
	CLASSES,
	Currency,
	currencyValues,
} from '../../../../Constant/Constant';
import {
	BLACK,
	BORDERCOLOR,
	GRAY10,
	TEXTCOLORGRAY,
	TRANSPARENT,
	WHITE,
} from '../../../../Constant/Color.Constant';
import { useDraggable } from 'react-use-draggable-scroll';
import NumberTag from '../../../../Components/Ui/NumberTag';
import RupeeSelected from '../../../../Components/Svg/RupeeSelected';
import RupeeUnselected from '../../../../Components/Svg/RupeeUnselected';
import FrameTitle from '../ui/FrameTitle';
import { SetState } from '../../../../Interface/Index';
import CommonDatePicker from '../../../../Components/DatePicker/DatePicker';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import UserImageInput from './components/UserImageInput';
import {
	BackgroundImageJson,
	BgData,
	fiveNotesDataTS,
	SelectedFrame,
	setOfFour,
	SetOfFourData,
} from '../../../../Interface/NewGiftInterface';
import useScreenSize from '../../../../utils/hooks/UseScreenSIze';

export const FiveNotes = ({
	id,
	setSetOfFour,
	setOfFour,
	setFiveNotesData,
	fiveNotesData,
	setSelectedFrame,
	setLayout,
	layout,
	formEl,
	isSubmitValid,
	backGroundImages,
	setBackGroundImages,
	backGround,
	setIsSetOfAllModalOpen,
	setIsImageDelete,
	isImageDelete,
	bgData,
	setSelectedFrameBackground,
	loading,
	typeEdit,
	selectedDate,
	setSelectedDate,
	resetAll,
	setResetAll,
	setOfFourData,
}: {
	id: string;
	setSetOfFour: SetState<setOfFour>;
	setOfFour: setOfFour;
	setFiveNotesData: SetState<fiveNotesDataTS>;
	fiveNotesData: fiveNotesDataTS;
	setSelectedFrame: SetState<SelectedFrame | undefined>;
	setLayout: SetState<boolean>;
	layout: boolean;
	formEl: {
		register: any;
		errors: any;
		touchedFields: any;
		dirtyFields: any;
		setValue: any;
		setError: any;
		clearErrors: any;
	};
	isSubmitValid: boolean;
	backGroundImages: BackgroundImageJson[];
	setBackGroundImages: SetState<BackgroundImageJson[]>;
	backGround: BackgroundImageJson[];
	setIsSetOfAllModalOpen: SetState<boolean>;
	setIsImageDelete: SetState<boolean>;
	isImageDelete: boolean;
	bgData: BgData;
	setSelectedFrameBackground: SetState<null | string | undefined>;
	loading: boolean;
	typeEdit: string;
	selectedDate: Date | string | undefined;
	setSelectedDate: SetState<Date | string | undefined>;
	resetAll: boolean;
	setResetAll: SetState<boolean>;
	setOfFourData: SetOfFourData;
}) => {
	const location = useLocation();
	const edittableData = location?.state?.id;

	const [isSelected, setIsSelected] = useState<boolean>(false);
	const [pricePerDenomination, setPricePerDenomination] = useState([]);
	const [showSetOfAllMessage, setShowSetOfAllMessage] = useState(false);
	const openSetOfAllModal = () => {
		setIsSetOfAllModalOpen(true);
	};
	const {
		register,
		errors,
		touchedFields,
		dirtyFields,
		setValue,
		setError,
		clearErrors,
	} = formEl;

	const ref = useRef<HTMLDivElement | null>(null);
	const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);

	const noteLimitData = 5;

	const currencyHandleChange = (index: number) => {
		let updatedSelectedCurrencies = [...setOfFour.currency];
		if (updatedSelectedCurrencies.includes(index)) {
			const indexToRemove = updatedSelectedCurrencies.indexOf(index);
			updatedSelectedCurrencies.splice(indexToRemove, 1);
		} else {
			if (
				noteLimitData > updatedSelectedCurrencies.length &&
				!setOfFour.currency.includes(index) &&
				updatedSelectedCurrencies.length < 5
			) {
				updatedSelectedCurrencies.push(index);
			} else if (updatedSelectedCurrencies.length >= 5) {
				setShowSetOfAllMessage(true);
				return;
			}
		}
		if (showSetOfAllMessage) {
			setShowSetOfAllMessage(false);
		}
		updatedSelectedCurrencies = updatedSelectedCurrencies.sort();
		const selectedImages = updatedSelectedCurrencies.map((selectedIndex) => {
			return {
				image: Currency[selectedIndex]?.image,
				rupee: Currency[selectedIndex]?.rupee,
				noteIndex: selectedIndex,
			};
		});

		const result: string[] = updatedSelectedCurrencies.map((item) => {
			return `${currencyValues[item]}`;
		});

		setValue('denomination', result);
		if (!result.length) {
			setError('denomination', {
				message: 'denomination field must have at least 1 items',
			});
		} else {
			clearErrors('denomination');
		}
		if (updatedSelectedCurrencies.length > 1) {
			setLayout(false);
			setSetOfFour((values: setOfFour) => ({
				...values,
				currency: updatedSelectedCurrencies,
				denomination: result,
				noteImage: selectedImages,
				isMultipleNote: true,
			}));
		} else {
			setLayout(true);
			setSetOfFour((values: setOfFour) => ({
				...values,
				currency: updatedSelectedCurrencies,
				denomination: result,
				noteImage: selectedImages,
			}));
		}
	};

	useEffect(() => {
		if (resetAll) {
			currencyHandleChange(0);
			setResetAll(false);
		}
	}, [resetAll]);

	const fetchData = async () => {
		try {
			const response = await getPriceByDenomination();
			setPricePerDenomination(response?.data?.rows);
		} catch (error) {
			console.error({ error });
		}
	};

	useEffect(() => {
		if (typeEdit) {
			if (edittableData?.gift?.image === '') {
				setIsImageDelete(true);
			}
		} else {
			setIsImageDelete(false);
		}

		fetchData();
		currencyHandleChange(0);
	}, []);

	useEffect(() => {
		if (typeEdit) {
			setSelectedDate(moment(edittableData.gift.date).toDate());
			setValue('date', edittableData.gift.date);
		}
	}, [edittableData]);

	const screenDetails = useScreenSize();

	return (
		<>
			{id === CLASSES && (
				<div>
					<div className="flex flex-col justify-between w-full h-full gap-8">
						<div className="flex flex-col gap-4 justify-end">
							<div className="xlg:pl-16 xlg:pr-24 lg:px-10 sm:px-8 px-4">
								<FrameTitle
									title="customize gift"
									subTitle={layout ? 'single date' : 'multiple notes'}
								/>

								<div className="flex flex-col lg:gap-8 gap-6 lg:pb-10">
									{layout && (
										<div>
											<div>
												<CommonLabel labelText="Select Occassion" required />
												<div
													className="rounded-lg border appearance-none focus:outline-none text-gray-900 text-sm block w-full lg:mt-3 mt-1 overflow-hidden"
													style={{
														borderColor: BORDERCOLOR,
														background: WHITE,
													}}
												>
													<input
														type="text"
														maxLength={20}
														name="occassion"
														{...register('occassion')}
														value={fiveNotesData.occassion}
														onChange={(event) => {
															event.target.value = (
																event.target.value || ''
															).trimStart();
															setFiveNotesData({
																...fiveNotesData,
																occassion: event.target.value,
															});
															register('occassion').onChange(event);
														}}
														className="lg:py-3 lg:px-4 p-3 w-full lg:text-lg text-sm lg:leading-8 leading-6 focus:outline-none"
														placeholder="Enter Occasion"
													/>
												</div>
												{errors?.occassion &&
													(touchedFields.occassion ||
														dirtyFields.occassion) && (
														<ErrorMessage
															message={errors?.occassion?.message}
														/>
													)}
											</div>
										</div>
									)}

									<div>
										<div>
											<div className="lg:mb-3 mb-1">
												<CommonLabel labelText="Select Date" required />
											</div>
											<CommonDatePicker
												name="date"
												register={register}
												setError={setError}
												className="w-full lg:py-3 lg:px-4 p-3 lg:text-lg text-sm h-12 lg:h-[56px]"
												handleDateChange={(date: Date | null | string) => {
													setSelectedDate(date as Date);
													setSetOfFour({
														...setOfFour,
														date: moment(date).format('YY/MM/DD'),
													});
													setValue('date', date);
													clearErrors('date');
												}}
												selected={selectedDate as Date}
											/>
											{errors?.date && (
												<ErrorMessage message={errors?.date?.message} />
											)}
										</div>
									</div>

									<div>
										<div className="flex flex-col w-full">
											<CommonLabel labelText="Select Currency" required />
											<div
												className="w-full overflow-auto"
												{...events}
												ref={ref}
											>
												<div className="w-full relative">
													<div className="flex gap-5 w-full lg:mt-3 mt-1">
														{pricePerDenomination?.map(
															(
																value: { denomination: number; price: number },
																index
															) => (
																<div
																	key={value?.denomination}
																	onClick={() => currencyHandleChange(index)}
																	className="font-normal text-2xl flex justify-center items-center rounded-lg border cursor-pointer"
																	style={{
																		border: `1px solid 
                                    									${
																												setOfFour?.currency?.includes(
																													index
																												)
																													? BLACK
																													: BORDERCOLOR
																											}`,
																	}}
																>
																	<div
																		className="border lg:px-2 lg:py-1 px-2 py-1.5 text-2xl rounded-lg gap-0.5 flex lg:w-[152px] w-[136px] lg:h-[72px] h-16 flex-col"
																		style={{
																			border: `3px solid 
                                     				 							${
																														setOfFour?.currency?.includes(
																															index
																														)
																															? BLACK
																															: TRANSPARENT
																													}`,
																		}}
																	>
																		<div className="flex gap-2 w-full items-center">
																			{setOfFour.currency.includes(index) ? (
																				<RupeeSelected
																					size={
																						screenDetails.isExistScreen.lg
																							? '20'
																							: '24'
																					}
																				/>
																			) : (
																				<RupeeUnselected
																					size={
																						screenDetails.isExistScreen.lg
																							? '20'
																							: '24'
																					}
																				/>
																			)}
																			<span className="lg:text-2xl text-base lg:leading-8 ">
																				₹{value.denomination}
																			</span>
																		</div>
																		<div
																			className="lg:text-base text-sm"
																			style={{ color: GRAY10 }}
																		>
																			<span>Price: </span>
																			<span>&#8377;{value?.price}/-</span>
																		</div>
																	</div>
																</div>
															)
														)}
													</div>
												</div>
											</div>

											{errors?.denomination && (
												<ErrorMessage message={errors?.denomination?.message} />
											)}
											{showSetOfAllMessage && (
												<div className="flex gap-1 pt-2">
													<span
														className=" text-base"
														style={{ color: TEXTCOLORGRAY }}
													>
														If you want more than five currency than go for
													</span>
													<span
														className=" text-base font-bold cursor-pointer underline"
														onClick={openSetOfAllModal}
													>
														Set of all
													</span>
												</div>
											)}
										</div>
									</div>

									<div>
										<Frame
											setIsSelected={setIsSelected}
											isSelected={isSelected}
											setSelectedFrame={setSelectedFrame}
											typeEdit={typeEdit}
											editData={edittableData}
											resetAll={resetAll}
										/>
									</div>

									{layout && (
										<div>
											<BackGround
												setSetOfFour={setSetOfFour}
												backGroundImages={backGroundImages}
												setBackGroundImages={setBackGroundImages}
												backGround={backGround}
												bgData={bgData}
												setSelectedFrameBackground={setSelectedFrameBackground}
												typeEdit={typeEdit}
												editData={edittableData}
												resetAll={resetAll}
											/>
										</div>
									)}
									<div>
										<div>
											<CommonLabel labelText="Images" />
											<UserImageInput
												setSetOfFour={setSetOfFour}
												setOfFour={setOfFour}
												edittableData={edittableData}
												id={id}
												errors={errors}
												typeEdit={typeEdit}
												setIsImageDelete={setIsImageDelete}
												isImageDelete={isImageDelete}
												formEl={{ setValue, clearErrors }}
												resetAll={resetAll}
											/>
										</div>
									</div>

									<div>
										<div className="flex justify-between">
											<CommonLabel
												labelText={`${
													layout ? 'Short Message' : 'Name Of Reciver'
												}`}
												required
											/>
											<NumberTag number={1} />
										</div>
										<div
											className="rounded-lg bg-white appearance-none focus:outline-none text-gray-900 text-sm block w-full lg:mt-3 mt-1 overflow-hidden"
											style={{ border: `1px solid ${BORDERCOLOR}` }}
										>
											<input
												type="text"
												maxLength={100}
												value={fiveNotesData.name}
												{...register('name')}
												onChange={(event) => {
													event.target.value = (
														event.target.value || ''
													).trimStart();
													setFiveNotesData({
														...fiveNotesData,
														name: event.target.value,
													});
													register('name').onChange(event);
												}}
												className="lg:py-3 lg:px-4 p-3 w-full lg:text-lg text-sm lg:leading-8 leading-6 focus:outline-none"
												placeholder="Write your message"
											/>
										</div>
										{errors?.name &&
											(dirtyFields.name || touchedFields.name) && (
												<ErrorMessage message={errors?.name?.message} />
											)}
									</div>

									<div>
										<div className="flex justify-between">
											<CommonLabel
												labelText={`${
													layout ? 'Long Message' : 'Short Message'
												}`}
												required
											/>
											<NumberTag number={2} />
										</div>
										{layout ? (
											<div className="rounded-lg bg-white border border-gray-300 text-gray-900 text-sm block w-full lg:mt-3 mt-1 overflow-hidden h-[120px]">
												<textarea
													maxLength={isImageDelete ? 300 : 100}
													value={fiveNotesData.text}
													{...register('text')}
													onChange={(event) => {
														event.target.value = (
															event.target.value || ''
														).trimStart();
														setFiveNotesData({
															...fiveNotesData,
															text: event.target.value,
														});
														register('text').onChange(event);
													}}
													placeholder="Write your message"
													cols={30}
													rows={4}
													className="outline-none w-full lg:py-3 lg:px-4 lg:text-lg lg:leading-8 p-3 text-sm leading-6 focus:outline-none resize-none"
												></textarea>
											</div>
										) : (
											<div className="rounded-lg bg-white border border-gray-300 appearance-none focus:outline-none text-gray-900 text-sm block w-full lg:mt-3 mt-1 overflow-hidden">
												<input
													type="text"
													maxLength={isImageDelete ? 300 : 100}
													{...register('text')}
													value={fiveNotesData.text}
													onChange={(event) => {
														event.target.value = (
															event.target.value || ''
														).trimStart();
														setFiveNotesData({
															...fiveNotesData,
															text: event.target.value,
														});
														register('text').onChange(event);
													}}
													className="lg:py-3 lg:px-4 w-full lg:text-lg lg:leading-8 p-3 text-sm leading-6 focus:outline-none"
													placeholder="Enter title"
												/>
											</div>
										)}

										{errors?.text &&
											(dirtyFields.text || touchedFields.text) && (
												<ErrorMessage message={errors?.text?.message} />
											)}
									</div>

									{layout && (
										<div>
											<div>
												<div className="flex justify-between">
													<CommonLabel labelText="Message For Note" required />
													<NumberTag number={3} />
												</div>

												<div className="rounded-lg bg-white border border-gray-300 appearance-none focus:outline-none text-gray-900 text-sm block w-full lg:mt-3 mt-1 overflow-hidden">
													<input
														type="text"
														maxLength={50}
														value={fiveNotesData.title}
														{...register('title')}
														onChange={(event) => {
															event.target.value = (
																event.target.value || ''
															).trimStart();
															setFiveNotesData({
																...fiveNotesData,
																title: event.target.value,
															});
															register('title').onChange(event);
														}}
														className="lg:py-3 lg:px-4 w-full lg:text-lg lg:leading-8 p-3 text-sm leading-6 focus:outline-none"
														placeholder="Write your message"
													/>
												</div>
												{errors?.title &&
													(dirtyFields.title || touchedFields.title) && (
														<ErrorMessage message={errors?.title?.message} />
													)}
											</div>
										</div>
									)}
								</div>
								<div className="lg:hidden block lg:mt-10 mt-6">
									<AddToBag
										setOfFour={setOfFour}
										isSubmitValid={isSubmitValid}
										loading={loading}
										id={id}
										typeEdit={typeEdit}
										setOfFourData={setOfFourData}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
