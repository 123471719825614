import React from 'react';
import { CommonSvgProps } from '../../Interface/Index';

interface DeleteProps extends CommonSvgProps {
	color?: string;
}

const Delete: React.FC<DeleteProps> = ({
	onClick,
	style,
	width = '24',
	color = 'black',
}) => {
	return (
		<div onClick={onClick} className={style}>
			<svg
				width={width}
				height={width}
				viewBox="0 0 20 20"
				fill="none"
				cursor="pointer"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M5.43766 17.5C5.09391 17.5 4.79964 17.3776 4.55485 17.1328C4.31006 16.888 4.18766 16.5938 4.18766 16.25V4.375H3.3335V3.125H7.25016V2.5H12.7502V3.125H16.6668V4.375H15.8127V16.25C15.8127 16.5833 15.6877 16.875 15.4377 17.125C15.1877 17.375 14.896 17.5 14.5627 17.5H5.43766ZM14.5627 4.375H5.43766V16.25H14.5627V4.375ZM7.646 14.4583H8.896V6.14583H7.646V14.4583ZM11.1043 14.4583H12.3543V6.14583H11.1043V14.4583Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};

export default Delete;
