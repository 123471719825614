import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addNewGift, getAddToCart, addToCart } from '../Api/UserListApi';

export type CartAdd = {
	type?: string;
	frameId?: string;
	occassion?: string;
	shortMsg?: string;
	longMsg?: string;
	msgForNote?: string;
	date?: Date;
	denomination?: string[];
	dataUrl?: string;
	productId?: string;
};
export type CartList = {
	loading: boolean;
	cartList: string[];
	cartAdd: CartAdd;
	addToCartAPI: () => void;
	limitedCartApi: () => void;
	limitedCart: string[];
	tableData: {
		count: number;
	};
};

const tableData = {
	count: 0,
};
export const initialState: CartList = {
	cartAdd: {
		type: '',
		frameId: '',
		occassion: '',
		shortMsg: '',
		longMsg: '',
		msgForNote: '',
		date: new Date(),
		denomination: [],
		dataUrl: '',
		productId: '',
	},
	addToCartAPI: () => {},
	limitedCartApi: () => {},
	cartList: [],
	limitedCart: [],
	loading: false,
	tableData: tableData,
};

export const getAllAddToCart = createAsyncThunk('/addToCart', async () => {
	try {
		const { data } = await getAddToCart();
		return data?.data;
	} catch (error) {
		if (
			(error as { response: string }).response &&
			(error as { response: { status: number } }).response.status === 401
		) {
			window.location.href = '/login';
		}
		console.log(error);
		throw error;
	}
});

export const addToCartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		allNoteCart: (state, action) => {
			state.cartAdd = action.payload;
			state.addToCartAPI = () => addNewGift(action.payload);
		},
		limitedEdition: (state, action) => {
			state.cartAdd = action.payload;
			state.limitedCartApi = () => addToCart(action.payload);
		},
		resetCartCount: (state) => {
			state.tableData.count = 0;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllAddToCart.fulfilled, (state, action) => {
				state.cartList = action?.payload?.rows;
				state.tableData.count = action?.payload?.count;
				state.loading = false;
			})
			.addCase(getAllAddToCart.pending, (state) => {
				state.loading = true;
			});
	},
});

// Action creators are generated for each case reducer function
export const { resetCartCount, allNoteCart, limitedEdition } =
	addToCartSlice.actions;

export default addToCartSlice.reducer;
