import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../Components/Button/CommonButton';
import { ROUTES } from '../../Constant/Routes.Constant';
import { navJson, notLoggedIn } from '../../Constant/Constant';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { setUser, setUserToken } from '../../store/UserSlice';
import { NavItem } from '../../Interface/Index';
import {
	BLACK,
	RED100,
	TEXTCOLORGRAY,
	WHITE,
	WHITE_SMOKE,
} from '../../Constant/Color.Constant';
import { fetchLimitedEditionDate } from '../../store/CountDownSlice';
import ShoopingBag from '../../Components/Svg/ShoopingBag';
import Person from '../../Components/Svg/Person';
import Profile from '../../Components/Svg/Profile';
import Menu from '../../Components/Svg/Menu';
import Remove from '../../Components/Svg/Remove';
import Drawer from '../../Components/Drawer/Drawer';
import useMobileMediaQuery from '../../utils/useMobileMediaQuery';
import Gift from '../../Components/Svg/Gift';
import moment from 'moment';
import Image from '../../Components/Image/Image';

interface NavBarProps {
	isDrawerOpen: boolean;
	setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
	toggleDrawer: () => void;
	isRightSidebarOpen: boolean;
}

const NavBar: React.FC<NavBarProps> = ({
	isDrawerOpen,
	setIsDrawerOpen,
	toggleDrawer,
	isRightSidebarOpen,
}) => {
	const drawer = document.getElementById('drawer-right-exmple');
	const [shouldShowLimitedEdition, setShouldShowLimitedEdition] =
		useState<boolean>(false);

	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const navigate = useNavigate();
	const dropdownRef = useRef<HTMLDivElement | null>(null);
	const currentPath = window.location.pathname;
	const { isMobile } = useMobileMediaQuery();

	const date = new Date();

	const badge = useSelector(
		(state: { cart: { tableData: { count: number } } }) =>
			state?.cart?.tableData.count
	);
	const limitedEditionDate = useAppSelector(
		(state: any) => state?.countdown?.endDate?.data?.limitedEditionDate
	);
	const dispatch = useAppDispatch();

	const handleClickToNavigate = (route: string, storeReset = false) => {
		if (storeReset) {
			dispatch(setUserToken(''));
			dispatch(
				setUser({
					id: '',
					name: '',
					role: '',
					email: '',
				})
			);

			localStorage.removeItem('ACCESSTOKEN');
		}

		navigate(route);
		setIsDrawerOpen(false);
		if (drawer) {
			drawer.style.transform = '';
		}
	};

	const getStoreData = useSelector(
		(state: { user: { token: string } }) => state.user.token
	);

	const handleLogIn = () => {
		navigate(`${ROUTES.LOGIN}`);
	};

	const handleHomePage = () => {
		navigate(`${ROUTES.HOME_PAGE}`);
	};

	const handelMenuDrawer = () => {
		if (isDrawerOpen) {
			setIsDrawerOpen(false);
		} else {
			setIsDrawerOpen(true);
		}
	};
	const fetchData = async () => {
		try {
			await dispatch(fetchLimitedEditionDate());
		} catch (error) {
			console.error('Error fetching countdown data:', error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		const offerDate = moment(limitedEditionDate);
		const todaysDate = moment(date);

		if (offerDate < todaysDate) {
			setShouldShowLimitedEdition(false);
		} else {
			setShouldShowLimitedEdition(true);
		}
	}, [limitedEditionDate]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				isDropdownOpen &&
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setDropdownOpen(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isDropdownOpen]);

	const handleOptionClick = () => {
		navigate(ROUTES.PROFILE);
		setDropdownOpen(false);
		setIsDrawerOpen(false);
	};

	return (
		<div className="limitedEdition">
			<div>
				<Drawer toggleDrawer={toggleDrawer} isOpen={isRightSidebarOpen} />
			</div>
			{isDrawerOpen && (
				<div
					onClick={handelMenuDrawer}
					className="fixed top-0 h-full w-full bg-opacity-70"
					style={{
						background: BLACK,
					}}
				/>
			)}
			<div
				id="drawer-right-exmple"
				className={`lg:hidden fixed top-0 right z-[400000] h-max overflow-scroll transition-transform  ${
					isDrawerOpen ? 'translate-y-10' : '-translate-y-full'
				} w-full drawer-bottom-example flex `}
				style={{
					background: WHITE,
				}}
				tabIndex={-1}
				aria-labelledby="drawer-right-label"
			>
				{getStoreData && isDrawerOpen ? (
					<div className="h-full w-full">
						<div className="flex flex-col md:flex-col md:gap-10 text-xl sm:pt-20 pt-7 divide-y divide-[#E5E7EB]">
							{navJson.map((e: NavItem, index: number) => (
								<div
									key={index}
									className={`cursor-pointer text-base leading-10 px-4 py-1.5 flex items-center gap-5 ${
										currentPath === e.route
											? 'text-black font-semibold'
											: 'text-gray-500'
									} ${
										e.showLimited && !shouldShowLimitedEdition ? 'hidden' : ''
									}`}
								>
									<p
										onClick={() =>
											handleClickToNavigate(e.route, e?.storeReset)
										}
									>
										{e.name}
									</p>
								</div>
							))}
						</div>
						<div className="flex justify-center gap-4 p-4 text-sm">
							<div
								className="flex  w-2/4 nd:w-full justify-center items-center border rounded-xl relative  gap-2 p-2"
								onClick={() => {
									toggleDrawer();
									setIsDrawerOpen(false);
								}}
							>
								<ShoopingBag height="20px" width="20px" />
								<button className="uppercase font-medium hidden nd:block">
									Your cart
								</button>
								{badge > 0 && (
									<span
										className="absolute -top-0 -right-2.5 bg-red-500 h-5 w-5 flex items-center justify-center text-xs transform -translate-y-2/4 rounded-full"
										style={{
											color: WHITE,
										}}
									>
										{badge}
									</span>
								)}
							</div>
							<div
								className="flex gap-2 justify-center items-center p-2 border rounded-xl w-2/4 nd:w-full"
								onClick={() => handleOptionClick()}
							>
								<Profile height="20px" width="20px" />
								<button className="uppercase font-medium hidden nd:block">
									Your Profile
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="h-full w-full">
						<div className="flex flex-col md:flex-col md:gap-10 text-xl sm:pt-20 pt-7 divide-y divide-[#E5E7EB]">
							{notLoggedIn.map((e: NavItem, index: number) => (
								<div
									key={index}
									className={`cursor-pointer text-base leading-10 px-4 py-1.5 flex items-center gap-5 ${
										currentPath === e.route
											? 'text-black font-semibold'
											: 'text-gray-500'
									}`}
								>
									<p
										onClick={() =>
											handleClickToNavigate(e.route, e?.storeReset)
										}
									>
										{e.name}
									</p>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
			<div
				className="flex max-w-[2500px] mx-auto w-full  xl:px-24 sm:px-5 md:px-5 xlg:px-24 lg:px-5 p-4 items-center !sticky !top-0 z-[700000] min-h-[72px] sm:min-h-[112px] border border-red"
				style={{ background: WHITE_SMOKE }}
			>
				<div className="flex flex-col md:flex-row sm:flex-row xs:flex-row items-center justify-between w-full">
					<div className="cursor-pointer sm:w-[176px] sm:h-8 w-[132px] h-6">
						<Image
							image="/TransparentDark.png"
							altName="Logo"
							width="100%"
							height="100%"
							onClick={handleHomePage}
						/>
					</div>
					<div className="xs:hidden xlg:gap-6 lg:gap-[18px] gap-6 lg:flex flex flex-row  items-center sm:justify-center sm:hidden md:hidden">
						<div className="flex xlg:gap-10 lg:gap-9 gap-10 items-center justify-center">
							<div className="flex flex-row   xlg:gap-12 lg:gap-7  ml-5 xl:text-xl lg:text-xl md:text-xl sm:text-base">
								{navJson.map((e, index: number) => (
									<p
										key={index}
										onClick={() => handleClickToNavigate(e.route)}
										className={`${
											currentPath.split('/')[1] === e.route.split('/')[1]
												? 'text-black font-semibold cursor-pointer'
												: 'text-gray-500 cursor-pointer'
										} ${
											e.showLimited && !shouldShowLimitedEdition ? 'hidden' : ''
										}`}
										style={
											currentPath.split('/')[1] === e.route.split('/')[1]
												? { color: BLACK, fontWeight: '500' }
												: { color: TEXTCOLORGRAY }
										}
									>
										{e.name}
										{e.showLimited && !shouldShowLimitedEdition ? '' : null}
									</p>
								))}
							</div>
							<div>
								{currentPath === ROUTES.NEW_GIFT ? (
									''
								) : (
									<div>
										{isMobile ? (
											<>
												<button
													onClick={() => handleOptionClick()}
													className="px-0 min-w-[50px] h-[50px] border rounded-lg flex justify-center items-center"
													style={{
														background: WHITE,
														borderColor: '#e5e7eb',
													}}
												>
													<div>
														<Gift />
													</div>
												</button>
											</>
										) : (
											<>
												<CommonButton
													onClick={() =>
														handleClickToNavigate(ROUTES.NEW_GIFT_LAYOUTS)
													}
													label="NEW GIFT"
													fontSize="16px"
													padding="12px 32px"
													height="48px"
												/>
											</>
										)}
									</div>
								)}
							</div>
						</div>
						{!getStoreData ? (
							<div>
								{currentPath === ROUTES.NEW_GIFT
									? ''
									: currentPath !== ROUTES.LOGIN && (
											<CommonButton
												onClick={handleLogIn}
												width="100%"
												fontSize="16px"
												label="Log In"
											/>
									  )}
							</div>
						) : (
							<div
								className="flex sm:flex xlg:gap-6 lg:gap-[18px] gap-6"
								ref={dropdownRef}
							>
								<button
									className="px-0 min-w-[50px] border rounded-lg flex justify-center items-center relative"
									style={{ background: '#ffffff', borderColor: '#e5e7eb' }}
									onClick={handleOptionClick}
								>
									<Person height="32px" width="32px" />
								</button>
								<button
									className="h-[50px] min-w-[50px] rounded-lg border relative overflow-visible hover:bg-gray-50 hover:border-gray-300 flex justify-center items-center"
									style={{ background: WHITE, borderColor: '#e5e7eb' }}
									onClick={toggleDrawer}
								>
									<ShoopingBag height="32px" width="32px" />
									{badge > 0 && (
										<span
											className="absolute -top-1/4 -right-2.5 h-5 w-5 flex items-center justify-center text-xs transform -translate-y-1/5 rounded-full"
											style={{ color: WHITE, background: RED100 }}
										>
											{badge}
										</span>
									)}
								</button>
							</div>
						)}
					</div>
					<div className="flex-row gap-4 flex sm:flex lg:hidden md:flex xl:hidden justify-center items-center">
						<div
							onClick={() => handleClickToNavigate(ROUTES.NEW_GIFT_LAYOUTS)}
							className="nd:hidden"
						>
							<Gift />
						</div>
						<div className="nd:block hidden">
							<CommonButton
								onClick={() => handleClickToNavigate(ROUTES.NEW_GIFT_LAYOUTS)}
								width="103px"
								fontSize="14px"
								label="NEW GIFT"
								padding="5px 8px"
							/>
						</div>
						<div className="sm:flex flex justify-center items-center">
							<button
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								color="inherit"
								onClick={handelMenuDrawer}
								className="relative"
							>
								{isDrawerOpen ? (
									<Remove width="24px" height="24px" />
								) : (
									<>
										<Menu size={24} />
										{badge > 0 && (
											<span
												className="absolute -top-1 -right-2 bg-red-500 h-5 w-5 flex items-center justify-center text-xs transform -translate-y-[15%] rounded-full"
												style={{
													color: WHITE,
												}}
											>
												{badge}
											</span>
										)}
									</>
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavBar;
