import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import OfferModal from './OfferModal/OfferModal';
import Sliders from './Slider/Slider';
import NoteLayouts from './NotesLayouts/NoteLayouts';
import Index from '../../Components/NoteForSale/NoteForSell';
import ExclusiveNotes from './ExclusiveNotes/ExclusiveNotes';
import OrdinaryGifts from './OrdinaryGifts/OrdinaryGifts';
import HomeFaqs from './HomeFaqs/HomeFaqs';
import LimitedEditionNotes from './LimitedEditionNotes/LimitedEditionNotes';
import SEO from '../../Components/Helmet/SEO';
import { HOME } from '../../Constant/Seo.Constant';
import Modal from '../../Components/Model/Modal';
import { incrementModalCount } from '../../store/ModalCountSlice';
import { useAppSelector } from '../../store/store';
import Wheel from '../../Components/Svg/Wheel';
import Spinner from './Spinner';
import { getSpinConfig } from '../../Api/UserListApi';

const HomePage = () => {
	const [isCommonCardModalOpen, setIsCommonCardModalOpen] = useState(true);
	const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);
	const [isExpired, setIsExpired] = useState(false);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const modalOpenCount = useAppSelector(
		(state) => state.modalCount.modalOpenCount
	);
	const fetchData = async () => {
		try {
			const res = await getSpinConfig();
			res.data.isExpired && setIsExpired(res.data.isExpired);
		} catch (error) {
			console.error('Error fetching spin config:', error);
		} finally {
			setLoading(false);
		}
	};

	const closeModal = () => {
		setIsCommonCardModalOpen(false);
		setIsSpinnerModalOpen(false);
		fetchData();
	};

	const handleRemove = () => {
		closeModal();
	};
	const openModal = () => {
		setIsSpinnerModalOpen(true);
	};
	useEffect(() => {
		if (modalOpenCount <= 3) {
			setIsCommonCardModalOpen(true);
			dispatch(incrementModalCount());
		} else {
			setIsCommonCardModalOpen(false);
		}
	}, [dispatch]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (modalOpenCount < 3) {
				setIsCommonCardModalOpen(true);
				dispatch(incrementModalCount());
			}
		}, 3000);
		return () => clearTimeout(timeoutId);
	}, [dispatch]);

	useEffect(() => {
		fetchData();
	}, []);
	return (
		<>
			<SEO title="Home" description={HOME} />
			<div>
				<>
					{isCommonCardModalOpen && (
						<Modal isOpen={isCommonCardModalOpen} onClose={closeModal}>
							<OfferModal onRemove={handleRemove} />
						</Modal>
					)}
				</>
				<Sliders />
				<NoteLayouts />
				<LimitedEditionNotes />

				{!isExpired && !loading && (
					<div
						className=" z-[10000] right-8 xs:right-[1.467rem] xs:bottom-3  flex items-center justify-center bottom-12 fixed rounded-full sm:w-[120px] sm:h-[120px] xs:h-20 xs:w-20 cursor-pointer"
						onClick={openModal}
					>
						<Wheel />
					</div>
				)}

				{isSpinnerModalOpen && (
					<Modal
						isOpen={isSpinnerModalOpen}
						onClose={closeModal}
						modalClasses="max-w-[620px] w-full"
					>
						<Spinner onClose={closeModal} />
					</Modal>
				)}
				<Index />
				<ExclusiveNotes />
				<Index />
				<OrdinaryGifts />
				<HomeFaqs />
			</div>
		</>
	);
};

export default HomePage;
