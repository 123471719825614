export const THE_RUPI = 'The Rupi';
export const LOGIN = 'Login';
export const SIGN_UP = 'Sign Up';
export const RESET_PASSWORD = ' Reset Password';
export const FORGOT_PASSWORD = 'Forget Password';
export const ALREADY_HAVE_AN_ACCOUNT = 'Already have an account?';
export const DONT_HAVE_AN_ACCOUNT = ' Do not have an Account?';
export const SEND_US_MESSAGE = ' SEND US A MESSAGE';
export const OCCASION = 'Occasion';
export const ADD_NEW_ADDRESS = 'Add a new address';
export const CONTACT_NUMBER = '+91 9512596662';
export const EMAIL = 'admin@therupi.com';
export const LOCATION = '6391 Elgin St. Celina, Delaware 10299';
export const INSTRAGRAM_URL = 'https://www.instagram.com/gift_to_give_/?hl=en';
export const FACEBOOK_URL = '';
export const TWITTER_URL = '';
