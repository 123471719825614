export const USER_IMAGE_MALE = '/Images/UserImage.jpg';
export const USER_IMAGE_FEMALE = '/Images/userImage2.jpeg';

export const SINGLE_NOTE_FRAME_BACKGROUND = '/Images/texture.jpg';

export const SET_OF_FIVE_FRAME_BACKGROUND = USER_IMAGE_MALE;

export const SET_OF_FOUR_FRAME_BACKGROUND = '/Images/texture.jpg';

export const SET_OF_FOUR_DEFAULT_DATES = [
	'02-12-2001',
	'02-12-2001',
	'02-12-2001',
	'02-12-2001',
];

export const SET_OF_FOUR_FRAME_DATA = {
	set1: {
		currency: '1',
		price: 0,
		date: '',
		message: 'he was born',
		image: USER_IMAGE_MALE,
	},
	set2: {
		currency: '1',
		price: 0,
		date: '',
		message: 'she was born',
		image: USER_IMAGE_FEMALE,
	},
	set3: {
		currency: '1',
		price: 0,
		date: '',
		message: 'they got engaged',
		image: USER_IMAGE_FEMALE,
	},
	set4: {
		currency: '1',
		price: 0,
		date: '',
		message: 'they got marride',
		image: USER_IMAGE_FEMALE,
	},
};

export const LAYOUT_ONE_DEFAULT_TEXT = {
	title: 'A Note Reflecting Your Special Date 02-12-2001',
	name: 'Manhar udhas',
	text: 'The legend of music',
	occassion: 'Happy Birthday',
};

export const NO_FOUND = {
	currency: 'select your currency',
	userImage: 'please select your image first.',
};

export const CART_PRODUCT_NAMES: any = {
	single: 'single note frame',
	multiple: 'multiple note frame',
	sets: 'set of images',
};

export const PRODUCT_IMAGES: Record<string, Record<string, string>> = {
	SINGLE: {
		name: 'single note product',
		url: '/Images/LayoutOne.png',
		alt: 'first-layout',
	},
	MULTIPLE: {
		name: 'multiple notes product',
		url: '/Images/LayoutTwo.png',
		alt: 'second-layout',
	},
	SETS: {
		name: 'set of note product',
		url: '/Images/LayoutTwo.png',
		alt: 'second-layout',
	},
};

export const FRAME_TEXT_LENGTH = {
	IsImageExit: {
		occasion: 20,
		shotMSG: 100,
	},
	IsImageNotexist: {},
};
