import React from 'react';

const CuoponCode = ({
	winningPercentage,
}: {
	winningPercentage: number | null;
}) => {
	return (
		<div className="flex justify-center items-center h-full">
			<b className="relative sm:w-[256px] sm:h-20 w-[192px] h-[60px] sm:text-2xl text-lg">
				<svg
					width="100%"
					height="100%"
					viewBox="0 0 256 82"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						id="Vector"
						d="M1.98629 9.43069L12.112 1.43069C12.465 1.15174 12.9019 1 13.3518 1H242.648C243.098 1 243.535 1.15174 243.888 1.43069L254.014 9.43069C255.027 10.2314 255.027 11.7686 254.014 12.5693L245.329 19.4307C244.316 20.2314 244.316 21.7686 245.329 22.5693L254.014 29.4307C255.027 30.2314 255.027 31.7686 254.014 32.5693L245.329 39.4307C244.316 40.2314 244.316 41.7686 245.329 42.5693L254.014 49.4307C255.027 50.2314 255.027 51.7686 254.014 52.5693L245.329 59.4307C244.316 60.2314 244.316 61.7686 245.329 62.5693L254.014 69.4307C255.027 70.2314 255.027 71.7686 254.014 72.5693L243.888 80.5693C243.535 80.8483 243.098 81 242.648 81H13.3518C12.9019 81 12.465 80.8483 12.112 80.5693L1.98629 72.5693C0.972778 71.7686 0.97278 70.2314 1.98629 69.4307L10.6708 62.5693C11.6843 61.7686 11.6843 60.2314 10.6708 59.4307L1.98629 52.5693C0.972778 51.7686 0.972777 50.2314 1.98629 49.4307L10.6708 42.5693C11.6843 41.7686 11.6843 40.2314 10.6708 39.4307L1.98629 32.5693C0.972778 31.7686 0.97278 30.2314 1.98629 29.4307L10.6708 22.5693C11.6843 21.7686 11.6843 20.2314 10.6708 19.4307L1.98629 12.5693C0.972778 11.7686 0.97278 10.2314 1.98629 9.43069Z"
						stroke="#D9DFE9"
						strokeWidth="2"
					/>
				</svg>
				<span
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						color: '#58648C',
						wordSpacing: '3.84px',
						lineHeight: '32px',
						fontWeight: 700,
					}}
				>
					{winningPercentage !== null ? `CODE${winningPercentage}` : ''}
				</span>
			</b>
		</div>
	);
};

export default CuoponCode;
