import React from 'react';
import { CommonSvgProps } from '../../Interface/Index';

const Person: React.FC<CommonSvgProps> = ({
	height = '20px',
	width = '20px',
}) => {
	return (
		<div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width}
				height={height}
				viewBox="0 0 32 32"
				fill="none"
			>
				<path
					d="M15.9999 15.9668C14.5333 15.9668 13.3333 15.5001 12.3999 14.5668C11.4666 13.6335 10.9999 12.4335 10.9999 10.9668C10.9999 9.50013 11.4666 8.30013 12.3999 7.3668C13.3333 6.43346 14.5333 5.9668 15.9999 5.9668C17.4666 5.9668 18.6666 6.43346 19.5999 7.3668C20.5333 8.30013 20.9999 9.50013 20.9999 10.9668C20.9999 12.4335 20.5333 13.6335 19.5999 14.5668C18.6666 15.5001 17.4666 15.9668 15.9999 15.9668ZM5.33325 26.6668V23.5335C5.33325 22.689 5.54436 21.9668 5.96659 21.3668C6.38881 20.7668 6.93325 20.3112 7.59992 20.0001C9.08881 19.3335 10.5166 18.8335 11.8833 18.5001C13.2499 18.1668 14.6221 18.0001 15.9999 18.0001C17.3777 18.0001 18.7444 18.1724 20.0999 18.5168C21.4555 18.8612 22.8768 19.3578 24.364 20.0066C25.0595 20.3205 25.6172 20.7757 26.037 21.3721C26.4567 21.9686 26.6666 22.689 26.6666 23.5335V26.6668H5.33325ZM7.33325 24.6668H24.6666V23.5335C24.6666 23.1779 24.561 22.839 24.3499 22.5168C24.1388 22.1946 23.8777 21.9557 23.5666 21.8001C22.1444 21.1112 20.8444 20.639 19.6666 20.3835C18.4888 20.1279 17.2666 20.0001 15.9999 20.0001C14.7333 20.0001 13.4999 20.1279 12.2999 20.3835C11.0999 20.639 9.79992 21.1112 8.39992 21.8001C8.08881 21.9557 7.83325 22.1946 7.63325 22.5168C7.43325 22.839 7.33325 23.1779 7.33325 23.5335V24.6668ZM15.9999 13.9668C16.8666 13.9668 17.5833 13.6835 18.1499 13.1168C18.7166 12.5501 18.9999 11.8335 18.9999 10.9668C18.9999 10.1001 18.7166 9.38346 18.1499 8.8168C17.5833 8.25013 16.8666 7.9668 15.9999 7.9668C15.1333 7.9668 14.4166 8.25013 13.8499 8.8168C13.2833 9.38346 12.9999 10.1001 12.9999 10.9668C12.9999 11.8335 13.2833 12.5501 13.8499 13.1168C14.4166 13.6835 15.1333 13.9668 15.9999 13.9668Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};

export default Person;
