import CommonLabel from '../../Components/InputLable/lable';
import CommonButton from '../../Components/Button/CommonButton';
import { useForm } from 'react-hook-form';
import { ResetType } from '../../Api/Types';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../Constant/Routes.Constant';
import { RESET_PASSWORD } from '../../Constant/KeyWord.Constant';
import { resertPassword } from '../../Api/UserListApi';
import { CustomTextField } from '../../Components/Input/TextField';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordValidationSchema } from '../../Components/Validataions/ValidataionSchema';
import { TEXTCOLORGRAY, WHITE } from '../../Constant/Color.Constant';
import { useState } from 'react';
import { ErrorToaster, SuccessToaster } from '../../helpers/CustomToaster';
import SEO from '../../Components/Helmet/SEO';
import { RESET_PASSWORD_DESCRIPTION } from '../../Constant/Seo.Constant';

const initialValue = {
	password: '',
	confirmPassword: '',
};

const ResetPassword = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const { token } = useParams();
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(ROUTES.LOGIN);
	};
	const onSubmit = async (data: ResetType) => {
		setLoading(true);

		const { error, message } = await resertPassword({
			password: data.password,
			token: token as string,
		});
		if (error) {
			ErrorToaster(message);
		} else {
			SuccessToaster(message);
			navigate(ROUTES.HOME_PAGE);
		}
		reset();
		setLoading(false);
	};

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<ResetType>({
		mode: 'all',
		defaultValues: initialValue,
		resolver: yupResolver(resetPasswordValidationSchema as any),
	});
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<SEO title="Reset Password" description={RESET_PASSWORD_DESCRIPTION} />
			<div className="flex justify-center items-center flex-col xs:h-[calc(100vh-80px)] lg:h-[calc(100vh-168px)] bg-gray-100 z-0 ">
				<div className="px-4 sm:max-w-[576px] w-full">
					<div
						className="lg:p-8 md:p-8 sm:p-8 xs:p-4 w-full shadow-md rounded-2xl gap-8 flex flex-col"
						style={{
							background: WHITE,
						}}
					>
						<div>
							<div className="font-bold text-3xl m-2 ml-0 mb-4">
								{RESET_PASSWORD}
							</div>
							<div className="flex justify-center flex-col gap-6">
								<div className="flex flex-col lg:gap-3 xs:gap-1">
									<CommonLabel labelText="Password" />
									<CustomTextField
										name="password"
										register={register}
										placeholder=" Enter your Password"
										type="password"
									/>
									{errors?.password && (
										<ErrorMessage
											message={errors?.password?.message as string}
										/>
									)}
								</div>

								<div className="flex flex-col lg:gap-3 xs:gap-1">
									<CommonLabel labelText=" Confirm Password" />
									<CustomTextField
										name="confirmPassword"
										register={register}
										placeholder=" Enter your confirm password"
										type="password"
									/>
									{errors?.confirmPassword && (
										<ErrorMessage
											message={errors?.confirmPassword?.message as string}
										/>
									)}
								</div>
							</div>
						</div>

						<div className="flex justify-center items-center">
							<CommonButton
								type="submit"
								width="100%"
								label="Reset Password"
								padding="16px 32px"
								loading={loading}
								className="flex justify-center items-center gap-2 rounded-xl text-white text-xl leading-9 uppercase font-semibold h-16"
							/>
						</div>
						<div className="w-full flex justify-between flex-col lg:flex-row gap-1">
							<div className="flex gap-1 lg:text-lg sm:text-base md:text-lg leading-8">
								<p className="" style={{ color: TEXTCOLORGRAY }}>
									Do you have an Account?
								</p>
								<div onClick={handleClick}>
									<p className="cursor-pointer underline font-semibold  ">
										Login
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default ResetPassword;
