import React from "react";
import { SliderProps } from "../../Interface/Index";


const RightSlider: React.FC<SliderProps> = ({ strokeColor = "black",width= '44px',height='44px' }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 44 44"
        fill="none"
      >
        <path
          d="M16.5 11L27.5 22L16.5 33"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default RightSlider;
