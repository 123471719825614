import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { loginUser } from '../../Api/UserListApi';
import CommonButton from '../../Components/Button/CommonButton';
import { LoginType } from '../../Api/Types';
import { STORAGEKEY } from '../../Constant/Constant';
import { ROUTES } from '../../Constant/Routes.Constant';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CustomTextField } from '../../Components/Input/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginValidationSchema } from '../../Components/Validataions/ValidataionSchema';
import { allNoteCart, getAllAddToCart } from '../../store/CartSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { setUser, setUserToken } from '../../store/UserSlice';
import CommonLabel from '../../Components/InputLable/lable';
import ErrorMessage from '../../Components/Typography/ErrorText';
import {
	TEXTCOLOR,
	TEXTCOLORGRAY,
	WHITE,
	WHITE10,
} from '../../Constant/Color.Constant';
import { ErrorToaster, SuccessToaster } from '../../helpers/CustomToaster';
import SEO from '../../Components/Helmet/SEO';
import { LOGIN } from '../../Constant/Seo.Constant';

declare global {
	interface Window {
		dataLayer: Array<{ event: string; user_email?: string }>;
	}
}

const initialValue = {
	email: '',
	password: '',
};

const Login = () => {
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const accessToken = useSelector(
		(state: { user: { token: string } }) => state.user.token
	);
	const params = searchParams.get(`redirectUrl`);
	const dispatch = useAppDispatch();

	const handleOpen = () => {
		navigate(ROUTES.FORGOT_PASSWORD);
	};
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(ROUTES.REGISTER);
	};
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<LoginType>({
		mode: 'all',
		defaultValues: initialValue,
		resolver: yupResolver(loginValidationSchema),
	});
	const GiftData = useAppSelector((state) => state?.cart);

	const onSubmit = async (data: LoginType) => {
		window.dataLayer.push({ event: 'login', user_email: data.email });
		setLoading(true);

		const { error, data: userData, message } = await loginUser(data);
		if (error) {
			ErrorToaster(message);
			setLoading(false);
		} else {
			localStorage.setItem(STORAGEKEY.ACCESSTOKEN, userData?.access_token);
			dispatch(setUserToken(userData?.access_token));
			dispatch(setUser(userData.user));
			SuccessToaster(message);
			if (params) {
				await GiftData.addToCartAPI();
				navigate(ROUTES.SHOPPING_BAG);
			} else {
				GiftData.addToCartAPI && (await GiftData?.addToCartAPI());
				GiftData.limitedCartApi && (await GiftData?.limitedCartApi());
				dispatch(getAllAddToCart());
				navigate(ROUTES.HOME_PAGE);
				dispatch(allNoteCart({}));
			}
			setLoading(false);
		}
	};
	useEffect(() => {
		if (accessToken) {
			navigate(ROUTES.HOME_PAGE);
		}
	}, [navigate]);

	return (
		<>
			<div className="min-h-[calc(100vh-168px)] flex justify-center items-center bg-[#fafafa]">
				<div
					className="xs:m-4 lg:m-0 rounded-2xl lg:p-8 md:p-8 m-auto sm:p-8 xs:p-3 flex flex-col lg:gap-8 md:gap-8 sm:gap-8 xs:gap-6 sm:max-w-[576px] w-full border"
					style={{ backgroundColor: WHITE, borderColor: WHITE10 }}
				>
					<SEO title="Login" description={LOGIN} />
					<div className="flex flex-col gap-1">
						<p
							className="font-semibold md:text-[32px] text-2xl uppercase leading-[32px]"
							style={{ color: TEXTCOLOR }}
						>
							Login
						</p>
						<p
							className="lg:text-lg xs:text-base leading-6"
							style={{ color: TEXTCOLORGRAY }}
						>
							Log in with your register email
						</p>
					</div>
					<div className="flex flex-col gap-6">
						<div className="flex flex-col lg:gap-3 xs:gap-1">
							<CommonLabel labelText="Email" />
							<CustomTextField
								name="email"
								register={register}
								placeholder="Enter your email"
								type="text"
							/>
							{errors?.email && (
								<ErrorMessage message={errors?.email?.message} />
							)}
						</div>
						<div className="flex flex-col lg:gap-3 xs:gap-1">
							<CommonLabel labelText="Password" />
							<div className="relative">
								<CustomTextField
									name="password"
									height="64px"
									register={register}
									placeholder="Enter your password"
									type="password"
								/>
							</div>
							{errors?.password && (
								<ErrorMessage message={errors?.password?.message} />
							)}
						</div>
					</div>
					<div className="flex flex-col gap-3">
						<div className="flex justify-center items-center ">
							<CommonButton
								type="submit"
								width="100%"
								padding="12px 32px"
								onClick={handleSubmit(onSubmit)}
								label="Login"
								loading={loading}
								className={`${
									!isValid
										? '!bg-[#828282] cursor-not-allowed font-semibold xs:text-base text-xl'
										: '!bg-gray '
								} text-white  py-2 px-4 rounded-xl font-semibold uppercase`}
							/>
						</div>
						<div className="w-full flex justify-between flex-col lg:flex-row gap-1">
							<div className="flex gap-1 sm:text-base md:text-lg leading-8">
								<p className="" style={{ color: TEXTCOLORGRAY }}>
									Do not have an Account?
								</p>
								<div onClick={handleClick}>
									<p className="cursor-pointer underline font-semibold  ">
										SignUp
									</p>
								</div>
							</div>

							<div className="cursor-pointer text-black-500 text-sm">
								<p
									onClick={handleOpen}
									className="cursor-pointer font-bold sm:text-base md:text-lg leading-8"
								>
									Forgot Password
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Login;
