import React from 'react';

const Plus = () => {
	return (
		<div>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="Frame">
					<path
						id="Vector"
						d="M6 12H12M12 12H18M12 12V6M12 12V18"
						stroke="#6B7280"
					/>
				</g>
			</svg>
		</div>
	);
};

export default Plus;
