import React, { ChangeEvent, useEffect, useState } from 'react';
import CommonLabel from '../../../../../Components/InputLable/lable';
import {
	BORDERCOLOR,
	WHITE,
	WHITE_SMOKE,
	GRAY10,
} from '../../../../../Constant/Color.Constant';
import ErrorMessage from '../../../../../Components/Typography/ErrorText';
import ImageUpload from '../../ui/ImageUpload';
import { getPriceByDenomination } from '../../../../../Api/UserListApi';
import NumberTag from '../../../../../Components/Ui/NumberTag';
import Cross from '../../../../../Components/Svg/Cross';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { newGiftSetLayoutValidationSchema } from '../../../../../Components/Validataions/ValidataionSchema';
import CommonDatePicker from '../../../../../Components/DatePicker/DatePicker';
import moment from 'moment';
import { SetState } from '../../../../../Interface/Index';
import {
	GiftTS,
	PricePerDenomination,
	setOfFour,
	SetOfFourData,
} from '../../../../../Interface/NewGiftInterface';

const SetLayout = ({
	setSetOfFour,
	index,
	setSetOfFourData,
	setOfFourData,
	handleDeleteSetLayout,
	id,
	setAllErrors,
	newCount,
	editData,
	typeEdit,
}: {
	setSetOfFour: SetState<setOfFour>;
	index: number;
	setSetOfFourData: SetState<SetOfFourData> | any;
	setOfFourData: SetOfFourData;
	handleDeleteSetLayout: (fieldindex: number) => void;
	id?: string;
	setAllErrors: any;
	newCount: number;
	editData: GiftTS;
	typeEdit?: string;
}) => {
	const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
	const [pricePerDenomination, setPricePerDenomination] = useState<
		PricePerDenomination[]
	>([]);
	const [denomination, setDenomination] = useState<string[] | any>(
		setOfFourData[`set${index + 1}`]?.currency
	);

	useEffect(() => {
		fetchData();
	}, [editData]);

	const [uploadedFileJson, setUploadedFileJson] = useState<any>();
	const [selectedDate, setSelectedDate] = useState<Date>();
	const [denominationPriceData, setDenominationPriceData] = useState<number>(0);

	const {
		register,
		setError,
		clearErrors,
		setValue,
		formState: { errors, touchedFields, dirtyFields },
	} = useForm<any>({
		mode: 'all',
		defaultValues: {
			imageString: null,
		},
		resolver: yupResolver(newGiftSetLayoutValidationSchema),
	});

	const count = index + 1;

	const handleDropdownClick = () => {
		setDropDownOpen(!dropDownOpen);
	};

	useEffect(() => {
		const getFirstProductPrice = pricePerDenomination?.filter(
			(item: PricePerDenomination) => item?.denomination == denomination
		);
		if (getFirstProductPrice?.length > 0) {
			setDenominationPriceData(getFirstProductPrice[0]?.price);
			setSetOfFourData((prevState: SetOfFourData) => ({
				...prevState,
				[`set${count}`]: {
					...prevState[`set${count}`],
					currency: getFirstProductPrice[0]?.denomination,
					price: getFirstProductPrice[0]?.price,
				},
			}));
		}
	}, [denomination, pricePerDenomination, editData]);

	const fetchData = async () => {
		try {
			const response = await getPriceByDenomination();
			setPricePerDenomination(response?.data?.rows);
			setDenominationPriceData(response?.data?.rows[0]?.price as number);
		} catch (error) {
			console.error({ error });
		}
	};

	useEffect(() => {
		if (typeEdit) {
			setDenomination(editData?.denomination[0]);
			setSelectedDate(moment(editData.date).toDate());
			setSetOfFourData((prevState: SetOfFourData) => ({
				...prevState,
				[`set${count}`]: {
					...prevState[`set${count}`],
					message: editData?.name,
				},
			}));
			setValue('date', editData.date);
			clearErrors('date');
		}
	}, [editData]);

	const handleDenominationSelect = (e: PricePerDenomination) => {
		setDenomination(e.denomination);
		setDenominationPriceData(e.price);
		setSetOfFourData((prevState: SetOfFourData) => ({
			...prevState,
			[`set${count}`]: {
				...prevState[`set${count}`],
				currency: e.denomination,
				price: e.price,
			},
		}));

		setDropDownOpen(!dropDownOpen);
	};

	const handleMessage = (e: ChangeEvent<HTMLInputElement>) => {
		setSetOfFourData((prevState: SetOfFourData) => ({
			...prevState,
			[`set${count}`]: {
				...prevState[`set${count}`],
				message: e.target.value,
			},
		}));
	};

	const handleDateChange = (date: Date) => {
		const formattedDate = moment(date).format('YYYY-MM-DD');
		setSetOfFourData((prevState: SetOfFourData) => ({
			...prevState,
			[`set${count}`]: {
				...prevState[`set${count}`],
				date: formattedDate,
			},
		}));
	};

	useEffect(() => {
		if (Object.keys(errors)?.length) {
			setAllErrors({ ...errors });
		}
	}, [Object.keys(errors)?.length]);

	async function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
		const file = event.target.files && event.target.files[0];
		if (file) {
			setUploadedFileJson(file);
			setSetOfFourData((prevState: SetOfFourData) => ({
				...prevState,
				[`set${count}`]: {
					...prevState[`set${count}`],
					image: URL.createObjectURL(file),
				},
			}));
			clearErrors('image');
		}
	}

	return (
		<div>
			<div
				className="border lg:mb-8 mb-6 rounded-xl flex flex-col lg:gap-8 gap-6 sm:p-6 nd:p-3 nd:static relative"
				style={{ borderColor: BORDERCOLOR }}
			>
				<div className="w-full flex nd:items-center justify-between nd:relative static nd:flex-row flex-col-reverse items-end nd:gap-0 gap-2">
					<div
						className="absolute top-2/4 h-2/4 w-full border-t-2 border-dashed nd:block hidden"
						style={{ borderColor: BORDERCOLOR }}
					></div>
					<div className="nd:pr-4 relative w-full">
						<div className="uppercase sm:text-xl text-sm font-bold flex items-center nd:justify-start justify-between">
							<div
								className="flex items-center gap-3 nd:pr-4"
								style={{ background: WHITE_SMOKE }}
							>
								<span>Date section</span>
								<NumberTag number={count} />
							</div>
						</div>
					</div>
					<div
						className="nd:pl-4 nd:relative absolute top-0 right-0 nd:translate-x-0 nd:translate-y-0 -translate-x-2/4 -translate-y-2/4"
						style={{ background: WHITE_SMOKE }}
					>
						<div
							className="w-6 h-6"
							style={{
								cursor: newCount === 2 ? 'not-allowed' : 'pointer',
							}}
							onClick={() => handleDeleteSetLayout(index)}
						>
							<Cross />
						</div>
					</div>
				</div>

				<div className="flex justify-between lg:gap-8 gap-6 sm:flex-row flex-col">
					<div className="sm:w-2/4 w-full">
						<div>
							<CommonLabel labelText="Select currency" required />
							<div className="w-full relative inline-block text-left ">
								<div>
									<button
										type="button"
										className="inline-flex w-full justify-between gap-x-1.5 rounded-md p-3.5 mt-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
										id="menu-button"
										aria-expanded="true"
										aria-haspopup="true"
										onClick={handleDropdownClick}
										style={{
											background: WHITE,
										}}
									>
										₹{denomination}
										<svg
											className="-mr-1 h-5 w-5 text-gray-400"
											viewBox="0 0 20 20"
											fill="currentColor"
											aria-hidden="true"
										>
											<path
												fillRule="evenodd"
												d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								{dropDownOpen && (
									<>
										<div
											onClick={() => setDropDownOpen(false)}
											className="fixed z-[700001] top-0 left-0 w-full h-full"
										></div>
										<div
											className=" w-full absolute right-0 z-[700002] mt-2 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
											role="menu"
											aria-orientation="vertical"
											aria-labelledby="menu-button"
											style={{
												background: WHITE,
											}}
										>
											{pricePerDenomination.map(
												(e: PricePerDenomination, index: number) => {
													return (
														<div className="py-1" role="none" key={index}>
															<div
																className="text-gray-700 block px-4 py-2 text-sm"
																role="menuitem"
																id="menu-item-0"
																onClick={() => handleDenominationSelect(e)}
															>
																₹{e.denomination}
															</div>
														</div>
													);
												}
											)}
										</div>
									</>
								)}
								<span
									className="text-base leading-5 mt-1 block"
									style={{
										color: GRAY10,
									}}
								>
									Price:&nbsp; ₹{denominationPriceData} /-
								</span>
							</div>
						</div>
					</div>
					<div className="sm:w-2/4 w-full">
						<div className="mb-2">
							<CommonLabel labelText="Select Date" required />
						</div>
						<CommonDatePicker
							name="date"
							register={register}
							setError={setError}
							className="w-full py-3 pl-4 pr-11"
							handleDateChange={(date: Date | null | string) => {
								setSelectedDate(date as Date);
								handleDateChange(date as Date);
								setValue('date', date);
								clearErrors('date');
							}}
							selected={selectedDate as Date}
						/>
						{errors?.date && (touchedFields.date || dirtyFields.date) && (
							<ErrorMessage message={errors?.date?.message} />
						)}
					</div>
				</div>

				<div>
					<div>
						<CommonLabel labelText="Short message" required />
						<div
							className="rounded-lg border appearance-none focus:outline-none text-gray-900 text-sm block w-full mt-3 overflow-hidden"
							style={{ borderColor: BORDERCOLOR, background: WHITE }}
						>
							<input
								type="text"
								maxLength={50}
								value={setOfFourData[`set${index + 1}`]?.message}
								{...register('message')}
								onChange={(e) => {
									e.target.value = (e.target.value || '').trimStart();
									register('message').onChange(e);
									handleMessage(e);
								}}
								className="py-3 px-4 w-full text-lg leading-8 focus:outline-none capitalize"
								placeholder="Enter name"
							/>
						</div>
						{errors?.message &&
							(touchedFields.message || dirtyFields.message) && (
								<ErrorMessage message={errors?.message?.message} />
							)}
					</div>
				</div>

				<div>
					<ImageUpload
						setOfFour={setOfFourData[`set${index + 1}`]}
						setSetOfFour={setSetOfFour}
						setSetOfFourData={setSetOfFourData}
						setOfFourData={setOfFourData}
						handleImageChange={handleImageChange}
						uploadedFileJson={uploadedFileJson}
						formEl={{
							register,
							errors,
							setError,
							setValue,
							clearErrors,
						}}
						index={index}
						id={id}
					/>
				</div>
			</div>
		</div>
	);
};

export default SetLayout;
