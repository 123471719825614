import React from "react";
import { TailSpin } from "react-loader-spinner";
import { CommonLoaderProps } from "../../Interface/Index";

const CommonLoader: React.FC<CommonLoaderProps> = ({
  height = 50,
  width = 100,
  color = "black",
  ariaLabel = "tail-spin-loading",
  radius = 1,
  wrapperStyle = {},
  wrapperClass = "",
  visible = true,
  
}) => {
  return (
    <>
      {visible && (
        <div className={wrapperClass} style={wrapperStyle}  >
          <TailSpin
            height={height}
            width={width}
            color={color}
            ariaLabel={ariaLabel}
            radius={radius}
          />
        </div>
      )}
    </>
  );
};

export default CommonLoader;
