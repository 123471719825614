// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FormData, OrderFormProps } from '../../../Interface/Index';
import { addSpecialContact } from '../../../Api/UserListApi';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomTextField } from '../../../Components/Input/TextField';
import CommonLabel from '../../../Components/InputLable/lable';
import ErrorMessage from '../../../Components/Typography/ErrorText';
import CommonDatePicker from '../../../Components/DatePicker/DatePicker';
import Remove from '../../../Components/Svg/Remove';
import OrderSlider from './OrderSlider';
import CommonButton from '../../../Components/Button/CommonButton';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { GRAY900, TEXTCOLORGRAY } from '../../../Constant/Color.Constant';
import { ErrorToaster, SuccessToaster } from '../../../helpers/CustomToaster';
import {
	otherValidationSchema,
	setOfAllNotesValidationSchema,
} from '../../../Components/Validataions/ValidataionSchema';
import useMobileMediaQuery from '../../../utils/useMobileMediaQuery';
import { ROUTES } from '../../../Constant/Routes.Constant';
import { useNavigate } from 'react-router-dom';

const OrderForm: React.FC<OrderFormProps> = ({ setIsModalOpen, id }) => {
	const userId = useSelector(
		(state: { user: { user: { id: string } } }) => state.user.user.id
	);
	const userEmail = useSelector(
		(state: { user: { user: { email: string } } }) => state?.user?.user?.email
	);
	const userPhone = useSelector(
		(state: { user: { user: { phone: string } } }) => state?.user?.user?.phone
	);

	const initialData = {
		email: userEmail || '',
		phone: String(userPhone) || '',
	};
	const navigate = useNavigate();
	const [selected, setSelected] = useState<Date | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const screen = useMobileMediaQuery();
	const isLogin = localStorage.getItem('ACCESSTOKEN');

	const onSubmit: SubmitHandler<FormData> = async (data) => {
		setLoading(true);
		try {
			if (!isLogin) {
				navigate(ROUTES.LOGIN);
				return;
			}

			data.userId = userId;
			data.date = moment(selected).format('YYYY-MM-DD');
			let response: FormData;

			if (id === 'setOfAllNotes') {
				const { email, phone, name, occasion, date } = data;
				const newData = {
					email,
					phone,
					name,
					occasion,
					date,
					isAlbum: false,
				};
				response = await addSpecialContact(newData);
			} else {
				const { email, phone, name, description } = data;
				const newData = {
					email,
					phone,
					name,
					description,
					isAlbum: true,
				};
				response = await addSpecialContact(newData);
			}

			SuccessToaster(response.message);
			setIsModalOpen(false);
		} catch (error) {
			ErrorToaster(error);
		} finally {
			setLoading(false);
		}
	};

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const validationSchema =
		id === 'setOfAllNotes'
			? setOfAllNotesValidationSchema
			: otherValidationSchema;

	const {
		register,
		handleSubmit,
		clearErrors,
		formState: { errors },
		setValue,
	} = useForm<FormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialData,
	});

	return (
		<div className="sm:px-12 sm:py-8 xs:p-3 md:w-[720px] sm:w-[500px] xs:w-[250px] nd:w-[300px]">
			<div className="md:h-[720px] h-[500px] overflow-scroll flex flex-col sm:gap-6 gap-5">
				<div className="flex sm:gap-2 gap-1 flex-col ">
					<div className="flex  items-center gap-2">
						<p
							className="sm:text-xl text-base font-bold w-[624px] uppercase"
							style={{ color: GRAY900 }}
						>
							{id === 'setOfAllNotes'
								? 'Set Of All Products'
								: 'Album of Products'}
						</p>
						<div className="cursor-pointer" onClick={handleClose}>
							<Remove width={screen.isMobile ? '20px' : '24px'} />
						</div>
					</div>
					<p className="sm:text-base text-sm" style={{ color: TEXTCOLORGRAY }}>
						This order is a customized order which takes 10-15 days. To Place
						this order, fill in the details below. We will contact you soon.
						Thank you!
					</p>
				</div>
				<OrderSlider
					bannerType={id === 'setOfAllNotes' ? 'setOfAll' : 'album'}
				/>

				<form
					onSubmit={handleSubmit(onSubmit)}
					className="xs:w-full flex flex-col gap-6 "
				>
					<div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-2  xs:grid-cols-1 xs:flex xs:flex-col md:gap-y-6 md:gap-x-8 gap-y-5 ">
						<div className="col-span-2 flex flex-col gap-1">
							<CommonLabel labelText="Email" />
							<CustomTextField
								placeholder="Enter your email"
								type="text"
								name="email"
								defaultValue={initialData.email}
								register={register}
								className="border border-gray-300 rounded p-2"
							/>
							<ErrorMessage message={errors.email?.message} />
						</div>

						<div className="flex flex-col gap-1">
							<CommonLabel labelText="Name" />
							<CustomTextField
								placeholder="Enter your name"
								name="name"
								register={register}
								className="border border-gray-300 rounded p-2"
							/>
							<ErrorMessage message={errors.name?.message} />
						</div>

						<div className="flex flex-col gap-1">
							<CommonLabel labelText="Phone Number" />
							<CustomTextField
								placeholder="Enter your phone number"
								name="phone"
								register={register}
								defaultValue={initialData.phone}
								className="border border-gray-300 rounded p-2"
							/>
							<ErrorMessage message={errors.phone?.message} />
						</div>
						{id === 'setOfAllNotes' && (
							<>
								<div className="flex flex-col gap-1">
									<CommonLabel labelText="Occassion" />
									<CustomTextField
										placeholder="Enter your occassion"
										name="occassion"
										register={register}
										className="border border-gray-300 rounded p-2"
									/>
									<ErrorMessage message={errors.occassion?.message} />
								</div>

								<div className="flex flex-col gap-1">
									<CommonLabel labelText="Date" />
									<CommonDatePicker
										name="date"
										register={register}
										handleDateChange={(date: Date | null | string) => {
											setValue('date', date as Date);
											setSelected(date as Date);
											clearErrors('date');
										}}
										selected={selected as Date}
									/>
									<ErrorMessage message={errors.date?.message as string} />
								</div>
							</>
						)}
						{id !== 'setOfAllNotes' && (
							<div className="flex-col gap-1">
								<CommonLabel labelText="Description" />
								<CustomTextField
									placeholder="Enter your description"
									name="description"
									register={register}
									className="border border-gray-300 rounded p-2"
								/>
								<ErrorMessage message={errors.description?.message} />
							</div>
						)}
					</div>
					<div className="w-full flex sm:justify-end justify-center static">
						<CommonButton
							className="!bg-black text-white rounded-lg sm:w-[129px] w-[319px] xs:w-full uppercase"
							type="submit"
							fontSize="16px"
							padding="12px 32px"
							label="Submit"
							loading={loading}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default OrderForm;
