import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getConfigData } from '../Api/UserListApi';

export interface CountdownState {
	endDate: string | null;
	error: string | null;
	loading: boolean;
	isCountdownComplete: boolean;
}

const initialState: CountdownState = {
	endDate: null,
	error: null,
	loading: false,
	isCountdownComplete: false,
};

export const fetchLimitedEditionDate = createAsyncThunk('/config', async () => {
	try {
		const limitedEditionDate = await getConfigData();
		return limitedEditionDate;
	} catch (error) {
		console.error('Error fetching limited edition date:', error);
	}
});

const countdownSlice = createSlice({
	name: 'countdown',
	initialState,
	reducers: {
		setEndDate: (state, action: PayloadAction<string>) => {
			state.endDate = action.payload;
		},
		setError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
		countdownComplete: (state, action) => {
			state.isCountdownComplete = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLimitedEditionDate.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchLimitedEditionDate.fulfilled, (state, action) => {
				state.endDate = action.payload;
				state.loading = false;
			});
	},
});

export const { setEndDate, setError, countdownComplete } =
	countdownSlice.actions;
export const selectEndDate = (state: { countdown: CountdownState }) =>
	state.countdown.endDate;
export const selectLoading = (state: { countdown: CountdownState }) =>
	state.countdown.loading;
export const selectIsCountdownComplete = (state: {
	countdown: CountdownState;
}) => state?.countdown?.isCountdownComplete;

export default countdownSlice.reducer;
