import React, {
	LegacyRef,
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import CommonLabel from '../../../../Components/InputLable/lable';
import { getFrameData } from '../../../../Api/UserListApi';
import { frameInterface, SetState } from '../../../../Interface/Index';
import {
	BLACK,
	BORDERCOLOR,
	TRANSPARENT,
} from '../../../../Constant/Color.Constant';
import { useDraggable } from 'react-use-draggable-scroll';
import { findIndex } from 'lodash';
import {
	GiftData,
	SelectedFrame,
} from '../../../../Interface/NewGiftInterface';

const Frame = ({
	setIsSelected,
	isSelected,
	setSelectedFrame,
	typeEdit,
	editData,
	resetAll,
}: {
	setIsSelected: SetState<boolean>;
	isSelected: boolean;
	setSelectedFrame: SetState<SelectedFrame | undefined>;
	typeEdit: string;
	editData: GiftData;
	resetAll: boolean;
}) => {
	const [scrollWidth, setScrollWidth] = useState(0);
	const [frames, setFrames] = useState<frameInterface[]>([]);
	const [outlineFrame, setOutlineFrame] = useState<number | undefined>(0);

	const ref = useRef<HTMLDivElement | null>(null);
	const scrollableElementRef = useRef<HTMLDivElement | null>(null);
	const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);

	const fetchFrameDetail = async () => {
		try {
			const res = await getFrameData();
			if (!res.error) {
				setFrames(res?.data?.rows);
				setSelectedFrame(res?.data?.rows[0]);
			}
		} catch (error) {
			console.error('Error fetching addresses:', error);
		}
	};
	const handleScroll = (e: React.SyntheticEvent<HTMLDivElement>) => {
		const element = e.currentTarget;
		setScrollWidth(element.scrollLeft);
	};
	useEffect(() => {
		fetchFrameDetail();

		if (scrollableElementRef.current) {
			scrollableElementRef.current.addEventListener(
				'scroll',
				handleScroll as unknown as EventListener
			);

			return () => {
				scrollableElementRef.current?.removeEventListener(
					'scroll',
					handleScroll as unknown as EventListener
				);
			};
		}
	}, []);
	const frameHandleChange = (item: frameInterface, index: number) => {
		setIsSelected(!isSelected);
		setOutlineFrame(index);
		setSelectedFrame(item);
	};

	useEffect(() => {
		if (typeEdit) {
			const editFrameIndex = findIndex(frames, { id: editData?.gift?.frameId });
			setSelectedFrame(frames[editFrameIndex]);
			setOutlineFrame(editFrameIndex);
		}
	}, [frames, editData]);

	useEffect(() => {
		setSelectedFrame(frames[0]);
		setOutlineFrame(0);
	}, [resetAll]);

	return (
		<div>
			<CommonLabel labelText="Select Frame" required />
			<div className="relative">
				{scrollWidth > 0 && (
					<div className="absolute left-0 top-0 h-full md:max-w-[60px] max-w-[30px] w-full bg-gradient-to-l from-transparent via-white to-white flex justify-center items-center">
						<span className="mr-1.5 border-l-2 border-b-2 border-gray-500 w-2 h-2 block rounded-sm rotate-45"></span>
					</div>
				)}

				<div
					className="w-full overflow-x-auto scrollbar-hidden scrollable-element"
					{...events}
					ref={ref as LegacyRef<HTMLDivElement> | undefined}
				>
					<div className="flex gap-x-5 w-full lg:mt-3 mt-1 mb-0.5 items-center">
						{frames.map((item, index) => (
							<div
								key={index}
								className="rounded-lg flex h-full"
								style={{
									border: `4px solid ${
										outlineFrame === index ? BLACK : TRANSPARENT
									}`,
								}}
							>
								<div
									onClick={() => frameHandleChange(item, index)}
									key={index}
									style={{
										border: `1px solid ${
											outlineFrame === index ? TRANSPARENT : BORDERCOLOR
										}`,
										padding:
											outlineFrame === index
												? '8px 18px 8px 14px'
												: '10px 18px 10px 14px',
									}}
									className="rounded-lg flex items-center justify-center gap-3 cursor-pointer"
								>
									<div className="lg:h-8 lg:w-8 w-6 h-6 rounded-lg overflow-hidden">
										<div className="flex flex-col h-full w-full">
											<div className="h-2/4 flex">
												<div className="w-2/4 relative overflow-hidden">
													<div
														className="w-8 h-8 absolute top-0 left-0"
														style={{
															backgroundImage: `url('${item.image}')`,
															backgroundRepeat: 'no-repeat',
															backgroundPosition: 'top left',
															backgroundSize: '250%',
														}}
													></div>
												</div>
												<div className="w-2/4 relative overflow-hidden">
													<div
														className="w-8 h-8 absolute top-0 right-0"
														style={{
															backgroundImage: `url('${item.image}')`,
															backgroundRepeat: 'no-repeat',
															backgroundPosition: 'top right',
															backgroundSize: '250%',
														}}
													></div>
												</div>
											</div>
											<div className="h-2/4 flex">
												<div className="w-2/4 relative overflow-hidden">
													<div
														className="w-8 h-8 absolute bottom-0 left-0"
														style={{
															backgroundImage: `url('${item.image}')`,
															backgroundRepeat: 'no-repeat',
															backgroundPosition: 'bottom left',
															backgroundSize: '250%',
														}}
													></div>
												</div>
												<div className="w-2/4 relative overflow-hidden">
													<div
														className="w-8 h-8 absolute bottom-0 right-0"
														style={{
															backgroundImage: `url('${item.image}')`,
															backgroundRepeat: 'no-repeat',
															backgroundPosition: 'bottom right',
															backgroundSize: '250%',
														}}
													></div>
												</div>
											</div>
										</div>
									</div>
									<div className="flex flex-col capitalize">
										<span className="whitespace-nowrap text-base">
											{item.name}
										</span>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Frame;
