import React from 'react';

const FileUploadIcon = ({ size = '32' }: { size?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M8 26.6668H24M16 21.3335V5.3335M16 5.3335L20.6667 10.0002M16 5.3335L11.3333 10.0002"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default FileUploadIcon;
