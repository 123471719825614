import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
	cases,
	Currency,
	fiveNotesDataStyle,
} from '../../../../Constant/Constant';
import {
	fiveNotesDataTS,
	noteImage,
	setOfFour,
} from '../../../../Interface/NewGiftInterface';
// import Image from '../../../../Components/Image/Image';

const ClassicFiveNotes = ({
	setOfFour,
	layout,
	fiveNotesData,
	screenWidth,
}: {
	setOfFour: setOfFour;
	layout: boolean;
	fiveNotesData: fiveNotesDataTS;
	screenWidth: string;
}) => {
	const [noteMaxWidth, setNoteMaxWidth] = useState(0);

	useEffect(() => {
		const maxRupeeObject = setOfFour?.noteImage.reduce(
			(max: noteImage, current: noteImage) => {
				return current.rupee >= max.rupee ? current : max;
			},
			setOfFour?.noteImage[0]
		);
		setNoteMaxWidth(
			Currency[maxRupeeObject?.noteIndex]?.screens[screenWidth]?.width
		);
	}, [setOfFour.currency, layout]);

	return (
		<div className="flex flex-col h-full w-full">
			<div className="flex h-[47%]">
				<div className="flex flex-col justify-center items-center lg:gap-16 md:gap-14 sm:gap-10 xs:gap-5 w-full">
					<div className="w-full flex justify-center">
						<p className="lg:text-4xl xs:text-lg md:text-3xl font-semibold max-w-[200px] text-center message tracking-normal"></p>
					</div>
					<div className="w-full  flex justify-center ">
						<p className="lg:text-2xl xs:text-lg md:text-2xl font-bold message lg:max-w-[200px] md:max-w-[150px] xs:max-w-[100px] text-center tracking-normal break-words"></p>
					</div>
				</div>
				<div className="hidden">
					<div className="h-full flex justify-center items-center"></div>
				</div>
			</div>
			<div className="flex flex-col items-center h-[53%]">
				<ul className="h-full w-full flex flex-col justify-end">
					<li className="flex flex-col justify-center items-center custom-height md:h-[calc(100%-110px)] sm:h-[calc(100%-106px)] nd:h-[calc(100%-90px)] h-[calc(100%-65px)] mb-5">
						<div
							className="flex flex-col relative justify-center items-center"
							style={{
								width: setOfFour?.noteImage?.length > 1 ? '450px' : 'unset',
							}}
						>
							{setOfFour?.noteImage.map(
								(notesData: { image: string }, index: number) => {
									return (
										<div key={index}>
											<img
												key={index}
												src={notesData?.image}
												alt={`Currency ${index + 1}`}
												style={{
													height: `${
														Currency[setOfFour.currency[index]].screens[
															screenWidth
														].height
													}rem`,
													maxWidth: `${
														Currency[setOfFour.currency[index]].screens[
															screenWidth
														].width
													}rem`,
													position: 'absolute',
													bottom:
														setOfFour?.noteImage?.length === 1
															? '50%'
															: `calc(50% - ${((index + 2.5) * 14) / 16}rem)`,
													transform:
														setOfFour?.noteImage?.length === 1
															? 'translate(-50%, 100%)'
															: `translate(${noteMaxWidth / 2}rem, calc(50% - ${
																	(setOfFour?.noteImage?.length * 14) / 16
															  }rem))`,
													right:
														setOfFour?.noteImage?.length === 1
															? 'unset'
															: '50%',
													zIndex: setOfFour.isMultipleNote
														? 500 - Currency[setOfFour.currency[index]].rupee
														: '',
												}}
											/>
										</div>
									);
								}
							)}
						</div>
					</li>
					<li className="text-center md:min-h-10 sm:min-h-[50px] nd:min-h-[47px] min-h-10 flex flex-col md:m-0 sm:-mb-2 nd:-mb-1 -m-1.5">
						<p className="mb-0.5">
							<span
								className={classNames(
									'md:text-[10px] md:leading-[23px] sm:text-xs nd:text-[8px] text-[6px]',
									cases[fiveNotesDataStyle?.text?.textCase].style
								)}
								style={{
									color: fiveNotesDataStyle?.text?.textColor,
									fontWeight: fiveNotesDataStyle?.text?.textBold
										? '700'
										: '400',
									fontStyle: fiveNotesDataStyle?.text?.textItalic
										? 'italic'
										: 'normal',
								}}
							>
								{fiveNotesData.text}
							</span>
						</p>
						<h3 className="md:text-[17px] md:leading-[23px] sm:text-2xl nd:text-xl text-sm font-bold -mt-2">
							<span
								className={classNames(
									'font-BeVietnamRegular',
									cases[fiveNotesDataStyle?.name?.nameCase].style
								)}
								style={{
									color: fiveNotesDataStyle?.name?.nameColor,
									fontStyle: fiveNotesDataStyle?.name?.nameItalic
										? 'italic'
										: 'normal',
									fontWeight: fiveNotesDataStyle?.name?.nameBold
										? '700'
										: '400',
								}}
							>
								{fiveNotesData.name}
							</span>
						</h3>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default ClassicFiveNotes;
