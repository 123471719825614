import React from 'react';
import { CustomInputLabelProps } from '../../Interface/Index';
import { GRAY100, TEXTCOLORGRAY } from '../../Constant/Color.Constant';

const CommonLabel: React.FC<CustomInputLabelProps> = ({
	labelText,
	optional,
	required,
	className,
}) => {
	return (
		<label className="text-sm sm:text-base font-medium">
			<div className="flex justify-between">
				<div
					className="relative capitalize"
					style={{
						color: GRAY100,
						lineHeight: '24px',
					}}
				>
					<span>{labelText}</span>
					{required && (
						<span className="text-red-500 ml-1 absolute top-0 -right-2.5">
							*
						</span>
					)}
				</div>
				<div className={`text-[${TEXTCOLORGRAY}] text-sm ${className}`}>
					{optional}
				</div>
			</div>
		</label>
	);
};

export default CommonLabel;
