import React from 'react';

const Correct = () => {
	return (
		<div>
			<svg
				width="24"
				height="24"
				viewBox="0 0 16 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1 7L5 11L15 1"
					stroke="#10B981"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};

export default Correct;
