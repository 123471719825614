import React, { Dispatch, FC, SetStateAction } from 'react';
import { Framedetails } from '../../Interface/Index';

interface ImageData {
	images?: string[];
}

interface CommonProductSmallImageProps<T extends ImageData | undefined> {
	dataDetails?: T;
	currentImage: number;
	setCurrentImage: Dispatch<SetStateAction<number>>;
}

const CommonProductSmallImage: FC<
	CommonProductSmallImageProps<Framedetails>
> = ({ dataDetails, currentImage, setCurrentImage }) => {
	const handleImageClick = (index: number) => {
		setCurrentImage(index);
	};

	return (
		<div>
			<div className="flex md:flex-col flex-row sm:gap-4 gap-3 overflow-x-scroll">
				{dataDetails?.images?.map((data: string | undefined, index: number) => (
					<div
						key={index}
						className={`cursor-pointer ${
							currentImage === index ? 'selected-image' : ''
						}`}
						onClick={() => handleImageClick(index)}
					>
						<img
							src={data}
							alt={`Frame ${index + 1}`}
							className={`rounded-lg md:w-[88px] md:h-[88px] h-11 w-11 md:min-w-[88px] md:min-h-[88px] min-h-[44px] min-w-[44px] ${
								currentImage === index
									? 'sm:border-4 border-2 border-black'
									: ''
							}`}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default CommonProductSmallImage;
