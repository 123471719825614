import React from 'react';
import CommonCard from '../../../Components/Ui/CommonCard';
import { noteLayoutsCard } from '../../../Constant/Constant';
import SEO from '../../../Components/Helmet/SEO';
import { NOTE_LAYOUTS } from '../../../Constant/Seo.Constant';

const NoteLayouts = () => {
	return (
		<div className="xlg:px-24 lg:px-12  lg:py-16 sm:py-16 xs:py-8 xs:px-4 h-full flex justify-center items-center flex-col bg-white flex-wrap xs:w-full sm:w-auto">
			<div className="xs:gap-4 lg:gap-8 sm:gap-4 md:gap-8 justify-center items-center flex-wrap grid grid-cols-1 lg:grid-cols-3 xlg:grid-cols-4 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 nd:grid-cols-2 gird-cols-1">
				<SEO title="Note Layouts" description={NOTE_LAYOUTS} />
				{noteLayoutsCard?.map((product, index) => (
					<React.Fragment key={index}>
						<CommonCard
							title={product.title}
							description={product.description}
							image={product.img}
							to={product.to}
							id={product.id}
							buttonLabel="GIFT NOW"
						/>
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

export default NoteLayouts;
{
	/* <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 gap-8 justify-center items-center w-full"></div> */
}
