import React from 'react';

const Dot = () => {
	return (
		<svg
			width="8"
			height="9"
			viewBox="0 0 8 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.03125 8.03125C2.95833 8.03125 2.10417 7.72917 1.46875 7.125C0.833333 6.51042 0.515625 5.70312 0.515625 4.70312V3.5C0.515625 2.51042 0.833333 1.69792 1.46875 1.0625C2.10417 0.427083 2.95312 0.109375 4.01562 0.109375C5.09896 0.109375 5.95833 0.427083 6.59375 1.0625C7.22917 1.6875 7.54688 2.5 7.54688 3.5V4.70312C7.54688 5.70312 7.22917 6.51042 6.59375 7.125C5.95833 7.72917 5.10417 8.03125 4.03125 8.03125Z"
				fill="#9CA3AF"
			/>
		</svg>
	);
};

export default Dot;
