import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Image = ({
	image,
	width = '100%',
	height = 'auto',
	altName = 'Image',
	style = { borderRadius: '0px 0 0px 0' },
	fallbackSrc = '/Images/HomeBackGround.png',
	className,
	onLoad,
	onClick,
}: {
	image?: string | any;
	width?: string;
	height?: string;
	altName?: string;
	style?: Record<string, string>;
	fallbackSrc?: string;
	className?: string;
	onLoad?: () => void;
	onClick?: () => void;
}) => {
	const handleError = (
		event: React.SyntheticEvent<HTMLImageElement, Event>
	) => {
		event.currentTarget.src = fallbackSrc;
	};
	return (
		<div
			className="flex"
			style={{
				height: height || width ? '100%' : 'unset',
				width: height || width ? '100%' : 'unset',
			}}
		>
			<LazyLoadImage
				src={image ?? fallbackSrc} // use normal <img> attributes as props
				width={width}
				height={height}
				className={className}
				style={style}
				alt={altName}
				effect="blur"
				onErrorCapture={handleError}
				onLoadCapture={onLoad}
				onClick={onClick}
			/>
		</div>
	);
};

export default Image;
