import React from 'react';
import { paragraphs, privacyPolicyData } from '../../Constant/Constant';
import '../../index.css';
import SEO from '../../Components/Helmet/SEO';
import { PRIVACY_POLICY } from '../../Constant/Seo.Constant';
import Image from '../../Components/Image/Image';

const PrivacyPolicy: React.FC = () => {
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<>
			<SEO title="Privacy Policy" description={PRIVACY_POLICY} />
			<div className="h-full w-full  max-w-[2500px] mx-auto bg-white lg:p-44 lg:pt-10 lg:pb-10">
				<div className="flex justify-center items-center flex-col">
					<div className="flex w-full pt-24 justify-center items-center ">
						<p className="text-3xl  font-medium tracking-widest uppercase">
							privacy policy
						</p>
					</div>
					<div className="h-full w-full flex flex-col gap-8 py-12 text-justify px-8">
						{paragraphs.map((paragraph, index) => (
							<p key={index}>{paragraph}</p>
						))}
					</div>
					{privacyPolicyData.map((item, index) => (
						<div
							key={index}
							className="w-full gap-10 flex flex-col py-12 text-justify px-8"
						>
							<p className="tracking-wide text-base font-semibold">
								{item.title}
							</p>
							<div className="flex flex-col gap-12">
								{item.content.map((content, idx) => (
									<p key={idx}>{content}</p>
								))}
							</div>
						</div>
					))}
					<div className="py-10 cursor-pointer" onClick={scrollToTop}>
						<Image
							image="/Images/UpAerrow.png"
							altName="Up Arrow"
							width="30px"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivacyPolicy;
