import React from 'react';
import NoteLayouts from '../NotesLayouts/NoteLayouts';
import SEO from '../../../Components/Helmet/SEO';
import { NEW_GIFT_LAYOUTS } from '../../../Constant/Seo.Constant';

const NewGiftLayots = () => {
	return (
		<>
			<SEO title="Exclusive Product" description={NEW_GIFT_LAYOUTS} />
			<div className="min-h-[calc(100vh-383px)] flex items-center justify-center">
				<NoteLayouts />
			</div>
		</>
	);
};

export default NewGiftLayots;
