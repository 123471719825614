/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import './Spinner.css';
import SpinnerStar from '../../../Components/Svg/SpinnerStar';
import SpinnerSelector from '../../../Components/Svg/SpinnerSelector';
import { BLACK, WHITE } from '../../../Constant/Color.Constant';
import useScreenSize from '../../../utils/hooks/UseScreenSIze';
import {
	ALL_STYLES,
	ALL_STYLE_SM,
	SPIN_ELEMENT_POSITIONS,
	WHEEL_BORDER_ROTATION,
} from '../../../Constant/SpinStyleConstants';

type SpinnerData = {
	id?: string;
	name?: string;
	color?: string;
	number?: string;
};

const CustomSpinner = ({
	spin,
	onSpinComplete,
	discountKeyToStopSpinner = undefined,
	spinnerData,
}: {
	spin?: boolean;
	onSpinComplete: (percentage: number) => void;
	discountKeyToStopSpinner?: string | null | undefined;
	spinnerData: SpinnerData[];
}) => {
	const boxRef: any = useRef();
	const mainRef: any = useRef();
	const [winningCompartment, setWinningCompartment] = useState<number | null>(
		null
	);
	const fetchData = async () => {
		try {
			const winningCompartmentValue = spinnerData.reduce(
				(acc: number | undefined, e, index) => {
					if (
						e?.name &&
						discountKeyToStopSpinner &&
						parseInt(e?.name) === parseInt(discountKeyToStopSpinner)
					) {
						acc = index;
					}
					return acc;
				},
				0
			);
			setWinningCompartment(winningCompartmentValue as number);
		} catch (error) {
			console.error('Error fetching spinning wheel data:', error);
		}
	};
	const screenDetails = useScreenSize();
	const isStyleSM = screenDetails.isExistScreen.sm;

	const spinTheWheel = useCallback(
		(win: number) => {
			const deg = 360 * 2 + 45 * SPIN_ELEMENT_POSITIONS[win];
			boxRef.current.style.transition = 'transform 3s ease-in-out';
			boxRef.current.style.transform = `rotate(-${deg}deg)`;

			console.log(
				boxRef.current.style.transform,
				'boxRef.current.style.transform ==='
			);
			setTimeout(() => {
				if (discountKeyToStopSpinner) {
					onSpinComplete(parseInt(discountKeyToStopSpinner));
				}
			}, 3700);
		},
		[winningCompartment]
	);

	useEffect(() => {
		if (spin) {
			fetchData().then(() => {
				console.log({ winningCompartment });
				if (winningCompartment !== null) {
					spinTheWheel(winningCompartment);
				}
			});
		}
	}, [spin, winningCompartment]);

	return (
		<div className="flex flex-col">
			<div
				className="relative sm:w-[400px] sm:h-[400px] w-60 h-60"
				ref={mainRef}
			>
				<div
					className="relative w-full h-full rounded-full border-[0.1rem] overflow-hidden transition-all ease duration-5000"
					ref={boxRef}
					style={{
						borderColor: BLACK,
						transform: 'rotate(23deg)',
					}}
				>
					<div>
						{spinnerData?.slice(0, 4).map((star: SpinnerData, index) => {
							const { numberStyle, ...spanStyle } =
								ALL_STYLES[`span${index + 1}`];
							const smStyle = isStyleSM ? ALL_STYLE_SM[`span${index + 1}`] : {};
							return (
								<span
									key={index}
									className="w-2/4 h-2/4 inline-block absolute"
									style={{
										backgroundColor: star.color,
										...spanStyle,
										...smStyle,
									}}
								>
									<SpinnerStar number={star?.name} numberStyle={numberStyle} />
								</span>
							);
						})}
					</div>
					<div className="w-full h-full -rotate-[135deg]">
						{spinnerData?.slice(4, 8).map((star: SpinnerData, index) => {
							const { numberStyle, ...spanStyle } =
								ALL_STYLES[`span${index + 1}`];
							const smStyle = isStyleSM ? ALL_STYLE_SM[`span${index + 1}`] : {};
							return (
								<span
									key={index}
									className="w-2/4 h-2/4 inline-block absolute"
									style={{
										backgroundColor: star.color,
										...spanStyle,
										...smStyle,
									}}
								>
									<SpinnerStar number={star?.name} numberStyle={numberStyle} />
								</span>
							);
						})}
					</div>
					<div className="absolute top-0 left-0 w-full h-full rounded-full rotate-[23deg]">
						<div className="w-full h-full relative">
							{WHEEL_BORDER_ROTATION.map((wheelBordersRotationEl, index) => {
								return (
									<div
										key={index}
										className="absolute left-2/4 top-2/4 h-[1px] w-full"
										style={{
											background: BLACK,
											transform: wheelBordersRotationEl,
										}}
									></div>
								);
							})}
						</div>
					</div>
				</div>
				<div
					className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 sm:w-40 sm:h-40 w-24 h-24 rounded-full font-bold sm:text-base text-[0.6em] uppercase flex items-center text-center justify-center px-4 sm:leading-6 leading-[14.6px]"
					style={{
						background: WHITE,
						color: BLACK,
					}}
				>
					claim discount
				</div>
				<div className="absolute sm:w-12 sm:h-8 w-8 h-5 -right-3 top-[51%] -translate-y-2/4">
					<SpinnerSelector />
				</div>
			</div>
		</div>
	);
};

export default CustomSpinner;
