import React from 'react';
import { socialMediaLinks } from '../../Constant/Constant';

const FooterSmall = () => {
	return (
		<div className="max-w-[2500px] mx-auto h-14 flex justify-between items-center bg-white py-4 xlg:px-24 lg:px-5 px-[50px] border-t border-gray-300">
			<div className="flex justify-center items-center">
				<p className="xs:text-xs sm:text-sm text-base leading-6">
					© 2023 The Rupi. All rights reserved.
				</p>
			</div>
			<div className="flex lg:gap-6 md:gap-5 sm:gap-4 xs:gap-3 items-center text-gray-700">
				{socialMediaLinks.map((link) => (
					<a
						key={link.name}
						href={link.url}
						target="_blank"
						rel="noopener noreferrer"
					>
						{link.icon}
					</a>
				))}
			</div>
		</div>
	);
};

export default FooterSmall;
