import { useEffect, useState } from 'react';

interface MobileMediaQueryHook {
	isMobile: boolean;
}

const useMobileMediaQuery = (): MobileMediaQueryHook => {
	const [isMobile, setIsMobile] = useState<boolean>(false);

	useEffect(() => {
		const mobileMediaQuery = window.matchMedia('(max-width: 600px)');

		const handleMobileChange = (event: MediaQueryListEvent) => {
			setIsMobile(event.matches);
		};

		mobileMediaQuery.addEventListener('change', handleMobileChange);
		setIsMobile(mobileMediaQuery.matches);

		return () => {
			mobileMediaQuery.removeEventListener('change', handleMobileChange);
		};
	}, []);

	return { isMobile };
};

export default useMobileMediaQuery;
