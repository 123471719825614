import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SetOf4 from './components/set-of-four/SetOf4';
import { FiveNotes } from './components/set-of-five/FiveNotes';
import FrameLayout from './components/frame/FrameLayout';
import { WHITE, WHITE_SMOKE } from '../../Constant/Color.Constant';
import { getSizeName } from '../../utils/getScreenSize';
import {
	LAYOUT_ONE_DEFAULT_TEXT,
	SET_OF_FOUR_FRAME_DATA,
	USER_IMAGE_MALE,
} from '../../Configuration/InitialData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as htmlToImage from 'html-to-image';
import {
	newGiftMultipleNoteValidationSchema,
	newGiftSingleNoteValidationSchema,
} from '../../Components/Validataions/ValidataionSchema';
import { useSelector } from 'react-redux';
import {
	addNewGift,
	getBackgroundImage,
	getImageToURLString,
	updateNewGift,
} from '../../Api/UserListApi';
import Modal from '../../Components/Model/Modal';
import OrderModel from '../SetOfAllNotes/OrderModel/OrderModel';
import { useAppDispatch } from './../../store/store';
import { allNoteCart, getAllAddToCart } from '../../store/CartSlice';
import {
	CLASSES,
	Currency,
	MULTIPLE,
	SETS,
	SET_OF_FOUR,
	SINGLE,
} from '../../Constant/Constant';
import moment from 'moment';
import { ErrorToaster, SuccessToaster } from '../../helpers/CustomToaster';
import {
	BackgroundImageJson,
	BgData,
	CurrencyData,
	CurrencyInterface,
	fiveNotesDataTS,
	SelectedFrame,
	SelectedFrameBackground,
	setOfFour,
	SetOfFourData,
} from '../../Interface/NewGiftInterface';
import { SetOfFourProductEl, SetState } from '../../Interface/Index';
import SEO from '../../Components/Helmet/SEO';
import { NEW_GIFT_LAYOUTS } from '../../Constant/Seo.Constant';

interface FormData {
	occassion: string;
	date: Date | string | null;
	name: string;
	text: string;
	title: string;
	denomination: string[];
	userImage: string;
	allSets: boolean | null;
	imageString: string | null;
}

const NewGift = (props: { setIsRightSidebarOpen?: SetState<boolean> }) => {
	const location = useLocation();
	const typeEdit = location?.state?.type;
	const editData = location?.state;

	const navigate = useNavigate();

	const filterObjectsByRupee = (
		arr: CurrencyInterface,
		rupeeValues: string[]
	) => {
		return arr
			.map((item: CurrencyData, index: number) =>
				rupeeValues.includes(String(item.rupee)) ? index : -1
			)
			.filter((index: number) => index !== -1);
	};

	let newData = {};
	if (editData && editData?.id.gift) {
		const {
			msgForNote: title,
			shortMsg: name,
			longMsg: text,
			occassion,
		} = editData.id.gift;
		newData = {
			title,
			name,
			text,
			occassion,
		};
	}

	const { setIsRightSidebarOpen } = props;
	const [selectedframe, setSelectedFrame] = useState<
		SelectedFrame | undefined
	>();
	const [loading, setLoading] = useState<boolean>(false);
	const [setOfFourData, setSetOfFourData] = useState<SetOfFourData>(
		SET_OF_FOUR_FRAME_DATA
	);
	const dispatch = useAppDispatch();
	const token = useSelector(
		(state: { user: { token: string } }) => state.user.token
	);

	const [layout, setLayout] = useState<boolean>(true);
	const [allErrors, setAllErrors] = useState({});
	const [isSubmitValid, setIsSubmitValid] = useState<boolean>(true);
	const [backGround, setBackGround] = useState<BackgroundImageJson[]>([]);
	const [backGroundImages, setBackGroundImages] = useState<
		BackgroundImageJson[]
	>([]);
	const [isImageDelete, setIsImageDelete] = useState(false);
	const [isSetOfAllModalOpen, setIsSetOfAllModalOpen] = useState(false);
	const [count, setCount] = useState(4);
	const [bgData, setBgData] = useState<BgData>([]);
	const [fiveNotesData, setFiveNotesData] = useState<fiveNotesDataTS>(
		LAYOUT_ONE_DEFAULT_TEXT
	);
	const [resetAll, setResetAll] = useState(false);
	const [selectedDate, setSelectedDate] = useState<Date | string>();
	const [selectedFrameBackground, setSelectedFrameBackground] =
		useState<SelectedFrameBackground>();

	const bgDataImages = useSelector(
		(item: { cartImageSlice: { backgroundImages: []; userImages: [] } }) =>
			item.cartImageSlice.backgroundImages
	);

	useEffect(() => {
		setBgData(bgDataImages);
	}, [bgDataImages]);

	const { id } = useParams<{ id: string | undefined }>();
	const setOfFourDefaultValue = {
		date: typeEdit ? moment(editData?.id?.gift?.date).format('YY/MM/DD') : '',
		textName: '',
		currency: [],
		noteImage: [],
		image: {},
		text: '',
		denomination: [],
		isMultipleNote: false,
		SelectBackGround: {
			id: '',
			image: '',
			name: '',
		},
		imageFileJson: {},
		isCustom: false,
	};
	const [setOfFour, setSetOfFour] = useState<setOfFour>(setOfFourDefaultValue);

	const fileToUploadedUrl = async (file: File) => {
		let getSelectedImageString = '';
		const formData = new FormData();
		formData.append('image', file);
		const result = await getImageToURLString(formData);
		if (result.status === 200) {
			getSelectedImageString = result?.data?.image[0];
		}
		return getSelectedImageString;
	};

	const fetchBackGroundDetail = async () => {
		try {
			const res = await getBackgroundImage();
			if (!res.error) {
				const newBackGround = res.data.rows;
				setBackGround(newBackGround);
				const latestBackgrounds = [...newBackGround];
				setBackGroundImages(latestBackgrounds);
				setSetOfFour((value: setOfFour) => ({
					...value,
					SelectBackGround: res.data.rows[0],
				}));
			}
		} catch (error) {
			console.error('Error fetching addresses:', error);
		}
	};

	const resetForm = () => {
		setSetOfFour({
			...setOfFour,
			date: '',
			SelectBackGround: backGroundImages[0],
			currency: [],
			noteImage: [],
			denomination: [],
		});
		setFiveNotesData({
			...LAYOUT_ONE_DEFAULT_TEXT,
		});
		setSelectedDate('');
		setResetAll(true);
		reset();
	};

	const handleOnSubmit = async (dataUrl: string) => {
		let finalFrameJson: any = { type: 'single' };
		const bgImgaeJson: { bgImageId?: string; backgroundImage?: string } = {};

		if (setOfFour.isCustom) {
			bgImgaeJson['backgroundImage'] = await fileToUploadedUrl(
				setOfFour.imageFileJson
			);
		} else {
			bgImgaeJson['bgImageId'] = setOfFour?.SelectBackGround?.id;
		}

		if (id === CLASSES) {
			let userImageString = '';
			if (typeEdit) {
				userImageString = setOfFour.image;
			}
			if (setOfFour?.image?.name && !typeEdit) {
				userImageString = await fileToUploadedUrl(setOfFour.image);
			}
			finalFrameJson = {
				type: layout ? SINGLE : MULTIPLE,
				frameId: selectedframe?.id,
				...bgImgaeJson,
				occassion: fiveNotesData?.occassion,
				shortMsg: fiveNotesData?.name,
				longMsg: fiveNotesData?.text,
				msgForNote: fiveNotesData?.title,
				date: moment(setOfFour?.date, 'YY/MM/DD').format('YYYY/MM/DD'),
				denomination: setOfFour?.denomination,
				dataUrl: dataUrl,
			};
			if (
				Object.keys(userImageString)?.length !== 0 ||
				userImageString?.length !== 0
			) {
				finalFrameJson.image = userImageString;
			}
		}

		if (id === SET_OF_FOUR) {
			finalFrameJson = {
				type: SETS,
				frameId: selectedframe?.id,
				...bgImgaeJson,
				sets: Object.values(setOfFourData).map((set) => ({
					denomination: [set.currency],
					date: set.date,
					name: set.message,
					image: set.image,
				})),
				dataUrl: dataUrl,
			};
		}
		console.log({ finalFrameJson });
		if (token) {
			let addCart;
			if (typeEdit) {
				addCart = await updateNewGift(
					{ id: editData?.id?.gift?.id },
					finalFrameJson
				);
			} else {
				addCart = await addNewGift(finalFrameJson);
			}
			if (addCart?.error) {
				ErrorToaster(addCart?.message);
			} else {
				SuccessToaster(addCart?.message);
				dispatch(getAllAddToCart());
				setLoading(false);
			}
		} else {
			dispatch(allNoteCart(finalFrameJson));
			navigate('/login');
		}
		resetForm();
		if (setIsRightSidebarOpen && token) {
			setIsRightSidebarOpen(true);
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(getSizeName());
		};

		fetchBackGroundDetail();

		const handleBeforeUnload = (e: BeforeUnloadEvent) => {
			e.preventDefault();
			e.returnValue = '';
		};

		window.addEventListener('resize', handleResize);
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const [screenWidth, setScreenWidth] = useState<string>(getSizeName());
	const node: HTMLElement | null = document.getElementById('your-element-id');

	const validationSchema: any =
		id === SET_OF_FOUR
			? newGiftMultipleNoteValidationSchema
			: newGiftSingleNoteValidationSchema;

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		reset,
		formState: { errors, touchedFields, dirtyFields },
	} = useForm<FormData>({
		mode: 'all',
		defaultValues: {
			occassion: LAYOUT_ONE_DEFAULT_TEXT.occassion,
			date: null,
			name: LAYOUT_ONE_DEFAULT_TEXT.name,
			text: LAYOUT_ONE_DEFAULT_TEXT.text,
			title: LAYOUT_ONE_DEFAULT_TEXT.title,
			denomination: ['1'],
			userImage: USER_IMAGE_MALE,
			imageString: null,
		},
		resolver: yupResolver(validationSchema),
	});

	const handleMoveToSubmit = async () => {
		setLoading(true);
		let dataUrl;
		if (node) {
			try {
				dataUrl = await htmlToImage.toPng(node);
			} catch (error) {
				console.error('Oops, something went wrong!', error);
			}
		}
		await handleOnSubmit(dataUrl as string);
		setLoading(false);
	};

	useEffect(() => {
		const allSets = 'allSets';
		if (Object.keys(allErrors).length) {
			setValue(allSets, null);
			setError(allSets, { message: 'invalid' });
		} else {
			setValue(allSets, true);
			clearErrors(allSets);
		}
	}, [Object.keys(allErrors).length]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			setIsSubmitValid(false);
		} else {
			setIsSubmitValid(true);
		}
	}, [Object.keys(errors).length]);

	useEffect(() => {
		if (typeEdit) {
			setValue('imageString', 'something');
			clearErrors('imageString');
		}
	}, []);

	useEffect(() => {
		if (editData && editData?.id?.gift?.denomination?.length) {
			const currencyAllIndex = filterObjectsByRupee(
				Currency,
				editData?.id?.gift?.denomination
			);
			const selectedImages = currencyAllIndex.map((selectedIndex: number) => {
				return {
					image: Currency[selectedIndex]?.image,
					rupee: Currency[selectedIndex]?.rupee,
					noteIndex: selectedIndex,
				};
			});

			if (editData?.id?.gift?.denomination?.length > 1) {
				setSetOfFour((values: setOfFour) => ({
					...values,
					currency: currencyAllIndex,
					denomination: editData?.id?.gift?.denomination,
					noteImage: selectedImages,
					isMultipleNote: true,
				}));
			} else {
				setSetOfFour((values: setOfFour) => ({
					...values,
					currency: currencyAllIndex,
					denomination: editData?.id?.gift?.denomination,
					noteImage: selectedImages,
				}));
			}
		}

		if (editData?.id?.gift?.type === SINGLE) {
			setLayout(true);
		} else if (editData?.id?.gift?.type === MULTIPLE) {
			setLayout(false);
		}
	}, [editData]);

	useEffect(() => {
		if (typeEdit) {
			setFiveNotesData({
				...fiveNotesData,
				...newData,
			});
			if (editData?.id?.gift?.type === SETS) {
				const sets = editData.id.gift.sets;

				const transformedData = sets.reduce(
					(result: SetOfFourData, set: SetOfFourProductEl, index: number) => {
						result[`set${index + 1}`] = {
							currency: JSON.stringify(set.denomination[0]),
							price: 0,
							date: set.date,
							message: set.name,
							image: set.image,
						};
						return result;
					},
					{}
				);
				setSetOfFourData(transformedData);
			}
		}
	}, [editData]);

	useEffect(() => {
		setResetAll(false);
	}, []);

	return (
		<>
			<SEO title="New Gift" description={NEW_GIFT_LAYOUTS} />
			<div className="lg:h-[876px] relative lg:min-h-[calc(100vh-168px)]">
				<form onSubmit={handleSubmit(handleMoveToSubmit)} className="h-full">
					<div className="max-w-[2500px] flex flex-col lg:flex-row my-0 mx-auto w-full bg-gray-50 overflow-hidden h-full">
						<FrameLayout
							id={id}
							node={node}
							count={count}
							layout={layout}
							bgData={bgData}
							setLayout={setLayout}
							setOfFour={setOfFour}
							screenWidth={screenWidth}
							fiveNotesData={fiveNotesData}
							selectedframe={selectedframe}
							isSubmitValid={isSubmitValid}
							setOfFourData={setOfFourData}
							isImageDelete={isImageDelete}
							backGroundImages={backGroundImages}
							selectedFrameBackground={selectedFrameBackground}
							setSelectedFrameBackground={setSelectedFrameBackground}
							loading={loading}
							typeEdit={typeEdit}
							editData={editData}
						/>

						<div
							className="lg:w-2/4 xxl:w-[60%] shadow-[rgba(0,0,0,0.19)_0px_0px_20px,rgba(0,0,0,0.23)_0px_6px_6px] h-full overflow-auto"
							style={{
								background: WHITE,
							}}
						>
							<div className="py-6" style={{ background: WHITE_SMOKE }}>
								{id === CLASSES && (
									<FiveNotes
										id={id}
										formEl={{
											register,
											errors,
											touchedFields,
											dirtyFields,
											setValue,
											setError,
											clearErrors,
										}}
										bgData={bgData}
										layout={layout}
										setLayout={setLayout}
										setOfFour={setOfFour}
										backGround={backGround}
										setSetOfFour={setSetOfFour}
										fiveNotesData={fiveNotesData}
										isSubmitValid={isSubmitValid}
										isImageDelete={isImageDelete}
										setSelectedFrame={setSelectedFrame}
										setFiveNotesData={setFiveNotesData}
										backGroundImages={backGroundImages}
										setIsImageDelete={setIsImageDelete}
										setBackGroundImages={setBackGroundImages}
										setIsSetOfAllModalOpen={setIsSetOfAllModalOpen}
										setSelectedFrameBackground={setSelectedFrameBackground}
										loading={loading}
										typeEdit={typeEdit}
										selectedDate={selectedDate}
										setSelectedDate={setSelectedDate}
										setResetAll={setResetAll}
										resetAll={resetAll}
										setOfFourData={setOfFourData}
									/>
								)}

								{id === SET_OF_FOUR && (
									<SetOf4
										id={id}
										setOfFour={setOfFour}
										backGround={backGround}
										setAllErrors={setAllErrors}
										setSetOfFour={setSetOfFour}
										setOfFourData={setOfFourData}
										setSelectedFrame={setSelectedFrame}
										setSetOfFourData={setSetOfFourData}
										backGroundImages={backGroundImages}
										setBackGroundImages={setBackGroundImages}
										setSelectedFrameBackground={setSelectedFrameBackground}
										setCount={setCount}
										count={count}
										isSubmitValid={isSubmitValid}
										bgData={bgData}
										loading={loading}
										typeEdit={typeEdit}
										editData={editData}
										resetAll={resetAll}
									/>
								)}
							</div>
						</div>
					</div>
				</form>
			</div>
			{isSetOfAllModalOpen && (
				<Modal
					isOpen={isSetOfAllModalOpen}
					onClose={() => setIsSetOfAllModalOpen(false)}
				>
					<OrderModel
						id="setOfAllNotes"
						isModalOpen={setIsSetOfAllModalOpen}
						setIsModalOpen={setIsSetOfAllModalOpen}
					/>
				</Modal>
			)}
		</>
	);
};

export default NewGift;
