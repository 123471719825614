import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getLimitedEdition } from '../Api/UserListApi';
import { Params } from '../Api/Types';
import { LimitedEditionData } from '../Interface/Index';

export interface LimitedEditionState {
	limitedList: LimitedEditionData[];
	isSold: boolean;
	loading: boolean;
	tableData: {
		limit: number;
		page: number;
		count: number;
	};
}
const tableData = {
	limit: 8,
	page: 1,
	count: 0,
};

const initialState: LimitedEditionState = {
	limitedList: [],
	loading: false,
	tableData: tableData,
	isSold: false,
};
export const getAllLimitedEditions = createAsyncThunk(
	'/limited',
	async (params?: Params) => {
		try {
			const response = await getLimitedEdition(params);
			return response.data;
		} catch (error) {
			console.log('Error fetching limited editions:', error);
		}
	}
);
const limitedEditionSlice = createSlice({
	name: 'limitedEdition',
	initialState,
	reducers: {
		setDataLimit: (state, action) => {
			state.tableData.limit = action.payload;
		},
		setPageData: (state, action) => {
			state.tableData.page = action.payload;
		},
		setCountData: (state, action) => {
			state.tableData.count = action.payload;
		},
		setIsSold: (state, action) => {
			state.isSold = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllLimitedEditions.fulfilled, (state, action) => {
				state.limitedList = action.payload?.rows;
				state.tableData.count = action.payload?.count;
				state.isSold = action.payload?.isSold;
				state.loading = false;
			})
			.addCase(getAllLimitedEditions.pending, (state) => {
				state.loading = true;
			});
	},
});

export const { setDataLimit, setPageData, setCountData, setIsSold } =
	limitedEditionSlice.actions;

export default limitedEditionSlice.reducer;
