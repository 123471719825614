import { toast } from 'react-toastify';

export const Toaster = ({
	status,
	message,
	toastId,
}: {
	status: 'error' | 'success';
	message?: string;
	toastId?: string;
}) => {
	toast[status](message, {
		position: 'top-right',
		toastId: toastId ?? '1',
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
	});
};
