import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFaq } from '../Api/UserListApi';

export type FaqList = {
	loading: boolean;
	faqList: Faq[];
};

export type Faq = {
	answer: string;
	id: string;
	question: string;
};

const initialState: FaqList = {
	faqList: [],
	loading: false,
};

export const getAllFaq = createAsyncThunk('/faq', async () => {
	try {
		const response = await getFaq();
		return response.data.rows;
	} catch (error) {
		console.log(error);
	}
});

export const faqSlice = createSlice({
	name: 'faq',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllFaq.fulfilled, (state, action) => {
				state.faqList = action.payload;
				state.loading = false;
			})
			.addCase(getAllFaq.pending, (state) => {
				state.loading = true;
			});
	},
});

export default faqSlice.reducer;
