import { THE_RUPI } from './KeyWord.Constant';

export const ABOUT_US = `Learn more about ${THE_RUPI} and our goal to curate a diverse collection of Indian currency notes featuring special serial numbers. Explore our platform where you can find and purchase distinctive notes to express your love, appreciation, and best wishes.`;

export const HOME = `Indian currency frames, special date frames, personalized gifts, currency art, custom frames, gift ideas, Indian rupee, currency notes, memorable gifts`;

export const CONTACT_US = `Reach out to ${THE_RUPI} through our Contact Us page. We value your inquiries, feedback, and suggestions. Whether you have questions about our products, need assistance, or want to collaborate, we're here to assist you. Connect with us today to make your experience with ${THE_RUPI} exceptional.`;

export const EXCLUSIVE_NOTE_COLLECTION = `Embark on a journey to discover the future of gifting with ${THE_RUPI}'s exclusive note collections. Carefully curated and not available for sale yet, these collections offer captivating glimpses of what's to come. Each piece is a unique and extraordinary preview of tomorrow's history. Stay tuned for the day when you can own one of these exceptional and exclusive treasures, making your gifting experience truly special and timeless.`;

export const LIMITED_EDITION_NOTES = `Experience the allure of ${THE_RUPI}'s limited edition notes, available for only a short period. Seize the opportunity to own these exclusive pieces that won't be around for long. Reach out to us now and secure your chance to acquire a piece of this unique and limited collection. Elevate your gifting with these rare and coveted notes, making every moment a cherished memory.`;

export const PERSONAL_INFORMATION = `Your personal information is your digital identity on ${THE_RUPI}. Safeguarded with advanced security measures, we prioritize the privacy and protection of your data. From your name and contact details to your preferences, trust us to keep your information confidential. Explore and update your personal details with confidence, knowing that your privacy is our priority.`;

export const MY_ORDERS = `Explore your order history on ${THE_RUPI}! The My Orders page provides a detailed overview of your past purchases, order status, and tracking information. Easily track the journey of your favorite currency notes, personalized gifts, and more. Your satisfaction is our priority, and the My Orders page is designed to keep you informed and delighted with every purchase.`;

export const USER_ADDRESS = `Manage your address details seamlessly on ${THE_RUPI}. The User Address page is your personalized space to add, update, and organize delivery addresses. Ensure a smooth shopping experience by keeping your address book up-to-date. Add new addresses, edit existing ones, and enjoy hassle-free deliveries of your favorite currency notes and personalized gifts.`;

export const FAQ_PAGE = `Explore the frequently asked questions about ${THE_RUPI} and gain valuable insights into our platform. The FAQ page is designed to provide answers to common queries, helping you navigate the world of unique Indian currency notes and personalized gifts effortlessly. Get to know more about our services, offerings, and how to make the most of your experience with ${THE_RUPI}.`;

export const NEW_GIFT_LAYOUTS = `Explore unique gift ideas on ${THE_RUPI}'s New Gift Layouts page. Discover a range of exclusive options`;

export const LOGIN = `Login Page of ${THE_RUPI}`;

export const CHECKOUT = `Checkout Page For Your Product.`;

export const PAYMENT_METHOD = `Payment For ${THE_RUPI} Product.`;

export const EXCLUSIVE_DETAILS = `Our Exclusive Notes Collection.`;

export const PRIVACY_POLICY = `Privacy Policy Page For ${THE_RUPI} User.`;

export const TERMS_CONDITION = `Terms & Conditions Page For ${THE_RUPI} User.`;

export const FORGOT_PASSWORD = `Forgot Password Page For ${THE_RUPI} User.`;

export const RESET_PASSWORD_DESCRIPTION = `Reset Password Page For ${THE_RUPI} User.`;

export const SIGN_UP_DESCRIPTION = `Sign Up Page For ${THE_RUPI} User.`;

export const NOTE_LAYOUTS = `Note Layouts Page For ${THE_RUPI} User.`;
