import {
	LG,
	MD,
	ND,
	SCREEN_SIZES,
	SM,
	XL,
	XS,
	XXL,
} from '../Constant/Constant';

export const getSizeName = () => {
	const windowSize = window.innerWidth;
	const {
		XXXL: S_XXXL,
		XXL: S_XXL,
		XL: S_XL,
		LG: S_LG,
		MD: S_MD,
		SM: S_SM,
		ND: S_ND,
	} = SCREEN_SIZES;
	let screenName = '';
	switch (true) {
		case (windowSize > S_XXL && windowSize < S_XXXL) || windowSize > S_XXXL:
			screenName = XXL;
			break;
		case windowSize > S_XL && windowSize <= S_XXL:
			screenName = XL;
			break;
		case windowSize > S_LG && windowSize <= S_XL:
			screenName = LG;
			break;
		case windowSize > S_MD && windowSize <= S_LG:
			screenName = MD;
			break;
		case windowSize > S_SM && windowSize <= S_MD:
			screenName = SM;
			break;
		case windowSize >= S_ND && windowSize <= S_SM:
			screenName = ND;
			break;
		case windowSize < S_ND:
			screenName = XS;
			break;
	}
	return screenName;
};
