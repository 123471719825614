import React from 'react';
import { BLACK, BORDERCOLOR } from '../../Constant/Color.Constant';
import { Address } from '../../Interface/Index';
import { PiAddressBookDuotone } from 'react-icons/pi';

interface AddressComponentProps {
	addresses: Address[];
	selectedAddressId: string;
	setSelectedAddressId: (id: string) => void;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	onSelectAddress: (addressId: string) => void;
}

const AddressComponent: React.FC<AddressComponentProps> = ({
	addresses,
	setSelectedAddressId,
	selectedAddressId,
}) => {
	const handleAddressSelection = (addressId: string) => {
		setSelectedAddressId(addressId);
	};

	return (
		<div className="flex w-full">
			{addresses.length > 0 ? (
				<div className="flex w-full flex-col gap-5">
					{addresses?.map((address: Address, index: number) => (
						<div
							className="flex w-full flex-col"
							key={address.id}
							onClick={() => handleAddressSelection(address.id)}
						>
							<div className="flex gap-3 w-full">
								<div className="w-6 pt-0.5">
									<div className="w-full h-6 flex justify-center items-center">
										<div
											className="h-5 w-5 border-2 rounded-full p-[3px] cursor-pointer"
											style={{
												borderColor: BLACK,
											}}
										>
											{address.id === selectedAddressId && (
												<div
													className="w-full h-full rounded-full"
													style={{
														background: BLACK,
													}}
												></div>
											)}
										</div>
									</div>
								</div>
								<div className="flex flex-col w-[calc(100%-36px)]">
									<div className="flex flex-col justify-center gap-1 leading-6">
										<p className="capitalize truncate">{address.name}</p>
										<p className="w-full break-all">{address?.address}</p>
										<p>{address.pinCode}</p>
									</div>
								</div>
							</div>
							{addresses.length - 1 !== index && (
								<div>
									<div
										className="border-t mt-5"
										style={{ borderColor: BORDERCOLOR }}
									/>
								</div>
							)}
						</div>
					))}
				</div>
			) : (
				<div className="w-full flex flex-col justify-center items-center gap-4 h-[230px]">
					<p className="text-3xl mt-4 font-medium font-RobotoSlab">
						Your Address is Empty
					</p>

					<PiAddressBookDuotone style={{ color: BLACK, fontSize: '40px' }} />
				</div>
			)}
		</div>
	);
};

export default AddressComponent;
