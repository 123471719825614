import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { testimonials } from '../../../Constant/Constant';
import { TestimonialData } from '../../../Interface/Index';
import RightSlider from '../../../Components/Svg/RightSlider';
import LeftSlider from '../../../Components/Svg/LeftSlider';
import { GRAY50, WHITE } from '../../../Constant/Color.Constant';
import Image from '../../../Components/Image/Image';

interface SampleArrowProps {
	onClick?: () => void;
}
const SlidersAbout = () => {
	const settings = {
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		autoplaySpeed: 2000,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 850,
				dots: true,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 500,
				settings: {
					dots: true,
					slidesToShow: 1,
				},
			},
		],
	};
	function SamplePrevArrow(props: SampleArrowProps) {
		const { onClick } = props;
		return (
			<div
				onClick={onClick}
				className="absolute left-5 lg:top-2/4 md:top-2/4 xs:top-[90%] sm:top-[86%] -translate-y-2/4 cursor-pointer z-10"
			>
				<div className="rounded-lg">
					<LeftSlider strokeColor="white" />
				</div>
			</div>
		);
	}

	function SampleNextArrow(props: SampleArrowProps) {
		const { onClick } = props;
		return (
			<div
				onClick={onClick}
				className="lg:top-2/4 md:top-2/4 xs:top-[90%] z-10 cursor-pointer -translate-y-2/4 absolute right-5"
			>
				<div className="rounded-lg">
					<RightSlider strokeColor="white" />
				</div>
			</div>
		);
	}

	const truncateText = (text: string, maxWords: number, breakpoint: number) => {
		const words = text.split(' ');
		const screenWidth = window.innerWidth;

		if (words.length <= maxWords) {
			return text;
		}

		if (screenWidth <= 400) {
			return words.slice(0, maxWords).join(' ') + '...';
		} else {
			return words.length > breakpoint
				? words.slice(0, breakpoint).join(' ') + '...'
				: words.slice(0, maxWords).join(' ') + '...';
		}
	};

	return (
		<div className="flex justify-center slider-slide mx-auto">
			<div className="about-slider w-full z-10 overflow-hidden flex flex-col justify-center">
				<Slider {...settings}>
					{testimonials?.map((testimonial: TestimonialData, index: number) => (
						<div
							key={index}
							className="slider-slide w-full bg-black lg:px-24 lg:py-16 sm:px-8 sm:py-16 xs:px-8 xs:py-16"
						>
							<div className="flex flex-col gap-8">
								<div
									className="flex sm:justify-center justify-start sm:items-center items-start text-justify max-w-[527px] w-full "
									style={{ color: WHITE }}
								>
									<p className="text-lg min-h-[112px] ">
										{truncateText(testimonial.description, 20, 50)}
									</p>
								</div>
								<div className="flex gap-4">
									<img
										className="rounded-lg"
										src={testimonial.image1}
										alt="Avatar"
										height="52px"
										width="52px"
									/>
									<div className="flex flex-col">
										<div className="h-6" style={{ color: WHITE }}>
											<p className="text-base uppercase">{testimonial.name}</p>
										</div>
										<div className="flex gap-4">
											<Image
												image={testimonial.image}
												className="!rounded-lg"
												altName="Avatar"
												width="52px"
												height="52px"
											/>
											<div className="flex flex-col">
												<div className="h-6" style={{ color: WHITE }}>
													<p className="text-base uppercase">
														{testimonial.name}
													</p>
												</div>
											</div>
											<div className="text-sm" style={{ color: GRAY50 }}>
												<p className="text-base">{testimonial.role}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default SlidersAbout;
