import React, { useEffect, useState } from 'react';
import CommonHeader from '../../../Components/Header/CommonHeader';
import { deleteUserAddress, getUserAddress } from '../../../Api/UserListApi';
import { Address } from '../../../Interface/Index';
import CommonLoader from '../../../Components/Loader/Loader';
import SEO from '../../../Components/Helmet/SEO';
import UserAddressForm from '../../../Components/UserAddressForm/UserAddressForm';
import CommonButton from '../../../Components/Button/CommonButton';
import DeleteModal from '../../../Components/DeleteModel/DeleteModel';
import Edit from '../../../Components/Svg/Edit';
import Delete from '../../../Components/Svg/Delete';
import { PiAddressBookDuotone } from 'react-icons/pi';
import { USER_ADDRESS } from '../../../Constant/Seo.Constant';
import {
	BGGRAY,
	BLACK,
	TEXTCOLOR,
	WHITE,
	WHITE10,
} from '../../../Constant/Color.Constant';
import { ErrorToaster, SuccessToaster } from '../../../helpers/CustomToaster';

interface UpdateAddressType {
	id: string;
	name: string;
	address: string;
	pinCode: string;
}

const UserAddressBook = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [addresses, setAddresses] = useState<Address[]>([]);
	const [loader, setLoader] = useState<boolean>();
	const [showForm, setShowForm] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [addressToDelete, setAddressToDelete] = useState<string>('');
	const [updateData, setUpdateData] = useState<UpdateAddressType>();
	const [typeSubmit, setTypeSubmit] = useState<string>();
	const [formData, setFormData] = useState<UpdateAddressType>({
		id: '',
		name: '',
		address: '',
		pinCode: '',
	});

	const handleAddNewAddress = (address?: any) => {
		if (address.type === 'edit') {
			setUpdateData(address.address);
			setTypeSubmit(address.type);
			setFormData(address.address);
		} else {
			setTypeSubmit(address.type);
			setFormData({
				id: '',
				name: '',
				address: '',
				pinCode: '',
			});
		}
		setShowForm(true);
	};

	const handleDelete = async (id: string) => {
		try {
			const { error, message } = await deleteUserAddress({ id });
			error ? ErrorToaster(message) : SuccessToaster(message);
		} catch (error) {
			console.error('Error fetching Products:', error);
		}
		setLoading(true);
	};

	const openDeleteModal = (addressId: string) => {
		setAddressToDelete(addressId);
		setIsDeleteModalOpen(true);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const fetchUserAddresses = async () => {
		setLoader(true);

		try {
			const res = await getUserAddress();

			setAddresses(res.data.rows);
		} catch (error) {
			console.error('Error fetching addresses:', error);
		}
		setLoader(false);
	};

	useEffect(() => {
		fetchUserAddresses();
		return () => {
			setLoading(false);
		};
	}, [loading]);

	return (
		<>
			<SEO title="User Address" description={USER_ADDRESS} />
			<div className="w-full h-full flex justify-center sm:justify-center xs:justify-center lg:items-center sm:items-center">
				<div className="lg:w-[80%] w-full flex flex-col gap-0 md:gap-4">
					<div className="hidden lg:block">
						<CommonHeader title="ADDRESS BOOK" />
					</div>
					<div
						className="hidden lg:block w-full"
						style={{ borderBottom: `1px solid ${WHITE10}` }}
					></div>
					<div className="flex flex-col justify-center items-center gap-4 w-full sm:w-full md:w-full lg:w-full xs:w-full ">
						<div className="w-full h-[calc(100vh-310px)] overflow-scroll">
							{addresses.length > 0 ? (
								<>
									<div className="flex lg:justify-between xs:justify-center rounded-lg">
										{loader && (
											<div>
												<CommonLoader visible={true} />
											</div>
										)}
										<div className="flex flex-col lg:gap-3 xs:gap-6 font-normal text-base w-full ">
											{addresses?.map((address: Address) => (
												<div
													className="flex w-full justify-between"
													key={address.id}
												>
													<div className="flex flex-col w-full">
														<div
															className="flex  md:flex-row flex-col sm:justify-between  justify-center p-5  rounded-xl gap-3"
															style={{ backgroundColor: BGGRAY }}
														>
															<div className="flex gap-1 md:gap-2 flex-col w-[calc(100%-44px)]">
																<p
																	className=" xs:text-base lg:text-lg  xs:font-medium lg:font-normal truncate"
																	style={{ color: TEXTCOLOR }}
																>
																	{address.name}
																</p>
																<p
																	className="xs:text-sm lg:text-lg leading-6 break-all"
																	style={{ color: TEXTCOLOR }}
																>
																	{address.address}
																</p>
																<p
																	className="xs:text-sm lg:text-lg leading-6 break-all"
																	style={{ color: TEXTCOLOR }}
																>
																	{address.pinCode}
																</p>
															</div>
															<div className="lg:gap-4 flex lg:flex-col xs:gap-3 items-start">
																<div className="md:hidden flex  gap-3 md:w-auto w-full">
																	<div
																		className="px-2 py-2 xs:py-2.5 rounded-[8px] min-w-[154px] xs:min-w-[100px] sm:w-2/4 flex w-full justify-center items-center"
																		style={{ backgroundColor: WHITE }}
																	>
																		<Edit
																			width="20px"
																			onClick={() =>
																				handleAddNewAddress({
																					type: 'edit',
																					address,
																				})
																			}
																		/>
																	</div>
																	<div
																		className="px-2 py-2 xs:py-2.5  rounded-[8px] min-w-[154px] xs:min-w-[100px] sm:w-2/4 w-full flex justify-center items-center"
																		style={{ backgroundColor: WHITE }}
																	>
																		<Delete
																			width="20px"
																			onClick={() =>
																				openDeleteModal(address?.id)
																			}
																			color="#EF4444"
																		/>
																	</div>
																</div>

																<div className="hidden md:block">
																	<div className="flex gap-2 flex-col">
																		<div
																			className="p-2  rounded-lg"
																			style={{ backgroundColor: WHITE }}
																		>
																			<Edit
																				width="20px"
																				onClick={() =>
																					handleAddNewAddress({
																						type: 'edit',
																						address,
																					})
																				}
																			/>
																		</div>
																		<div
																			className="p-2  rounded-lg"
																			style={{ backgroundColor: WHITE }}
																		>
																			<Delete
																				width="20px"
																				onClick={() =>
																					openDeleteModal(address.id)
																				}
																				color="#EF4444"
																			/>
																		</div>
																	</div>
																</div>

																{/* mobile view */}
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</>
							) : (
								<div className="w-full flex flex-col justify-center items-center gap-4 h-[230px] border">
									<p className="text-3xl mt-4 font-medium font-RobotoSlab">
										Your Address is Empty
									</p>

									<PiAddressBookDuotone
										style={{ color: BLACK, fontSize: '40px' }}
									/>
								</div>
							)}

							<div className="mt-5">
								<CommonButton
									width="100%"
									label="ADD NEW ADDRESS"
									onClick={() => handleAddNewAddress({ type: 'submit' })}
								></CommonButton>
								{showForm && (
									<UserAddressForm
										setLoading={setLoading}
										onClose={() => setShowForm(false)}
										updateData={{
											address: updateData,
											type: typeSubmit,
										}}
										typeSubmit={typeSubmit}
										formData={formData}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{isDeleteModalOpen && (
				<DeleteModal
					title="Delete Address"
					message="Are you sure you want to delete Address?"
					onDelete={() => {
						handleDelete(addressToDelete);
						closeDeleteModal();
					}}
					onCancel={closeDeleteModal}
					onClose={closeDeleteModal}
				/>
			)}
		</>
	);
};

export default UserAddressBook;
