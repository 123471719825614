import React from "react";
import { CommonSvgProps } from "../../Interface/Index";

const Accordion: React.FC<CommonSvgProps> = ({
  onClick,
  style,
  width = "24",
  height = "24",
}) => {
  return (
    <div onClick={onClick} className={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 9L12 15L6 9"
          stroke="#1E1E1E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Accordion;
