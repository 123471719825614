import React from 'react';

const Information = () => {
	return (
		<div>
			<svg
				width="25px"
				height="25px"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx="12"
					cy="12"
					r="9"
					stroke="#292929"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<rect
					height="0.01"
					stroke="#292929"
					strokeLinejoin="round"
					strokeWidth="3"
					width="0.01"
					x="12"
					y="8"
				/>
				<path
					d="M12 12V16"
					stroke="#292929"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
			</svg>
		</div>
	);
};

export default Information;
