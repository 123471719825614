import React, { useState } from 'react';
import CommonButton from '../Button/CommonButton';
import { postUserAddress, updateUserAdress } from '../../Api/UserListApi';
import { Toaster } from '../Toaster/Index';
import ErrorMessage from '../Typography/ErrorText';
import CommonLabel from '../InputLable/lable';
import Modal from '../Model/Modal';
import { CustomTextField } from '../Input/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { UpdateAddressType, UserAddressType } from '../../Interface/Index';
import Remove from '../Svg/Remove';
import { userAddressValidationSchema } from '../Validataions/ValidataionSchema';
import { GRAY900 } from '../../Constant/Color.Constant';

interface UserAddressFormProps {
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	onClose: () => void;
	updateData?: { address?: UpdateAddressType; type?: string };
	typeSubmit?: string;
	formData?: {
		id: string;
		name: string;
		address: string;
		pinCode: string;
	};
}

const UserAddressForm: React.FC<UserAddressFormProps> = ({
	setLoading,
	onClose,
	updateData,
	typeSubmit,
	formData,
}) => {
	const [isUpdate, setIsUpdate] = useState(false);
	const [loader, setLoader] = useState<boolean>();

	const defaultValue = {
		name: formData?.name ?? '',
		address: formData?.address ?? '',
		pinCode: formData?.pinCode ?? '',
	};

	const onSubmit = async (event: UserAddressType) => {
		setLoader(true);
		if (typeSubmit === 'edit') {
			const res = await updateUserAdress(
				{ id: updateData?.address?.id },
				event
			);
			if (res.error) {
				Toaster({ status: 'error', message: res.message });
			} else {
				Toaster({ status: 'success', message: res.message });
				setIsUpdate(!isUpdate);
				onClose();
			}
			setLoading(true);
		} else {
			const res = await postUserAddress(event);
			if (res.error) {
				Toaster({ status: 'error', message: res.message });
			} else {
				Toaster({ status: 'success', message: res.message });
				onClose();
			}
			setLoading(true);
			setLoader(false);
		}
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<UserAddressType>({
		defaultValues: defaultValue,
		resolver: yupResolver(userAddressValidationSchema),
	});

	return (
		<Modal
			buttonName="Submit Order"
			onClose={onClose}
			onButtonClick={() => {}}
			className=" w-full xs:overflow-scroll sm:overflow-scroll lg:px-12 lg:py-8 p-3"
		>
			<div className="flex flex-col  md:gap-6 gap-5 w-full">
				<div className="flex justify-between w-full items-center">
					<h2
						className={`text-base md:text-xl leading-6 md:leading-7 md:font-bold font-semibold uppercase text-${GRAY900}`}
					>
						{typeSubmit === 'edit' ? 'Edit Address' : 'New Address'}
					</h2>
					<button onClick={onClose}>
						<Remove />
					</button>
				</div>
				<form className="h-full" onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-col md:gap-6 gap-5">
						<div className="lg:grid lg:gap-8 grid-cols-2 sm:gap-5 xs:gap-5 xs:flex xs:flex-col">
							<div className="flex flex-col gap-1">
								<CommonLabel labelText="Full Name" />
								<CustomTextField
									placeholder="Enter your full name"
									name="name"
									register={register}
								/>
								<ErrorMessage message={errors.name?.message} />
							</div>

							<div className="flex flex-col gap-1">
								<CommonLabel labelText="Pincode" />
								<CustomTextField
									placeholder="Enter your pincode"
									name="pinCode"
									type="number"
									register={register}
								/>
								<ErrorMessage message={errors.pinCode?.message} />
							</div>
						</div>
						<div className="flex flex-col gap-1">
							<CommonLabel labelText="Address" />
							<CustomTextField
								placeholder="Enter your address"
								name="address"
								type="textarea"
								rows={4}
								register={register}
							/>
							<ErrorMessage message={errors.address?.message} />
						</div>
					</div>

					<div className="flex xs:w-full justify-end text-center md:mt-6 mt-5">
						<CommonButton
							className="!bg-black text-white rounded-lg uppercase xs:w-full items-center justify-center flex lg:w-[100px] leading-6 !text-base font-semibold"
							padding="12px 32px"
							type="submit"
							label={typeSubmit === 'edit' ? 'Edit' : 'Add'}
							loading={loader}
						/>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default UserAddressForm;
