import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getExclusiveNoteData } from '../Api/UserListApi';
import { Params } from '../Api/Types';

export type ExclusiveList = {
	loading: boolean;
	exclusiveList: exclusiveList[];
	tableData: {
		limit: number;
		page: number;
		count: number;
	};
};

export type exclusiveList = {
	name: string;
	images: string[];
	description: string | undefined;
	to: string | undefined;
};

const tableData = {
	limit: 8,
	page: 1,
	count: 0,
};

const initialState: ExclusiveList = {
	exclusiveList: [],
	loading: false,
	tableData: tableData,
};

export const getAllExclusiveNote = createAsyncThunk(
	'/exclusive',
	async (params?: Params) => {
		try {
			const response = await getExclusiveNoteData(params);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);

export const exclusiveSlice = createSlice({
	name: 'exclusive',
	initialState,
	reducers: {
		setDataLimit: (state, action) => {
			state.tableData.limit = action.payload;
		},
		setPageData: (state, action) => {
			state.tableData.page = action.payload;
		},
		setCountData: (state, action) => {
			state.tableData.page = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllExclusiveNote.fulfilled, (state, action) => {
				state.exclusiveList = action?.payload?.rows;
				state.tableData.count = action?.payload?.count;
				state.loading = false;
			})
			.addCase(getAllExclusiveNote.pending, (state) => {
				state.loading = true;
			});
	},
});

export const { setDataLimit, setPageData, setCountData } =
	exclusiveSlice.actions;

export default exclusiveSlice.reducer;
