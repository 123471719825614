import React from 'react';
import { ButtonProps } from '../../Interface/Index';
import CommonLoader from '../Loader/Loader';
import RightArrow from '../Svg/Right Arrow';

const CommonButton: React.FC<ButtonProps & { loading?: boolean }> = ({
	id,
	label,
	width,
	height,
	fontSize = '20px',
	padding,
	backgroundColor,
	arrowPosition = false,
	lineHeight,
	loading = false,
	justifyContent = 'center',
	...props
}) => {
	return (
		<button
			id={id}
			style={{
				lineHeight: lineHeight,
				width: width ? width : '',
				height: height,
				padding: padding ? padding : '12px 32px  ',
				backgroundColor: backgroundColor ? backgroundColor : 'black',
				fontSize: fontSize,
				fontWeight: 500,
			}}
			disabled={loading}
			className={`p-[${
				padding ? padding : '12px'
			}] tracking-wide flex items-center justify-center ${
				arrowPosition ? 'justify-between' : 'justify-center'
			} text-white text-lg font-bold p-0.5  relative rounded-lg uppercase `}
			{...props}
		>
			<div
				style={{
					display: 'flex',
					gap: '4px',
					justifyContent: `${justifyContent}`,
					alignItems: 'center',
				}}
			>
				<div className="relative flex gap-4">
					<span>{label}</span>
					{loading && (
						<div className="flex justify-center items-center">
							<CommonLoader height={20} width={20} color="white" />
						</div>
					)}
				</div>
				{arrowPosition && <RightArrow className="z-100000 h-5" />}
			</div>
		</button>
	);
};

export default CommonButton;
