import { BLACK, WHITE } from '../../Constant/Color.Constant';
import { TrackerProps } from '../../Interface/Index';

export default function Tracker({ activeStep, setPagination }: TrackerProps) {
	return (
		<>
			<ol className="flex items-center w-full">
				{Array.from({ length: 3 }, (_, index) => (
					<li
						key={index}
						className={`flex items-center justify-start`}
						style={{ width: index !== 2 ? '100%' : 'auto' }}
					>
						{index <= 1 && (
							<div
								className={`${activeStep >= index
									? 'border-black dark:border-black'
									: 'border-gray-200 dark:border-[#E5E7EB]'
									} border-t-2 w-8 lg:w-4`}
							>
								<div
									className={`w-0 transition-width duration-3000 ${activeStep >= index ? 'w-[20px]' : 'w-0'
										}`}
									style={{ backgroundColor: BLACK }}
								></div>
							</div>
						)}
						<span
							className={`flex  items-center justify-center lg:text-2xl text-base rounded-lg w-8 h-8 lg:w-12 lg:h-12  shrink-0`}
							style={{
								backgroundColor: activeStep >= index ? BLACK : '#E5E7EB',
								color: activeStep >= index ? WHITE : '#6B7280',
								cursor: activeStep >= index ? "pointer" : "not-allowed"
							}}
							onClick={() => activeStep > index && setPagination(index)
							}
						>
							{index + 1}
						</span>
						{index < 2 && (
							<div
								className={`${activeStep > index
									? 'border-black dark:border-black'
									: 'border-gray-200 dark:border-[#E5E7EB]'
									} border-t-2 w-full`}
							>
								<div
									className={`w-0 transition-width duration-3000 ${activeStep >= index ? 'w-full' : 'w-0'
										}`}
									style={{ backgroundColor: BLACK }}
								></div>
							</div>
						)}
						{index === 2 && (
							<div
								className={`${activeStep >= index
									? 'border-black dark:border-black'
									: 'border-gray-200 dark:border-[#E5E7EB]'
									} border-t-2 w-8 lg:w-4`}
							>
								<div
									className={`w-0 transition-width duration-3000 ${activeStep >= index ? 'w-[20px]' : 'w-0'
										}`}
									style={{ backgroundColor: BLACK }}
								></div>
							</div>
						)}
					</li>
				))}
			</ol>
		</>
	);
}
