import React from 'react';
import { ErrorMessageProps } from '../../Interface/Index';

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
	function capitalizeFirstCharacter(inputString: string) {
		if (typeof inputString !== 'string' || inputString.length === 0) {
			return inputString;
		}
		return inputString.charAt(0).toUpperCase() + inputString.slice(1);
	}
	return (
		<>
			{message?.length > 0 && (
				<div>
					<p className="text-[red] text-sm mt-0.5">
						{capitalizeFirstCharacter(message)}
					</p>
				</div>
			)}
		</>
	);
};

export default ErrorMessage;
