import React, { useState } from 'react';
import { forgetPassword } from '../../Api/UserListApi';
import CommonButton from '../../Components/Button/CommonButton';
import { useForm } from 'react-hook-form';
import { ForgetType } from '../../Api/Types';
import ErrorMessage from '../../Components/Typography/ErrorText';
import { forgetPasswordValidationSchema } from '../../Components/Validataions/ValidataionSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import CommonLabel from '../../Components/InputLable/lable';
import { CustomTextField } from '../../Components/Input/TextField';
import {
	TEXTCOLOR,
	TEXTCOLORGRAY,
	WHITE,
	WHITE10,
} from '../../Constant/Color.Constant';
import { ROUTES } from '../../Constant/Routes.Constant';
import { useNavigate } from 'react-router-dom';
import { ErrorToaster, SuccessToaster } from '../../helpers/CustomToaster';
import SEO from '../../Components/Helmet/SEO';
import { FORGOT_PASSWORD } from '../../Constant/Seo.Constant';

interface ForgetPasswordProps {
	handleClose: () => void;
}

const ForgetPassword: React.FC<ForgetPasswordProps> = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [change, setChange] = useState({
		email: '',
	});

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedChange = { ...change, email: e.target.value };
		setChange(updatedChange);
	};

	const navigate = useNavigate();
	const handleClick = () => {
		navigate(ROUTES.LOGIN);
	};
	const onSubmit = async (data: { email: string }) => {
		setLoading(true);
		const { error, message } = await forgetPassword(data);
		if (error) {
			ErrorToaster(message);
		} else {
			SuccessToaster(message);
			setChange({ email: '' });
		}
		setLoading(false);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ForgetType>({
		mode: 'all',
		defaultValues: {
			email: '',
		},
		resolver: yupResolver(forgetPasswordValidationSchema),
	});

	return (
		<>
			<div className="min-h-[calc(100vh-168px)] flex justify-center items-center bg-[#fafafa]">
				<div
					className="lg:p-8 md:p-8 sm:p-8 xs:p-4 md:w-1/2 lg:max-w-[576px] md:max-w-[576px] bg-white rounded-2xl gap-8 flex flex-col mx-4 border"
					style={{ backgroundColor: WHITE, borderColor: WHITE10 }}
				>
					<SEO title="Forgot Password" description={FORGOT_PASSWORD} />
					<div className="flex flex-col gap-6">
						<div className="flex flex-col">
							<p
								className="font-semibold lg:text-[32px] xs:text-2xl uppercase leading-[48px]"
								style={{
									color: TEXTCOLOR,
								}}
							>
								Forgot Password
							</p>
							<p
								className="text-lg font-normal leading-8"
								style={{
									color: TEXTCOLORGRAY,
								}}
							>
								Please enter your email to receive the confirmation email
							</p>
						</div>

						<div>
							<div className="flex flex-col lg:gap-3 xs:gap-1">
								<CommonLabel labelText="Email" />
								<CustomTextField
									name="email"
									register={register}
									placeholder="Enter your email"
									type="text"
									value={change?.email}
									onChange={handleEmailChange}
								/>
								{errors?.email && (
									<ErrorMessage message={errors?.email?.message} />
								)}
							</div>
						</div>
					</div>
					<div className="flex justify-center items-center">
						<CommonButton
							type="submit"
							width="100%"
							className="flex py-4 px-8 justify-center items-center gap-2 rounded-xl text-white text-xl uppercase font-semibold "
							onClick={handleSubmit(onSubmit)}
							label="Forgot Password"
							loading={loading}
						/>
					</div>
					<div className="w-full flex justify-between flex-col lg:flex-row gap-1">
						<div className="flex gap-1 lg:text-lg sm:text-base md:text-lg leading-8">
							<p className="" style={{ color: TEXTCOLORGRAY }}>
								Do you have an Account?
							</p>
							<div onClick={handleClick}>
								<p className="cursor-pointer underline font-semibold  ">
									Login
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ForgetPassword;
