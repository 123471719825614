import React from 'react';
import Login from '../Authentication/Login/Login';
import RegisterUser from '../Authentication/Register/RegisterUser';
import About from '../Pages/About/About';
import ChekOut from '../Pages/Checkout/CheckoutIndex/Checkout';
import Contact from '../Pages/Contact/Contact';
import ExclusiveNotes from '../Pages/Home/ExclusiveNotes/ExclusiveNotes';
import HomePage from '../Pages/Home/HomePage';
import Profile from '../Pages/MyAccount/MyAccount';
import NewGift from '../Pages/NewGift/NewGift';
import PrivacyPolicy from '../Pages/PrivacyPolicy/PrivacyPolicy';
import ResetPasssWord from '../Authentication/ResetPassword/ResetPasssWord';
import TermsAndConditions from '../Pages/Terms&Conditions/TermsAndConditions';
import LimitedEditionNotes from '../Pages/Home/LimitedEditionNotes/LimitedEditionNotes';
import NewGiftLayots from '../Pages/Home/NewGiftLayouts/NewGiftLayots';
import ForgetPassword from '../Authentication/ForgotPassword/ForgetPassword';
import LimitedEditionDetail from '../Pages/Home/LimitedEditionNotes/LimitedEditionDetail';
import ExclusiveDetail from '../Pages/Home/ExclusiveNotes/ExclusiveDetail';

export const ROUTES = {
	LOGIN: '/login',
	REGISTER: '/register',
	LIMITED_EDITION: '/limited-edition',
	RESET_PASSWORD: '/reset-password',
	FORGOT_PASSWORD: '/forgot-password',
	HOME_PAGE: '/',
	SET_OF_ALL_NOTES: '/setofallnotes',
	LIMITED_DETAILES: '/limited-edition',
	EXCLUSIVE_DETAILES: 'exclusive-details',
	EXCLUSIVE: '/exclusive',
	ORDER_MODAL: 'order-model',
	NEW_GIFT: '/new-gift',
	NEW_GIFT_CLASSIC: '/new-gift/classic',
	NEW_GIFT_LAYOUTS: '/note-layouts',
	NEW_GIFT_SET_OF_FOUR: '/new-gift/set-of-four',
	SHOPPING_BAG: '/checkout',
	PROFILE: '/profile',
	CONTACT: '/contact',
	ABOUT: '/about',
	PRIVACY_POLICY: '/privacy-policy',
	TERMS_CONDITION: '/terms-condition',
	NOT_FOUND: '*',
};
export const authRoutes = [
	{ path: ROUTES.SHOPPING_BAG, component: <ChekOut /> },
	{ path: ROUTES.PROFILE, component: <Profile /> },
];

export const publicRoutes = [
	{ path: `${ROUTES.NEW_GIFT}/:id`, component: <NewGift /> },
	{ path: ROUTES.NEW_GIFT_LAYOUTS, component: <NewGiftLayots /> },
	{
		path: ROUTES.LIMITED_EDITION,
		component: <LimitedEditionNotes />,
	},
	{ path: ROUTES.HOME_PAGE, component: <HomePage /> },
	{ path: ROUTES.LOGIN, component: <Login /> },
	{ path: ROUTES.CONTACT, component: <Contact /> },
	{ path: ROUTES.ABOUT, component: <About /> },
	{ path: ROUTES.EXCLUSIVE, component: <ExclusiveNotes /> },
	{
		path: `${ROUTES.LIMITED_DETAILES}/:id`,
		component: <LimitedEditionDetail />,
	},
	{
		path: `${ROUTES.EXCLUSIVE}/${ROUTES.EXCLUSIVE_DETAILES}/:id`,
		component: <ExclusiveDetail />,
	},
	{ path: ROUTES.PRIVACY_POLICY, component: <PrivacyPolicy /> },
	{ path: ROUTES.REGISTER, component: <RegisterUser /> },
	{ path: ROUTES.RESET_PASSWORD, component: <ResetPasssWord /> },
	{ path: ROUTES.TERMS_CONDITION, component: <TermsAndConditions /> },
	{
		path: ROUTES.FORGOT_PASSWORD,
		component: (
			<ForgetPassword
				handleClose={function (): void {
					throw new Error('Function not implemented.');
				}}
			/>
		),
	},
];
