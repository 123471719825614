import React from 'react';
import { CommonSvgProps } from '../../Interface/Index';

const ShoopingBag: React.FC<CommonSvgProps> = ({
	height = '20px',
	width = '20px',
}) => {
	return (
		<div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width}
				height={height}
				viewBox="0 0 32 32"
				fill="none"
			>
				<path
					d="M7.33325 29.3337C6.79992 29.3337 6.33325 29.1337 5.93325 28.7337C5.53325 28.3337 5.33325 27.867 5.33325 27.3337V10.0003C5.33325 9.46699 5.53325 9.00033 5.93325 8.60033C6.33325 8.20033 6.79992 8.00033 7.33325 8.00033H10.9999V7.66699C10.9999 6.26699 11.4833 5.08366 12.4499 4.11699C13.4166 3.15033 14.5999 2.66699 15.9999 2.66699C17.3999 2.66699 18.5833 3.15033 19.5499 4.11699C20.5166 5.08366 20.9999 6.26699 20.9999 7.66699V8.00033H24.6666C25.1999 8.00033 25.6666 8.20033 26.0666 8.60033C26.4666 9.00033 26.6666 9.46699 26.6666 10.0003V27.3337C26.6666 27.867 26.4666 28.3337 26.0666 28.7337C25.6666 29.1337 25.1999 29.3337 24.6666 29.3337H7.33325ZM7.33325 27.3337H24.6666V10.0003H20.9999V13.0003C20.9999 13.2837 20.9035 13.5212 20.7108 13.7128C20.518 13.9045 20.2791 14.0003 19.9941 14.0003C19.7091 14.0003 19.4721 13.9045 19.2833 13.7128C19.0944 13.5212 18.9999 13.2837 18.9999 13.0003V10.0003H12.9999V13.0003C12.9999 13.2837 12.9035 13.5212 12.7108 13.7128C12.518 13.9045 12.2791 14.0003 11.9941 14.0003C11.7091 14.0003 11.4721 13.9045 11.2833 13.7128C11.0944 13.5212 10.9999 13.2837 10.9999 13.0003V10.0003H7.33325V27.3337ZM12.9999 8.00033H18.9999V7.66699C18.9999 6.82255 18.711 6.11144 18.1333 5.53366C17.5555 4.95588 16.8444 4.66699 15.9999 4.66699C15.1555 4.66699 14.4444 4.95588 13.8666 5.53366C13.2888 6.11144 12.9999 6.82255 12.9999 7.66699V8.00033Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};

export default ShoopingBag;
