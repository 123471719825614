import { Navigate } from "react-router";
import { ROUTES } from "../Constant/Routes.Constant";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ Component }: { Component: JSX.Element }) => {
  const autentication = useSelector(
    (state: { user: { token: string } }) => state.user.token
  );
  const auth = autentication;
  if (auth) {
    return Component;
  } else {
    return <Navigate to={ROUTES.LOGIN} />;
  }
};

export default ProtectedRoute;
