import React, { useState } from 'react';
import Remove from '../../../../Components/Svg/Remove';
import { TEXTCOLORGRAY } from '../../../../Constant/Color.Constant';
import { WHITE10 } from '../../../../Constant/Color.Constant';
import CommonButton from '../../../../Components/Button/CommonButton';
import ErrorMessage from '../../../../Components/Typography/ErrorText';
import { CustomTextField } from '../../../../Components/Input/TextField';
import CommonLabel from '../../../../Components/InputLable/lable';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { inquireValidationSchema } from '../../../../Components/Validataions/ValidataionSchema';
import { GRAY900 } from '../../../../Constant/Color.Constant';
import { Inquiry } from '../../../../Api/UserListApi';
import { InquireData } from '../../../../Interface/Index';
import {
	ErrorToaster,
	SuccessToaster,
} from '../../../../helpers/CustomToaster';

const InquireModal = ({
	closeModal,
	productId,
}: {
	closeModal: () => void;
	productId?: string;
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<InquireData>({
		resolver: yupResolver(inquireValidationSchema),
	});

	const [loading, setLoading] = useState(false);
	const onSubmit = async (data: InquireData) => {
		data.productId = productId;
		setLoading(true);
		try {
			const { error, message } = await Inquiry(data);
			error ? ErrorToaster(message) : SuccessToaster(message);
			closeModal();
			setLoading(false);
		} catch (error) {
			console.error('Registration error:', error);
		}
	};

	return (
		<div className="md:w-[720px] sm:w-[450px] w-[250px] sm:py-8 sm:px-12 p-3 flex flex-col gap-6">
			<div className="flex flex-col gap-2">
				<div className="w-full flex items-center justify-between gap-2 ">
					<div>
						<p
							className="text-xl font-bold uppercase"
							style={{ color: GRAY900 }}
						>
							Make inquiry
						</p>
					</div>
					<div className="cursor-pointer" onClick={closeModal}>
						<Remove height="25px" width="25px" />
					</div>
				</div>
				<div>
					<p
						className="text-base xs:text-base"
						style={{ color: TEXTCOLORGRAY }}
					>
						Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores
						impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis
						ratione.
					</p>
				</div>
			</div>
			<div style={{ borderColor: WHITE10 }} className="border"></div>
			<div>
				<form
					className="xs:w-full flex flex-col gap-6"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="xs:w-full h-full flex flex-col gap-6">
						<div className="sm:grid sm:grid-cols-2 xs:grid-cols-1 xs:flex xs:flex-col gap-6">
							<div className="col-span-2 flex flex-col gap-1">
								<CommonLabel labelText="Email" />
								<CustomTextField
									placeholder="Enter your email"
									type="text"
									name="email"
									register={register}
									className="border border-gray-300 rounded p-2"
								/>
								<ErrorMessage message={errors.email?.message} />
							</div>

							<div className="flex-col gap-1">
								<CommonLabel labelText="Name" />
								<CustomTextField
									placeholder="Enter your name"
									name="name"
									register={register}
									className="border border-gray-300 rounded p-2"
								/>
								<ErrorMessage message={errors.name?.message} />
							</div>

							<div className="flex-col gap-1">
								<CommonLabel labelText="Phone Number" />
								<CustomTextField
									placeholder="Enter your phone number"
									name="phoneNumber"
									type="number"
									register={register}
									className="border border-gray-300 rounded p-2"
								/>
								<ErrorMessage message={errors.phoneNumber?.message} />
							</div>
						</div>

						<div className="flex-col gap-1">
							<CommonLabel labelText="message" optional="Max. 500 characters" />
							<CustomTextField
								placeholder="Enter your message"
								name="message"
								type="textarea"
								register={register}
								className="border border-gray-300 rounded p-2"
							/>
							<ErrorMessage message={errors.message?.message} />
						</div>
					</div>
					<div className="w-full flex justify-end items-end">
						<CommonButton
							type="submit"
							fontSize="16px"
							width="129px"
							loading={loading}
							className="font-semibold text-base text-white rounded-lg uppercase"
							label="Submit"
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default InquireModal;
