import React from 'react';
import { BORDERCOLOR, TEXTCOLOR } from '../../Constant/Color.Constant';
import { CommonHeaderProps } from '../../Interface/Index';

const CommonHeader: React.FC<CommonHeaderProps> = ({ title, className }) => {
	return (
		<div
			className="w-auto !flex justify-center items-center lg:border-none lg:pb-0 pb-4 border-b"
			style={{
				borderColor: BORDERCOLOR,
			}}
		>
			<p
				className={`font-medium w-full sm:text-lg text-sm ${className}`}
				style={{ color: TEXTCOLOR }}
			>
				{title}
			</p>
		</div>
	);
};

export default CommonHeader;
