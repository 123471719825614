const url = {
	FORGET_PASSWORD: 'auth/forgetPassword',

	RESET_PASSWORD: 'user/resetPassword',

	LOGIN: 'auth/login',

	REGISTER: 'auth/register',

	USER_DATA: 'user',

	LOGIN_USER: 'user/me',

	CONTACT_US: 'contactUs',

	USER_ADDRESS: 'address',

	CREATE_IMAGE_URL: 'imageUpload',

	ADD_TO_CART: 'cart',

	FRAME_LIST: 'frame',

	IMAGE: 'imageUpload',

	NOTES: 'product',

	DENOMINATION_PRICE: 'denominationPrice',

	CHECKOUT: 'checkOut',

	BACKGROUNDIMAGE: 'background',

	SET_OF_FOUR: 'setOfFour',

	SET_OF_FIVE: 'setOfFive',

	SINGLE_NOTE: 'singleNote',

	PAYMENT: 'payment',

	CANCEL_ORDER: 'order/sendMail',

	FAQ: 'faq',

	SPECIAL_CONTACT: 'contact',

	BANNER: 'banner',

	CONFIG: 'config',

	NEW_GIFT: 'gift',

	MY_ORDER: 'order',

	RAZORPAY: 'razor',

	CART: 'cart',

	REFERRALCODE: 'coupon',

	INQUIRY: 'inquiry',

	SPINITEAM: 'spinItem',

	SPINWHEEL: 'spiner',

	REVIEW: 'review',

	SPINCONFIG: 'spinConfig',
};

export default url;
