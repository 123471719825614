import React from 'react';

const Copy = ({ width }: { width: number }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={width}
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M25.8667 26.6657H12.8C12.3582 26.6657 12 26.3076 12 25.8657V12.799C12 12.3572 12.3582 11.999 12.8 11.999H25.8667C26.3085 11.999 26.6667 12.3572 26.6667 12.799V25.8657C26.6667 26.3076 26.3085 26.6657 25.8667 26.6657Z"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.0002 11.9997V6.13301C20.0002 5.69118 19.642 5.33301 19.2002 5.33301H6.1335C5.69167 5.33301 5.3335 5.69118 5.3335 6.13301V19.1997C5.3335 19.6415 5.69167 19.9997 6.1335 19.9997H12.0002"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Copy;
