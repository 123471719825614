import React from 'react';
import NumberTag from '../../../../Components/Ui/NumberTag';
import {
	Currency,
	defaultCardJson,
	fiveNotesDataStyle,
	fonts,
} from '../../../../Constant/Constant';
import { BORDERCOLOR } from '../../../../Constant/Color.Constant';
import {
	LAYOUT_ONE_DEFAULT_TEXT,
	NO_FOUND,
	USER_IMAGE_MALE,
} from '../../../../Configuration/InitialData';
import {
	fiveNotesDataTS,
	setOfFour,
} from '../../../../Interface/NewGiftInterface';
import { getSizeName } from '../../../../utils/getScreenSize';

const ClassicSingleNote = ({
	fiveNotesData,
	setOfFour,
	layout,
	screenWidth,
	isImageDelete,
	typeEdit,
}: {
	fiveNotesData: fiveNotesDataTS;
	setOfFour: setOfFour;
	layout: boolean;
	screenWidth: string;
	isImageDelete: boolean;
	typeEdit: string;
}) => {
	const isDefaultImage = true;
	return (
		<div className="flex items-center justify-center w-full h-full">
			<div className="w-full flex flex-col justify-between h-full">
				<div
					className="w-full flex justify-center md:h-[290px] sm:h-[330px] nd:h-[270px] h-[195px] overflow-hidden"
					style={{
						alignItems: !isImageDelete ? 'center' : 'flex-start',
						padding: isImageDelete ? '35px 0' : 'unset',
					}}
				>
					<div className="flex items-center w-full px-1.5">
						<div
							className="text-center flex flex-col items-center justify-center h-full pr-2 break-words"
							style={{ width: isImageDelete ? '100%' : '55%' }}
						>
							<p className="flex md:text-[10px] justify-center items-center gap-1.5 w-full sm:text-xs nd:text-[9px] text-[6px] font-bold font-BeVietnamMedium md:leading-4 sm:leading-[14px] nd:leading-[38px]  break-words mb-[3px] relative">
								<span
									className={`${
										fiveNotesDataStyle?.occassion?.occassionBold
											? 'font-BeVietnamExtraBold'
											: 'font-BeVietnamRegular'
									} ${
										fiveNotesDataStyle?.occassion?.occassionItalic && 'italic'
									}  ${
										fonts[fiveNotesDataStyle?.occassion?.occassionFont].classes
									} `}
									style={{
										color: fiveNotesDataStyle?.occassion?.occassionColor,
									}}
								>
									{fiveNotesData.occassion}
								</span>
							</p>
							<p
								className="flex gap-1.5 items-center justify-center w-full font-BeVietnamRegular md:text-lg sm:text-2xl nd:text-lg text-[11px] font-bold md:leading-[21px] nd:leading-[25px] leading-[15px] break-words mb-1 relative"
								style={{
									paddingRight: !(
										!isImageDelete || Object.keys(setOfFour.image).length > 0
									)
										? '12px'
										: 'unset',
									paddingLeft: !(
										!isImageDelete || Object.keys(setOfFour.image).length > 0
									)
										? '12px'
										: 'unset',
								}}
							>
								{LAYOUT_ONE_DEFAULT_TEXT.name === fiveNotesData.name && (
									<div>
										<NumberTag number={1} size={15} />
									</div>
								)}
								<span
									className={`break-all ${
										fiveNotesDataStyle?.name?.nameBold
											? 'font-BeVietnamExtraBold'
											: 'font-BeVietnamRegular'
									} ${fiveNotesDataStyle?.name?.nameItalic && 'italic'}  ${
										fonts[fiveNotesDataStyle?.name?.nameFont].classes
									} `}
									style={{ color: fiveNotesDataStyle?.name?.nameColor }}
								>
									{fiveNotesData.name}
								</span>
							</p>
							<p
								className="flex justify-center gap-1.5 items-center font-normal md:text-[17px] sm:text-[11px] nd:text-[10px] w-full text-[8px] md:leading-[14px] sm:leading-[29px] nd:leading-[18px] leading-3 break-words relative"
								style={{
									paddingRight: !(
										!isImageDelete || Object.keys(setOfFour.image).length > 0
									)
										? '12px'
										: 'unset',
									paddingLeft: !(
										!isImageDelete || Object.keys(setOfFour.image).length > 0
									)
										? '12px'
										: 'unset',
								}}
							>
								{LAYOUT_ONE_DEFAULT_TEXT.text === fiveNotesData.text && (
									<div>
										<NumberTag number={2} size={15} />
									</div>
								)}
								<span
									className={`md:text-[10px] sm:text-xs nd:text-[8px] text-[6px] break-all ${
										fiveNotesDataStyle?.text?.textBold
											? 'font-bold'
											: 'font-normal'
									} ${fiveNotesDataStyle?.text?.textItalic && 'italic'} ${
										fonts[fiveNotesDataStyle?.text?.textFont].classes
									}`}
									style={{ color: fiveNotesDataStyle?.text?.textColor }}
								>
									{fiveNotesData.text}
								</span>
							</p>
						</div>
						{
							(!isImageDelete || Object.keys(setOfFour.image).length > 0) && (
								<div className="w-[45%] py-[30px]">
									<div
										className="md:h-[240px] md:w-full sm:h-[295px] nd:h-[230px] h-[160px] !bg-no-repeat !bg-cover !bg-center"
										style={{
											background:
												isDefaultImage || setOfFour?.image?.name
													? `url('${
															typeEdit
																? Object.keys(setOfFour.image).length > 0
																	? setOfFour.image
																	: USER_IMAGE_MALE
																: setOfFour?.image?.name
																  ? URL.createObjectURL(setOfFour.image)
																  : USER_IMAGE_MALE
													  }')`
													: 'unset',
											border:
												!isDefaultImage && !setOfFour?.image?.name
													? `2px dashed ${BORDERCOLOR}`
													: 'unset',
										}}
									>
										{!isDefaultImage && !setOfFour?.image?.name && (
											<div className="h-full w-full flex justify-center items-center">
												<p className="capitalize text-center text-sm">
													<span>{NO_FOUND.userImage}</span>
												</p>
											</div>
										)}
									</div>
								</div>
							)
							// )
						}
					</div>
				</div>
				<div className="flex flex-col justify-between items-center custom-height md:pt-6 md:h-[calc(100%-290px)] sm:h-[calc(100%-330px)] nd:h-[calc(100%-270px)] h-[calc(100%-195px)] ">
					<p className="flex items-center justify-center w-full gap-1.5 font-medium nd:text-[8px] text-center md:text-[10px] sm:text-[11px] text-[6px]">
						{LAYOUT_ONE_DEFAULT_TEXT.title === fiveNotesData?.title && (
							<div>
								<NumberTag number={3} size={15} />
							</div>
						)}
						<span>{fiveNotesData?.title}</span>
					</p>
					<div className="flex flex-col justify-center items-center w-full relative bottom-16">
						{setOfFour?.noteImage.length === 0 && (
							<div
								className="border-dashed border-2 border-gray-200 absolute flex justify-center items-center capitalize rounded-md shadow-[rgba(0,0,0,0.25)_0px_54px_55px,_rgba(0,0,0,0.12)_0px_-12px_30px,_rgba(0,0,0,0.12)_0px_4px_6px,_rgba(0,0,0,0.17)_0px_12px_13px,_rgba(0,0,0,0.09)_0px_-3px_5px]"
								style={{
									width: `${defaultCardJson[getSizeName()].width}rem`,
									height: `${defaultCardJson[getSizeName()].height}rem`,
								}}
							>
								<p className="text-center md:text-sm sm:text-base nd:text-sm text-[10px] w-[80%]">
									{NO_FOUND.currency}
								</p>
							</div>
						)}
						{setOfFour?.noteImage.length !== 0 &&
							setOfFour?.noteImage.map(
								(notesData: { image: string }, index: number) => {
									return (
										<div
											key={index}
											className={`w-full ${
												setOfFour?.noteImage?.length === 1 &&
												'flex justify-center'
											}`}
										>
											{layout ? (
												<img
													key={index}
													src={notesData?.image}
													alt={`Currency ${index + 1}`}
													style={{
														height: `${
															Currency[setOfFour.currency[index]].screens[
																screenWidth
															].height
														}rem`,
														width: `${
															Currency[setOfFour.currency[index]].screens[
																screenWidth
															].width
														}rem`,
														position: 'absolute',
														bottom: '50%',
														transform: 'translateY(50%)',
														objectFit: 'contain',
														objectPosition: 'center',
													}}
												/>
											) : (
												<img
													key={index}
													src={notesData?.image}
													alt={`Currency ${index + 1}`}
													style={{
														height: `${
															Currency[setOfFour.currency[index]].screens[
																screenWidth
															].height
														}rem`,
														width: `${
															Currency[setOfFour.currency[index]].screens[
																screenWidth
															].width
														}rem`,
														position: setOfFour.isMultipleNote
															? 'absolute'
															: 'unset',
														right: setOfFour.isMultipleNote
															? `${5 * (5 - index) + 50}px`
															: '',
														bottom: setOfFour.isMultipleNote
															? `calc(53% + ${(5 - index) * 30}px)`
															: '',
														transform: setOfFour.isMultipleNote
															? `translate(-25%, calc(288% - ${
																	setOfFour.noteImage.length * 30
															  }px))`
															: '',
														zIndex: setOfFour.isMultipleNote
															? 500 - Currency[setOfFour.currency[index]].rupee
															: '',
														objectFit: 'contain',
														objectPosition: 'center',
													}}
												/>
											)}
										</div>
									);
								}
							)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClassicSingleNote;
