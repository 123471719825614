import classNames from 'classnames';
import React from 'react';
import { GRAY50, WHITE10 } from '../../Constant/Color.Constant';
import Dot from '../Svg/Dot';

interface IndexProps {
	className?: string;
}

const NoteForSell: React.FC<IndexProps> = ({ className }) => {
	const items: string[] = [];
	for (let i = 0; i < 50; i++) {
		items.push('NOT FOR SALE');
	}

	return (
		<div className="w-full">
			<div
				className={classNames(
					'w-full h-full flex flex-row justify-center items-center sm:py-3 xs:py-[4px] overflow-hidden',
					className
				)}
				style={{ background: WHITE10 }}
			>
				<div className="marquee">
					<div className="track">
						<div className="content flex items-center gap-2">
							{items.map((e, index: number) => {
								return (
									<div
										key={index}
										className="flex gap-2 justify-center items-center"
									>
										<div className="sm:h-8 sm:w-8 h-6 w-2 flex justify-center items-center">
											<Dot />
										</div>
										<p
											className="flex items-center sm:text-xl text-sm sm:leading-8 leading-6 font-medium"
											style={{ color: GRAY50 }}
										>
											&nbsp;{e}
										</p>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NoteForSell;
