import React, { FC } from 'react';
import CommonButton from '../Button/CommonButton';
import {
	BLACK,
	GRAY,
	GRAY100,
	TEXTCOLOR,
	TEXTCOLORGRAY,
	WHITE10,
} from '../../Constant/Color.Constant';
import { CommonDetailPageProps } from '../../Interface/Index';

const ProductDetailSection: FC<CommonDetailPageProps> = ({
	selectedImageData,
	loading,
	isDisableBtn,
	handleAddToCart,
	openModal,
	screen,
}) => {
	return (
		<div className="xlg:w-[calc(100%-624px)] w-full md:px-10 md:py-6 py-0 flex flex-col items-start gap-3">
			<p
				className="font-semibold uppercase sm:text-[32px] text-xl leading-8 sm:leading-[48px]"
				style={{ color: BLACK }}
			>
				{selectedImageData?.name}
			</p>
			<div className="border w-full" style={{ color: WHITE10 }}></div>
			<div className="flex flex-col gap-6 w-ull justify-start items-start">
				<div className="flex flex-col sm:gap-6 gap-3">
					<p
						className="text-base text-justify leading-6 font-normal"
						style={{ color: TEXTCOLORGRAY }}
					>
						{selectedImageData?.description}
					</p>
					{selectedImageData?.price !== undefined && (
						<p
							className="text-[32px] leading-[44px] font-semibold"
							style={{ color: TEXTCOLOR }}
						>
							<span>₹</span>
							{selectedImageData?.price?.toLocaleString('en-IN')}
							<span>/-</span>
						</p>
					)}
				</div>
				{handleAddToCart && (
					<div className="flex gap-6 justify-center items-center">
						<div>
							<CommonButton
								label="add to bag"
								padding={screen?.isMobile ? '12px 24px' : '12px 32px'}
								fontSize={screen?.isMobile ? '16px' : '20px'}
								lineHeight={screen?.isMobile ? '24px' : '32px'}
								loading={loading}
								disabled={selectedImageData?.isSold || isDisableBtn}
								backgroundColor={selectedImageData?.isSold ? GRAY : BLACK}
								onClick={() => handleAddToCart(selectedImageData?.id as string)}
							/>
						</div>
					</div>
				)}
				{openModal && (
					<div className="flex sm:gap-6 gap-5 flex-col justify-center items-start">
						<div className="flex flex-col sm:gap-2 gap-3">
							<p
								className="sm:text-base text-sm  sm:leading-8  leading-5 font-bold uppercase"
								style={{ color: GRAY100 }}
							>
								Key highlights:
							</p>
							<ul className="list-disc pl-6  flex flex-col gap-3">
								{selectedImageData?.keyHighlights?.map(
									(keyHighlight: string, index: number) => (
										<li
											key={index}
											className="sm:leading-6 sm:text-base text-sm leading-5 font-normal"
											style={{ color: TEXTCOLORGRAY }}
										>
											{keyHighlight}
										</li>
									)
								)}
							</ul>
						</div>
						<div className="flex justify-center items-center">
							<CommonButton
								label="inquire to buy"
								onClick={openModal}
								padding={screen?.isMobile ? '12px 36px' : '12px 32px'}
								fontSize={screen?.isMobile ? '16px' : '20px'}
								lineHeight={screen?.isMobile ? '24px' : '32px'}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductDetailSection;
