import React, { CSSProperties } from 'react';
import { BLACK } from '../../Constant/Color.Constant';

interface SpinnerStarProps {
	number?: string | number;
	numberStyle: CSSProperties;
}

const SpinnerStar: React.FC<SpinnerStarProps> = ({ number, numberStyle }) => {
	const percentage = parseInt(number as string, 10) || 0;

	return (
		<div className="flex justify-center items-center h-full">
			<b className="sm:w-[65px] sm:h-[65px] w-11 h-11 leading-[65px] rounded-full text-[26px] text-center flex items-center justify-center relative">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="66"
					height="66"
					viewBox="0 0 66 66"
					fill="none"
				>
					<path
						d="M29.2312 3.59798C30.4923 0.0516295 35.5077 0.0516262 36.7688 3.59798L39.1692 10.3479C39.9633 12.5812 42.5144 13.6379 44.6552 12.6203L51.1254 9.5447C54.5248 7.9288 58.0712 11.4752 56.4553 14.8746L53.3797 21.3448C52.3621 23.4856 53.4188 26.0367 55.6521 26.8308L62.402 29.2312C65.9484 30.4923 65.9484 35.5077 62.402 36.7688L55.6521 39.1692C53.4188 39.9633 52.3621 42.5144 53.3797 44.6552L56.4553 51.1254C58.0712 54.5248 54.5248 58.0712 51.1254 56.4553L44.6552 53.3797C42.5144 52.3621 39.9633 53.4188 39.1692 55.6521L36.7688 62.402C35.5077 65.9484 30.4923 65.9484 29.2312 62.402L26.8308 55.6521C26.0367 53.4188 23.4856 52.3621 21.3448 53.3797L14.8746 56.4553C11.4752 58.0712 7.9288 54.5248 9.54469 51.1254L12.6203 44.6552C13.6379 42.5144 12.5812 39.9633 10.3479 39.1692L3.59798 36.7688C0.0516295 35.5077 0.0516262 30.4923 3.59798 29.2312L10.3479 26.8308C12.5812 26.0367 13.6379 23.4856 12.6203 21.3448L9.5447 14.8746C7.9288 11.4752 11.4752 7.9288 14.8746 9.54469L21.3448 12.6203C23.4856 13.6379 26.0367 12.5812 26.8308 10.3479L29.2312 3.59798Z"
						fill="white"
					/>
				</svg>
				<span
					className="z-[99999999] absolute sm:text-sm sm:leading-6 text-[0.525rem] leading-[0.9rem]"
					style={{
						color: BLACK,
						...numberStyle,
					}}
				>
					{percentage}%
				</span>
			</b>
		</div>
	);
};

export default SpinnerStar;
