import React from 'react';
import { CommonSvgProps } from '../../Interface/Index';

const Edit: React.FC<CommonSvgProps> = ({ onClick, style, width = '24' }) => {
	return (
		<div onClick={onClick} className={style}>
			<svg
				width={width}
				height={width}
				viewBox="0 0 20 20"
				fill="none"
				cursor="pointer"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M11.969 4.71001L13.2023 3.47668C13.3571 3.32184 13.5409 3.19901 13.7431 3.11521C13.9454 3.03141 14.1622 2.98828 14.3811 2.98828C14.6 2.98828 14.8168 3.03141 15.0191 3.11521C15.2213 3.19901 15.4051 3.32184 15.5598 3.47668L16.7382 4.65501C17.0506 4.96755 17.2262 5.3914 17.2262 5.83334C17.2262 6.27528 17.0506 6.69913 16.7382 7.01168L15.5048 8.24501M11.969 4.71001L3.95567 12.7225C3.67899 12.9992 3.50848 13.3644 3.47401 13.7542L3.27234 16.0375C3.26151 16.1589 3.27743 16.2812 3.31899 16.3957C3.36054 16.5103 3.42672 16.6143 3.51285 16.7006C3.59898 16.7868 3.70297 16.853 3.81749 16.8947C3.93202 16.9364 4.05428 16.9524 4.17567 16.9417L6.45901 16.74C6.84936 16.7059 7.21523 16.5354 7.49234 16.2583L15.5048 8.24501M11.969 4.71001L15.5048 8.24501"
					stroke="black"
					strokeWidth="1.25"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};

export default Edit;
