import React from 'react';
import OrderForm from '../OrderForm/OrderForm';
import { OrderFormProps } from '../../../Interface/Index';

const OrderModel = ({ setIsModalOpen, isModalOpen, id }: OrderFormProps) => {
	return (
		<div className="flex justify-center items-center h-full">
			<div className="fixed inset-0  bg-opacity-80 transition-opacity " />

			<div className=" fixed inset-0 z-10 justify-center items-center flex ">
				<div className="flex justify-center items-center">
					<div className="">
						<div className="bg-white rounded-lg ">
							<div className="sm:flex sm:items-start ">
								<OrderForm
									id={id}
									isModalOpen={isModalOpen}
									setIsModalOpen={setIsModalOpen}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderModel;
