import React, { useState, useEffect, useMemo } from 'react';
import CustomSpinner from './CustomSpinner';
import Remove from '../../../Components/Svg/Remove';
import { GRAY900, TEXTCOLORGRAY } from '../../../Constant/Color.Constant';
import CommonButton from '../../../Components/Button/CommonButton';
import { SpinnerDataTexts } from '../../../Constant/Constant';
import CuoponCode from '../../../Components/Svg/CuoponCode';
import { SpinWheel, getSpinnerDatas } from '../../../Api/UserListApi';
import { SuccessToaster } from '../../../helpers/CustomToaster';
import useScreenSize from '../../../utils/hooks/UseScreenSIze';
import useMobileMediaQuery from '../../../utils/useMobileMediaQuery';

type SpinnerProps = {
	onClose: () => void;
};

const Spinner: React.FC<SpinnerProps> = ({ onClose }) => {
	const [spin, setSpin] = useState(false);
	const [copied, setCopied] = useState(false);
	const [winningPercentage, setWinningPercentage] = useState<number | null>(
		null
	);
	const [buttonVisible, setButtonVisible] = useState(true);
	const [spinnerData, setSpinnerData] = useState([]);
	const [discountKeyToStopSpinner, setDiscountKeyToStopSpinner] = useState<
		string | null
	>(null);

	const handleRemove = () => {
		onClose();
	};

	const handleSpinComplete = (percentage: number) => {
		setWinningPercentage(percentage);
		setButtonVisible(false);
	};

	const handleCopy = () => {
		const textToCopy = `CODE${winningPercentage}`;
		navigator.clipboard
			.writeText(textToCopy)
			.then(() => {
				setCopied(true);
				SuccessToaster('Discount Code Copied!');
			})
			.catch((err) => {
				console.error('Unable to copy to clipboard', err);
			});
	};

	const handleSpinClick = async () => {
		try {
			const response = await SpinWheel();
			const discountKeyToStopSpinner = response?.data?.discount;
			setDiscountKeyToStopSpinner(discountKeyToStopSpinner);
			setSpin(true);
		} catch (error) {
			console.error('Error spinning the wheel:', error);
		}
	};

	const fetchData = async () => {
		try {
			const response = await getSpinnerDatas();
			console.log(response);
			setSpinnerData(response?.data?.rows);
		} catch (error) {
			console.error('Error fetching spinning wheel data:', error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (copied || (winningPercentage !== null && buttonVisible)) {
			setButtonVisible(false);
		}
	}, [copied, winningPercentage, buttonVisible]);

	useEffect(() => {
		copied && onClose();
	}, [copied, onClose]);

	const screenDetails = useScreenSize();
	const isStyleSM = screenDetails.isExistScreen.sm;
	const isStyleLG = screenDetails.isExistScreen.lg;

	const spinner = useMemo(() => {
		return (
			<CustomSpinner
				spin={spin}
				onSpinComplete={handleSpinComplete}
				discountKeyToStopSpinner={discountKeyToStopSpinner}
				spinnerData={spinnerData}
			/>
		);
	}, [spin, spinnerData]);

	const screen = useMobileMediaQuery();

	return (
		<div className="sm:px-12 sm:py-8 xs:p-3 w-full">
			<div className="flex flex-col sm:gap-10 gap-5 w-full items-center justify-around">
				<div className="w-full flex flex-col sm:gap-2 gap-1">
					<div className="flex justify-between w-full items-center">
						<h2 className="sm:text-xl font-semibold uppercase sm:leading-7 leading-6 text-base">
							{SpinnerDataTexts.heading}
						</h2>
						<div className="cursor-pointer" onClick={handleRemove}>
							<Remove width={isStyleLG ? '20' : '25'} />
						</div>
					</div>
					<p
						className="sm:text-base font-normal sm:leading-6 leading-[22px] text-sm"
						style={{ color: TEXTCOLORGRAY }}
					>
						{SpinnerDataTexts.subHeading}
					</p>
				</div>
				{spinner}
				{(buttonVisible || copied || winningPercentage !== null) && (
					<div className="w-full flex flex-col justify-center items-center gap-6">
						{winningPercentage !== null && (
							<div className="flex flex-col gap-6 justify-center items-center">
								<div className="flex flex-col gap-2 justify-center items-center text-center">
									<p
										className="sm:text-xl text-base font-bold leading-7 uppercase"
										style={{ color: GRAY900 }}
									>
										you won {winningPercentage}% off coupon
									</p>
									<p
										className="sm:text-base text-sm font-normal leading-6"
										style={{ color: TEXTCOLORGRAY }}
									>
										{SpinnerDataTexts.winningText.description}
									</p>
								</div>
								<div>
									<CuoponCode winningPercentage={winningPercentage} />
								</div>

								<CommonButton
									onClick={handleCopy}
									fontSize="16px"
									padding={screen.isMobile ? '12px 32px' : '16px 32px'}
									label={copied ? 'Code Copied!' : 'Copy Code'}
								></CommonButton>
							</div>
						)}
						{buttonVisible && !copied && (
							<CommonButton
								onClick={handleSpinClick}
								fontSize="16px"
								padding={screen.isMobile ? '12px 32px' : '16px 32px'}
								label="Spin the Wheel"
								width={isStyleSM ? '100%' : ''}
							></CommonButton>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Spinner;
