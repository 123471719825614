import React, { FC } from 'react';
import ReactImageMagnify from 'react-image-magnify';
interface CommonMagnifyProps {
	imageSrc: string;
}

const CommonMagnify: FC<CommonMagnifyProps> = ({ imageSrc }) => {
	return (
		<div className="magnify flex flex-col gap-3 md:w-[520px] sm:w-[500px] nd:w-[343px] sm:h-[520px] nd:h-[343px] xlg:items-center items-center">
			<div className="flex md:w-[520px] sm:w-[500px] nd:w-[343px] md:h-[520px] sm:h-[500px] nd:h-[343px] magnify-container">
				<ReactImageMagnify
					smallImage={{
						alt: 'Image',
						isFluidWidth: true,
						src: imageSrc,
					}}
					imageStyle={{ borderRadius: '12px' }}
					largeImage={{
						src: imageSrc,
						width: 3000,
						height: 1200,
					}}
					enlargedImageContainerStyle={{
						zIndex: 2,
						width: '200%',
						height: '100%',
					}}
					enlargedImageContainerDimensions={{
						width: '200%',
						height: '100%',
					}}
				/>
			</div>
		</div>
	);
};

export default CommonMagnify;
