import { Toaster } from '../Components/Toaster/Index';

const types: Record<string, string> = {
	e: 'error',
	s: 'success',
};

export const ErrorToaster = ( message: string, status: string = 'e',) => {
	Toaster({ status: types[status] as 'error' | 'success', message });
};

export const SuccessToaster = (message: string) => {
	ErrorToaster(message, 's');
};
