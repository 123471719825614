import React, { useState } from 'react';
import { GRAY900, WHITE } from '../../Constant/Color.Constant';

interface TooltipProps {
	text: string;
	children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div
			className="relative"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{children}
			{isHovered && (
				<div
					className="absolute px-2 py-1 -ml-10 rounded-md shadow-md text-xs"
					style={{ color: WHITE, backgroundColor: GRAY900 }}
				>
					{text}
				</div>
			)}
		</div>
	);
};

export default Tooltip;
