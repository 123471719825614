import React, { useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import * as htmlToImage from 'html-to-image';

const FullScreenImage = ({
	onClose,
	node,
}: {
	onClose: () => void;
	node: HTMLElement | null;
}) => {
	const imgRef = useRef<HTMLImageElement | null>(null);

	useEffect(() => {
		getDataUrl();
	}, []);

	function dataURLtoBlob(dataURL: string) {
		const arr = dataURL.split(',');
		const mime = (arr[0].match(/:(.*?);/) as RegExpMatchArray)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new Blob([u8arr], { type: mime });
	}

	const getDataUrl = async () => {
		let dataUrl;
		if (node) {
			try {
				dataUrl = await htmlToImage.toPng(node);
				const blob = dataURLtoBlob(dataUrl);
				if (imgRef?.current) imgRef.current.src = URL.createObjectURL(blob);
			} catch (error) {
				console.error('Oops, something went wrong!', error);
			}
		}
	};

	return (
		<div className="fixed top-0 left-0 z-[700005] w-full h-screen bg-[rgba(0,0,0,0.8)]">
			<div className="h-full relative">
				<div className="h-full">
					<TransformWrapper>
						<TransformComponent>
							<div>
								<img
									ref={imgRef}
									className="h-full w-full object-contain"
									alt="generated-frame-image"
								/>
							</div>
						</TransformComponent>
					</TransformWrapper>
				</div>
				<div className="absolute top-5 left-5">
					<button
						className="h-10 w-10 bg-gray-200 rounded-full"
						onClick={onClose}
					>
						x
					</button>
				</div>
			</div>
		</div>
	);
};

export default FullScreenImage;
