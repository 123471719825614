import React, { useState } from 'react';
import CommonButton from '../../Components/Button/CommonButton';
import CommonLabel from '../../Components/InputLable/lable';
import { useEffect } from 'react';
import { ROUTES } from '../../Constant/Routes.Constant';
import { registerUser } from '../../Api/UserListApi';
import { useNavigate } from 'react-router';
import { RegisterType } from '../../Api/Types';
import { registerValidationSchema } from '../../Components/Validataions/ValidataionSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CustomTextField } from '../../Components/Input/TextField';
import ErrorMessage from '../../Components/Typography/ErrorText';
import {
	ALREADY_HAVE_AN_ACCOUNT,
	SIGN_UP,
} from '../../Constant/KeyWord.Constant';
import { useSelector } from 'react-redux';
import {
	BORDERCOLOR,
	TEXTCOLOR,
	TEXTCOLORGRAY,
	WHITE,
	WHITE_SMOKE,
} from '../../Constant/Color.Constant';
import { ErrorToaster, SuccessToaster } from '../../helpers/CustomToaster';
import SEO from '../../Components/Helmet/SEO';
import { SIGN_UP_DESCRIPTION } from '../../Constant/Seo.Constant';

const initialValue = {
	email: '',
	name: '',
	password: '',
	phone: '',
};

const RegisterUser = () => {
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<RegisterType>({
		mode: 'all',
		defaultValues: initialValue,
		resolver: yupResolver(registerValidationSchema),
	});
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const accessToken = useSelector(
		(state: { user: { token: string } }) => state.user.token
	);
	const onSubmit = async (data: RegisterType) => {
		setLoading(true);
		try {
			const { error, message } = await registerUser(data);
			if (error) {
				ErrorToaster(message);
			} else {
				SuccessToaster(message);
				navigate(ROUTES.LOGIN);
			}
			setLoading(false);
		} catch (error) {
			console.error('Registration error:', error);
		}
	};

	const handleClickLogin = () => {
		navigate(ROUTES.LOGIN);
	};

	useEffect(() => {
		if (accessToken) {
			navigate(ROUTES.HOME_PAGE);
		}
	}, [navigate]);

	return (
		<div
			className="flex  items-center flex-col sm:w-full justify-center border-t lg:min-h-[calc(100vh-168px)] xs:min-h-[calc(100vh-76px)] px-24 "
			style={{
				borderColor: BORDERCOLOR,
				backgroundColor: WHITE_SMOKE,
			}}
		>
			<div
				className="sm:w-[576px] w-full xs:p-3 lg:p-8 md:p-8 sm:p-8 my-10 rounded-2xl border border-gray-200 flex flex-col lg:gap-8 xs:gap-6 h-[90%]"
				style={{
					backgroundColor: WHITE,
				}}
			>
				<SEO title="Sign Up" description={SIGN_UP_DESCRIPTION} />
				<div className="flex flex-col gap-1.5">
					<p
						className="font-semibold lg:text-[32px] md:text-[32px] sm:text-[28px] xs:text-[20px] leading-[48px] uppercase"
						style={{ color: TEXTCOLOR }}
					>
						{SIGN_UP}
					</p>
					<p className="md:text-lg text-sm" style={{ color: TEXTCOLORGRAY }}>
						We will create your account using your Email
					</p>
				</div>

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-col sm:gap-[24px] gap-[12px]">
						<div className="flex flex-col lg:gap-3 xs:gap-1">
							<CommonLabel labelText="Full Name" required />
							<CustomTextField
								name="name"
								register={register}
								placeholder=" Enter your full name"
							/>
							{errors?.name && <ErrorMessage message={errors?.name?.message} />}
						</div>
						<div className="flex flex-col lg:gap-3 xs:gap-1">
							<CommonLabel labelText="Email" required />
							<CustomTextField
								name="email"
								register={register}
								placeholder=" Enter your email"
								type="text"
							/>
							{errors?.email && (
								<ErrorMessage message={errors?.email?.message} />
							)}
						</div>
						<div className="flex flex-col lg:gap-3 xs:gap-1">
							<CommonLabel labelText="Password" required />
							<CustomTextField
								name="password"
								register={register}
								placeholder=" Enter your password"
								type="password"
							/>
							{errors?.password && (
								<ErrorMessage message={errors?.password?.message} />
							)}
						</div>
						<div className="flex flex-col lg:gap-3 xs:gap-1">
							<CommonLabel labelText="Phone number" required />
							<CustomTextField
								name="phone"
								register={register}
								type="number"
								placeholder="Enter your phone number"
								includeCountryCode={true}
							/>
							{errors?.phone && (
								<ErrorMessage message={errors?.phone?.message} />
							)}
						</div>
						<div className="flex flex-col lg:gap-3 xs:gap-1">
							<CommonLabel labelText="Referral code" />
							<CustomTextField
								name="referralCode"
								register={register}
								placeholder="Enter your referral code"
							/>
						</div>
						<div className="flex gap-3 flex-col ">
							<CommonButton
								type="submit"
								width="100%"
								className={`${
									!isValid
										? '!bg-[#828282] cursor-not-allowed font-semibold xs:text-base text-xl '
										: '!bg-gray '
								} text-white py-4 px-8 rounded-xl font-semibold uppercase`}
								label="verify & sign up"
								loading={loading}
							/>
							<div
								className="flex items-center  md:text-lg text-sm  lg:gap-2 xs:gap-1"
								style={{ color: TEXTCOLORGRAY }}
							>
								<p onClick={handleClickLogin}>{ALREADY_HAVE_AN_ACCOUNT}</p>
								<p
									className="font-bold underline cursor-pointer ont-semibold text-black"
									onClick={handleClickLogin}
								>
									Login
								</p>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default RegisterUser;
