import React, { CSSProperties } from 'react';
import { useState, useEffect, useContext } from 'react';
import { CollapseContextProvider } from './context/collapseContext';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import MenuContext from './context/menuContext';
import type { ReactNode, MouseEvent } from 'react';
import Accordion from '../Svg/UpDownArrow';
import { CommonProps } from '../../Interface/Index';

export interface MenuCollapseProps extends CommonProps {
	eventKey?: string;
	expanded?: boolean;
	label?: string | ReactNode;
	MenuStyle?: CSSProperties;
	onToggle?: (expanded: boolean, e: MouseEvent<HTMLDivElement>) => void;
}

const MenuCollapse = (props: MenuCollapseProps) => {
	const {
		children,
		className,
		eventKey,
		expanded = false,
		MenuStyle,
		label = null,
		onToggle,
	} = props;

	const [isExpanded, setIsExpanded] = useState(expanded);

	const { variant, sideCollapsed, defaultExpandedKeys } =
		useContext(MenuContext);

	useEffect(() => {
		if ((defaultExpandedKeys as string[]).includes(eventKey as string)) {
			setIsExpanded(true);
		}
		if (expanded !== isExpanded) {
			setIsExpanded(true);
		}
	}, [expanded, onToggle, eventKey, defaultExpandedKeys]);

	const toggleCollapse = (e: MouseEvent<HTMLDivElement>) => {
		if (typeof onToggle === 'function') {
			onToggle(!isExpanded, e);
		}
		setIsExpanded(!isExpanded);
	};

	const menuCollapseItemClass = classNames(
		'menu-collapse-item',
		`menu-collapse-item-${variant}`,
		className
	);

	return (
		<div className="menu-collapse">
			<div
				className={menuCollapseItemClass}
				style={MenuStyle}
				role="presentation"
				onClick={toggleCollapse}
			>
				<span className="flex items-center">{label}</span>
				<motion.span
					className="text-lg mt-1"
					initial={{ transform: 'rotate(0deg)' }}
					animate={{
						transform: isExpanded ? '-rotate-180' : 'rotate(0deg)',
					}}
					transition={{ duration: 0.15 }}
				>
					{sideCollapsed ? null : <Accordion />}
				</motion.span>
			</div>
			<CollapseContextProvider value={isExpanded}>
				<motion.ul
					initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
					animate={{
						opacity: isExpanded ? 1 : 0,
						height: isExpanded ? 'auto' : 0,
					}}
					transition={{ duration: 0.15 }}
				>
					{children}
				</motion.ul>
			</CollapseContextProvider>
		</div>
	);
};

MenuCollapse.displayName = 'MenuCollapse';

export default MenuCollapse;
