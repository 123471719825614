import React from 'react';
import KeyValues from './KeyValues/KeyValues';
import SlidersAbout from './Testimonial/SlidersAbout';
import SEO from '../../Components/Helmet/SEO';
import '../../index.css';
import { ABOUT_US } from '../../Constant/Seo.Constant';
import { BLACK200, BORDERCOLOR, WHITE } from '../../Constant/Color.Constant';
const About = () => {
	return (
		<>
			<SEO title="About Us" description={ABOUT_US} />

			<div>
				<div className="w-full h-full">
					<div className="flex justify-center items-center ">
						<div
							className="flex justify-center items-center w-full  xs:h-[460px] lg:h-[368px] sm:h-[450px] md:h-[400px] relative overflow-hidden max-w-[2500px] mx-auto"
							style={{ backgroundColor: BLACK200 }}
						>
							<div
								className="absolute lg:top-[40%] xs:top-[80%] right-0 h-full lg:w-[265px] xs:w-[150px]  skew-x-0 skew-y-[-36deg]"
								style={{ backgroundColor: BLACK200 }}
							></div>
							<div className="absolute z-2 top-0 left-0 w-full h-full flex justify-center items-center">
								<div
									className="flex-col text-center   lg:w-[1056px] md:w-[800px] sm:w-[600px] xs:w-[343px] gap-5 flex"
									style={{ color: WHITE }}
								>
									<p className="lg:text-6xl xs:text-2xl lg:font-semibold xs:font-medium  uppercase">
										About us
									</p>
									<p
										className="lg:text-lg md:text-xl sm:text-xl xs:text-base leading-8  xs:text-center lg:text-center"
										style={{
											color: BORDERCOLOR,
										}}
									>
										Our goal is to curate a diverse collection of Indian
										currency notes featuring special serial numbers based on
										significant dates or lucky numbers. We aim to create a
										platform where customers can easily find and purchase these
										distinctive notes, allowing them to express their love,
										appreciation, and best wishes to their friends, family, and
										acquaintances.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<KeyValues />
				<div className="about-slider-container">
					<SlidersAbout />
				</div>
			</div>
		</>
	);
};

export default About;
