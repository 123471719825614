import { createSlice } from '@reduxjs/toolkit';

export type User = {
	token: string;
	refreshToken: string;
	user: {
		id: string;
		name: string;
		role: string;
		email: string;
		phone: string;
	};
};

const initialState: User = {
	token: '',
	refreshToken: '',
	user: {
		id: '',
		name: '',
		role: '',
		email: '',
		phone: '',
	},
};

export const UserSlice = createSlice({
	name: 'User',
	initialState,
	reducers: {
		setUserToken: (state, action) => {
			state.token = action.payload;
		},
		setRefreshToken: (state, action) => {
			state.refreshToken = action.payload;
		},
		setUser: (state, action) => {
			state.user = { ...state.user, ...action.payload };
		},
	},
});

export const { setUserToken, setRefreshToken, setUser } = UserSlice.actions;

export default UserSlice.reducer;
