import {
	AnyAction,
	ThunkAction,
	ThunkDispatch,
	combineReducers,
	configureStore,
} from '@reduxjs/toolkit';
import cart from './CartSlice';
import faq from './FaqSlice';
import exclusive from './ExclusiveSlice';
import user from './UserSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import limited from './LimitedEditionSlice';
import banner from './BannerSlice';
import cartImageSlice from './CartImageSlice';
import modalCount from './ModalCountSlice';

import countdown from './CountDownSlice';
const persistConfig = {
	key: 'root',
	storage,
};

const rootReducer = combineReducers({
	cart,
	faq,
	exclusive,
	user,
	limited,
	banner,
	cartImageSlice,
	countdown,
	modalCount,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type AppThunkDispatch = ThunkDispatch<unknown, undefined, AnyAction>;

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	AnyAction
>;

export const useAppDispatch = () => useDispatch<AppThunkDispatch>();

export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
