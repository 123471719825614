import React from 'react';

const TickSquare = ({ size = '32' }: { size?: string }) => {
	return (
		<div>
			<svg
				width={size}
				height={size}
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="vuesax/bold/tick-square">
					<g id="tick-square">
						<path
							id="Vector"
							d="M21.5865 2.66699H10.4132C5.55984 2.66699 2.6665 5.56033 2.6665 10.4137V21.5737C2.6665 26.4403 5.55984 29.3337 10.4132 29.3337H21.5732C26.4265 29.3337 29.3198 26.4403 29.3198 21.587V10.4137C29.3332 5.56033 26.4398 2.66699 21.5865 2.66699ZM22.3732 12.9337L14.8132 20.4937C14.6265 20.6803 14.3732 20.787 14.1065 20.787C13.8398 20.787 13.5865 20.6803 13.3998 20.4937L9.6265 16.7203C9.23984 16.3337 9.23984 15.6937 9.6265 15.307C10.0132 14.9203 10.6532 14.9203 11.0398 15.307L14.1065 18.3737L20.9598 11.5203C21.3465 11.1337 21.9865 11.1337 22.3732 11.5203C22.7598 11.907 22.7598 12.5337 22.3732 12.9337Z"
							fill="white"
						/>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default TickSquare;
