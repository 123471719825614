import React, { useEffect, useState } from 'react';
import { countdownComplete } from '../../../store/CountDownSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import Loader from '../../../Components/Svg/Loader';
import {
	BLACK,
	GRAY200,
	GRAY300,
	GRAY50,
	WHITE,
} from '../../../Constant/Color.Constant';
import { Countdown, TimerSegmentProps } from '../../../Interface/Index';
import { ROUTES } from '../../../Constant/Routes.Constant';

const TimerSegment: React.FC<TimerSegmentProps> = ({
	value,
	label,
	labelColor = GRAY50,
	digitColor = BLACK,
}) => (
	<div
		style={{
			color: digitColor,
			background:
				window.location.pathname === ROUTES.LIMITED_EDITION ? GRAY300 : WHITE,
		}}
		className="font-normal  rounded justify-center flex items-center sm:p-3 p-2"
	>
		<div className="max-w-[100px]">
			<div className="lg:text-[32px] xs:text-2xl flex justify-center">
				<span style={{ color: digitColor }}>{`${value}`}</span>
				<span style={{ color: labelColor }}>{`${label}`}</span>
			</div>
		</div>
	</div>
);

const OfferTime = () => {
	const dispatch = useAppDispatch();
	const [countdown, setCountdown] = useState<Countdown | null>(null);

	const endDates = useAppSelector(
		(state: any) => state?.countdown?.endDate?.data?.limitedEditionDate
	);

	useEffect(() => {
		if (!endDates) {
			setCountdown(null);
			return;
		}

		const intervalId = setInterval(() => {
			const newCountdown = calculateCountdown(endDates);
			setCountdown(newCountdown);

			if (
				newCountdown.days <= 0 &&
				newCountdown.hours <= 0 &&
				newCountdown.minutes <= 0 &&
				newCountdown.seconds <= 0
			) {
				clearInterval(intervalId);
				dispatch(countdownComplete(true));
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, [endDates]);

	function calculateCountdown(endDate: string): Countdown {
		const endDateObj = new Date(endDate);
		const now = new Date();
		const timeRemaining = Math.max(0, endDateObj.getTime() - now.getTime());
		const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
		const hours = Math.floor(
			(timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const minutes = Math.floor(
			(timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
		);
		const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

		return { days, hours, minutes, seconds };
	}

	return (
		<>
			{countdown === null ? (
				<div className="w-full flex justify-center items-center">
					<button
						disabled
						type="button"
						className={`py-2.5 w-[20%] px-5 me-2 text-sm font-medium rounded-lg inline-flex items-center`}
						style={{
							color: BLACK,
							backgroundColor: WHITE,
							border: `1px solid ${GRAY200}`,
						}}
					>
						<Loader />
						Loading...
					</button>
				</div>
			) : (
				<div className="flex justify-center gap-4 items-center">
					{countdown.days > 0 && (
						<>
							<TimerSegment value={countdown.days} label="D" />
							<span
								className="lg:text-[32px] xs:text-xl"
								style={{ color: BLACK }}
							>
								:
							</span>
						</>
					)}
					<TimerSegment value={countdown.hours} label="H" />
					<span className="lg:text-[32px] xs:text-xl" style={{ color: BLACK }}>
						:
					</span>
					<TimerSegment value={countdown.minutes} label="M" />
					{countdown.days === 0 && (
						<>
							<span
								className="lg:text-[32px] xs:text-xl"
								style={{ color: BLACK }}
							>
								:
							</span>
							<TimerSegment value={countdown.seconds} label="S" />
						</>
					)}
				</div>
			)}
		</>
	);
};

export default OfferTime;
