import React from 'react';
import { CommonSvgProps } from '../../Interface/Index';

const Remove: React.FC<CommonSvgProps> = ({
	onClick,
	className,
	width = '25',
	strokeColor = 'black',
}) => {
	return (
		<div className={className} onClick={onClick}>
			<svg
				width={width}
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4.7583 15.2428L10.0013 9.99984L15.2443 15.2428M15.2443 4.75684L10.0003 9.99984L4.7583 4.75684"
					stroke={strokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};

export default Remove;
