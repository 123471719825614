import React from 'react';
const RupeeUnselected = ({ size = '24' }: { size?: string }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="vuesax/linear/tick-square">
				<g id="tick-square">
					<path
						id="Vector"
						d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
						stroke="#9CA3AF"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_2"
						d="M7.75 11.9999L10.58 14.8299L16.25 9.16992"
						stroke="#9CA3AF"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
		</svg>
	);
};

export default RupeeUnselected;
