import { createSlice } from '@reduxjs/toolkit';
export type CartImages = {
	backgroundImages: Array<File>;
	userImages: Array<File>;
};
const initialState: CartImages = {
	backgroundImages: [],
	userImages: [],
};
export const cartImagesSlice = createSlice({
	name: 'cartImages',
	initialState,
	reducers: {
		setBackgroundImage: (state, action) => {
			state.backgroundImages.push(action.payload);
		},
		setUserImages: (state, action) => {
			state.userImages.push(action.payload);
		},
		removeBackgroundImage: (state, action) => {
			state.backgroundImages.splice(action.payload, 1);
		},
		removeAllBackgroundImage: (state) => {
			state.backgroundImages = [];
		},
		removeUserImage: (state, action) => {
			state.userImages.splice(action.payload, 1);
		},
	},
});
// Action creators are generated for each case reducer function
export const {
	setBackgroundImage,
	setUserImages,
	removeBackgroundImage,
	removeUserImage,
	removeAllBackgroundImage,
} = cartImagesSlice.actions;
export default cartImagesSlice.reducer;
