import SEO from '../../../Components/Helmet/SEO';
import SimpleAccordion from '../../../Components/Payment/Payment';
import { PAYMENT_METHOD } from '../../../Constant/Seo.Constant';

const PaymentMethod = ({
	setPaymentMethod,
	grandTotal,
	couponCode,
}: {
	setPaymentMethod: React.Dispatch<React.SetStateAction<boolean>>;
	grandTotal: number;
	couponCode: string;
	applyCoupon: boolean;
}) => {
	return (
		<>
			<SEO title="Payment Method" description={PAYMENT_METHOD} />
			<div className="w-full flex justify-center items-center lg:flex lg:justify-start">
				<div className="w-full">
					<SimpleAccordion
						setPaymentMethod={setPaymentMethod}
						grandTotal={grandTotal}
						couponCode={couponCode}
					/>
				</div>
			</div>
		</>
	);
};

export default PaymentMethod;
