import * as yup from 'yup';
import {
	EMAIL_ID_IS_REQUIRED,
	PASSWORD_IS_REQUIRED,
	PHONE_NUMBER_IS_REQUIRED,
	FIRST_NAME_IS_REQUIRED,
	LAST_NAME_IS_REQUIRED,
	RELATED_SUBJECT_REQUIRED,
	MESSAGE_REQUIRED,
	NAME_IS_REQUIRED,
	ZIP_CODE_IS_REQUIRED,
	ADDRESS_IS_REQUIRED,
	PHONE_NUMBER_IS_INVALID,
	EMAIL_ID_IS_INVALID,
	PASSWORD_IS_NOT_MATCH,
	INVALID_PASSWORD,
	IMAGE_REMOVE_OR_CHANGE,
	PHONE_NUMBER_NOT_VALID,
	DATE_REQUIRED,
	OCCASION_IS_REQUIRED,
	DESCRIPTION_IS_REQUIRED,
	SHORT_MESSAGE_IS_REQUIRED,
	LONG_MESSAGE_IS_REQUIRED,
	FULL_NAME_IS_REQUIRED,
	PIN_CODE_MIN_MAX_MESSAGE,
} from '../../Constant/Error.Constant';

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
export const MOBILE_REGEX = /^(\+91[-\s]?)?[56789]\d{9}$/;

//Login Form Validations
export const loginValidationSchema = yup.object().shape({
	email: yup
		.string()
		.required(EMAIL_ID_IS_REQUIRED)
		.matches(EMAIL_REGEX, EMAIL_ID_IS_INVALID),
	password: yup.string().required(PASSWORD_IS_REQUIRED),
});

// Forgetpassword form validation
export const forgetPasswordValidationSchema = yup.object().shape({
	email: yup
		.string()
		.required(EMAIL_ID_IS_REQUIRED)
		.matches(EMAIL_REGEX, EMAIL_ID_IS_INVALID),
});

//Register Form Validations
export const registerValidationSchema = yup.object().shape({
	name: yup.string().required(FULL_NAME_IS_REQUIRED),
	email: yup
		.string()
		.required(EMAIL_ID_IS_REQUIRED)
		.matches(EMAIL_REGEX, EMAIL_ID_IS_INVALID),
	password: yup
		.string()
		.required(PASSWORD_IS_REQUIRED)
		.matches(PASSWORD_REGEX, INVALID_PASSWORD),
	phone: yup
		.string()
		.required(PHONE_NUMBER_IS_REQUIRED)
		.min(10, PHONE_NUMBER_IS_INVALID)
		.matches(MOBILE_REGEX, PHONE_NUMBER_NOT_VALID),
});

// new gift signle note validation schema
export const newGiftSingleNoteValidationSchema = yup.object().shape({
	occassion: yup.string().required(OCCASION_IS_REQUIRED),
	date: yup.date().required(DATE_REQUIRED),
	name: yup.string().required(SHORT_MESSAGE_IS_REQUIRED),
	text: yup.string().required(LONG_MESSAGE_IS_REQUIRED),
	title: yup.string().required(MESSAGE_REQUIRED),
	denomination: yup.array().min(1),
	imageString: yup.string().required(IMAGE_REMOVE_OR_CHANGE),
});

export const newGiftMultipleNoteValidationSchema = yup.object().shape({
	allSets: yup.boolean().required(),
});

export const newGiftSetLayoutValidationSchema = yup.object().shape({
	message: yup.string().required(),
	date: yup.string().required('date123'),
	currency: yup.string().required(),
	image: yup.string().required(),
	imageString: yup.string().required(IMAGE_REMOVE_OR_CHANGE),
});

// forgot password
export const resetPasswordValidationSchema = yup.object().shape({
	password: yup
		.string()
		.required(PASSWORD_IS_REQUIRED)
		.matches(PASSWORD_REGEX, INVALID_PASSWORD),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password')], PASSWORD_IS_NOT_MATCH),
});

// This Is Contact Form validations
export const contactUsValidationSchema = yup.object().shape({
	firstName: yup.string().required(FIRST_NAME_IS_REQUIRED),
	lastName: yup.string().required(LAST_NAME_IS_REQUIRED),
	email: yup
		.string()
		.required(EMAIL_ID_IS_REQUIRED)
		.matches(EMAIL_REGEX, EMAIL_ID_IS_INVALID),
	phone: yup
		.string()
		.required(PHONE_NUMBER_IS_REQUIRED)
		.min(10, PHONE_NUMBER_IS_INVALID)
		.matches(MOBILE_REGEX, PHONE_NUMBER_NOT_VALID),
	subject: yup.string().required(RELATED_SUBJECT_REQUIRED),
	message: yup.string().required(MESSAGE_REQUIRED),
});

export const userAddressValidationSchema = yup.object().shape({
	name: yup.string().required(NAME_IS_REQUIRED),
	pinCode: yup
		.string()
		.required(ZIP_CODE_IS_REQUIRED)
		.min(6, PIN_CODE_MIN_MAX_MESSAGE)
		.max(6, PIN_CODE_MIN_MAX_MESSAGE),
	address: yup.string().required(ADDRESS_IS_REQUIRED),
});

export const personalInfoValidataionSchema = yup.object().shape({
	name: yup.string().required(NAME_IS_REQUIRED),
	phone: yup
		.string()
		.required(PHONE_NUMBER_IS_REQUIRED)
		.min(10, PHONE_NUMBER_IS_INVALID)
		.matches(MOBILE_REGEX, PHONE_NUMBER_NOT_VALID),
});

export const inquireValidationSchema = yup.object().shape({
	email: yup
		.string()
		.required(EMAIL_ID_IS_REQUIRED)
		.matches(EMAIL_REGEX, EMAIL_ID_IS_INVALID),
	name: yup.string().required(NAME_IS_REQUIRED),
	phoneNumber: yup
		.string()
		.required(PHONE_NUMBER_IS_REQUIRED)
		.min(10, PHONE_NUMBER_IS_INVALID)
		.matches(MOBILE_REGEX, PHONE_NUMBER_NOT_VALID),
	message: yup.string().required(MESSAGE_REQUIRED),
});

export const commonValidationSchema = yup.object({
	email: yup
		.string()
		.required(EMAIL_ID_IS_REQUIRED)
		.matches(EMAIL_REGEX, EMAIL_ID_IS_INVALID),
	phone: yup
		.string()
		.required(PHONE_NUMBER_IS_REQUIRED)
		.min(10, PHONE_NUMBER_IS_INVALID)
		.matches(MOBILE_REGEX, PHONE_NUMBER_NOT_VALID),
	name: yup.string().required(NAME_IS_REQUIRED),
});

export const setOfAllNotesValidationSchema = commonValidationSchema.shape({
	occassion: yup.string().required(OCCASION_IS_REQUIRED),
	date: yup.date().required(DATE_REQUIRED),
});

export const otherValidationSchema = commonValidationSchema.shape({
	description: yup.string().required(DESCRIPTION_IS_REQUIRED),
});
