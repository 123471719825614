import { useEffect, useState } from 'react';
import { LG, MD, ND, SM, XL, XS, XXL } from '../../Constant/Constant';
import { getSizeName } from '../getScreenSize';

const useScreenSize = () => {
	const [screenSize, setScreenSize] = useState(getSizeName);

	const handleResize = () => {
		setScreenSize(getSizeName());
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const screenSizes = {
		xxl: [XXL, XL, LG, MD, SM, ND, XS],
		xl: [XL, LG, MD, SM, ND, XS],
		lg: [LG, MD, SM, ND, XS],
		md: [MD, SM, ND, XS],
		sm: [SM, ND, XS],
		nd: [ND, XS],
		xs: [XS],
	};

	const isExistScreen = {
		xxl: screenSizes.xxl.includes(screenSize),
		xl: screenSizes.xl.includes(screenSize),
		lg: screenSizes.lg.includes(screenSize),
		md: screenSizes.md.includes(screenSize),
		sm: screenSizes.sm.includes(screenSize),
		nd: screenSizes.nd.includes(screenSize),
		xs: screenSizes.xs.includes(screenSize),
	};

	return { screenSize, isExistScreen };
};

export default useScreenSize;
