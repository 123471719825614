import React from 'react';
import { Gift } from '../../Interface/Index';

const ItemsBatch = ({ data }: { data: Gift[] }) => {
	const ItemsCount = data?.length;
	const totalItemsLabel = ItemsCount
		? ItemsCount === 1
			? `${ItemsCount} item`
			: `${ItemsCount} items`
		: '0 item';
	return (
		<div className="capitalize text-sm flex">
			<span>{'('}</span>
			<div>{totalItemsLabel}</div>
			<span>{')'}</span>
		</div>
	);
};

export default ItemsBatch;
