import React from 'react';
import {
	Collection,
	FooterItem,
	FormField,
	InputField,
	InputOrderField,
	Item,
	NavItem,
	PrivacyPolicyItem,
	TestimonialData,
	UserAddress,
	casesProps,
	noteLayouts,
} from '../Interface/Index';

import { ROUTES } from './Routes.Constant';

import {
	FACEBOOK_URL,
	INSTRAGRAM_URL,
	THE_RUPI,
	TWITTER_URL,
} from './KeyWord.Constant';
import LogOut from '../Components/Svg/LogOut';
import Facebook from '../Components/Svg/Facebook';
import Twiter from '../Components/Svg/Twiter';
import Instragram from '../Components/Svg/Instragram';
import {
	CurrencyInterface,
	fiveNotesDataStyleTS,
} from '../Interface/NewGiftInterface';

export const CLASSES = 'classic';
export const SET_OF_FOUR = 'set-of-four';
export const SINGLE = 'single';
export const MULTIPLE = 'multiple';
export const SETS = 'sets';
export const EDIT = 'edit';

export const ALL_GIFTS = [SINGLE, MULTIPLE, SETS];

export const STORAGEKEY = {
	NAME: 'NAME',
	ACCESSTOKEN: 'ACCESSTOKEN',
	ROLE: 'ROLE',
	EMAIL: 'EMAIL',
	REFRESHTOKEN: 'REFRESHTOKEN',
};

export const ACCEPT_FILES_EXTENSSIONS = '.jpg, .jpeg, .png';
export const WEBSITE_URL = 'www.therupi.com';

export const inputFields: InputField[] = [
	{
		label: 'Full Name',
		name: 'name',
		placeholder: `Enter your Full name`,
	},
	{
		label: 'Email address',
		name: 'email',
		placeholder: `Enter your email Id`,
	},

	{
		label: 'Password',
		name: 'password',
		placeholder: `Enter your password number`,
	},
	{
		label: 'Phone Number',
		name: 'phone',
		placeholder: `Enter your phone number`,
	},
];

// this is Order SetOfAll Notes
export const inputOrderFields: InputOrderField[] = [
	{
		label: 'Name',
		name: 'name',
		placeholder: 'Enter your Name',
	},
	{
		label: 'Email address',
		name: 'email',
		placeholder: 'Enter your email Id',
	},
	{
		label: 'Occasion',
		name: 'occasion',
		placeholder: 'Enter your Occasion',
	},
	{
		label: 'Date',
		name: 'date',
		placeholder: 'Enter your Date',
	},
];
// This Is Static Data For Carousel.
export const stackItems = [
	{
		id: 1,
		heading: 'QUALITY ASSURANCE',
		content:
			'We are committed to sourcing authentic and genuine Indian currency notes, ensuring that each note with a special serial number meets the highest quality standards.',
	},
	{
		id: 2,
		imageSrc: 'Svg/Correct.svg',
		heading: ' UNIQUE SELECTION',
		content:
			'We strive to offer a wide range of currency notes featuring special serial numbers,  with important dates, such as birthdays, anniversaries, festivals, and other significant occasions.',
	},
	{
		id: 3,
		imageSrc: 'Svg/Correct.svg',
		heading: 'PERSONALIZATION',
		content:
			'We understand the importance of personal touch in gifting. Our platform enables customers to customize their purchases by adding personalized messages or dedications.',
	},
];

export const stackItems2 = [
	{
		id: 2,
		imageSrc: 'Svg/Correct.svg',
		heading: 'ACCSESSIBILITY',
		content:
			'We aim to make our products accessible to customers worldwide. Through our user-friendly website and secure online purchasing system, we ensure a seamless and convenient shopping experience.',
	},
	{
		id: 2,
		imageSrc: 'Svg/Correct.svg',
		heading: 'CUSTOMER SATISFACTION',
		content:
			'We prioritize customer satisfaction by providing exceptional customer service and promptly addressing any inquiries or concerns. Our dedicated team is always ready to assist customers.',
	},

	{
		id: 3,
		heading: 'ETHICAL BUSINESS PRACTIES',
		content:
			'We conduct our business with integrity, adhering to all relevant laws and regulations. We maintain transparency and foster mutually beneficial relationships with our customers and stakeholders.',
	},
];

// This Is Static Data For Persnoal Details Feildes of My Account.
export const items: Item[] = [
	{
		id: 1,
		label: 'First Name',
		type: 'text',
		placeholder: `Enter your Name`,
		value: 'firstName',
		name: 'firstName',
	},
	{
		id: 5,
		label: 'Last Name',
		type: 'text',
		placeholder: `Enter your Name`,
		value: 'lastName',
		name: 'lastName',
	},
	{
		id: 2,
		label: 'Email Address',
		type: 'email',
		placeholder: `Enter your Email Address`,
		value: 'email',
		name: 'email',
	},
	{
		id: 3,
		label: 'Phone Number',
		type: 'number',
		placeholder: `Enter your Number`,
		value: 'phone',
		name: 'phone',
	},
	{
		id: 4,
		label: 'Date Of Birth',
		type: 'date',
		placeholder: `Enter your Date`,
		value: 'DOB',
		name: 'dob',
	},
];

// This Is  Static Data For Testimonial Data.
export const testimonials: TestimonialData[] = [
	{
		description: `I recently purchased a product from your store, and I must say I am extremely satisfied with the quality and service. The item arrived promptly, well-packaged, and exceeded my expectations. The customer support team was also very helpful and responsive to my inquiries."`,
		name: 'Shahwat Shah',
		role: 'CEO, Tuple',
		image1: '/Images/user/shash.png',
	},
	{
		description: `I'm absolutely thrilled with my purchase! The product quality is outstanding, and the attention to detail is commendable. The delivery was swift, and the packaging was eco-friendly. A delightful shopping experience overall!`,
		name: 'Vyas Utsav',
		role: 'CEO, Workcation',
		image1: '/Images/user/utsav.png',
	},
	{
		description: `The customer service I received was exceptional. I had a few questions before making my purchase, and the support team was quick to respond and very helpful. It's refreshing to see a company that truly cares about its customers.`,
		name: 'helly kakdiya',
		role: 'CEO, Tuple',
		image1: '/Images/user/helly.png',
	},
	{
		description: `I'm a repeat customer, and once again, I'm impressed. The variety of products is fantastic, and the pricing is fair. My order arrived ahead of schedule, and the product lived up to the hype. Keep up the great work!`,
		name: 'Mansi Patel',
		role: 'CEO, Workcation',
		image1: '/Images/user/mansi.png',
	},
	{
		description: `I'm a repeat customer, and once again, I'm impressed. The variety of products is fantastic, and the pricing is fair. My order arrived ahead of schedule, and the product lived up to the hype. Keep up the great work!`,
		name: 'Denish Prajapati',
		role: 'CEO, Workcation',
		image1: '/Images/user/Denish.png',
	},
];

// This Is  Static Data For paymentMethods.
export const paymentMethods = [
	{
		id: 'debit-credit',
		summary: 'Debit/Credit Card',
		details: null,
		method: { method: 'card', title: 'Pay Via Card' },
	},
	{
		id: 'upi',
		summary: 'UPI',
		details: null,
		method: { method: 'upi', title: 'Pay Via UPI' },
	},

	{
		id: 'net-banking',
		summary: 'Net Banking',
		details: null,
		method: { method: 'netbanking', title: 'Pay Via Net Banking' },
	},
];

// This Is  Static Data For Currency Images With Image height and width.
export const Currency: CurrencyInterface = [
	{
		image: '/Images/OneRupee.jpg',
		rupee: 1,
		screens: {
			xxl: {
				width: 8.599,
				height: 5.514,
			},
			xl: {
				width: 8.599,
				height: 5.514,
			},
			lg: {
				width: 8.599,
				height: 5.514,
			},
			md: {
				height: 7.245526127,
				width: 9.980052493,
			},
			sm: {
				height: 4.954916077,
				width: 8.040052493,
			},
			nd: {
				height: 4.382188805,
				width: 5.453385826,
			},
		},
	},
	{
		image: '/Images/TwoRupee.jpg',
		rupee: 2,
		screens: {
			xxl: {
				width: 9.146,
				height: 5.45,
			},
			xl: {
				width: 9.146,
				height: 5.45,
			},
			lg: {
				width: 9.146,
				height: 5.45,
			},
			md: {
				height: 7.245526127,
				width: 11.626246719,
			},
			sm: {
				height: 4.954916077,
				width: 9.366246719,
			},
			nd: {
				height: 4.382188805,
				width: 6.352913386,
			},
		},
	},
	{
		image: '/Images/FiveRupee.png',
		rupee: 5,
		screens: {
			xxl: {
				width: 9.718,
				height: 5.305,
			},
			xl: {
				width: 9.718,
				height: 5.305,
			},
			lg: {
				width: 9.718,
				height: 5.305,
			},
			md: {
				height: 7.245526127,
				width: 12.037795276,
			},
			sm: {
				height: 4.954916077,
				width: 9.697795276,
			},
			nd: {
				height: 4.382188805,
				width: 6.577795276,
			},
		},
	},
	{
		image: '/Images/TenRupee.jpg',
		rupee: 10,
		screens: {
			xxl: {
				width: 10.354,
				height: 5.004,
			},
			xl: {
				width: 10.354,
				height: 5.004,
			},
			lg: {
				width: 10.354,
				height: 5.004,
			},
			md: {
				height: 7.245526127,
				width: 12.65511811,
			},
			sm: {
				height: 4.954916077,
				width: 10.19511811,
			},
			nd: {
				height: 4.382188805,
				width: 6.91511811,
			},
		},
	},
	{
		image: '/Images/TwentyRupee.jpg',
		rupee: 20,
		screens: {
			xxl: {
				width: 11.033,
				height: 5.526,
			},
			xl: {
				width: 11.033,
				height: 5.526,
			},
			lg: {
				width: 11.033,
				height: 5.526,
			},
			md: {
				height: 7.245526127,
				width: 13.272440945,
			},
			sm: {
				height: 4.954916077,
				width: 10.692440945,
			},
			nd: {
				height: 4.382188805,
				width: 7.252440945,
			},
		},
	},
	{
		image: '/Images/FiftyRupee.jpg',
		rupee: 50,
		screens: {
			xxl: {
				width: 11.789,
				height: 5.778,
			},
			xl: {
				width: 11.789,
				height: 5.778,
			},
			lg: {
				width: 11.789,
				height: 5.778,
			},
			md: {
				height: 7.590551181102363,
				width: 13.88976378,
			},
			sm: {
				height: 5.190864462,
				width: 11.18976378,
			},
			nd: {
				height: 4.590864462,
				width: 7.58976378,
			},
		},
	},
	{
		image: '/Images/HundredRupee.png',
		rupee: 100,
		screens: {
			xxl: {
				height: 6.119,
				width: 13.09,
			},
			xl: {
				height: 6.119,
				width: 13.09,
			},
			lg: {
				height: 6.119,
				width: 13.09,
			},
			md: {
				height: 7.590551181102363,
				width: 14.609973753,
			},
			sm: {
				height: 5.190864462,
				width: 11.769973753,
			},
			nd: {
				height: 4.590864462,
				width: 7.983307086,
			},
		},
	},
	{
		image: '/Images/TwoHundred.jpg',
		rupee: 200,
		screens: {
			xxl: {
				width: 13.661,
				height: 6.164,
			},
			xl: {
				width: 13.661,
				height: 6.164,
			},
			lg: {
				width: 13.661,
				height: 6.164,
			},
			md: {
				height: 7.590551181102363,
				width: 15.02152231,
			},
			sm: {
				height: 5.190864462,
				width: 12.10152231,
			},
			nd: {
				height: 4.590864462,
				width: 8.208188977,
			},
		},
	},
	{
		image: '/Images/FiveHundred.png',
		rupee: 500,
		screens: {
			xxl: {
				width: 14.433,
				height: 6.593,
			},
			xl: {
				width: 14.433,
				height: 6.593,
			},
			lg: {
				width: 14.433,
				height: 6.593,
			},
			md: {
				height: 7.590551181102363,
				width: 15.43307086614173,
			},
			sm: {
				height: 5.190864462,
				width: 12.43307086614173,
			},
			nd: {
				height: 4.590864462,
				width: 8.43307086614173,
			},
		},
	},
];

export const defaultCardJson: Record<string, Record<string, number>> = {
	xxl: {
		width: 8.599,
		height: 5.514,
	},
	xl: {
		width: 8.599,
		height: 5.514,
	},
	lg: {
		width: 8.599,
		height: 5.514,
	},
	md: {
		width: 10.98005,
		height: 6.74553,
	},
	sm: {
		width: 8.04005,
		height: 4.95492,
	},
	nd: {
		width: 5.85339,
		height: 3.78219,
	},
};

// This Is  Static Data For Currency Values.
export const currencyValues = [1, 2, 5, 10, 20, 50, 100, 200, 500];

// This Is  Static Data Contact Us Feildes.
export const formFields: FormField[] = [
	{
		label: 'First Name',
		type: 'text',
		name: 'firstName',
		required: true,
	},
	{
		label: 'Last Name',
		type: 'text',
		name: 'lastName',
		required: true,
	},
	{
		label: 'Email',
		type: 'text',
		name: 'email',
		required: true,
		pattern: /^\S+@\S+$/i,
	},
	{
		label: 'Phone',
		type: 'number',
		name: 'phone',
		required: true,
		pattern: /^[0-9]{10}$/,
	},

	{
		label: 'Subject',
		type: 'text',
		name: 'subject',
		required: true,
	},
	{
		label: 'Message',
		type: 'textarea',
		name: 'message',
		required: true,
		rows: 3,
	},
];

// This Is  Static Data For Exclusive Notes Of HomePage.
export const collections: Collection[] = [
	{
		imgSrc: './Images/original/Album.jpg',
		title: 'Album',
		description:
			'This is Album Layout its show your many memories with your special date.in this album you will get your photos with indian currency.',
		to: `${ROUTES.LIMITED_DETAILES}`,
	},
	{
		imgSrc: './Images/original/IMG-8749.jpg',
		title: 'Single Note',
		description:
			'This is Single Note Layout in this layout you will get your one photo with indian currency and that reflects your special occasion.',
		to: `${ROUTES.LIMITED_DETAILES}`,
	},
	{
		imgSrc: './Images/original/SetOfAll.jpg',
		title: 'Set Of All Notes',
		description:
			'This is Set of All notes in this you will get all indian currency with your special occasion date.we will give all currency your special date.',
		to: `${ROUTES.LIMITED_DETAILES}`,
	},
	{
		imgSrc: './Images/original/IMG-3479.jpg',
		title: 'Without Image',
		description:
			'This is without image layout in this you will get your special occasion date in you selected currency.in this layout you have only currency with special date.',
		to: `${ROUTES.LIMITED_DETAILES}`,
	},
	{
		imgSrc: './Images/original/SingleNote.jpg',
		title: 'Single Note Layout 2',
		description:
			'This is single note layout in this your one image and one you selected currency with the your special occasion.',
		to: `${ROUTES.LIMITED_DETAILES}`,
	},
	{
		imgSrc: './Images/original/IMG-7424.jpg',
		title: 'End With 786 Number',
		description:
			'This is special date with end number 786  in currency.this is special note and our special collection.',
		to: `${ROUTES.LIMITED_DETAILES}`,
	},
	{
		imgSrc: './Images/original/Album2.jpg',
		title: 'COLLECTION TITLE 2',
		description:
			'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam atque quis esse error aliquid itaque velit. Quibusdam a modi, ut.',
		to: `${ROUTES.LIMITED_DETAILES}`,
	},
	{
		imgSrc: './Images/setoffour.jpg',
		title: 'Set Of Four',
		description:
			'This is Set of Four notes layout in this layout your four photos with your slected four currency.in this you get special occasion with four currency.',
		to: `${ROUTES.LIMITED_DETAILES}`,
	},
];

// This Is  Static Data For NoteLayouts Of HomePage.
export const noteLayoutsCard: noteLayouts[] = [
	{
		img: ['/Images/Hero 1.png'],
		title: 'SINGLE NOTE',
		description:
			'Experience our Single Note selection, where you receive a unique Indian currency note with a special date, accompanied by a personalized photo of yours.',
		to: `${ROUTES.NEW_GIFT_CLASSIC}`,
		id: CLASSES,
	},
	{
		img: ['/Images/Hero 3.png'],
		title: 'SET OF 4',
		description:
			'Explore our Set of 4 product, featuring four distinct Indian currency notes with special dates, complemented by a collection of four personalized photos.',
		to: `${ROUTES.NEW_GIFT_SET_OF_FOUR}`,
		id: SET_OF_FOUR,
	},
	{
		img: ['/Images/Hero 2.png'],
		title: 'SET OF ALL',
		description:
			'Delve into our Set of All product, offering a comprehensive ensemble of all Indian currency notes with your special date, combined with your cherished photos.',
		// to: `${ROUTES.ORDER_MODAL}/setOfAllNotes`,
		id: 'setOfAllNotes',
	},
	{
		img: ['/Images/Hero 1.png'],
		title: 'ALBUM',
		description:
			'Discover our Album product, presenting a personalized album filled with your memories. Each album includes customized pages with Indian currency notes featuring your special date.',
		// to: `${ROUTES.ORDER_MODAL}/album`,
		id: 'album',
	},
];

export const UserShippingAddress: UserAddress[] = [
	{
		id: 1,
		label: 'Address line 1',
		type: 'text',
		placeholder: `Enter your address`,
		value: 'addressLineOne',
	},
	{
		id: 2,
		label: 'Address line 2',
		type: 'text',
		placeholder: `Enter your second address`,
		value: 'addressLineTwo',
	},

	{
		id: 3,
		label: 'City',
		type: 'text',
		placeholder: 'Select Your City',
		value: 'city',
	},
	{
		id: 4,
		label: 'State',
		type: 'text',
		placeholder: 'Select Your State',
		value: 'state',
	},
	{
		id: 5,
		label: 'ZipCode',
		type: 'number',
		placeholder: 'Select Your Aerea Zip Code',
		value: 'zipCode',
	},
	{
		id: 6,
		label: 'Phone',
		type: 'tel',
		placeholder: `Enter your Phone Number`,
		value: 'phone',
	},
];

// This Is Static Data For Select Values For Occasion Of NewGift Page.
export const occasionData = [
	{
		name: 'Select Your Occasion',
		value: '',
	},
	{
		name: 'Birthday',
		value: 'HAPPY BIRTHDAY',
	},
	{
		name: 'Anniversary',
		value: 'HAPPY ANNIVERSARY',
	},
];

// This Is Static Data For Footeritems
export const menuItems: FooterItem[] = [
	{
		label: 'About',
		fontSize: { xs: '14px', sm: '14px', lg: '18px', md: '15px' },
		fontWeight: '600',
		link: ROUTES.ABOUT,
	},
	{
		label: 'Contact',
		fontSize: { xs: '14px', sm: '14px', lg: '18px', md: '15px' },
		fontWeight: '600',
		link: ROUTES.CONTACT,
	},
	{
		label: 'Terms & Conditions',
		fontSize: { xs: '14px', sm: '14px', lg: '18px', md: '15px' },
		fontWeight: '600',
		link: ROUTES.TERMS_CONDITION,
	},
	{
		label: 'Privacy Policy',
		fontSize: { xs: '14px', sm: '14px', lg: '18px', md: '15px' },
		fontWeight: '600',
		link: ROUTES.PRIVACY_POLICY,
	},
];

export const positionButtons = [
	{
		name: 'top-left',
		class: 'top-0 left-0',
	},
	{
		name: 'center-left',
		class: 'left-0 top-2/4 -translate-y-2/4',
	},
	{
		name: 'bottom-left',
		class: 'bottom-0 left-0',
	},
	{
		name: 'top-center',
		class: 'top-0 left-2/4 -translate-x-2/4',
	},
	{
		name: 'center',
		class: 'top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4',
	},
	{
		name: 'bottom-center',
		class: 'bottom-0 left-2/4 -translate-x-2/4',
	},
	{
		name: 'top-right',
		class: 'top-0 right-0',
	},
	{
		name: 'center-right',
		class: 'right-0 top-2/4 -translate-y-2/4',
	},
	{
		name: 'bottom-right',
		class: 'bottom-0 right-0',
	},
];

export const privacyPolicyData: PrivacyPolicyItem[] = [
	{
		title: 'USER INFORMATION',
		content: [
			'To avail certain services on our Websites, users are required to provide certain information for the registration process namely: - a) your name, b) email address, c) sex, d) age, e) PIN code, f) credit card or debit card details g) medical records and history h) sexual orientation, i) biometric information, j) password etc., and / or your occupation, interests, and the like. The Information as supplied by the users enables us to improve our sites and provide you the most user-friendly experience.',
			'All required information is service dependent and we may use the above said user information to, maintain, protect, and improve its services (including advertising services) and for developing new services',
			'Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.',
		],
	},
	{
		title: 'COOKIES',
		content: [
			"To improve the responsiveness of the sites for our users, we may use cookies, or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user's individual interests using the Identified Computer. Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. The only personal information a cookie can contain is information you supply (an example of this is when you ask for our Personalised Horoscope). A cookie cannot read data off your hard drive. Our advertisers may also assign their own cookies to your browser (if you click on their ads), a process that we do not control",
			"Our web servers automatically collect limited information about your computer's connection to the Internet, including your IP address, when you visit our site. (Your IP address is a number that lets computers attached to the Internet know where to send you data -- such as the web pages you view.) Your IP address does not identify you personally. We use this information to deliver our web pages to you upon request, to tailor our site to the interests of our users, to measure traffic within our site and let advertisers know the geographic locations from where our visitors come. ",
		],
	},
	{
		title: 'LINKS TO THE OTHER SITES',
		content: [
			'Our policy discloses the privacy practices for our own web site only. Our site provides links to other websites also that are beyond our control. We shall in no way be responsible in the way of your use of such sites.',
		],
	},
	{
		title: 'INFORMATION SHARING',
		content: [
			"We don't share the sensitive personal information to any third party without obtaining the prior consent of the user in the following limited circumstances:",
		],
	},
	{
		title: 'INFORMATION SECURITY',
		content: [
			'We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.',
			'All information gathered on our Website is securely stored within our controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. And, of course, any information you include in a posting to the discussion areas is available to anyone with Internet access. ',
			'However, the internet is an ever evolving medium. We may change our Privacy Policy from time to time to incorporate necessary future changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be.',
		],
	},
];

export const paragraphs: string[] = [
	`The terms "We" / "Us" / "Our"/”Company” individually and collectively refer to ${THE_RUPI} and the terms "You" /"Your" / Yourself" refer to the users. ${THE_RUPI} is a subsidiary owned by The Rupe OWENER. Our privacy policy is regarding the use of our clients.`,
	'This Privacy Policy is an electronic record in the form of an electronic contract formed under the information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents / records in various statutes as amended by the information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature.',
	`This Privacy Policy is a legally binding document between you and ${THE_RUPI} (both terms defined below). The terms of this Privacy Policy will be effective upon your acceptance of the same (directly or indirectly in electronic form, by clicking on the I accept tab or by use of the website or by other means) and will govern the relationship between you and ${THE_RUPI} for your use of the website`,
	'This document is published and shall be construed in accordance with the provisions of the Information Technology (reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under Information Technology Act, 2000; that require publishing of the Privacy Policy for collection, use, storage and transfer of sensitive personal data or information.',
];

export const TermsandCondtions: string[] = [
	'PLEASE READ THE FOLLOWING TERMS OF USE (“Terms of Use”) CAREFULLY:',
	`By accessing or using this website (“${THE_RUPI}”, “We”, “Our”) or ${THE_RUPI}.com, buying any frames or other products (collectively, “Products.`,
	`This Privacy Policy is a legally binding document between you and ${THE_RUPI} (both terms defined below). The terms of this`,
	'1. You have read, understood, and agree to be bound by these terms of use',
	`2. You are of legal age and competent to enter into a binding contract with ${THE_RUPI} under the provisions of the Indian Contract Act, 1872, and`,
	'3. You have the authority to agree and to be bound personally or bind any person on whose behalf you are acting. ',
	`The term “you” refers to the individual or legal entity engaging or transacting with ${THE_RUPI}. If you do not agree to be bound by these terms of use, you may not access or use ${THE_RUPI} or its services.`,
];

export const TermsandCondtionsData: PrivacyPolicyItem[] = [
	{
		title: '1. USERS',
		content: [
			` You may simply browse ${THE_RUPI} or register yourself by creating an account. You do need an account to order Products. You must, however, only provide us with true, accurate, current and complete information for your account and/or Orders. If we believe or suspect that your information is not true, accurate, current or complete, we may deny or terminate your access to ${THE_RUPI} or any of its Services.`,
		],
	},
	{
		title: '2. ACCOUNTS.',
		content: [
			` If you set up an account, you are required to provide your name, mobile number, email address, and select a password (collectively, your “Account Information”). If someone accesses ${THE_RUPI} or its Services using your Account Information, We shall be entitled to presume that it is you or your agent who is accessing ${THE_RUPI} or its Services. You are solely responsible for any and all use of your Account Information and all Orders and activities that occur under or in connection with your account. In no event shall ${THE_RUPI} be held liable to you for any loss or damages resulting from or arising out of (i) any action or inaction of ${THE_RUPI} in connection with your Account Information, and/or (ii) any unauthorized access to your account or use of your password. `,
		],
	},
	{
		title: '3. SHIPPING OF ORDERS.',
		content: [
			` At present, ${THE_RUPI} ships only to select addresses in India. For shipping, it uses reliable third party courier services. Usually, the standard shipping time for the Products is three business days. However, any estimated shipping date provided by ${THE_RUPI} is based on the date that ${THE_RUPI} receives your Artwork and payment processing time. The estimated time does not include time incurred in transit. Please note that ${THE_RUPI} or ${THE_RUPI} shall not be responsible for any loss, damage, or delay caused to the Product in the process of shipping.`,
		],
	},
	{
		title: '4. PAYMENT.',
		content: [
			` To pay for an Order, you will need to provide information necessary to process an Order from you, including your shipping address and billing information. ${THE_RUPI} uses Paytm as a payment gateway partner. You may pay for your Order via credit card, debit card or any other payment mechanism made available by ${THE_RUPI}. By submitting your payment information to us, you authorize us to charge the applicable payment method at Our convenience at any time within thirty (30) days of such authorization. You represent that you will not use any credit card, debit card or other form of payment unless you have all necessary authorization and legal competency to do so. `,
		],
	},
	{
		title: '5. PRICING.',
		content: [
			` Prices are subject to change. Upon receipt of your Artwork or Digital Copy, if We determine that the Product that you have ordered will not be suitable, We will communicate with you further if you would like to change your Order to a different Product. In the event you change your Order, the price of your Order may change. Unless otherwise provided, all prices include shipping, handling and taxes.`,
		],
	},
	{
		title: '6. DISCLAIMER OF WARRANTIES.',
		content: [
			`The products and services included on or otherwise made available to you through ${THE_RUPI} are provided “with all faults” and on an “as is” and “as available basis”, unless otherwise specified in writing. ${THE_RUPI} makes no representations or warranties of any kind, whether express or implied, as to the merchantability, fitness for a particular use or purpose, non-infringement, quiet enjoyment, and accuracy. You expressly agree that your use of this site is at your sole risk.`,
		],
	},
	{
		title: '7. TRADEMARKS & COPYRIGHT NOTICE.',
		content: [
			`The trademarks, logos and service marks displayed on the Site are the property of ${THE_RUPI} or its licensors. Unless otherwise specified, all information and screens appearing on this Site including all materials, documents, services, site design, text, graphics, logos, images and icons, as well as the selection and arrangement thereof, are the sole property of ${THE_RUPI}. All rights not expressly granted herein are reserved. Except as otherwise required by applicable law, any reproduction, distribution, modification, retransmission, or publication of any copyrighted material is strictly prohibited without the express written consent of the copyright owner.`,
		],
	},
	{
		title: '8. INTERNATIONAL USERS.',
		content: [
			`${THE_RUPI} can be accessed from countries around the world. Those who access or use the Site or the Services from other jurisdictions do so at their own volition and are responsible for compliance with local law. `,
		],
	},
];

export const cases: casesProps[] = [
	{ tag: 'Aa', style: 'capitalize' },
	{ tag: 'AA', style: 'uppercase' },
	{ tag: 'aa', style: 'lowercase' },
];

export const navJson: NavItem[] = [
	{
		name: 'Home',
		route: ROUTES.HOME_PAGE,
	},
	{
		name: 'Limited Edition',
		route: ROUTES.LIMITED_EDITION,
		showLimited: true,
	},
	{
		name: 'Exclusives',
		route: ROUTES.EXCLUSIVE,
	},
	{
		name: 'Contact',
		route: ROUTES.CONTACT,
	},
];

export const notLoggedIn: NavItem[] = [
	...navJson,
	{
		name: 'Log In',
		route: ROUTES.LOGIN,
		icon: <LogOut />,
	},
];

export const layoutsData = [
	{
		name: 'set of two',
	},
	{
		name: 'set of three',
	},
	{
		name: 'set of four',
	},
];

export const SCREEN_SIZES = {
	XXXL: 1900,
	XXL: 1500,
	XL: 1024,
	LG: 768,
	MD: 500,
	SM: 375,
	ND: 280,
};

export const XXL = 'xxl';
export const XL = 'xl';
export const LG = 'lg';
export const MD = 'md';
export const SM = 'sm';
export const ND = 'nd';
export const XS = 'xs';

export const ExclusiveTitile = {
	title: 'EXCLUSIVE PRODUCTS PREVIEW',
	description:
		"Discover the future of gifting with our exclusive note collections, carefully curated and not available for sale yet. Explore these captivating glimpses of what's to come, and stay tuned for the day you can own a truly extraordinary piece of tomorrow's history.",
};
export const LimitedTitle = {
	title: 'Limited edition notes',
	description:
		'These notes are only available for a limited period of time, reach out to us and get these notes while you can.',
};
export const fonts = [
	{
		name: 'sans-serif',
		classes: 'font-sans',
	},
	{
		name: 'serif',
		classes: 'font-serif',
	},
	{
		name: 'monospace',
		classes: 'font-mono',
	},
];

export const socialMediaLinks = [
	{
		name: 'Instagram',
		url: `${INSTRAGRAM_URL}/gift_to_give_`,
		icon: <Instragram />,
	},
	{
		name: 'Twitter',
		url: `${FACEBOOK_URL}/gift_to_give_`,
		icon: <Twiter />,
	},
	{
		name: 'Facebook',
		url: `${TWITTER_URL}/gifttogive`,
		icon: <Facebook />,
	},
];

export const fiveNotesDataStyle: fiveNotesDataStyleTS = {
	name: {
		nameCase: 0,
		nameColor: '#000',
		nameBold: true,
		nameItalic: false,
		nameFont: 0,
	},
	text: {
		textCase: 0,
		textColor: '#000',
		textBold: false,
		textItalic: false,
		textFont: 0,
	},
	occassion: {
		occassionCase: 0,
		occassionColor: '#000',
		occassionBold: false,
		occassionItalic: false,
		occassionFont: 0,
	},
};

export const SpinnerDataTexts = {
	heading: 'SPIN THE WHEEL',
	subHeading: 'Spin the wheel to earn rewards and discount coupons.',
	winningText: {
		description:
			'This coupon is valid for the next 24 hrs only. When making your next purchase on the website, apply the following coupon code.',
	},
};
