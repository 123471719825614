import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LeftSlider from '../../../../Components/Svg/LeftSlider';
import RightSlider from '../../../../Components/Svg/RightSlider';
import useScreenSize from '../../../../utils/hooks/UseScreenSIze';
import { TRANSPERNT_GRAY } from '../../../../Constant/Color.Constant';
interface SampleNextArrowProps extends React.HTMLProps<HTMLDivElement> {
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const ExclusiveNoteSlider = () => {
	const { isExistScreen } = useScreenSize();
	const settings = {
		speed: 500,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};

	function SamplePrevArrow(props: SampleNextArrowProps) {
		const { onClick } = props;
		return (
			<div onClick={onClick} className="SamplePrevArrow-slider">
				<div
					className="rounded-lg flex md:p-[10px] p-2 justify-center items-center gap-2"
					style={{
						backgroundColor: TRANSPERNT_GRAY,
					}}
				>
					<LeftSlider
						strokeColor="white"
						width={isExistScreen.md ? '32px' : '44px'}
						height={isExistScreen.md ? '32px' : '44px'}
					/>
				</div>
			</div>
		);
	}

	function SampleNextArrow(props: SampleNextArrowProps) {
		const { onClick } = props;
		return (
			<div onClick={onClick} className="SampleNextArrow-slider">
				<div
					className="rounded-lg flex md:p-[10px] p-2 justify-center items-center gap-2"
					style={{
						backgroundColor: TRANSPERNT_GRAY,
					}}
				>
					<RightSlider
						strokeColor="white"
						width={isExistScreen.md ? '32px' : '44px'}
						height={isExistScreen.md ? '32px' : '44px'}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className="w-full z-10 overflow-hidden relative">
			<Slider {...settings} className="w-full md:h-[720px] h-[400px]">
				<>
					<div
						className="relative lg:h-[720px] md:h-[360px] sm:h-[168px] xs:h-[168px] w-full"
						style={{
							background:
								"url('/Images/HomeBackGround.png') no-repeat center center fixed",
							backgroundSize: 'cover',
						}}
					></div>
				</>
				<>
					<div
						className="relative lg:h-[720px] md:h-[720px] sm:h-80 xs:h-[400px] w-full"
						style={{
							background: `url('/Images/HomeBackGround.png') no-repeat center center fixed`,
							backgroundSize: 'cover',
						}}
					></div>
				</>
			</Slider>
		</div>
	);
};

export default ExclusiveNoteSlider;
