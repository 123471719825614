import React, { useState } from 'react';
import Frame from '../ui/Frame';
import BackGround from '../ui/BackGround';
import AddToBag from '../frame/components/AddToBag';
import SetLayout from './components/SetLayout';
import FrameTitle from '../ui/FrameTitle';
import { SetState } from '../../../../Interface/Index';
import Plus from '../../../../Components/Svg/Plus';
import {
	BackgroundImageJson,
	BgData,
	EditData,
	SelectedFrame,
	setOfFour,
	SetOfFourData,
} from '../../../../Interface/NewGiftInterface';

function SetOf4({
	id,
	setSelectedFrame,
	setSetOfFour,
	setOfFour,
	setSetOfFourData,
	setOfFourData,
	setAllErrors,
	backGroundImages,
	setBackGroundImages,
	isSubmitValid,
	backGround,
	setCount,
	count,
	bgData,
	loading,
	typeEdit,
	setSelectedFrameBackground,
	editData,
	resetAll,
}: {
	id?: string;
	setSelectedFrame: SetState<SelectedFrame | undefined>;
	setSetOfFour: SetState<setOfFour>;
	setOfFour: setOfFour;
	setSetOfFourData: SetState<SetOfFourData>;
	setOfFourData: SetOfFourData;
	setAllErrors: any;
	backGroundImages: BackgroundImageJson[];
	setBackGroundImages: SetState<BackgroundImageJson[]>;
	backGround: BackgroundImageJson[];
	setCount: SetState<number>;
	count: number;
	isSubmitValid: boolean;
	bgData: BgData;
	loading: boolean;
	typeEdit: string;
	setSelectedFrameBackground: SetState<null | string | undefined>;
	editData: EditData;
	resetAll: boolean;
}) {
	const [isSelected, setIsSelected] = useState<boolean>(false);
	const [removedSets, setRemovedSets] = useState<any>({});
	function deleteAndReplaceKey(obj: SetOfFourData, keyToDelete: string) {
		if (Object.prototype.hasOwnProperty.call(obj, keyToDelete)) {
			const keys = Object.keys(obj);

			const indexToDelete = keys.indexOf(keyToDelete);

			if (indexToDelete !== -1) {
				delete obj[keyToDelete];

				for (let i = indexToDelete + 1; i < keys.length; i++) {
					const currentKey = keys[i];
					const newKey = `set${i}`;
					obj[newKey] = obj[currentKey];
					delete obj[currentKey];
				}
			}
		}

		return obj;
	}

	const handleDeleteSetLayout = (fieldindex: number) => {
		if (count > 2) {
			setRemovedSets({
				...removedSets,
				[`set${fieldindex + 1}`]: setOfFourData[`set${fieldindex + 1}`],
			});
			if (fieldindex <= Object.keys(setOfFourData).length) {
				setSetOfFourData((setOfFourEl: SetOfFourData) =>
					deleteAndReplaceKey(setOfFourEl, `set${fieldindex + 1}`)
				);
			}
			setCount((cValue) => cValue - 1);
		}
	};

	return (
		<>
			<div className="xlg:pl-16 xlg:pr-24 lg:px-10 sm:px-8 px-4">
				<div className="w-full lg:pb-10">
					<div className="w-full mb-8">
						<FrameTitle title="customize gift" subTitle="multiple dates" />
						{Array.from({ length: count }, (_, index) => {
							return (
								<SetLayout
									key={index}
									setSetOfFour={setSetOfFour}
									index={index}
									setOfFourData={setOfFourData}
									setSetOfFourData={setSetOfFourData}
									handleDeleteSetLayout={handleDeleteSetLayout}
									id={id}
									newCount={count}
									setAllErrors={setAllErrors}
									typeEdit={typeEdit}
									editData={editData?.id?.gift?.sets[index]}
								/>
							);
						})}
					</div>
					{count < 4 && (
						<div className="w-full mb-8">
							<button
								type="button"
								className="w-full h-16 border-2 border-dashed border-gray-200 rounded-lg relative capitalize text-lg text-[#6B7280]"
								onClick={() => {
									const nextKey = `set${count + 1}`;
									count < 4 && setCount(count + 1);
									const newRemovedKeys = removedSets;
									const getFirstKey = Object.keys(newRemovedKeys)[0];
									setSetOfFourData({
										...setOfFourData,
										[nextKey]: newRemovedKeys[getFirstKey],
									});
									if (getFirstKey in newRemovedKeys) {
										delete newRemovedKeys[getFirstKey];
									}
									setRemovedSets({ ...newRemovedKeys });
								}}
							>
								<div className="flex items-center justify-center ">
									<Plus />
									<span className="ml-2">Add Date Selection</span>
								</div>
							</button>
						</div>
					)}

					<div className="mt-3 mb-8">
						<Frame
							setIsSelected={setIsSelected}
							isSelected={isSelected}
							setSelectedFrame={setSelectedFrame}
							typeEdit={typeEdit}
							editData={editData?.id}
							resetAll={resetAll}
						/>
					</div>
					<BackGround
						setSetOfFour={setSetOfFour}
						backGroundImages={backGroundImages}
						setBackGroundImages={setBackGroundImages}
						setSelectedFrameBackground={setSelectedFrameBackground}
						backGround={backGround}
						bgData={bgData}
						typeEdit={typeEdit}
						editData={editData?.id}
						resetAll={resetAll}
					/>
					<div className="lg:hidden block lg:mt-10 mt-6">
						<AddToBag
							setOfFour={setOfFour}
							isSubmitValid={isSubmitValid}
							loading={loading}
							typeEdit={typeEdit}
							id={id}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default SetOf4;
