import React, { useEffect, useState } from 'react';
import Details from './PersnoalInformataion/PersnoalInfo';
import MyOrders from './MyOrders/MyOrders';
import AddressBook from './UserAddress/UserAddressBook';
import Faqs from './FAQS/FAQs';

import {
	BORDERCOLOR,
	GRAY10,
	RED100,
	TEXTCOLOR,
	WHITE,
	WHITE_SMOKE,
} from '../../Constant/Color.Constant';
import RightIcon from '../../Components/Svg/RightIcon';
import useMobileMediaQuery from '../../utils/useMobileMediaQuery';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser, setUserToken } from '../../store/UserSlice';
import { useAppDispatch } from '../../store/store';
import { ROUTES } from '../../Constant/Routes.Constant';
import { useLocation } from 'react-router-dom';
import Copy from '../../Components/Svg/Copy';
import { SuccessToaster } from '../../helpers/CustomToaster';
import Tooltip from '../../Components/Tooltip/Tooltip';

const Profile = () => {
	const [currentComponent, setCurrentComponent] = useState('Details');
	const user = useSelector(
		(state: { user: { user: { name: string; referralCode: string } } }) =>
			state?.user?.user
	);

	const { isMobile } = useMobileMediaQuery();
	const location = useLocation();
	const activeComponent = location.state;

	useEffect(() => {
		if (activeComponent != null) {
			setCurrentComponent(activeComponent);
		}
	}, []);

	const referralCode = user?.referralCode;

	const listItems = [
		{ primaryText: 'Personal Info', component: 'Details' },
		{ primaryText: 'My Orders', component: 'MyOrders' },
		{ primaryText: 'Address Book', component: 'AddressBook' },
		{ primaryText: 'FAQs ', component: 'Faqs' },
	];

	const handleClick = (component: string) => {
		setCurrentComponent(component);
	};

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleClickToNavigate = (route: string, storeReset = false) => {
		if (storeReset) {
			dispatch(setUserToken(''));
			dispatch(
				setUser({
					id: '',
					name: '',
					role: '',
					email: '',
				})
			);

			localStorage.removeItem('ACCESSTOKEN');
			SuccessToaster('User Logout');
		}

		navigate(route);
	};

	const handleCopy = () => {
		navigator.clipboard.writeText(referralCode);
		SuccessToaster('Referral Code copied!');
	};

	const componentMap: Record<string, JSX.Element> = {
		Details: <Details />,
		MyOrders: (
			<MyOrders
				products={[]}
				setLoading={function (): void {
					throw new Error('Function not implemented.');
				}}
			/>
		),
		AddressBook: <AddressBook />,
		Faqs: <Faqs />,
	};

	return (
		<div
			className="w-full max-w-[2500px] mx-auto lg:min-h-[calc(100vh-170px)] sm:min-h-[calc(100vh-168px)] min-h-[calc(100vh-128px)] flex flex-col xs:gap-6 lg:gap-8 xs:py-6 xs:px-4 lg:p-0"
			style={{
				background: WHITE_SMOKE,
			}}
		>
			<div className="flex justify-start sm:justify-center md:justify-start items-center w-full xs:flex-col">
				<div className="max-w-[2500px] mx-auto w-full py-0 pb-6 md:pb-8 md:pt:4 md:py-8 xl:px-24 sm:px-5 md:px-5 xlg:px-24 lg:px-5 px-0  items-center md:items-center gap-4 flex justify-between flex-row">
					<div>
						<p
							className="w-full flex lg:justify-start xs:items-center md:text-[32px] sm:text-3xl text-base leading-6 font-semibold"
							style={{ color: TEXTCOLOR }}
						>
							MY ACCOUNT
						</p>
					</div>
					<div className="flex justify-center items-center gap-4">
						<p
							className="sm:text-xl text-base leading-9 text-center md:flex hidden"
							style={{ color: GRAY10 }}
						>
							Referral Code:
						</p>

						<div className=" flex items-center justify-center">
							<div
								style={{
									borderColor: BORDERCOLOR,
									backgroundColor: WHITE,
								}}
								className="rounded-l-lg lg:h-14 h-9 lg:px-6 px-3 flex justify-center items-center border"
							>
								<p className="uppercase sm:text-2xl text-base sm:px-6 px-3 ">
									{referralCode}
								</p>
							</div>
							<div
								style={{
									borderColor: BORDERCOLOR,
									backgroundColor: WHITE,
								}}
								className="border border-l-0 rounded-r-lg lg:w-16 cursor-pointer lg:h-14 h-9 lg:px-4 px-2 lg:py-3 py-2 flex items-center justify-center"
								onClick={handleCopy}
							>
								<Tooltip text="copy">
									<Copy width={isMobile ? 24 : 32} />
								</Tooltip>
							</div>
						</div>
					</div>
				</div>

				<div
					className="border-b w-full"
					style={{ borderColor: BORDERCOLOR }}
				></div>
			</div>

			<div className="w-full flex justify-center items-center">
				<div className="max-w-[2500px] mx-auto w-full xl:px-24 sm:px-5 md:px-5 xlg:px-24 lg:px-5 px-0 xs:gap-6 flex lg:flex-row flex-col justify-center lg:max-h-screen">
					<div className=" lg:w-[40%] sm:w-full rounded-none">
						<div className="w-full flex justify-center items-center p-0">
							<div className="w-full flex justify-start flex-col gap-4">
								<div className="w-full hidden lg:block">
									<p
										className="text-lg font-medium leading-8 uppercase"
										style={{ color: TEXTCOLOR }}
									>
										{user.name}
									</p>
								</div>
								<div
									className="w-full rounded-xl lg:bg-[#F3F4F6] !p-0 lg:border"
									style={{ borderColor: BORDERCOLOR }}
								>
									<div className="flex lg:flex-col flex-row lg:w-full w-full overflow-scroll gap-3 lg:gap-0">
										{listItems.map((item, index) => (
											<React.Fragment key={index}>
												<div
													className={`flex justify-between items-center lg:px-4 lg:py-0 cursor-pointer lg:border-none border rounded-xl lg:bg-[#F3F4F6]  ${
														currentComponent === item.component
															? ' lg:bg-[#F3F4F6] bg-black lg:text-black text-white'
															: 'text-[#000]'
													}`}
													style={{ borderColor: BORDERCOLOR }}
													onClick={() => handleClick(item.component)}
												>
													<div className="w-[145px] lg:justify-start flex justify-center lg:px-0 lg:py-0 px-5 py-[9px]">
														<p
															style={{
																fontWeight:
																	currentComponent === item.component
																		? 700
																		: 400,
															}}
															className="lg:py-5 py-0 xs:text-[14px] leading-[22px] !font-normal text-center"
														>
															{item.primaryText}
														</p>
													</div>
													<div className="lg:block hidden">
														<RightIcon />
													</div>
												</div>

												{index !== listItems.length - 1 && (
													<div
														className="hidden lg:block border mx-4"
														style={{
															borderColor: BORDERCOLOR,
														}}
													/>
												)}
											</React.Fragment>
										))}
									</div>
								</div>
								<div
									className="lg:block hidden"
									onClick={() => handleClickToNavigate(ROUTES.LOGIN, true)}
								>
									<p
										className="text-lg uppercase leading-8 font-medium cursor-pointer"
										style={{ color: RED100 }}
									>
										Sign out
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="w-full rounded-none">
						<div className="flex ">{componentMap[currentComponent]}</div>
					</div>
				</div>
			</div>

			<div className="border w-full lg:block hidden"></div>
		</div>
	);
};
export default Profile;
