import React from 'react';
import { BORDERCOLOR } from '../../Constant/Color.Constant';

const Cross = () => {
	return (
		<div>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="Frame">
					<path
						id="Vector"
						d="M5.70947 18.2912L12.0011 11.9996L18.2927 18.2912M18.2927 5.70801L11.9999 11.9996L5.70947 5.70801"
						stroke={BORDERCOLOR}
					/>
				</g>
			</svg>
		</div>
	);
};

export default Cross;
