import React from 'react';

const Wheel = () => {
	return (
		<svg
			width="120"
			height="120"
			viewBox="0 0 152 124"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="15.4643"
				y="1.46429"
				width="121.071"
				height="121.071"
				rx="60.5357"
				stroke="black"
				strokeWidth="1.07143"
			/>
			<path
				d="M118.426 104.426C112.855 109.998 106.241 114.417 98.961 117.433C91.6815 120.448 83.8793 122 76 122L76 62L118.426 104.426Z"
				fill="#FDBA74"
				stroke="black"
				strokeWidth="2.14286"
			/>
			<path
				d="M76 122C60.087 122 44.8258 115.679 33.5736 104.426L76 62L76 122Z"
				fill="#FCD34D"
				stroke="black"
				strokeWidth="2.14286"
			/>
			<path
				d="M33.5736 104.426C22.3214 93.1742 16 77.913 16 62L76 62L33.5736 104.426Z"
				fill="#6EE7B7"
				stroke="black"
				strokeWidth="2.14286"
			/>
			<path
				d="M16 62C16 46.087 22.3214 30.8258 33.5736 19.5736L76 62L16 62Z"
				fill="#A5B4FC"
				stroke="black"
				strokeWidth="2.14286"
			/>
			<path
				d="M33.5736 19.5736C44.8258 8.32141 60.087 2 76 2V62L33.5736 19.5736Z"
				fill="#C4B5FD"
				stroke="black"
				strokeWidth="2.14286"
			/>
			<path
				d="M76 2C83.8793 2 91.6815 3.55195 98.961 6.56723C106.241 9.58252 112.855 14.0021 118.426 19.5736L76 62V2Z"
				fill="#E5E7EB"
				stroke="black"
				strokeWidth="2.14286"
			/>
			<path
				d="M118.426 19.5736C123.998 25.1451 128.418 31.7595 131.433 39.039C134.448 46.3186 136 54.1207 136 62L76 62L118.426 19.5736Z"
				fill="#F9A8D4"
				stroke="black"
				strokeWidth="2.14286"
			/>
			<path
				d="M136 62C136 69.8793 134.448 77.6815 131.433 84.961C128.417 92.2405 123.998 98.8549 118.426 104.426L76 62H136Z"
				fill="#FCA5A5"
				stroke="black"
				strokeWidth="2.14286"
			/>
			<rect y="44" width="152" height="36" rx="4" fill="black" />
			<path
				d="M25.796 63.1364C25.796 64.2096 25.2638 65.1313 24.1994 65.9015C23.1351 66.6717 21.9196 67.0568 20.5531 67.0568C19.1865 67.0568 18.0827 66.7348 17.2417 66.0909C16.4139 65.4343 16 64.7588 16 64.0644C16 63.37 16.2037 63.0227 16.611 63.0227C16.9264 63.0227 17.2549 63.1742 17.5965 63.4773C17.9382 63.7803 18.3192 64.0833 18.7397 64.3864C19.1733 64.6894 19.6595 64.8409 20.1983 64.8409C20.737 64.8409 21.348 64.6263 22.0313 64.197C22.7146 63.7677 23.0563 63.4078 23.0563 63.1174C23.0563 62.827 22.6818 62.3409 21.9328 61.6591C21.1969 60.9773 20.4545 60.2765 19.7055 59.5568C18.9697 58.8245 18.6017 58.2626 18.6017 57.8712C18.6017 57.4672 18.674 57.0316 18.8186 56.5644C18.9762 56.0972 19.1668 55.7753 19.3902 55.5985C20.0997 54.9293 21.013 54.3548 22.1299 53.875C23.2599 53.3826 24.252 53.1364 25.1061 53.1364C25.6054 53.1364 26.0194 53.3447 26.3479 53.7614C26.6764 54.1654 26.8406 54.5884 26.8406 55.0303C26.8406 55.2071 26.6895 55.3523 26.3873 55.4659C26.0982 55.5669 25.6711 55.6742 25.1061 55.7879C24.5542 55.9015 23.9892 56.0657 23.411 56.2803C22.0707 56.7601 21.4006 57.1641 21.4006 57.4924C21.4006 57.6439 21.6305 57.9217 22.0905 58.3258C22.5504 58.7172 23.0497 59.1338 23.5884 59.5758C24.1403 60.0051 24.6462 60.5543 25.1061 61.2235C25.566 61.8801 25.796 62.5177 25.796 63.1364Z"
				fill="white"
			/>
			<path
				d="M34.4834 57.322C34.9696 57.322 35.4886 57.7386 36.0405 58.572C36.6055 59.4053 36.888 60.2386 36.888 61.072C36.888 61.577 36.6647 62.1768 36.2179 62.8712C35.7711 63.5657 35.2061 64.2222 34.5228 64.8409C33.8527 65.447 33.0708 65.9646 32.1773 66.3939C31.2969 66.8232 30.4559 67.0379 29.6544 67.0379H29.4179C28.59 70.346 28.0053 72 27.6637 72C27.3877 72 27.0986 71.779 26.7964 71.3371C26.4811 70.8952 26.3234 70.529 26.3234 70.2386C26.3234 69.9482 26.5139 69.0581 26.895 67.5682C28.025 63.149 28.59 59.8157 28.59 57.5682C28.59 57.1894 28.7806 57 29.1616 57C29.477 57 29.8778 57.1705 30.364 57.5114C30.8501 57.8523 31.1524 58.2942 31.2706 58.8371C32.3875 57.827 33.4585 57.322 34.4834 57.322ZM30.0289 64.7841C31.0538 64.5947 32.0196 64.0518 32.9263 63.1553C33.8461 62.2588 34.306 61.4823 34.306 60.8258C34.306 60.1692 34.1155 59.8409 33.7344 59.8409C33.1825 59.8409 32.5978 60.1439 31.9802 60.75C31.7305 61.0025 31.4546 61.375 31.1524 61.8674C30.8501 62.3598 30.5873 62.9343 30.364 63.5909L30.0289 64.7841Z"
				fill="white"
			/>
			<path
				d="M39.866 54.9356C39.866 54.6073 40.0171 54.2727 40.3193 53.9318C40.6347 53.5783 40.9369 53.4015 41.226 53.4015C41.5282 53.4015 41.883 53.7424 42.2903 54.4242C42.6977 55.1061 42.9013 55.6048 42.9013 55.9205C42.9013 56.2235 42.7962 56.4571 42.586 56.6212C42.3757 56.7854 42.1589 56.8674 41.9355 56.8674C41.5545 56.8674 41.1143 56.6275 40.615 56.1477C40.1156 55.6679 39.866 55.2639 39.866 54.9356ZM40.1616 57.303C40.6215 57.303 41.0289 57.5556 41.3837 58.0606C41.7516 58.5657 41.9355 59.0518 41.9355 59.5189C41.9355 59.7588 41.7056 60.6237 41.2457 62.1136C40.7989 63.5909 40.3522 64.9924 39.9054 66.3182C39.7477 66.8106 39.4258 67.0568 38.9396 67.0568C38.6505 67.0568 38.3943 66.9558 38.1709 66.7538C37.9607 66.5391 37.8555 66.2677 37.8555 65.9394C37.8555 65.5985 38.0198 64.7652 38.3483 63.4394C38.6899 62.101 38.9462 60.9646 39.117 60.0303C39.3009 59.0833 39.4652 58.3952 39.6097 57.9659C39.7674 57.524 39.9514 57.303 40.1616 57.303Z"
				fill="white"
			/>
			<path
				d="M50.3078 57.303C50.991 57.303 51.5889 57.5997 52.1014 58.1932C52.627 58.774 52.8898 59.399 52.8898 60.0682C52.8898 61.1414 52.7058 62.2652 52.3379 63.4394C51.9831 64.601 51.5889 65.4975 51.1553 66.1288C50.7348 66.7601 50.3012 67.0758 49.8544 67.0758C49.4208 67.0758 49.204 66.779 49.204 66.1856C49.204 65.7311 49.4208 64.8157 49.8544 63.4394C50.1829 62.303 50.3472 61.4886 50.3472 60.9962C50.3472 60.5038 50.2815 60.2576 50.1501 60.2576C49.9793 60.2576 49.8084 60.3396 49.6376 60.5038C49.4799 60.6679 49.3485 60.8131 49.2434 60.9394C49.1383 61.0657 49.0135 61.2298 48.8689 61.4318C48.7375 61.6212 48.6192 61.798 48.5141 61.9621C48.4221 62.1136 48.2973 62.3157 48.1396 62.5682C47.9951 62.8081 47.8768 63.0101 47.7849 63.1742C47.6929 63.3258 47.5746 63.5278 47.4301 63.7803C46.208 65.9773 45.2488 67.0758 44.5524 67.0758C44.2764 67.0758 43.9282 66.8485 43.5077 66.3939C43.0873 65.9268 42.877 65.5859 42.877 65.3712C42.877 65.2323 42.969 64.9356 43.153 64.4811C43.3501 64.0139 43.488 63.6604 43.5669 63.4205C43.6588 63.1679 43.7311 62.9028 43.7837 62.625C43.8494 62.3472 43.9019 62.0505 43.9414 61.7348C43.9808 61.4066 44.0071 61.154 44.0202 60.9773C44.0465 60.8005 44.0728 60.5227 44.099 60.1439C44.2173 58.5025 44.3159 57.5934 44.3947 57.4167C44.4867 57.2399 44.5852 57.1515 44.6904 57.1515C45.1897 57.1515 45.6693 57.3535 46.1292 57.7576C46.5891 58.149 46.8191 58.5088 46.8191 58.8371C46.8191 59.6199 46.6088 60.7437 46.1883 62.2083C47.0424 60.7689 47.8177 59.5947 48.5141 58.6856C49.2237 57.7639 49.8216 57.303 50.3078 57.303Z"
				fill="white"
			/>
			<path
				d="M58.3044 55.8826C56.8853 55.9583 56.11 55.9962 55.9786 55.9962C55.6632 55.9962 55.3479 55.8131 55.0325 55.447C54.7171 55.0682 54.5595 54.6768 54.5595 54.2727C54.5595 53.654 56.4319 53.3447 60.1769 53.3447C62.0953 53.3447 63.5079 53.5341 64.4146 53.9129C65.3212 54.2917 65.7746 54.7525 65.7746 55.2955C65.7746 55.4596 65.676 55.6174 65.4789 55.7689C65.2818 55.9078 65.0322 55.9773 64.7299 55.9773C64.4277 55.9773 63.8758 55.9583 63.0743 55.9205C62.2727 55.8826 61.5434 55.8573 60.8864 55.8447C60.939 55.9962 60.9653 56.173 60.9653 56.375C60.9653 56.577 60.8864 56.9747 60.7288 57.5682C60.5842 58.1616 60.374 58.9886 60.098 60.0492C59.8221 61.0972 59.5199 62.3725 59.1914 63.875C58.8629 65.3649 58.6329 66.2551 58.5015 66.5455C58.3832 66.8232 58.265 66.9621 58.1467 66.9621C57.8708 66.9621 57.5226 66.7222 57.1021 66.2424C56.6947 65.75 56.4911 65.3523 56.4911 65.0492C56.4911 64.7462 56.7604 63.4962 57.2992 61.2992C57.8379 59.0896 58.173 57.2841 58.3044 55.8826Z"
				fill="white"
			/>
			<path
				d="M67.1532 52.7955C67.1532 52.2652 67.37 52 67.8036 52C68.1584 52 68.5658 52.2841 69.0257 52.8523C69.4856 53.4205 69.7155 53.9066 69.7155 54.3106C69.7155 54.7146 69.6301 55.2828 69.4593 56.0152C69.2885 56.7475 69.1111 57.4167 68.9271 58.0227C68.7563 58.6288 68.4935 59.4937 68.1387 60.6174C67.7839 61.7285 67.5408 62.5051 67.4094 62.947C68.2635 61.2803 69.1636 59.923 70.1097 58.875C71.069 57.827 71.8705 57.303 72.5144 57.303C72.9217 57.303 73.3882 57.6503 73.9138 58.3447C74.4394 59.0265 74.7022 59.6452 74.7022 60.2008C74.7022 60.7437 74.5971 61.5391 74.3868 62.5871C74.1766 63.6351 73.8087 64.6452 73.2831 65.6174C72.7575 66.5896 72.2187 67.0758 71.6668 67.0758C71.2332 67.0758 71.0164 66.779 71.0164 66.1856C71.0164 65.7563 71.1478 65.0556 71.4106 64.0833C71.7917 62.6313 71.9822 61.7475 71.9822 61.4318C71.9822 61.1162 71.9296 60.9583 71.8245 60.9583C71.0624 60.9583 69.9455 62.1768 68.4738 64.6136C67.9745 65.447 67.554 66.0657 67.2123 66.4697C66.8838 66.8737 66.5685 67.0758 66.2662 67.0758C65.964 67.0758 65.5961 66.8422 65.1625 66.375C64.742 65.9078 64.5317 65.548 64.5317 65.2955C64.5317 65.0303 64.5843 64.6452 64.6894 64.1402C64.8077 63.6351 64.9785 62.9154 65.2019 61.9811C65.4384 61.0467 65.8195 59.3548 66.3451 56.9053C66.8838 54.4432 67.1532 53.0732 67.1532 52.7955Z"
				fill="white"
			/>
			<path
				d="M84.6258 60.8447C84.6258 61.4634 84.1856 62.0189 83.3053 62.5114C82.438 63.0038 81.5445 63.25 80.6247 63.25C79.7048 63.25 78.9493 63.1869 78.358 63.0606C78.2397 63.4015 78.1806 63.7424 78.1806 64.0833C78.1806 64.4242 78.2397 64.6705 78.358 64.822C78.4762 64.9609 78.7719 65.0303 79.2449 65.0303C80.2305 65.0303 81.0714 64.9419 81.7678 64.7652C82.4774 64.5884 82.8453 64.5 82.8716 64.5C83.4104 64.5 83.6797 64.6705 83.6797 65.0114C83.6797 65.5038 83.2527 65.971 82.3986 66.4129C81.5576 66.8548 80.5787 67.0758 79.4618 67.0758C78.358 67.0758 77.471 66.7412 76.8009 66.072C76.1307 65.4028 75.7957 64.6136 75.7957 63.7045C75.7957 62.7828 76.0716 61.8232 76.6235 60.8258C77.1885 59.8283 77.8915 58.9949 78.7325 58.3258C79.5734 57.6439 80.4079 57.303 81.2357 57.303C82.0766 57.303 82.8519 57.7008 83.5615 58.4962C84.271 59.279 84.6258 60.0619 84.6258 60.8447ZM82.3394 60.5417C82.3394 60.4154 82.2015 60.2323 81.9255 59.9924C81.6496 59.7525 81.3868 59.6326 81.1371 59.6326C80.9006 59.6326 80.5918 59.803 80.2107 60.1439C79.8297 60.4722 79.4683 60.9015 79.1267 61.4318C79.2449 61.4444 79.4158 61.4508 79.6391 61.4508C81.4393 61.4508 82.3394 61.1477 82.3394 60.5417Z"
				fill="white"
			/>
			<path
				d="M87.9427 67.0568C87.6799 67.0568 87.2988 66.8611 86.7995 66.4697C86.3133 66.0657 86.0702 65.6679 86.0702 65.2765C86.0702 64.3548 86.2082 62.7639 86.4841 60.5038C86.7601 58.2437 86.898 56.072 86.898 53.9886C86.898 53.4583 87.0689 53.1932 87.4105 53.1932C87.8836 53.1932 88.4486 53.4457 89.1056 53.9508C89.7757 54.4558 90.1108 54.9609 90.1108 55.4659C90.1108 55.6427 89.894 56.7222 89.4604 58.7045C89.0267 60.6742 88.7771 62.2588 88.7114 63.4583C89.3421 62.2336 90.0845 61.2109 90.9386 60.3902C91.8059 59.5694 92.5155 59.1591 93.0673 59.1591C93.4615 59.1591 93.7178 59.9987 93.836 61.678C93.8886 62.3093 93.9937 62.8902 94.1514 63.4205C94.3091 63.9381 94.5259 64.197 94.8018 64.197C95.3143 64.197 95.7676 63.673 96.1618 62.625C96.5692 61.5644 96.7729 60.4722 96.7729 59.3485C96.7729 56.9116 96.0764 55.6932 94.6836 55.6932C94.2762 55.6932 93.8952 55.4912 93.5404 55.0871C93.1987 54.6831 93.0279 54.3232 93.0279 54.0076C93.0279 53.4394 93.3893 53.1553 94.112 53.1553C95.0844 53.1553 95.9319 53.3321 96.6546 53.6856C97.3904 54.0265 97.962 54.5 98.3694 55.1061C99.1841 56.3056 99.5914 57.7828 99.5914 59.5379C99.5914 60.7247 99.3746 61.899 98.941 63.0606C98.5074 64.2096 97.9029 65.1629 97.1276 65.9205C96.3655 66.678 95.5508 67.0568 94.6836 67.0568C93.0805 67.0568 92.1672 65.75 91.9439 63.1364L91.3328 63.9697C89.8874 66.0278 88.7574 67.0568 87.9427 67.0568Z"
				fill="white"
			/>
			<path
				d="M103.359 52.7955C103.359 52.2652 103.576 52 104.01 52C104.364 52 104.772 52.2841 105.232 52.8523C105.692 53.4205 105.921 53.9066 105.921 54.3106C105.921 54.7146 105.836 55.2828 105.665 56.0152C105.494 56.7475 105.317 57.4167 105.133 58.0227C104.962 58.6288 104.699 59.4937 104.345 60.6174C103.99 61.7285 103.747 62.5051 103.615 62.947C104.469 61.2803 105.37 59.923 106.316 58.875C107.275 57.827 108.076 57.303 108.72 57.303C109.128 57.303 109.594 57.6503 110.12 58.3447C110.645 59.0265 110.908 59.6452 110.908 60.2008C110.908 60.7437 110.803 61.5391 110.593 62.5871C110.383 63.6351 110.015 64.6452 109.489 65.6174C108.963 66.5896 108.425 67.0758 107.873 67.0758C107.439 67.0758 107.222 66.779 107.222 66.1856C107.222 65.7563 107.354 65.0556 107.617 64.0833C107.998 62.6313 108.188 61.7475 108.188 61.4318C108.188 61.1162 108.136 60.9583 108.03 60.9583C107.268 60.9583 106.151 62.1768 104.68 64.6136C104.18 65.447 103.76 66.0657 103.418 66.4697C103.09 66.8737 102.774 67.0758 102.472 67.0758C102.17 67.0758 101.802 66.8422 101.368 66.375C100.948 65.9078 100.738 65.548 100.738 65.2955C100.738 65.0303 100.79 64.6452 100.895 64.1402C101.014 63.6351 101.184 62.9154 101.408 61.9811C101.644 61.0467 102.025 59.3548 102.551 56.9053C103.09 54.4432 103.359 53.0732 103.359 52.7955Z"
				fill="white"
			/>
			<path
				d="M120.832 60.8447C120.832 61.4634 120.392 62.0189 119.511 62.5114C118.644 63.0038 117.75 63.25 116.831 63.25C115.911 63.25 115.155 63.1869 114.564 63.0606C114.446 63.4015 114.387 63.7424 114.387 64.0833C114.387 64.4242 114.446 64.6705 114.564 64.822C114.682 64.9609 114.978 65.0303 115.451 65.0303C116.436 65.0303 117.277 64.9419 117.974 64.7652C118.683 64.5884 119.051 64.5 119.078 64.5C119.616 64.5 119.886 64.6705 119.886 65.0114C119.886 65.5038 119.459 65.971 118.605 66.4129C117.764 66.8548 116.785 67.0758 115.668 67.0758C114.564 67.0758 113.677 66.7412 113.007 66.072C112.337 65.4028 112.002 64.6136 112.002 63.7045C112.002 62.7828 112.278 61.8232 112.829 60.8258C113.394 59.8283 114.097 58.9949 114.938 58.3258C115.779 57.6439 116.614 57.303 117.442 57.303C118.283 57.303 119.058 57.7008 119.767 58.4962C120.477 59.279 120.832 60.0619 120.832 60.8447ZM118.545 60.5417C118.545 60.4154 118.407 60.2323 118.131 59.9924C117.856 59.7525 117.593 59.6326 117.343 59.6326C117.107 59.6326 116.798 59.803 116.417 60.1439C116.036 60.4722 115.674 60.9015 115.333 61.4318C115.451 61.4444 115.622 61.4508 115.845 61.4508C117.645 61.4508 118.545 61.1477 118.545 60.5417Z"
				fill="white"
			/>
			<path
				d="M130.456 60.8447C130.456 61.4634 130.016 62.0189 129.135 62.5114C128.268 63.0038 127.375 63.25 126.455 63.25C125.535 63.25 124.779 63.1869 124.188 63.0606C124.07 63.4015 124.011 63.7424 124.011 64.0833C124.011 64.4242 124.07 64.6705 124.188 64.822C124.306 64.9609 124.602 65.0303 125.075 65.0303C126.061 65.0303 126.901 64.9419 127.598 64.7652C128.307 64.5884 128.675 64.5 128.702 64.5C129.24 64.5 129.51 64.6705 129.51 65.0114C129.51 65.5038 129.083 65.971 128.229 66.4129C127.388 66.8548 126.409 67.0758 125.292 67.0758C124.188 67.0758 123.301 66.7412 122.631 66.072C121.961 65.4028 121.626 64.6136 121.626 63.7045C121.626 62.7828 121.902 61.8232 122.454 60.8258C123.019 59.8283 123.722 58.9949 124.563 58.3258C125.404 57.6439 126.238 57.303 127.066 57.303C127.907 57.303 128.682 57.7008 129.392 58.4962C130.101 59.279 130.456 60.0619 130.456 60.8447ZM128.17 60.5417C128.17 60.4154 128.032 60.2323 127.756 59.9924C127.48 59.7525 127.217 59.6326 126.967 59.6326C126.731 59.6326 126.422 59.803 126.041 60.1439C125.66 60.4722 125.298 60.9015 124.957 61.4318C125.075 61.4444 125.246 61.4508 125.469 61.4508C127.269 61.4508 128.17 61.1477 128.17 60.5417Z"
				fill="white"
			/>
			<path
				d="M134.029 52C134.344 52 134.752 52.2778 135.251 52.8333C135.75 53.3889 136 53.9192 136 54.4242C136 54.9293 135.921 55.8321 135.763 57.1326C135.606 58.4205 135.442 59.4495 135.271 60.2197C134.18 64.7904 133.418 67.0758 132.984 67.0758C132.695 67.0758 132.334 66.8548 131.9 66.4129C131.48 65.9583 131.27 65.5859 131.27 65.2955C131.27 65.2702 131.447 64.6136 131.802 63.3258C132.157 62.0379 132.511 60.3838 132.866 58.3636C133.234 56.3308 133.418 54.3927 133.418 52.5492C133.418 52.1831 133.622 52 134.029 52Z"
				fill="white"
			/>
		</svg>
	);
};

export default Wheel;
