import React from 'react';
import CommonButton from '../../Components/Button/CommonButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Constant/Routes.Constant';
import Image from '../../Components/Image/Image';

const NotFound = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(ROUTES.HOME_PAGE);
	};
	return (
		<div className="flex justify-center items-center !flex-col h-screen bg-white w-full max-w-[2500px] mx-auto">
			<div className="w-full flex justify-center items-center ">
				<div className="h-auto">
					<Image image="/Images/404Page.gif" altName="Not Found Image" />
				</div>
			</div>
			<div className="mt-5">
				<CommonButton
					onClick={handleClick}
					height="30%"
					width="100%"
					padding="30px 12px"
					label="BACK TO HOME"
				></CommonButton>
			</div>
		</div>
	);
};

export default NotFound;
