import React from 'react';
import { GRAY50 } from '../../../../Constant/Color.Constant';

const FrameTitle = ({
	title,
	subTitle,
}: {
	title: string;
	subTitle: string;
}) => {
	return (
		<>
			<h2 className="pb-4 relative">
				<div>
					<div className="pb-0.5 flex items-center justify-between">
						<div className="sm:text-2xl uppercase font-bold flex flex-col nd:flex-row nd:items-end xlg:flex-row text-base">
							<span className="uppercase">{title}&nbsp;</span>
							<span
								className="uppercase text-base sm:text-lg xlg:text-2xl"
								style={{
									color: GRAY50,
								}}
							>
								({subTitle})
							</span>
						</div>
					</div>
				</div>
			</h2>
			<hr className="lg:mb-8 mb-6" />
		</>
	);
};

export default FrameTitle;
