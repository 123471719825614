import React from 'react';
import { Faq } from '../../../store/FaqSlice';
import CommonHeader from '../../../Components/Header/CommonHeader';
import SEO from '../../../Components/Helmet/SEO';
import Menu from '../../../Components/Menu';
import { FAQ_PAGE } from '../../../Constant/Seo.Constant';
import { useAppSelector } from '../../../store/store';
import {
	GRAY100,
	TEXTCOLORGRAY,
	WHITE10,
	WHITE_SMOKE,
} from '../../../Constant/Color.Constant';

export default function ControlledAccordions() {
	const faq = useAppSelector((state) => state.faq.faqList);

	return (
		<>
			<SEO title="FAQ's" description={FAQ_PAGE} />
			<div className="flex lg:justify-center w-full">
				<div className="lg:w-[80%] w-full flex flex-col gap-0 md:gap-8">
					<div className="flex flex-col gap-4">
						<div className="hidden lg:block">
							<CommonHeader title="FAQs" />
						</div>
						<div
							className="hidden lg:block w-full"
							style={{ borderBottom: `1px solid ${WHITE10}` }}
						></div>
					</div>
					<div>
						<div>
							<div className="w-full flex flex-col justify-start">
								{faq?.map((faq: Faq, index: number) => (
									<div key={index}>
										<Menu
											style={{
												borderBottom: `1px solid ${WHITE10}`,
												backgroundColor: WHITE_SMOKE,
											}}
										>
											<Menu.MenuCollapse
												eventKey={`item-${index}`}
												label={faq.question}
												className={`flex justify-between lg-w-[608px] w-full leading-8 items-center text-base font-medium cursor-pointer gap-2 mb-4 mt-${
													index !== 0 ? '4' : '0'
												}`}
												MenuStyle={{ color: GRAY100 }}
											>
												<Menu.MenuItem className="!h-fit mb-4">
													<p
														className={`text-sm mt-2 mb-4`}
														style={{ color: TEXTCOLORGRAY }}
													>
														{faq.answer}
													</p>
												</Menu.MenuItem>
											</Menu.MenuCollapse>
										</Menu>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
