import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Constant/Routes.Constant';
import CommonButton from '../Button/CommonButton';
import classNames from 'classnames';
import '../../index.css';
import Image from '../Image/Image';
import Modal from '../Model/Modal';
import OrderModel from '../../Pages/SetOfAllNotes/OrderModel/OrderModel';
import {
	BLACK,
	GRAY900,
	TEXTCOLORGRAY,
	WHITE,
	WHITE_SMOKE,
} from '../../Constant/Color.Constant';
import { CLASSES, SET_OF_FOUR } from '../../Constant/Constant';
import useMobileMediaQuery from '../../utils/useMobileMediaQuery';

export interface CommonCardProps {
	image?: string | string[];
	title: string;
	description?: string;
	to?: string;
	id?: string;
	buttonLabel?: string;
	collectionID?: string;
	isSold?: boolean;
}

const CommonCard = ({
	image,
	title,
	description,
	to,
	id,
	buttonLabel,
	collectionID,
	isSold = false,
}: CommonCardProps) => {
	const navigate = useNavigate();
	const [isVisible] = useState(false);
	const [isCommonCardModalOpen, setIsCommonCardModalOpen] = useState(false);
	const screen = useMobileMediaQuery();
	const handleClick = () => {
		if (id !== 'setOfAllNotes' && id !== 'album') {
			navigate(
				to
					? to
					: id === CLASSES || id === SET_OF_FOUR
					  ? `${ROUTES.ORDER_MODAL}/${collectionID}`
					  : `${ROUTES.LIMITED_DETAILES}/${collectionID}`,
				{ state: collectionID }
			);
		} else {
			setIsCommonCardModalOpen(true);
		}
	};

	const closeModal = () => {
		setIsCommonCardModalOpen(false);
	};

	return (
		<div className="sm:max-w-[288px] w-full h-full relative">
			<div
				onClick={handleClick}
				className={classNames(
					'w-full flex flex-col justify-start items-start cursor-pointer h-full relative',
					id,
					{ 'visible-card': isVisible }
				)}
				style={{ position: 'relative' }}
			>
				{isSold && (
					<div className="absolute -top-0.5 z-10 -left-0.5 w-28 h-28 overflow-hidden">
						<div className="relative w-full h-full">
							<div
								className="-rotate-45 absolute top-[62%] left-[62%] flex items-center justify-center -translate-x-[calc(50%+24px)] w-[300%] -translate-y-[calc(50%+24px)] h-8 text-center"
								style={{
									background: BLACK,
									color: WHITE,
								}}
							>
								<span className="mr-1 uppercase font-bold">Sold Out</span>
							</div>
						</div>
					</div>
				)}
				<div className="w-full flex">
					<Image
						image={image}
						className="xs:w-[240px] nd:w-[164px] nd:h-24 md:h-[200px] md:w-72 sm:w-72 sm:h-[200px] lg:h-[200px] lg:w-72 !rounded-t-[10px] h-full w-full relative"
					/>
				</div>
				<div
					className="flex flex-col sm:border-2 border !border-t-0 lg:p-4 xs:p-3 xs:rounded-b-lg xs:max-w-[240px] nd:max-w-[164px] w-full  sm:max-w-full   h-full sm:gap-5 gap-3"
					style={{ backgroundColor: WHITE_SMOKE }}
				>
					<div className="flex flex-col sm:gap-2 gap-0">
						<p
							className="sm:text-xl text-sm leading-6 font-bold uppercase"
							style={{ color: GRAY900 }}
						>
							{title}
						</p>
						{description && (
							<p
								className="sm:text-base text-xs  font-normal w-full sm:leading-6 leading-5 line-clamp-4"
								style={{ color: TEXTCOLORGRAY }}
							>
								{description}
							</p>
						)}
					</div>
					{buttonLabel && (
						<div className="w-full flex justify-center items-center">
							<CommonButton
								id={id}
								width="256px"
								padding={screen.isMobile ? '8px 32px' : '12px 32px'}
								className="sm:text-base !text-sm font-semibold !leading-6 text-white !px-8 !sm:py-4 !xs:py-3 rounded-lg"
								label={buttonLabel || 'GIFT NOW'}
							/>
						</div>
					)}
				</div>
				{isCommonCardModalOpen && (
					<Modal isOpen={isCommonCardModalOpen} onClose={closeModal}>
						<OrderModel
							setIsModalOpen={setIsCommonCardModalOpen}
							isModalOpen={setIsCommonCardModalOpen}
							id={id}
						/>
					</Modal>
				)}
			</div>
		</div>
	);
};

export default CommonCard;
