import React from "react";
import { CommonSvgProps } from "../../Interface/Index";


const BackAerrow: React.FC<CommonSvgProps> = ({ onClick, className }) => {
  return (
    <div onClick={onClick} className={className}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="8" fill="#F3F4F6" />
        <path
          d="M28.2998 34L18.2998 24L28.2998 14L29.6998 15.425L21.1248 24L29.6998 32.575L28.2998 34Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default BackAerrow;
