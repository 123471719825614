import React, { FC } from 'react';
import { CommonProductTitleProps } from '../../Interface/Index';
import { TEXTCOLOR, TEXTCOLORGRAY } from '../../Constant/Color.Constant';

const CommonProductTitle: FC<CommonProductTitleProps> = ({
	homeText,
	categoryText,
}) => {
	return (
		<div>
			<p className="flex gap-2">
				<span
					className={`text-center sm:text-2xl text-sm font-semibold uppercase`}
					style={{ color: TEXTCOLORGRAY }}
				>
					{homeText}
				</span>
				<span
					className={`text-center sm:text-2xl text-sm font-semibold uppercase`}
					style={{ color: TEXTCOLOR }}
				>
					{categoryText}
				</span>
			</p>
		</div>
	);
};

export default CommonProductTitle;
