import React from 'react';
import { CommonSvgProps } from '../../Interface/Index';

const RightArrow: React.FC<CommonSvgProps> = ({ className }) => {
	return (
		<div className={className}>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1 10H19M19 10L10.5 1.5M19 10L10.5 18.5"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};

export default RightArrow;
