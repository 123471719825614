import React from 'react';

const SpinnerSelector = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 34 22"
			fill="none"
		>
			<path
				d="M11.4712 0.995272L11.4712 0.995272C12.8582 0.499862 14.3619 0.49991 17.1016 0.499998L17.2122 0.5H22.5L22.5781 0.499999C24.3629 0.499965 25.3369 0.499947 26.1583 0.663325C29.5301 1.33403 32.166 3.96986 32.8367 7.34173C33.0001 8.16309 33 9.13707 33 10.9219L33 11L33 11.0781C33 12.8629 33.0001 13.8369 32.8367 14.6583C32.166 18.0301 29.5301 20.666 26.1583 21.3367C25.3369 21.5001 24.3629 21.5 22.5781 21.5H22.5H17.2122L17.1016 21.5C14.3619 21.5001 12.8582 21.5001 11.4712 21.0047C11.2922 20.9408 11.1155 20.8709 10.9413 20.7952C9.59056 20.208 8.4936 19.1795 6.4949 17.3056L6.41426 17.23L3.27072 14.2829L3.24306 14.257C2.53706 13.5951 1.96925 13.0628 1.58283 12.5858C1.18226 12.0913 0.924227 11.5946 0.924227 11C0.924227 10.4054 1.18226 9.90867 1.58283 9.41419C1.96925 8.93717 2.53708 8.40486 3.24308 7.74299L3.27072 7.71709L6.41426 4.77001L6.49489 4.69442C8.4936 2.82051 9.59056 1.79204 10.9413 1.20481C11.1155 1.12906 11.2922 1.05918 11.4712 0.995272Z"
				fill="#F59E0B"
				stroke="black"
			/>
		</svg>
	);
};

export default SpinnerSelector;
