import React from 'react';
import { BLACK, WHITE } from '../../Constant/Color.Constant';
import { DeleteModalProps } from '../../Interface/Index';

const DeleteModal: React.FC<DeleteModalProps> = ({
	title,
	onDelete,
	onCancel,
	onClose,
	message = 'Are you sure you want to delete this?',
}) => {
	return (
		<div className="flex justify-center items-center">
			<div className="fixed inset-0 z-[10000000] bg-gray-500 bg-opacity-80 transition-opacity" />
			<div className="fixed inset-0 z-[10000000] justify-center items-center flex flex-col">
				<div className="flex justify-center items-center min-h-full">
					<div>
						<div
							className="rounded-lg px-4 pb-4 pt-5 sm:p-6 sm:pb-4 xs:mx-4"
							style={{
								background: WHITE,
							}}
						>
							<div className="sm:flex sm:items-start flex-col xs:p-0 lg:w-[400px]">
								<div className="flex justify-between w-full items-center pb-5">
									<h2 className="text-xl font-semibold">{title}</h2>
								</div>
								<p className="text-lg">{message}</p>
								<div className="flex justify-end pt-5 w-full gap-4">
									<button
										onClick={() => {
											onCancel();
											onClose();
										}}
										className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md"
									>
										Cancel
									</button>
									<button
										onClick={() => {
											onDelete();
											onClose();
										}}
										className="px-4 py-2 rounded-md"
										style={{
											color: WHITE,
											background: BLACK,
										}}
									>
										Delete
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeleteModal;
