import React, { useEffect, useState } from 'react';
import CommonHeader from '../../../Components/Header/CommonHeader';
import {
	Gift,
	ProductOverviewProps,
	SetOfFourProductEl,
} from '../../../Interface/Index';
import moment from 'moment';
import CommonLoader from '../../../Components/Loader/Loader';
import { deleteAddToCart, getAddToCart } from '../../../Api/UserListApi';
import DeleteModal from '../../../Components/DeleteModel/DeleteModel';
import Delete from '../../../Components/Svg/Delete';
import { BLACK, BORDERCOLOR, GRAY100 } from '../../../Constant/Color.Constant';
import { PiShoppingCart } from 'react-icons/pi';
import { ROUTES } from '../../../Constant/Routes.Constant';
import { useNavigate } from 'react-router-dom';
import { ErrorToaster, SuccessToaster } from '../../../helpers/CustomToaster';
import ItemsBatch from '../../../Components/Ui/ItemsBatch';
import Image from '../../../Components/Image/Image';
import Tooltip from '../../../Components/Tooltip/Tooltip';

const ProductOverview: React.FC<ProductOverviewProps> = ({
	products,
	setLoading,
}) => {
	const [loader, setLoader] = useState<boolean>(false);
	const [data, setData] = useState<Gift[]>([]);
	const nevigate = useNavigate();

	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedProductId, setSelectedProductId] = useState<string | null>(
		null
	);

	const handleOpenDeleteModal = (id: string) => {
		setSelectedProductId(id);
		setShowDeleteModal(true);
	};

	const handleCloseDeleteModal = () => {
		setSelectedProductId(null);
		setShowDeleteModal(false);
	};
	const handleBackToShopping = () => {
		nevigate(ROUTES.NEW_GIFT_LAYOUTS);
	};
	const handleDelete = async () => {
		if (selectedProductId) {
			setLoader(true);
			try {
				const { error, message } = await deleteAddToCart({
					id: selectedProductId,
				});
				error ? ErrorToaster(message) : SuccessToaster(message);
			} catch (error) {
				console.error('Error fetching Products:', error);
			}
			setLoading(true);
			setLoader(false);
			handleCloseDeleteModal();
		}
	};
	const fetchFrameDetail = async () => {
		try {
			const { data } = await getAddToCart();
			setData(data?.data?.rows);
		} catch (error) {
			console.error('Error fetching add to cart:', error);
		}
	};
	useEffect(() => {
		fetchFrameDetail();
	}, [products]);

	return (
		<div className="flex-col justify-center items-center w-full">
			<div>
				<div className="xs:w-full pb-4 flex justify-start gap-2 items-center">
					<CommonHeader
						title="PRODUCT OVERVIEW"
						className="leading-6 lg:leading-8"
					/>
					<ItemsBatch data={data} />
				</div>
				<div
					className="border w-full lg:block hidden"
					style={{ borderColor: BORDERCOLOR }}
				></div>
				{loader && (
					<div className="flex justify-center">
						<CommonLoader visible={true} />
					</div>
				)}
				{products?.length === 0 ? (
					<div className="flex justify-center items-center xs:p-5 lg:p-20 sm:p-5 md:p-20 flex-col gap-4">
						<p className="text-3xl mt-4 font-semibold">Your Product is Empty</p>
						<p
							className="underline cursor-pointer underline-offset-2"
							onClick={handleBackToShopping}
						>
							BACK TO SHOPPING
						</p>
						<PiShoppingCart style={{ color: BLACK, fontSize: '50px' }} />
					</div>
				) : (
					<div className="overflow-auto lg:max-h-[500px] max-h-[300px]">
						{data?.map((product, index) => (
							<React.Fragment key={index}>
								<div>
									<div className="flex flex-col items-start">
										<div className="grid grid-cols-[1fr,8fr,1fr] my-5 ml-0 w-full">
											<div className="flex sm:gap-4 gap-3 col-span-2">
												<div
													className="rounded-lg overflow-hidden sm:w-[112px] sm:h-[112px] h-24 w-24"
													style={{
														background:
															'linear-gradient(to right bottom, rgb(234, 235, 236), rgb(164, 165, 167))',
													}}
												>
													<Image
														image={
															!product?.product
																? product.gift?.dataUrl
																: product?.product?.images[0]
														}
														className="rounded-lg w-full h-full object-contain"
														altName="Product"
													/>
												</div>
												<div className="flex justify-center items-start flex-col font-medium">
													<p className="sm:text-lg text-sm capitalize text-[#374151]">
														{!product?.product
															? product.gift?.type === 'sets'
																? product.gift?.type
																: product.gift?.occassion
															: product?.product?.name}
													</p>
													<div className="flex sm:gap-1 lg:flex-row items-center">
														<p className="sm:text-sm text-xs text-[#374151]">
															Currency : &nbsp;
															<span className="font-normal">
																{!product?.product
																	? product.gift?.denomination
																			?.map((str) => +str)
																			.join(' , ') ||
																	  product.gift?.sets
																			?.map(
																				(item: SetOfFourProductEl) =>
																					item.denomination
																			)
																			.join(' , ')
																	: product?.product?.denomination}
															</span>
														</p>
														{!product?.product && <div className="px-2">|</div>}
														<p className="sm:text-sm text-xs">
															{product.gift?.type !== 'sets' &&
																!product?.product && (
																	<p
																		className="text-sm"
																		style={{ color: GRAY100 }}
																	>
																		Date :{' '}
																		{moment(product.gift?.date).format(
																			'DD-MM-YYYY'
																		)}
																	</p>
																)}
														</p>
													</div>
													<div className="sm:mt-5">
														<p className="sm:text-sm text-xs font-semibold">
															₹
															{!product?.product
																? product.gift?.price
																: product?.product?.price}
															/-
														</p>
													</div>
												</div>
											</div>
											<div className="flex justify-end items-start mt-5">
												<Tooltip text="Delete">
													<Delete
														onClick={() => handleOpenDeleteModal(product.id)}
													/>
												</Tooltip>
											</div>
										</div>
									</div>
								</div>
								<div
									className="border w-full"
									style={{ borderColor: BORDERCOLOR }}
								></div>
							</React.Fragment>
						))}
					</div>
				)}
			</div>

			{showDeleteModal && (
				<DeleteModal
					title="Delete Product"
					message="Are you sure you want to delete this Product?"
					onDelete={handleDelete}
					onCancel={handleCloseDeleteModal}
					onClose={handleCloseDeleteModal}
				/>
			)}
		</div>
	);
};

export default ProductOverview;
