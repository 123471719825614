import React, { useEffect, useState } from 'react';
import { getConfigData } from '../../../Api/UserListApi';
import CommonLoader from '../../../Components/Loader/Loader';
import { ConfigData } from '../../../Interface/Index';
import CommonButton from '../../../Components/Button/CommonButton';
import { GRAY900, TEXTCOLORGRAY } from '../../../Constant/Color.Constant';
import Image from '../../../Components/Image/Image';

const OfferModal = ({ onRemove }: { onRemove: () => void }) => {
	const [data, setData] = useState<ConfigData>();
	const [loader, setLoader] = useState<boolean>();

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoader(true);
				const response = await getConfigData();
				const data = await response?.data;
				setData(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
			setLoader(false);
		};

		fetchData();
	}, []);

	return (
		<>
			{loader ? (
				<div className="h-full w-full  flex justify-center items-center">
					<CommonLoader height={50} />
				</div>
			) : (
				<>
					{data && (
						<div className="flex  md:w-[720px] sm:w-[500px] nd:w-[343px] xs:w-[250px] h-full sm:px-12 sm:py-8 p-3 flex-col">
							<div className="flex flex-col sm:gap-6 gap-5 w-full justify-center items-center">
								<div className="flex flex-col sm:gap-2 gap-1 justify-center items-center">
									<div>
										<p
											className="uppercase sm:text-2xl text-base text-center sm:font-bold font-semibold sm:max-w-full max-w-[280px]"
											style={{ color: GRAY900 }}
										>
											{data.offerTitle}
										</p>
									</div>
									<div>
										<p
											className="sm:text-base text-sm  font-normal text-center capitalize"
											style={{ color: TEXTCOLORGRAY }}
										>
											{data.offerDiscription}
										</p>
									</div>
								</div>
								<div className="md:h-64 sm:h-[220px] nd:h-[168px] xs:h-[140px]  md:w-[624px] sm:w-[400px] nd:w-[319px] xs:w-[200px] flex justify-center">
									<div className="overflow-hidden rounded-lg w-full h-full object-cover">
										<Image image={data.offerImage} altName="offer image" />
									</div>
								</div>
								<CommonButton
									type="submit"
									label="GOT IT"
									padding="12px 32px"
									className="sm:w-[117px] nd:w-[319px] xs:w-[200px] text-white sm:rounded-lg rounded-xl text-base "
									fontSize="16px"
									onClick={onRemove}
								/>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default OfferModal;
