import React from 'react';

const Twiter = () => {
	return (
		<div>
			<svg
				width="24"
				height="24"
				viewBox="0 0 21 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M6.79 17.251C14.337 17.251 18.465 10.998 18.465 5.57596C18.465 5.39796 18.465 5.22096 18.453 5.04596C19.2562 4.46448 19.9493 3.74457 20.5 2.91996C19.7511 3.25196 18.9566 3.46972 18.143 3.56596C18.9996 3.05302 19.6408 2.24631 19.947 1.29596C19.1417 1.77384 18.2607 2.11069 17.342 2.29196C16.7234 1.63372 15.905 1.19779 15.0136 1.05166C14.1222 0.905532 13.2075 1.05736 12.4111 1.48363C11.6147 1.9099 10.9811 2.58683 10.6083 3.40962C10.2355 4.2324 10.1444 5.15514 10.349 6.03496C8.71759 5.9532 7.1216 5.52928 5.66465 4.79073C4.20769 4.05217 2.92233 3.01548 1.892 1.74796C1.36732 2.65116 1.20659 3.72038 1.44254 4.73792C1.67848 5.75547 2.29337 6.64483 3.162 7.22496C2.50926 7.20602 1.87065 7.03042 1.3 6.71296V6.76496C1.30039 7.71225 1.6284 8.63026 2.2284 9.36331C2.8284 10.0964 3.66347 10.5993 4.592 10.787C3.98781 10.9517 3.35389 10.9756 2.739 10.857C3.00116 11.6724 3.51168 12.3856 4.19913 12.8966C4.88658 13.4076 5.71657 13.6909 6.573 13.707C5.72212 14.3755 4.74779 14.8698 3.70573 15.1615C2.66367 15.4532 1.57432 15.5366 0.5 15.407C2.3766 16.6113 4.56019 17.2501 6.79 17.247"
					fill="#9CA3AF"
				/>
			</svg>
		</div>
	);
};

export default Twiter;
