import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../Constant/Routes.Constant';
import { Gift } from '../../../Interface/Index';
import ProductOverview from '../ProductOverView/ProductOverview';
import ShippingAdress from '../ShippingAdress/ShippingAdress';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import CommonButton from '../../../Components/Button/CommonButton';
import SEO from '../../../Components/Helmet/SEO';
import Traker from '../../../Components/Traker/Traker';
import BackAerrow from '../../../Components/Svg/BackAerrow';
import moment from 'moment';
import { useAppDispatch } from '../../../store/store';
import { getAllAddToCart } from '../../../store/CartSlice';
import { useSelector } from 'react-redux';
import LeftSlider from '../../../Components/Svg/LeftSlider';
import useMobileMediaQuery from '../../../utils/useMobileMediaQuery';
import CommonLabel from '../../../Components/InputLable/lable';
import {
	BGGRAY,
	BLACK,
	BORDERCOLOR,
	GRAY50,
	RED100,
	TEXTCOLOR,
	WHITE,
} from '../../../Constant/Color.Constant';
import Remove from '../../../Components/Svg/Remove';
import { referralCode } from '../../../Api/UserListApi';
import ErrorMessage from '../../../Components/Typography/ErrorText';
import OfferCard from './OfferCard';
import { PiShoppingCart } from 'react-icons/pi';
import { ErrorToaster } from '../../../helpers/CustomToaster';
import { CHECKOUT } from '../../../Constant/Seo.Constant';

const Checkout = () => {
	const [pagination, setPagination] = useState<number>(0);
	const [loading, setLoading] = useState(false);
	const [selectedAddressId, setSelectedAddressId] = useState<string>('');
	const [couponCode, setCouponCode] = useState<string>('');
	const [couponError, setCouponError] = useState<string>('');
	const [discountAmount, setDiscountAmount] = useState<number>(0);
	const [grandTotal, setGrandTotal] = useState<number>(0);
	const [applyCoupon, setApplyCoupon] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(false);
	const [isCouponApplied, setIsCouponApplied] = useState(false);
	const [selectedOffer, setSelectedOffer] = useState<string>('');

	const nevigate = useNavigate();

	const screen = useMobileMediaQuery();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const products = useSelector(
		(state: { cart: { cartList: any } }) => state.cart?.cartList
	);

	const handelClick = () => {
		if (pagination < 2) {
			if (!(products?.length === 0)) {
				setPagination(pagination + 1);
			}
		}

		if (pagination >= 2) {
			setPaymentMethod(true);
		}
	};
	const handleBackToShopping = () => {
		nevigate(ROUTES.NEW_GIFT_LAYOUTS);
	};
	useEffect(() => {
		dispatch(getAllAddToCart());
		return () => {
			setLoading(false);
		};
	}, [loading]);
	useEffect(() => {
		if (!applyCoupon) {
			const TotalAmount = products
				?.map((items: Gift) =>
					!items?.product ? items.gift?.price : items?.product?.price
				)
				.reduce((a: number, b: number) => a + b, 0);
			setGrandTotal(TotalAmount);
		}
	}, [applyCoupon, products]);

	const applyCouponCode = async () => {
		setApplyCoupon(true);
		setLoading(true);
		const { error, message, data } = await referralCode({
			userReferralCode: couponCode,
			price: grandTotal,
		});
		if (error) {
			setLoading(false);
			ErrorToaster(message);
			setCouponError(message);
		} else {
			setDiscountAmount(data?.discountAmount);
			setGrandTotal(data?.discountedPrice);
			setApplyCoupon(true);
			setIsCouponApplied(true);
		}
	};
	const setCouponCodeFromOffer = (code: string) => {
		setCouponCode(code);
	};
	return (
		<>
			<SEO title="Checkout" description={CHECKOUT} />
			<div
				className="w-full max-w-[2500px] mx-auto"
				style={{
					background: WHITE,
				}}
			>
				<div className="flex justify-center items-center border-t border-gray-300 w-full flex-row xlg:px-24 sm:px-5 px-4">
					<div className="flex justify-start items-center w-full border-b border-b-gray-200">
						<div className="text-gray-900 leading-6 font-bold md:text-3xl text-base md:py-8 pt-6 pb-4 uppercase flex items-center justify-center md:gap-5 gap-3">
							{screen.isMobile ? (
								<LeftSlider
									width="20px"
									height="20px"
									onClick={() => {
										if (pagination > 0) {
											setPagination(pagination - 1);
										} else {
											navigate(ROUTES.HOME_PAGE);
										}
									}}
								/>
							) : (
								<BackAerrow
									onClick={() => {
										setPaymentMethod(false);
										if (pagination > 0) {
											setPagination(pagination - 1);
										} else {
											navigate(-1);
										}
									}}
									className="cursor-pointer"
								/>
							)}
							<p className="md:text-[32px] text-base leading-6 md:leading-12">
								checkout
							</p>
						</div>
					</div>
				</div>

				{products?.length === 0 && (
					<div className="flex justify-center items-center xs:p-5 lg:p-20 sm:p-5 md:p-20 flex-col gap-4">
						<p className="text-3xl mt-4 font-semibold">
							Your Order Summary is Empty
						</p>
						<p
							className="underline cursor-pointer underline-offset-2"
							onClick={handleBackToShopping}
						>
							BACK TO SHOPPING
						</p>
						<PiShoppingCart style={{ color: BLACK, fontSize: '50px' }} />
					</div>
				)}

				{products?.length && (
					<div className="w-full flex justify-center items-center">
						<div className="w-full lg:w-[90%] sm:px-0 px-4">
							<div className="flex justify-between lg:h-[688px] overflow-x-scroll">
								<div className="flex lg:flex-row sm:flex-col xs:flex-col justify-between w-full sm:gap-20 gap-6 ">
									<div className="xl:w-1/2 w-full flex flex-col justify-center items-center">
										<div className="sm:py-8 py-6 w-full">
											<Traker
												activeStep={pagination}
												setPagination={setPagination}
											/>
										</div>
										<div
											className={`flex flex-col ${
												pagination === 0
													? 'h-full'
													: 'lg:h-[600px] h-full sx:h-full xs:w-full'
											}   w-full`}
										>
											{pagination === 0 && (
												<ProductOverview
													products={products}
													setLoading={setLoading}
												/>
											)}
											{pagination === 1 && (
												<ShippingAdress
													selectedAddressId={selectedAddressId}
													setSelectedAddressId={setSelectedAddressId}
												/>
											)}
											{pagination === 2 && (
												<PaymentMethod
													setPaymentMethod={setPaymentMethod}
													grandTotal={grandTotal}
													couponCode={couponCode}
													applyCoupon={applyCoupon}
												/>
											)}
											{paymentMethod && (
												<span className="text-red-500 p-[6px]">
													Please select Payment Method
												</span>
											)}
										</div>
									</div>
									<div className="xl:w-1/2 md:w-full">
										<div className="flex flex-col md:gap-8 gap-6 md:py-8 py-6">
											<div>
												<OfferCard
													onSelectOffer={setCouponCodeFromOffer}
													isCouponApplied={isCouponApplied}
													setSelectedOffer={setSelectedOffer}
													selectedOffer={selectedOffer}
												/>
											</div>
											{applyCoupon ? (
												<div className="flex flex-col gap-2">
													<div
														className="rounded-lg p-4 flex justify-between items-center"
														style={{ background: BGGRAY }}
													>
														<p className="text-[#2563EB] text-lg font-bold">
															{couponCode}
														</p>
														<Remove
															width="28"
															strokeColor={RED100}
															className="cursor-pointer"
															onClick={() => {
																setApplyCoupon(false);
																setCouponCode('');
																setDiscountAmount(0);
																setCouponError('');
																setIsCouponApplied(false);
																setSelectedOffer('');
															}}
														/>
													</div>
													{couponError && (
														<ErrorMessage message={couponError} />
													)}
												</div>
											) : (
												<div className="flex flex-col gap-3 ">
													<CommonLabel labelText="Coupon code" />
													<div className="relative z-10">
														<input
															type="text"
															className={`bg-white border rounded-lg p-4 w-full h-16 text-lg uppercase placeholder-${TEXTCOLOR} placeholder:capitalize focus:border-${BORDERCOLOR} focus:ring-${BORDERCOLOR}`}
															placeholder={'Enter your code'}
															defaultValue={couponCode}
															onChange={(e) =>
																setCouponCode(e.target.value.toUpperCase())
															}
														/>
														<button
															type="button"
															disabled={couponCode !== '' ? false : true}
															className={`absolute top-1/2 right-4 transform -translate-y-1/2 text-lg font-bold`}
															style={{
																color: couponCode !== '' ? BLACK : GRAY50,
																cursor:
																	couponCode !== '' ? 'pointer' : 'not-allowed',
															}}
															onClick={applyCouponCode}
														>
															APPLY
														</button>
													</div>
												</div>
											)}
											<div className="flex flex-col justify-center items-center">
												<div
													className="flex flex-col p-4 gap-4 w-full rounded-xl"
													style={{ background: BGGRAY }}
												>
													<div className="flex flex-col justify-between">
														<p
															className="sm:text-xl text-[17px] font-semibold sm:leading-8 leading-6 uppercase"
															style={{ color: TEXTCOLOR }}
														>
															Order summary
														</p>
													</div>
													<div
														className="border w-full"
														style={{ borderColor: BORDERCOLOR }}
													></div>
													<div className="max-h-72  overflow-scroll flex flex-col gap-4">
														{products?.map((item: Gift, index: number) => (
															<div className="flex flex-col " key={index}>
																{products?.length === 0 ? (
																	<div className="flex justify-center items-center xs:p-5 lg:p-20 sm:p-5 md:p-20 flex-col gap-4">
																		<p className="text-3xl mt-4 font-semibold">
																			Your Order Summary is Empty
																		</p>
																		<p
																			className="underline cursor-pointer underline-offset-2"
																			onClick={handleBackToShopping}
																		>
																			BACK TO SHOPPING
																		</p>
																		<PiShoppingCart
																			style={{ color: BLACK, fontSize: '50px' }}
																		/>
																	</div>
																) : (
																	<div className="flex items-center justify-between">
																		<div className="flex gap-1 justify-start items-center flex-row">
																			<p className="sm:text-lg text-sm text-[#374151] lg:leading-8 leading-6">
																				{!item?.product
																					? item.gift?.frameId?.length > 13
																						? `${item.gift?.frameId.substring(
																								0,
																								13
																						  )}`
																						: item.gift?.frameId
																					: item.product?.id?.length > 13
																					  ? `${item.product?.id.substring(
																								0,
																								13
																					    )}`
																					  : item.product?.id}
																			</p>

																			{!item?.product && (
																				<p
																					className="sm:text-lg text-sm lg:leading-8 leading-6"
																					style={{ color: TEXTCOLOR }}
																				>
																					{moment(item.gift?.date).format(
																						'DD/MM/YYYY'
																					)}
																					(x
																					{item.quantity})
																				</p>
																			)}
																		</div>
																		<div>
																			<p
																				className="sm:text-lg text-sm lg:leading-8 leading-6"
																				style={{ color: TEXTCOLOR }}
																			>
																				₹
																				{!item?.product
																					? item.gift?.price
																					: item.product?.price}
																			</p>
																		</div>
																	</div>
																)}
															</div>
														))}
													</div>
													{applyCoupon && discountAmount > 0 && (
														<div className="flex justify-between">
															<p
																className="sm:text-lg text-sm"
																style={{ color: TEXTCOLOR }}
															>
																Coupon discount
															</p>
															<p
																className="sm:text-lg text-sm"
																style={{ color: TEXTCOLOR }}
															>
																₹ {discountAmount}
															</p>
														</div>
													)}
													<div
														className="border w-full"
														style={{ borderColor: BORDERCOLOR }}
													></div>
													<div className="flex items-center justify-between">
														<p>
															<div
																className="sm:text-lg text-base font-bold  capitalize lg:leading-8 leading-6"
																style={{ color: TEXTCOLOR }}
															>
																grand total
															</div>
														</p>
														<p>
															<div
																className="sm:text-lg text-base font-bold lg:leading-8 leading-6"
																style={{ color: TEXTCOLOR }}
															>
																₹{grandTotal}
															</div>
														</p>
													</div>
												</div>
											</div>
											<div className="flex justify-center items-center w-full">
												{/* {pagination !== 2 && ( */}
												<>
													<CommonButton
														width="100%"
														className="text-white px-8 py-4 rounded-xl"
														padding={
															screen.isMobile ? '12px 20px' : '16px 32px'
														}
														label="CONTINUE"
														arrowPosition={true}
														justifyContent="space-between"
														onClick={handelClick}
														backgroundColor={
															pagination === 1 && !selectedAddressId
																? '#808080'
																: BLACK
														}
														disabled={
															pagination === 1 && !selectedAddressId
																? true
																: false
														}
													/>
												</>
												{/* )} */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Checkout;
