import React from 'react';
import { useEffect, useState } from 'react';
import { Framedetails } from '../../../Interface/Index';
import SEO from '../../../Components/Helmet/SEO';
import { useNavigate, useParams } from 'react-router-dom';
import { addToCart, getProductDataById } from '../../../Api/UserListApi';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { WHITE_SMOKE } from '../../../Constant/Color.Constant';
import { getAllAddToCart, limitedEdition } from '../../../store/CartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorToaster, SuccessToaster } from '../../../helpers/CustomToaster';
import useMobileMediaQuery from '../../../utils/useMobileMediaQuery';
import CommonProductSmallImage from '../../../Components/CommonProductSmallImage/CommonProductSmallImage';
import CommonSimilarProduct from '../../../Components/CommonSimilarProduct/CommonSimilarProduct';
import CommonProductTitle from '../../../Components/CommonProductTitle/CommonProductTitle';
import CommonMagnify from '../../../Components/CommonMagnify/CommonMagnify';
import CommonProductDetailPage from '../../../Components/CommonProductDetailPage/CommonProductDetailPage';
import { AnyAction } from '@reduxjs/toolkit';
import { ROUTES } from '../../../Constant/Routes.Constant';
export interface Product {
	id: string;
	name: string;
	productCode: string;
	denomination: string;
	noteNo: string;
}

export interface ApiResponse {
	message: string;
	status: number;
	data: Product[];
}

const LimitedEditionDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [selectedImageData, setSelectedImageData] = useState<Framedetails>();
	const [currentImage, setCurrentImage] = useState<number>(0);
	const [similarData, setSimilarData] = useState<ApiResponse>();
	const [loading, setLoading] = useState<boolean>(false);
	const [isDisableBtn, setIsDisableBtn] = useState<boolean>(false);
	const screen = useMobileMediaQuery();
	const cartData = useSelector(
		(state: { cart: { cartList: any } }) => state.cart?.cartList
	);
	const fetchData = async () => {
		try {
			const res = await getProductDataById({ id });
			if (!res?.error) {
				setCurrentImage(0);
				setSelectedImageData(res?.data?.data);
				setSimilarData(res?.data?.similarProduct);
			}
		} catch (error) {
			console.error('Error fetching Products:', error);
		}
	};

	useEffect(() => {
		if (selectedImageData) {
			const isAvialable = cartData?.filter(
				(elem: { product: { id: string } }) => elem?.product?.id === id
			);
			setIsDisableBtn(isAvialable?.length > 0);
		}
	}, [cartData, selectedImageData]);

	useEffect(() => {
		fetchData();
	}, []);

	const isLogin = localStorage.getItem('ACCESSTOKEN');

	const handleAddToCart = async (productId: string) => {
		if (!isLogin) {
			dispatch(limitedEdition({ productId }));
			navigate(ROUTES.LOGIN);
		} else {
			setLoading(true);
			const { error, message } = await addToCart({ productId });
			if (error) {
				setLoading(false);
				ErrorToaster(message);
			} else {
				setLoading(false);
				navigate(-1);
				SuccessToaster(message);
				dispatch(getAllAddToCart() as unknown as AnyAction);
			}
		}
	};
	useEffect(() => {
		if (selectedImageData) {
			const isAvialable = cartData?.filter(
				(elem: { product: { id: string } }) => elem?.product?.id === id
			);
			setIsDisableBtn(isAvialable.length > 0);
		}
	}, [cartData, selectedImageData]);

	useEffect(() => {
		fetchData();
	}, [id]);
	const handleCardClick = (selectedItem: Framedetails) => {
		navigate(`${ROUTES.LIMITED_EDITION}/${selectedItem.id}`);
		setSelectedImageData(selectedItem);
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<>
			<div
				className="xlg:py-12 xlg:px-24 md:p-12 p-4"
				style={{ backgroundColor: WHITE_SMOKE }}
			>
				<SEO
					title="Limited Edition Details"
					description=" Our Limited Edition Notes Collection."
				/>
				<div className="w-full flex flex-col sm:gap-8 gap-6">
					<CommonProductTitle
						homeText="home /"
						categoryText="limited edition notes"
					/>
					<div className="flex lg:flex-row xs:flex-col md:flex-col md:gap-0 gap-5">
						<div className="flex md:flex-row flex-col-reverse  md:gap-4 gap-3">
							<CommonProductSmallImage
								dataDetails={selectedImageData}
								setCurrentImage={setCurrentImage}
								currentImage={currentImage}
							/>
							<CommonMagnify
								imageSrc={selectedImageData?.images?.[currentImage]}
							/>
						</div>
						<CommonProductDetailPage
							selectedImageData={selectedImageData}
							handleAddToCart={handleAddToCart}
							loading={loading}
							screen={screen}
							isDisableBtn={isDisableBtn}
						/>
					</div>
				</div>
			</div>
			<div>
				<CommonSimilarProduct
					similarData={similarData}
					title="similar products"
					onCardClick={handleCardClick}
					selectedImageData={selectedImageData}
				/>
			</div>
		</>
	);
};
export default LimitedEditionDetail;
