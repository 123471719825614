export const WHITE = '#ffffff';
export const WHITE10 = '#E5E7EB';
export const WHITE20 = '#EEF2FF';
export const WHITE_SMOKE = '#fafafa';
export const BLACK = '#000000';
export const BLACK100 = '#0D2126';
export const BLACK200 = '#181818';
export const GRAY = '#828282';
export const BGGRAY = '#F2F2F2';
export const GRAY10 = '#4B5563';
export const GRAY50 = '#9CA3AF';
export const GRAY100 = '#374151';
export const GRAY200 = '#625353';
export const GRAY300 = '#F2F4F7';
export const GRAY900 = '#111827';
export const ROSE = '#CC0000';
export const RED100 = '#EF4444';
export const TEXTCOLORGRAY = '#6B7280';
export const BORDERCOLOR = '#D1D5DB';
export const TEXTCOLOR = '#1E1E1E';
export const ORANGE = '#F97316';
export const RED = '#FF0000';
export const GREEN = '#059669';
export const TRANSPARENT = '#00000000';
export const HOVER_COLOR = '#efefef';
export const ORANGE_LIGHT = '#FB923C';
export const BRIGHT_GRAY = '#ECEBEA';
export const QUICK_SILVER = '#A7A6A4';
export const DIVIDER_GRAY = '#4D4D4D';
export const TRANSPERNT_GRAY = '#000000bf';
