import { createContext, useContext } from 'react';
import { TypeAttributes } from '../../Interface/Index';

export enum SIZES {
	XS = 'xs',
	SM = 'sm',
	MD = 'md',
	LG = 'lg',
}
export type ColorLevel =
	| 50
	| 100
	| 200
	| 300
	| 400
	| 500
	| 600
	| 700
	| 800
	| 900;

export type Config = {
	themeColor: string;
	mode: 'light' | 'dark';
	locale: string;
	primaryColorLevel: ColorLevel;
	cardBordered: boolean;
	controlSize: TypeAttributes.ControlSize;
	navMode: TypeAttributes.MenuVariant;
	direction: TypeAttributes.Direction;
};

export const defaultConfig = {
	themeColor: 'indigo',
	direction: 'ltr',
	mode: 'light',
	locale: 'en',
	primaryColorLevel: 600,
	cardBordered: false,
	controlSize: SIZES.MD,
	navMode: 'light',
} as const;

export const ConfigContext = createContext<Config>(defaultConfig);

const ConfigProvider = ConfigContext.Provider;

export const ConfigConsumer = ConfigContext.Consumer;

export const useConfig = () => {
	return useContext(ConfigContext);
};

export default ConfigProvider;
