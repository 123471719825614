import React from 'react';
import { SET_OF_FOUR_DEFAULT_DATES } from '../../../../Configuration/InitialData';
import { Currency } from '../../../../Constant/Constant';
import { CurrencyData } from '../../../../Interface/NewGiftInterface';
import Image from '../../../../Components/Image/Image';

const SetOfFour = ({
	setOfFourData,
	count,
}: {
	setOfFourData: Record<string, Record<string, string>>;
	count: number;
}) => {
	const setOfFourDataKeys = Object.keys(setOfFourData);
	return (
		<div className="!h-full w-full">
			<div className="flex justify-center pb-2 pt-[3px]">
				<h2 className="font-black md:text-[10px] md:mt-3.5 sm:text-[10px] sm:mt-0.5 nd:text-[9px] mt-1 text-[7px]">
					Just A Note To Reflect Our Special Days
				</h2>
			</div>
			<div className="h-[calc(100%-37px)] px-7 flex flex-col justify-center">
				{Array.from({ length: count }, (_, index) => {
					const noteImage = Currency?.filter(
						(rupee: CurrencyData) =>
							rupee.rupee ===
							parseInt(setOfFourData[setOfFourDataKeys[index]]?.currency)
					);
					return (
						<div
							key={index}
							className="h-1/3 w-full flex"
							style={{
								flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
								height:
									count === 2 ? '50%' : count === 3 ? '33.333333%' : '25%',
							}}
						>
							<div className="md:w-[109px] sm:w-[127px] nd:w-[100px] w-[69px] h-full flex items-center justify-center p-0.5">
								<div className="w-full h-full pb-2">
									<div className="relative h-full overflow-hidden md:w-[100px] sm:w-[119px] nd:w-[92px] w-[61px] rounded-full shadow-[rgba(0,0,0,0.25)_0px_54px_55px,_rgba(0,0,0,0.12)_0px_-12px_30px,_rgba(0,0,0,0.12)_0px_4px_6px,_rgba(0,0,0,0.17)_0px_12px_13px,_rgba(0,0,0,0.09)_0px_-3px_5px]">
										<div className="h-full w-full flex justify-center items-center bg-black">
											<p className="p-3 text-center text-[10px] leading-4 capitalize text-white font-bold">
												please select your image
											</p>
										</div>
										{setOfFourData[setOfFourDataKeys[index]]?.image && (
											<div className="absolute top-0 left-0 w-full h-full">
												<Image
													image={setOfFourData[setOfFourDataKeys[index]]?.image}
													className="w-full h-full object-cover"
													altName={`user-image-${index}`}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="md:w-[calc(100%-109px)] sm:w-[calc(100%-127px)] nd:w-[calc(100%-100px)] w-[calc(100%-69px)] h-full flex flex-col">
								<div className="md:h-[calc(100%-22px)] h-[calc(100%-16px)] flex items-center justify-center">
									<div className="h-[48.38px] nd:h-[76.27px] sm:h-[100.34px] md:h-[78.75px]">
										<div className="h-[90%]">
											<Image
												image={noteImage[0]?.image}
												altName={`currency-note-${index}`}
											/>
										</div>
									</div>
								</div>
								<div className="h-4 flex items-center">
									<p className="text-center flex justify-center items-center w-full">
										<span className="w-full inline-flex items-center justify-center">
											<span className="md:text-[7px] sm:text-[8px] nd:text-[6px] text-[4px] capitalize">
												{setOfFourData[setOfFourDataKeys[index]]?.message}
												&nbsp;
											</span>
											<span className="text-orange-500 md:text-[7px] sm:text-[8px] nd:text-[6px] text-[4px]">
												{setOfFourData[setOfFourDataKeys[index]]?.date?.length >
												0
													? setOfFourData[setOfFourDataKeys[index]]?.date
													: SET_OF_FOUR_DEFAULT_DATES[index]}
											</span>
										</span>
									</p>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SetOfFour;
