import React from 'react';
import { BLACK } from '../../Constant/Color.Constant';

const Menu = ({ size }: { size: number }) => {
	return (
		<div>
			<div>
				<svg
					width={size}
					height={size}
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4 6H20M4 12H20M4 18H20"
						stroke={BLACK}
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		</div>
	);
};

export default Menu;
