import React, { createContext, useState } from 'react';
import { MyContextData, MyProviderProps } from '../../Interface/Index';

const MyContext = createContext<MyContextData | undefined>(undefined);

const MyProvider: React.FC<MyProviderProps & { initialBadgeCount: number }> = ({
	children,
	initialBadgeCount,
}) => {
	const [badge, setBadge] = useState<number>(initialBadgeCount);

	const updateBadge = (newData: number) => {
		setBadge(newData);
	};

	return (
		<MyContext.Provider value={{ badge, updateBadge }}>
			{children}
		</MyContext.Provider>
	);
};

export { MyContext, MyProvider };
