import React from 'react';
import { useEffect } from 'react';
import ContactUsForm from '../../Components/ContactUs/ContactUsForm';
import ContactusDetails from '../../Components/ContactUs/ContactusDetails';
import SEO from '../../Components/Helmet/SEO';
import { SEND_US_MESSAGE } from '../../Constant/KeyWord.Constant';
import ReactGA from 'react-ga';
import { CONTACT_US } from '../../Constant/Seo.Constant';
import { BORDERCOLOR, GRAY900, WHITE10, WHITE_SMOKE } from '../../Constant/Color.Constant';

const Contact = () => {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<>
			<SEO title="Contact" description={CONTACT_US} />
			<div
				className="lg:px-24 lg:py-16 sm:px-8 sm:py-8 w-full flex justify-center items-center min-h-[calc(100vh-200px)]"
				style={{ background: WHITE_SMOKE, borderTop: `1px solid ${BORDERCOLOR}` }}
			>
				<div className="w-full">
					<div className="flex lg:h-auto sm:rounded-2xl sm:border-2  border-[#E5E7EB] h-auto overflow-hidden">
						<div className="flex w-full sm:flex-col lg:flex-row xs:flex-col">
							<div className="lg:max-w-[416px] w-full ">
								<ContactusDetails />
							</div>
							<div
								className="w-full lg:p-12 xs:py-8 xs:px-4 flex flex-col gap-6"
								style={{ background: '#FFF' }}
							>
								<div
									className="font-medium text-xl max-w-[832px]"
									style={{ color: GRAY900 }}
								>
									{SEND_US_MESSAGE}
									<div className="h-[1px] w-full mt-4 block md:hidden" style={{ color: WHITE10 }} />
								</div>
								<ContactUsForm />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Contact;
