import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LeftSlider from '../../../Components/Svg/LeftSlider';
import RightSlider from '../../../Components/Svg/RightSlider';

import { useAppDispatch, useAppSelector } from '../../../store/store';
import 'resize-observer-polyfill';
import { WHITE } from '../../../Constant/Color.Constant';
import { getAllHomeBanner } from '../../../store/BannerSlice';
import Image from '../../../Components/Image/Image';

interface SampleNextArrowProps extends React.HTMLProps<HTMLDivElement> {
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Sliders: React.FC<{ bannerType?: string }> = ({
	bannerType = 'home',
}) => {
	const settings = {
		dots: true,
		speed: 500,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};

	function SamplePrevArrow(props: SampleNextArrowProps) {
		const { onClick } = props;

		return (
			<div
				onClick={onClick}
				className="absolute left-5 lg:top-2/4 md:top-2/4 xs:top-[86%] sm:top-[86%] -translate-y-2/4 cursor-pointer z-10"
			>
				<div
					style={{
						borderRadius: '8px',
					}}
				>
					<LeftSlider strokeColor={WHITE} />
				</div>
			</div>
		);
	}

	function SampleNextArrow(props: SampleNextArrowProps) {
		const { onClick } = props;
		return (
			<div
				onClick={onClick}
				className="lg:top-2/4 xs:top-[86%] md:top-2/4 z-10 cursor-pointer -translate-y-2/4 absolute right-5"
			>
				<div style={{ borderRadius: '8px' }}>
					<RightSlider strokeColor={WHITE} />
				</div>
			</div>
		);
	}

	const BannerList = useAppSelector((state) => state.banner.homeBannerList);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getAllHomeBanner({ limit: 10, type: bannerType }));
	}, [bannerType]);

	useEffect(() => {
		const ro = new ResizeObserver(() => {});
		ro.observe(document.body);
		return () => ro.disconnect();
	}, []);
	return (
		<div className="w-full z-10 overflow-hidden relative home-slider">
			<Slider
				{...settings}
				className="w-full lg:h-[720px] md:h-[720px] xs:h-[484px] sm:h-80 bg-black bg-opacity-50"
			>
				{BannerList?.map((slide, index) => (
					<div
						key={index}
						className="relative lg:h-[720px] md:h-[720px] sm:h-80  w-full"
					>
						{slide.image.toLowerCase().endsWith('.mp4') ? (
							<video
								width="100%"
								height="100%"
								muted
								autoPlay={true}
								loop
								style={{ objectFit: 'cover', height: '100%' }}
								onError={(e) => console.error('Error loading video:', e)}
							>
								<source src={slide.image} type="video/mp4" />
							</video>
						) : (
							<Image image={slide.image} altName={slide.name} />
						)}

						<div className="absolute top-0 left-0 h-full w-full flex items-center justify-center bg-black bg-opacity-70 gap-4 flex-col xs:text-center">
							<p className="text-white lg:text-[64px] sm:text-[32px] xs:w-[343px] xs:text-[32px] font-medium lg:tracking-[1.28px] px-6 sm:px-0 xs:text-center lg:w-full uppercase">
								{slide.name}
							</p>
							<p className="text-white lg:text-2xl xs:text-base md:w-4/5 max-w-[786px] sm:w-[500px] xs:w-[343px] px-6 sm:px-0 text-center font-normal">
								{slide.description}
							</p>
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default Sliders;
