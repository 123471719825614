import React, { useEffect, useState } from 'react';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonHeader from '../../../Components/Header/CommonHeader';
import { getLoginUser, updateUser } from '../../../Api/UserListApi';
import { useForm } from 'react-hook-form';
import SEO from '../../../Components/Helmet/SEO';
import CommonLabel from '../../../Components/InputLable/lable';
import { CustomTextField } from '../../../Components/Input/TextField';
import ErrorMessage from '../../../Components/Typography/ErrorText';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddUserType } from '../../../Api/Types';
import CommonDatePicker from '../../../Components/DatePicker/DatePicker';
import moment from 'moment';
import { personalInfoValidataionSchema } from '../../../Components/Validataions/ValidataionSchema';
import { useAppDispatch } from '../../../store/store';
import { PERSONAL_INFORMATION } from '../../../Constant/Seo.Constant';
import { setUser, setUserToken } from '../../../store/UserSlice';
import { useSelector } from 'react-redux';
import { UserData } from '../../../Interface/Index';
import { ErrorToaster, SuccessToaster } from '../../../helpers/CustomToaster';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../Constant/Routes.Constant';
import { RED100, WHITE10 } from '../../../Constant/Color.Constant';

const PersonalInfo: React.FC = () => {
	const [selected, setselected] = useState<Date | undefined>();
	const [userData, setUserData] = useState<UserData>();
	const [loader, setLoader] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [isDirty, setIsDirty] = useState(false);

	const userId = useSelector(
		(state: { user: { user: { id: string } } }) => state.user.user.id
	);

	const persnolInfoDefaultValues: AddUserType = {
		name: userData?.name || '',
		email: userData?.email || '',
		phone: userData?.phone || '',
		dob: userData?.dob ? moment(userData?.dob).toDate() : null,
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		formState,
	} = useForm<AddUserType>({
		mode: 'all',
		defaultValues: userData,
		resolver: yupResolver(personalInfoValidataionSchema),
	});

	useEffect(() => {
		async function fetchInitialData() {
			try {
				const response = await getLoginUser();
				setUserData(response?.data);
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		}
		fetchInitialData();
	}, []);

	useEffect(() => {
		if (userData?.dob) {
			setselected(moment(userData.dob).toDate());
		}
	}, [userData]);

	useEffect(() => {
		setValue('name', persnolInfoDefaultValues?.name);
	}, [setValue, persnolInfoDefaultValues?.name]);

	useEffect(() => {
		setValue('phone', persnolInfoDefaultValues?.phone);
	}, [setValue, persnolInfoDefaultValues?.phone]);

	const onSubmit = async (data: AddUserType) => {
		setLoader(true);

		if (formState.isDirty || isDirty) {
			data.dob = moment(data?.dob)?.format('YYYY-MM-DD') as string;

			try {
				const { error, message } = await updateUser({ id: userId }, data);
				dispatch(setUser(data));

				if (error) {
					ErrorToaster(message);
				} else {
					SuccessToaster(message);
					setIsDirty(false);
					reset();
				}
			} catch (error) {
				console.error('Error updating user data:', error);
			}
		} else {
			ErrorToaster('No changes detected. Update your data first.');
		}

		setLoader(false);
	};

	const handleClickToNavigate = (route: string, storeReset = false) => {
		if (storeReset) {
			dispatch(setUserToken(''));
			dispatch(
				setUser({
					id: '',
					name: '',
					role: '',
					email: '',
				})
			);

			localStorage.removeItem('ACCESSTOKEN');
			SuccessToaster('User Logout');
		}

		navigate(route);
	};

	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate());

	return (
		<>
			<SEO title="Personal Info" description={PERSONAL_INFORMATION} />

			<div className="w-full h-full flex justify-center sm:justify-center xs:justify-center lg:items-center sm:items-center">
				<div className="lg:w-[80%] w-full flex flex-col lg:gap-8">
					<div className="flex flex-col sm:gap-4">
						<div className="hidden lg:block">
							<CommonHeader title="PERSONAL INFO" />
						</div>
						<div
							className="hidden lg:block w-full"
							style={{ borderBottom: `1px solid ${WHITE10}` }}
						></div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="w-full grid grid-cols-1 lg:grid-cols-2 lg:gap-8 xs:gap-6 mt-0 md:mt-4">
								<div className="flex flex-col xs:gap-1 lg:gap-3">
									<CommonLabel labelText="Name" />
									<CustomTextField
										placeholder="Enter your name"
										name="name"
										register={register}
										defaultValue={persnolInfoDefaultValues?.name}
									/>
									<ErrorMessage message={errors.name?.message} />
								</div>
								<div className="flex flex-col xs:gap-1 lg:gap-3">
									<CommonLabel labelText="Email address" />
									<CustomTextField
										placeholder="Enter your email"
										name="email"
										register={register}
										disabled={true}
										defaultValue={persnolInfoDefaultValues?.email}
									/>
									<ErrorMessage message={errors.email?.message} />
								</div>
								<div className="flex flex-col xs:gap-1 lg:gap-3">
									<CommonLabel labelText="Phone Number" />
									<CustomTextField
										placeholder="Enter your phone number"
										name="phone"
										type="number"
										defaultValue={persnolInfoDefaultValues?.phone}
										register={register}
									/>
									<ErrorMessage message={errors.phone?.message} />
								</div>
								<div className="flex flex-col xs:gap-1 lg:gap-3">
									<CommonLabel labelText="Date of Birth" />
									<CommonDatePicker
										name="dob"
										register={register}
										handleDateChange={(date: Date | null | string) => {
											setValue('dob', date as Date);
											setselected(date as Date);
										}}
										setIsDirty={setIsDirty}
										selected={selected}
										maxDate={tomorrow}
									/>
								</div>
							</div>

							<div className="w-ful md:mt-8 mt-6 flex flex-col gap-6">
								<CommonButton
									className="text-white rounded-xl lg:!px-8 lg:!py-4 !py-3 !px-5 lg:!leading-8 !leading-6 lg:!text-xl !text-base"
									width="100%"
									type="submit"
									loading={loader}
									label="SAVE"
								/>

								<div
									className="lg:hidden"
									onClick={() => handleClickToNavigate(ROUTES.LOGIN, true)}
								>
									<p
										className="text-lg uppercase leading-8 font-medium cursor-pointer"
										style={{ color: RED100 }}
									>
										Sign out
									</p>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default PersonalInfo;
