import React, { useEffect, useRef, useState } from 'react';
import AddToBag from './components/AddToBag';
import FullScreenImage from '../ui/FullScreenImage';
import ClassicFiveNotes from '../layouts/ClassicFiveNotes';
import ClassicSingleNote from '../layouts/ClassicSingleNote';
import SetOfFour from '../layouts/SetOfFour';
import Watermark from '../ui/Watermark';
import _ from 'lodash';
import { SetState } from '../../../../Interface/Index';
import {
	PRODUCT_IMAGES,
	SET_OF_FIVE_FRAME_BACKGROUND,
	SET_OF_FOUR_FRAME_BACKGROUND,
	SINGLE_NOTE_FRAME_BACKGROUND,
} from '../../../../Configuration/InitialData';
import { getBackgroundImage } from '../../../../Api/UserListApi';
import { CLASSES, SET_OF_FOUR } from '../../../../Constant/Constant';
import {
	BackgroundImageJson,
	BgData,
	EditData,
	fiveNotesDataTS,
	SelectedFrame,
	SelectedFrameBackground,
	setOfFour,
	SetOfFourData,
} from '../../../../Interface/NewGiftInterface';
import { inchesToMillimeters } from '../../../../utils/comon.functions';
import {
	BLACK,
	BRIGHT_GRAY,
	QUICK_SILVER,
	TRANSPARENT,
} from '../../../../Constant/Color.Constant';
import Image from '../../../../Components/Image/Image';

const FrameLayout = ({
	node,
	id,
	layout,
	setOfFour,
	setLayout,
	fiveNotesData,
	selectedframe,
	screenWidth,
	isSubmitValid,
	setOfFourData,
	count,
	bgData,
	isImageDelete,
	backGroundImages,
	loading,
	selectedFrameBackground,
	setSelectedFrameBackground,
	typeEdit,
	editData,
}: {
	node: HTMLElement | null;
	id: string | undefined;
	layout: boolean;
	loading: boolean;
	setLayout: SetState<boolean>;
	setOfFour: setOfFour;
	fiveNotesData: fiveNotesDataTS;
	selectedframe: SelectedFrame | undefined;
	screenWidth: string;
	isSubmitValid: boolean;
	setOfFourData: SetOfFourData;
	isImageDelete: boolean;
	count: number;
	backGroundImages: BackgroundImageJson[];
	bgData: BgData;
	setSelectedFrameBackground: SetState<SelectedFrameBackground>;
	selectedFrameBackground: SelectedFrameBackground;
	typeEdit: string;
	editData: EditData;
}) => {
	const [isFrameFullScreen, setIsFrameFullScreen] = useState(false);
	const [layoutFormat, setLayoutFormat] = useState(0);
	const layout1 = PRODUCT_IMAGES.SINGLE;
	const [layoutsData, setLayoutsData] = useState<
		Record<string, Record<string, string>>
	>({
		layout1,
		layout2: PRODUCT_IMAGES.MULTIPLE,
	});

	let frameSizeDetails = {
		frameWidth: '',
		frameHeight: '',
	};
	if (selectedframe?.size) {
		const [frameWidth, frameHeight] = selectedframe.size.split('x');
		frameSizeDetails = {
			frameWidth,
			frameHeight,
		};
	}

	const fetchSelectedBackground = async () => {
		const selectedBGImageID = typeEdit
			? editData?.id?.gift?.bgImageId
			: (setOfFour?.SelectBackGround?.id as string);
		let params;
		if (selectedBGImageID) params = { id: selectedBGImageID };
		const { data }: { data: Record<string, string> } =
			await getBackgroundImage(params);
		setSelectedFrameBackground(data?.image);
	};

	const layoutKeys = Object.keys(layoutsData) as 'layout1'[] | 'layout2'[];
	const elementRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!typeEdit && bgData.length > 0) {
			setSelectedFrameBackground(bgData[0]?.image);
		} else if (!typeEdit && backGroundImages.length > 0) {
			setSelectedFrameBackground(backGroundImages[0]?.image);
		}
	}, [backGroundImages, bgData]);

	useEffect(() => {
		setLayoutsData({
			...(setOfFour.currency?.length > 1 ? _.omit(layoutsData, 'layout1') : {}),
			layout1,
			...layoutsData,
		});
	}, [setOfFour.currency]);

	const getImageURL = () => {
		let BG = '';
		if (id === CLASSES && !layout)
			BG = setOfFour?.image?.name
				? URL.createObjectURL(setOfFour?.image)
				: SET_OF_FIVE_FRAME_BACKGROUND;
		else if (id === SET_OF_FOUR)
			BG = setOfFour?.isCustom
				? setOfFour?.imageFileJson
				: selectedFrameBackground || SET_OF_FOUR_FRAME_BACKGROUND;
		else if (id === CLASSES && layout)
			BG = setOfFour?.isCustom
				? setOfFour?.imageFileJson
				: selectedFrameBackground || SINGLE_NOTE_FRAME_BACKGROUND;
		else BG = SINGLE_NOTE_FRAME_BACKGROUND;
		return BG;
	};

	let backgroundImageURL = getImageURL();

	useEffect(() => {
		fetchSelectedBackground();
		backgroundImageURL = getImageURL();
	}, [editData]);

	return (
		<div
			className="flex flex-col lg:min-h-[calc(100vh-168px)] lg:w-2/4 xxl:w-[40%] justify-between relative sm:h-[876px] nd:h-[720px] h-[565px]"
			style={{
				background: `linear-gradient(to bottom right, ${BRIGHT_GRAY}, ${QUICK_SILVER})`,
			}}
		>
			<div className="flex items-center justify-center flex-col w-full lg:h-[calc(100vh-120px)] lg:min-h-[677px] relative h-full">
				<div
					className="h-full w-full flex items-center justify-center px-6 py-16 md:p-[75px] relative"
					id="your-element-id"
				>
					<div className="w-full sm:max-w-[420px] sm:h-[630px] overflow-hidden nd:max-w-[329px] nd:h-[480px] max-w-[230px] h-[350px] md:max-w-[350px] md:h-[520px] relative">
						<div className="absolute w-full h-full bg-white top-0 left-0"></div>
						<div
							style={{
								backgroundImage: `url('${backgroundImageURL}')`,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
							}}
							className="w-full flex justify-center items-center absolute h-full"
						>
							<div
								ref={elementRef}
								className="relative h-full w-full flex justify-center items-center"
							>
								<div className="h-full top-0 left-0"></div>
								<div className="absolute top-0 left-2/4 -translate-x-2/4 h-full md:w-[340px] md:h-[512px] sm:w-[400px] sm:h-[608px] nd:w-[314px] w-[218px] md:py-0 sm:py-3 flex flex-col justify-center items-center">
									<div className="h-full w-full">
										<div className="md:h-[475px] sm:h-[547px] nd:h-[440px] h-[316px] w-full">
											{id === CLASSES && layout ? (
												<ClassicSingleNote
													fiveNotesData={fiveNotesData}
													setOfFour={setOfFour}
													layout={layout}
													screenWidth={screenWidth}
													isImageDelete={isImageDelete}
													typeEdit={typeEdit}
												/>
											) : id === CLASSES && !layout ? (
												<ClassicFiveNotes
													setOfFour={setOfFour}
													layout={layout}
													fiveNotesData={fiveNotesData}
													screenWidth={screenWidth}
												/>
											) : id === SET_OF_FOUR ? (
												<SetOfFour
													setOfFourData={
														setOfFourData as Record<
															string,
															Record<string, string>
														>
													}
													count={count}
												/>
											) : null}
										</div>
										<Watermark />
									</div>
								</div>
							</div>
							<div
								className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
								style={{
									boxShadow: 'inset 0px 0px 100px #000',
								}}
							>
								<div className="h-[200%] min-w-[400%] -rotate-45 animation-shiny-button-main-frame"></div>
							</div>
						</div>
					</div>
					<div>
						<div
							className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 w-full sm:max-w-[470px] sm:h-[690px] nd:max-w-[370px] nd:h-[520px] max-w-[255px] h-[372px] md:max-w-[400px] md:h-[573px] flex justify-center items-center z-[500] shadow-[25px_25px_100px_rgba(0,0,0,0.4)]"
							style={{
								backgroundImage: `url('${
									selectedframe?.image ?? '/Images/newFrame.png'
								}')`,
								backgroundRepeat: 'no-repeat',
								backgroundSize: '100% 100%',
								backgroundPosition: 'center',
							}}
						></div>
					</div>
					{false && (
						<div className="absolute top-0 left-0 w-full px-4 pt-2">
							<div className="bg-green-200 rounded-md">
								<p className="py-1.5 px-4 text-sm text-center text-blue-600 underline underline-offset-2">
									Click here for change your selected layout.
								</p>
							</div>
						</div>
					)}
				</div>
				<div
					className="absolute top-0 left-0 w-full h-full z-[550]"
					onClick={() => setIsFrameFullScreen(true)}
				></div>
				{id !== SET_OF_FOUR && (
					<div className="absolute top-6 left-6 md:w-24 sm:w-16 sm:h-auto h-16 md:p-4 p-2 bg-white z-[560] rounded-lg flex sm:flex-col  md:gap-[21px] gap-2">
						{layoutKeys?.map(
							(element: 'layout1' | 'layout2', index: number) => {
								const { url: layoutsDataElURL, alt: layoutsDataElALT } =
									layoutsData[element];
								return (
									<div
										key={index}
										className="border-2 w-12 h-12 md:w-16 md:h-16 rounded overflow-hidden cursor-pointer"
										onClick={() => {
											if (layoutKeys?.length > 1) {
												setLayoutFormat(index);
												setLayout(!index);
											}
										}}
										style={{
											borderColor:
												layoutKeys.length > 1 && layoutFormat === index
													? BLACK
													: TRANSPARENT,
										}}
									>
										<Image
											image={layoutsDataElURL}
											altName={layoutsDataElALT}
										/>
									</div>
								);
							}
						)}
					</div>
				)}
				<div className="absolute bottom-3 right-3 flex flex-col px-2 py-0.5 rounded-md shadow-[rgba(0,0,0,0.25)_0px_54px_55px,_rgba(0,0,0,0.12)_0px_-12px_30px,_rgba(0,0,0,0.12)_0px_4px_6px,_rgba(0,0,0,0.17)_0px_12px_13px,_rgba(0,0,0,0.09)_0px_-3px_5px] bg-[rgba(255,255,255,0.4)] z-[550]">
					<h3 className="text-xs mb-0.5 capitalize font-bold">frame size</h3>
					<hr className="border border-gray-500 mb-0.5" />
					<div className="flex gap-1 text-[13px] leading-4">
						<div>
							<span>
								{inchesToMillimeters(parseInt(frameSizeDetails?.frameWidth))}
							</span>
							&nbsp;
							<span>x</span>
							&nbsp;
							<span>
								{inchesToMillimeters(parseInt(frameSizeDetails?.frameHeight))}
							</span>
						</div>
						<span>mm</span>
					</div>
					<div className="flex gap-1 text-[13px] leading-4">
						<div>
							<span>{frameSizeDetails?.frameWidth}</span>
							&nbsp;
							<span>x</span>
							&nbsp;
							<span>{frameSizeDetails?.frameHeight}</span>
						</div>
						<span>in.</span>
					</div>
				</div>
			</div>
			<div className="lg:block hidden">
				<AddToBag
					setOfFour={setOfFour}
					isSubmitValid={isSubmitValid}
					setOfFourData={setOfFourData}
					id={id}
					loading={loading}
					typeEdit={typeEdit}
				/>
			</div>
			{isFrameFullScreen && (
				<FullScreenImage
					onClose={() => setIsFrameFullScreen(false)}
					node={node}
				/>
			)}
		</div>
	);
};

export default FrameLayout;
