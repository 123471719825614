import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { BORDERCOLOR, WHITE } from '../../Constant/Color.Constant';
import Calendar from '../Svg/Calendar';

type CommonDatePickerProps<T extends FieldValues> = {
	name: Path<T>;
	register: UseFormRegister<T>;
	selected?: Date;
	handleDateChange: (date: Date | null) => void;
	className?: string;
	wrapperClassName?: string;
	placeholderText?: string;
	peekNextMonth?: boolean;
	showMonthDropdown?: boolean;
	showYearDropdown?: boolean;
	setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
	maxDate?: Date;
};
type RestProps = {
	[key: string]: unknown;
};

type Props<T extends FieldValues> = CommonDatePickerProps<T> & RestProps;

const CommonDatePicker = <T extends FieldValues>({
	name,
	register,
	selected,
	handleDateChange,
	className = 'w-full p-3.5',
	wrapperClassName = 'w-full',
	placeholderText = 'Select date (DD/MM/YY)',
	peekNextMonth = true,
	showMonthDropdown = true,
	showYearDropdown = true,
	setIsDirty,
	maxDate,
}: Props<T>) => {
	const handleChange = (date: Date | null) => {
		setIsDirty && setIsDirty(true);
		handleDateChange(date);
	};
	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const inputElement = e.target as HTMLInputElement;
		if (e.key === '-') {
			e.preventDefault();
		} else if (inputElement.value.length >= 8 && e.key.length === 1) {
			e.preventDefault();
		}
	};
	return (
		<div className="relative">
			<div
				className="w-full overflow-hidden rounded-md border"
				style={{ borderColor: BORDERCOLOR, background: WHITE }}
			>
				<DatePicker
					{...register(name)}
					dateFormat="dd/MM/yy"
					selected={selected}
					onChange={handleChange}
					peekNextMonth={peekNextMonth}
					showMonthDropdown={showMonthDropdown}
					showYearDropdown={showYearDropdown}
					dropdownMode="select"
					className={className}
					placeholderText={placeholderText}
					wrapperClassName={wrapperClassName}
					autoComplete="off"
					onKeyDown={handleKeyDown}
					maxDate={maxDate}
					// required
				/>
				<div className="absolute right-3 top-1/2 transform -translate-y-1/2">
					<Calendar />
				</div>
			</div>
		</div>
	);
};

export default CommonDatePicker;
