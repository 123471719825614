import { useDraggable } from 'react-use-draggable-scroll';
import { MutableRefObject, useRef } from 'react';
import CommonCard from '../Ui/CommonCard';
import { GRAY10, WHITE } from '../../Constant/Color.Constant';

const CommonSimilarProduct = ({ similarData, onCardClick }: any) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);
	const SimilarData = location?.pathname;
	return (
		<div>
			<div
				className="w-full lg:pl-24 md:pl-12 sm:pl-4  sm:py-11 xs:py-8  sm:px-0 px-4 h-full flex flex-col gap-8 "
				id="similarProductId"
				style={{
					background: WHITE,
				}}
			>
				<div className="flex flex-col sm:w-auto sm:gap-8 gap-4">
					<div className="w-full flex items-start justify-start">
						<p
							className="text-center font-bold leading-8 text-2xl  uppercase"
							style={{ color: GRAY10 }}
						>
							similar products
						</p>
					</div>
					<div
						className="flex sm:gap-8 gap-4 w-full overflow-x-scroll"
						{...events}
						ref={ref}
					>
						{similarData?.map((item: any, index: number) => (
							<div
								className="sm:min-w-[288px] min-w-[164px] grid grid-cols-1"
								key={index}
								onClick={() => onCardClick(item)}
							>
								<CommonCard
									title={item.name}
									description={item.description}
									image={item.images[0]}
									to={SimilarData}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CommonSimilarProduct;
