/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { STORAGEKEY } from '../Constant/Constant';
import { Params } from './Types';
export const Bearer = 'Bearer ';
const URL = process.env.REACT_APP_BACKEND_URL;

export enum ApiMethods {
	POST = 'post',
	GET = 'get',
	PUT = 'put',
	DELETE = 'delete',
}
axios.interceptors.response.use(
	function (response: AxiosResponse) {
		return response;
	},
	function (error: AxiosError) {
		if (error.response && error.response.status === 401) {
			localStorage.clear();
		} else {
			return Promise.reject(error);
		}
	}
);

export const callApi = async (
	method: ApiMethods,
	url: string,
	params?: Params,
	data?: unknown,
	header: {
		accept?: string;
		authorization?: string;
		'Content-Type'?: string;
	} = {
		authorization: localStorage.getItem(STORAGEKEY.ACCESSTOKEN)
			? Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN)
			: '',
		accept: 'application/json',
	}
) => {
	try {
		const response = await axios({
			method: method,
			params: params,
			data: data,
			url: URL + url,
			headers: header,
		});
		return response?.data;
	} catch (error) {
		if (isAxiosError(error)) {
			return { ...error?.response?.data, error: true };
		}
		return error;
	}
};

export const callApiResetPassword = async (
	method: ApiMethods,
	url: string,
	params?: Params,
	data?: any
) => {
	try {
		const response = await axios({
			method: method,
			params: params,
			data: data,
			url: URL + url,
			headers: {
				authorization:
					Bearer + data?.token ?? localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
			},
		});
		return response.data;
	} catch (error: any) {
		return { ...error?.response?.data, error: true };
	}
};
