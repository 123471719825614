import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SEOProps } from '../../Interface/Index';

const SEO: React.FC<SEOProps> = ({ title, description, keywords }) => {
	const hrefPath = window.location.pathname;
	const fullHref = `${window.location.origin}${hrefPath}`;

	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			{keywords && <meta name="keywords" content={keywords} />}
			{fullHref && <link rel="canonical" href={fullHref} />}
		</Helmet>
	);
};

export default SEO;
