import React from 'react';
import { WEBSITE_URL } from '../../../../Constant/Constant';
import Image from '../../../../Components/Image/Image';

const Watermark = () => {
	return (
		<div className="text-center md:h-[45px] sm:min-h-[35px] nd:min-h-[25px] min-h-[17px] flex flex-col justify-center md:pb-2.5 -sm:mt-[9px] -mt-1">
			<div className="md:m-0 sm:-mt-0.5 nd:-mt-1.5 -mt-1.5">
				<div className="flex justify-center">
					<div className="md:h-2.5 sm:h-[13px] nd:h-3 h-2 m-1.5">
						<Image image="/Images/TheRupee.png" altName="watermark-logo" />
					</div>
				</div>
				<p className="nd:-m-1.5 -mt-[5px] md:text-[6px] md:leading-[10px] sm:text-[10px] nd:text-[8px] text-[5px]">
					{WEBSITE_URL}
				</p>
			</div>
		</div>
	);
};

export default Watermark;
