/* eslint-disable @typescript-eslint/no-explicit-any */
import url from './Url';
import {
	AddUserType,
	CheckOut,
	ContactUsType,
	LoginType,
	Params,
	RegisterType,
	SpecialContact,
} from './Types';
import { ApiMethods, callApi, callApiResetPassword } from './Api';
import { InquireData, UserAddressType } from '../Interface/Index';

export const forgetPassword = async (data: { email: string }) => {
	return await callApi(ApiMethods.PUT, url.FORGET_PASSWORD, {}, data);
};

export const resertPassword = async (data: {
	confirmPassword?: string;
	password?: string;
	token?: string;
}) => {
	return await callApiResetPassword(
		ApiMethods.PUT,
		url.RESET_PASSWORD,
		{},
		data
	);
};
//Users Login api
export const loginUser = async (data: LoginType) => {
	return await callApi(ApiMethods.POST, url.LOGIN, {}, data);
};

//Users Register api
export const registerUser = async (data: RegisterType) => {
	return await callApi(ApiMethods.POST, url.REGISTER, {}, data);
};
//post Contact Us
export const contactUs = async (data: ContactUsType) => {
	return await callApi(ApiMethods.POST, url.CONTACT_US, {}, data);
};
//get User api
export const getUsers = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.USER_DATA, params);
};
// login user info
export const getLoginUser = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.LOGIN_USER, params);
};

//post User api
export const addUser = async (data: AddUserType) => {
	return await callApi(ApiMethods.POST, url.USER_DATA, {}, data);
};

//update User api
export const updateUser = async (params: Params, data: AddUserType) => {
	return await callApi(ApiMethods.PUT, url.USER_DATA, params, data);
};

//delete User api
export const deleteUser = async (params?: Params) => {
	return await callApi(ApiMethods.DELETE, url.USER_DATA, params);
};

//get User Address api
export const getUserAddress = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.USER_ADDRESS, params);
};

//get User Address api
export const getImageToURLString = async (data?: any) => {
	return await callApi(ApiMethods.POST, url.CREATE_IMAGE_URL, {}, data);
};

//post User Address api
export const postUserAddress = async (data: UserAddressType) => {
	return await callApi(ApiMethods.POST, url.USER_ADDRESS, {}, data);
};

//delete  User Address api
export const deleteUserAddress = async (params: Params) => {
	return await callApi(ApiMethods.DELETE, url.USER_ADDRESS, params);
};

//get Frame api
export const getFrameData = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.FRAME_LIST, params);
};

//post Upload Images api
export const uploadImagesHanlder = async (data: unknown) => {
	return await callApi(ApiMethods.POST, url.IMAGE, {}, data, {
		'Content-Type': `multipart/form-data`,
	});
};

//post Add To Cart api
// export const postAddToCart = async (data: newGiftPayload) => {
// 	return await callApi(ApiMethods.POST, url.ADD_TO_CART, {}, data);
// };
//get Add To Cart api
export const getAddToCart = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.ADD_TO_CART, params);
};

//GET PAYMENT API
export const getMyOrder = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.MY_ORDER, params);
};

//Cancel PAYMENT API
export const cancelPayment = async (params?: Params) => {
	return await callApi(ApiMethods.POST, url.CANCEL_ORDER, params);
};

//delete Add To Cart api
export const deleteAddToCart = async (params?: Params) => {
	return await callApi(ApiMethods.DELETE, url.ADD_TO_CART, params);
};

// get note helper
export const getNoteData = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.NOTES, params);
};
export const getExclusiveNoteData = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.NOTES, params);
};
export const getPriceByDenomination = async () => {
	return await callApi(ApiMethods.GET, url.DENOMINATION_PRICE);
};
export const getProductDataById = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.NOTES, params);
};
// get note helper
export const getBackgroundImage = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.BACKGROUNDIMAGE, params);
};

//post checkout Add To Cart api
export const checkoutAddToCart = async (data?: CheckOut, params?: Params) => {
	return await callApi(ApiMethods.POST, url.CHECKOUT, params, data);
};

//add new gift in cart
export const addNewGift = async (data?: any) => {
	return await callApi(ApiMethods.POST, url.NEW_GIFT, {}, data);
};

//update new gift in cart
export const updateNewGift = async (params: Params, data: any) => {
	return await callApi(ApiMethods.PUT, url.NEW_GIFT, params, data);
};

// FAQ's
export const getFaq = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.FAQ, params);
};

// special comntact
export const addSpecialContact = async (data?: SpecialContact) => {
	return await callApi(ApiMethods.POST, url.SPECIAL_CONTACT, {}, data);
};

//update user adress
export const updateUserAdress = async (
	params: Params,
	data: UserAddressType
) => {
	return await callApi(ApiMethods.PUT, url.USER_ADDRESS, params, data);
};

// limited edition
export const getLimitedEdition = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.NOTES, params);
};

export const getHomeBannerData = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.BANNER, params);
};
export const getAlbumBannerData = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.BANNER, params);
};
export const getSetOfAllBannerData = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.BANNER, params);
};
export const getConfigData = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.CONFIG, params);
};
export const postRazorPay = async (data?: { amount: number }) => {
	return await callApi(ApiMethods.POST, url.RAZORPAY, {}, data);
};
export const addToCart = async (data?: { productId: string }) => {
	return await callApi(ApiMethods.POST, url.CART, {}, data);
};
export const referralCode = async (data?: {
	userReferralCode: string;
	price: number;
}) => {
	return await callApi(ApiMethods.POST, url.REFERRALCODE, {}, data);
};

export const getOfferList = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.REFERRALCODE, params);
};

export const Inquiry = async (data: InquireData) => {
	return await callApi(ApiMethods.POST, url.INQUIRY, {}, data);
};

export const getSpinnerDatas = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.SPINITEAM, params);
};

export const SpinWheel = async () => {
	return await callApi(ApiMethods.GET, url.SPINWHEEL, {});
};

export const getReview = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.REVIEW, params);
};

export const getSpinConfig = async (params?: Params) => {
	return await callApi(ApiMethods.GET, url.SPINCONFIG, params);
};
