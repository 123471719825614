import React from 'react';
import CommonButton from '../../Components/Button/CommonButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactUsValidationSchema } from '../Validataions/ValidataionSchema';
import { ContactUsType } from '../../Api/Types';
import { contactUs } from '../../Api/UserListApi';
import { CustomTextField } from '../Input/TextField';
import ErrorMessage from '../Typography/ErrorText';
import CommonLabel from '../InputLable/lable';
import ReactGA from 'react-ga';
import { useEffect, useState } from 'react';
import { ErrorToaster, SuccessToaster } from '../../helpers/CustomToaster';
const defaultValues: ContactUsType = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	subject: '',
	message: '',
};

const ContactUsForm = () => {
	const [isMobile, setIsMobile] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const mobileMediaQuery = window.matchMedia('(max-width: 630px)');
		const handleMobileChange = (event: MediaQueryListEvent) => {
			setIsMobile(event.matches);
		};
		mobileMediaQuery.addEventListener('change', handleMobileChange);
		setIsMobile(mobileMediaQuery.matches);
		return () => {
			mobileMediaQuery.removeEventListener('change', handleMobileChange);
		};
	}, []);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<ContactUsType>({
		defaultValues: defaultValues,
		resolver: yupResolver(contactUsValidationSchema),
	});
	const onSubmit = (data: ContactUsType) => {
		ReactGA.event({
			category: 'Denish',
			action: 'Testing',
			label: 'Testing',
		});
		setLoading(true);
		contactUs(data).then(({ error, message }: ContactUsType) => {
			setLoading(false);
			if (error) {
				ErrorToaster(message);
			} else {
				SuccessToaster(message);
				reset();
			}
			
		});
	};

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="w-full h-auto flex flex-col gap-6">
					<div className="grid md:grid-cols-2 grid-cols-1 w-full gap-6 md:gap-8">
						<div className="flex flex-col gap-1">
							<CommonLabel labelText="First name" />
							<CustomTextField
								name="firstName"
								placeholder="Enter your first name"
								register={register}
								defaultValue={defaultValues.firstName}
								autoComplete="off"
							/>
							<ErrorMessage message={errors.firstName?.message} />
						</div>
						<div className="flex flex-col gap-1">
							<CommonLabel labelText="Last name" />
							<CustomTextField
								placeholder="Enter your last name"
								name="lastName"
								register={register}
								defaultValue={defaultValues.lastName}
							/>
							<ErrorMessage message={errors.lastName?.message} />
						</div>
					</div>
					<div className="grid md:grid-cols-2 grid-cols-1 w-full gap-8">
						<div className="flex flex-col gap-1">
							<CommonLabel labelText="Email" />
							<CustomTextField
								name="email"
								placeholder="Enter your email"
								register={register}
								defaultValue={defaultValues.email}
								autoComplete="off"
							/>
							<ErrorMessage message={errors.email?.message} />
						</div>
						<div className="flex flex-col gap-1">
							<CommonLabel labelText="Phone Number" />
							<CustomTextField
								name="phone"
								type="number"
								placeholder="Enter your phone number"
								register={register}
								defaultValue={defaultValues.phone}
								autoComplete="off"
							/>
							<ErrorMessage message={errors.phone?.message} />
						</div>
					</div>
					<div>
						<div className="flex flex-col gap-6">
							<div className="flex flex-col gap-1">
								<CommonLabel labelText="Subject" />
								<CustomTextField
									name="subject"
									placeholder="Enter subject"
									register={register}
									defaultValue={defaultValues.subject}
									autoComplete="off"
								/>
								<ErrorMessage message={errors.subject?.message} />
							</div>
							<div className="flex flex-col gap-1">
								<CommonLabel
									labelText="Message"
									optional="Max. 500 characters"
								/>
								<CustomTextField
									name="message"
									placeholder="Enter message"
									type="textarea"
									rows={4}
									register={register}
									defaultValue={defaultValues.message}
									autoComplete="off"
								/>
								<ErrorMessage message={errors.message?.message} />
							</div>
						</div>
					</div>
					<div className="w-full flex sm:justify-end sm:items-end justify-center items-center ">
						<CommonButton
							type="submit"
							label="SUBMIT"
							loading={loading}
							width={isMobile ? '100%' : '129px'}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ContactUsForm;
