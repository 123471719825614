import React from 'react';
import { useEffect, useState } from 'react';
import { Framedetails } from '../../../Interface/Index';
import SEO from '../../../Components/Helmet/SEO';
import { useNavigate, useParams } from 'react-router-dom';
import { getProductDataById } from '../../../Api/UserListApi';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { WHITE_SMOKE } from '../../../Constant/Color.Constant';
import NoteForSell from '../../../Components/NoteForSale/NoteForSell';
import Modal from '../../../Components/Model/Modal';
import InquireModal from './Components/InquireModal';
import useMobileMediaQuery from '../../../utils/useMobileMediaQuery';
import CommonProductSmallImage from '../../../Components/CommonProductSmallImage/CommonProductSmallImage';
import CommonSimilarProduct from '../../../Components/CommonSimilarProduct/CommonSimilarProduct';
import CommonProductTitle from '../../../Components/CommonProductTitle/CommonProductTitle';
import CommonMagnify from '../../../Components/CommonMagnify/CommonMagnify';
import { EXCLUSIVE_DETAILS } from '../../../Constant/Seo.Constant';
import { ROUTES } from '../../../Constant/Routes.Constant';
import CommonProductDetailPage from '../../../Components/CommonProductDetailPage/CommonProductDetailPage';

export interface Product {
	id: string;
	name: string;
	productCode: string;
	denomination: string;
	noteNo: string;
	keyHighlights: string[];
}

export interface ApiResponse {
	message: string;
	status: number;
	data: Product[];
}

const ExclusiveDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [selectedImageData, setSelectedImageData] = useState<Framedetails>();
	const [currentImage, setCurrentImage] = useState<number>(0);
	const [similarData, setSimilarData] = useState<ApiResponse>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const screen = useMobileMediaQuery();
	const fetchData = async () => {
		try {
			const res = await getProductDataById({ id });
			if (!res?.error) {
				setCurrentImage(0);
				setSelectedImageData(res?.data?.data);
				setSimilarData(res?.data?.similarProduct);
			}
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const handleCardClick = (selectedItem: Framedetails) => {
		setSelectedImageData(selectedItem);
		navigate(
			`${ROUTES.EXCLUSIVE}/${ROUTES.EXCLUSIVE_DETAILES}/${selectedItem.id}`
		);
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		fetchData();
	}, [id]);
	return (
		<>
			<div className="flex flex-col sm:gap-0 gap-6">
				<NoteForSell />
				<div
					className="sm:py-12 md:px-24  px-4"
					style={{ backgroundColor: WHITE_SMOKE }}
				>
					<SEO title="Exclusive Details" description={EXCLUSIVE_DETAILS} />
					<div className="w-full flex flex-col sm:gap-8 gap-6">
						<CommonProductTitle
							homeText="home /"
							categoryText="Exclusive Collection"
						/>
						<div className="h-full w-full flex lg:flex-row xs:flex-col md:flex-col md:gap-0 gap-5">
							<div className="flex md:flex-row flex-col-reverse  md:gap-4 gap-3">
								<CommonProductSmallImage
									dataDetails={selectedImageData}
									setCurrentImage={setCurrentImage}
									currentImage={currentImage}
								/>

								<CommonMagnify
									imageSrc={selectedImageData?.images?.[currentImage]}
								/>
							</div>
							<CommonProductDetailPage
								selectedImageData={selectedImageData}
								openModal={openModal}
								screen={screen}
							/>
						</div>
					</div>
				</div>
				<div>
					<NoteForSell />
					<CommonSimilarProduct
						similarData={similarData}
						title="similar products"
						selectedImageData={selectedImageData}
						onCardClick={handleCardClick}
					/>
				</div>
			</div>
			{isModalOpen && (
				<Modal onClose={closeModal}>
					<InquireModal closeModal={closeModal} productId={id} />
				</Modal>
			)}
		</>
	);
};
export default ExclusiveDetail;
