import React, { useEffect, useState } from 'react';
import CommonHeader from '../../../Components/Header/CommonHeader';
import UserAddressForm from '../../../Components/UserAddressForm/UserAddressForm';
import { ShippingAddressProps } from '../../../Interface/Index';
import { getUserAddress } from '../../../Api/UserListApi';
import CommonLoader from '../../../Components/Loader/Loader';
import { ADD_NEW_ADDRESS } from '../../../Constant/KeyWord.Constant';
import AddressComponent from '../../../Components/Address/AddressBook';
import { BORDERCOLOR } from '../../../Constant/Color.Constant';
export interface Address {
	id: string;
	name: string;
	pinCode?: string;
	address?: string;
	onSelectAddress?: string;
	addressId?: string;
}
const ShippingAddress = ({
	selectedAddressId,
	setSelectedAddressId,
}: ShippingAddressProps) => {
	const [isFormVisible, setIsFormVisible] = useState(false);
	const [addresses, setAddresses] = useState<Address[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>();

	const handleAddAddressClick = (): void => {
		setIsFormVisible(true);
	};

	const fetchUserAddresses = async () => {
		setLoader(true);
		try {
			const res = await getUserAddress();
			if (res.error) {
				// handle error
			} else {
				setAddresses(res.data.rows);
			}
		} catch (error) {
			console.error('Error fetching addresses:', error);
		}
		setLoader(false);
	};

	useEffect(() => {
		fetchUserAddresses();
		return () => {
			setLoading(false);
		};
	}, [loading]);

	const handleCloseForm = () => {
		setIsFormVisible(false);
	};

	return (
		<div className="w-full flex flex-col">
			<div className="w-full">
				<CommonHeader title="SHIPPING ADDRESS" />
			</div>
			<div className="w-full flex mt-4 flex-col">
				<div
					className="max-w-full p-5 flex flex-col rounded-xl border"
					style={{ background: '#f3f4f6', borderColor: BORDERCOLOR }}
				>
					{loader && (
						<div className="flex justify-center">
							<CommonLoader visible={true} />
						</div>
					)}
					<AddressComponent
						addresses={addresses}
						selectedAddressId={selectedAddressId}
						setSelectedAddressId={setSelectedAddressId}
						setLoading={setLoading}
						onSelectAddress={(addressId: string) =>
							setSelectedAddressId(addressId)
						}
					/>
				</div>
			</div>
			<div>
				<p
					className="underline cursor-pointer mt-4 w-full lg:border-none border-b pb-6 mb-6 underline-offset-2"
					onClick={handleAddAddressClick}
					style={{
						borderColor: BORDERCOLOR,
					}}
				>
					{ADD_NEW_ADDRESS}
				</p>
				{isFormVisible && (
					<UserAddressForm setLoading={setLoading} onClose={handleCloseForm} />
				)}
			</div>
		</div>
	);
};

export default ShippingAddress;
