export const EMAIL_ID_IS_REQUIRED = 'Email is required';
export const EMAIL_ID_IS_INVALID = 'Email is invalid';
export const PASSWORD_IS_REQUIRED = 'Password is required';
export const INVALID_PASSWORD =
	'Password must contain minimum 8 and maximum 20 characters, at least one uppercase letter, one lowercase letter, one number and one special character';
export const CONFIRM_PASSWORD_IS_REQUIRED = 'Confirm password is required';
export const PASSWORD_IS_NOT_MATCH =
	'Password and confirm password must be same';
export const FULL_NAME_IS_REQUIRED = 'Full name is required';
export const SHORT_MESSAGE_IS_REQUIRED = 'Short message is required';
export const LONG_MESSAGE_IS_REQUIRED = 'Long message is required';
export const PHONE_NUMBER_IS_REQUIRED = 'Phone number is required';
export const PHONE_NUMBER_IS_INVALID =
	'Phone number should be min 10 digits long';
export const PHONE_NUMBER_NOT_VALID = 'Phone number is invalid';
export const FIRST_NAME_IS_REQUIRED = 'First name is required';
export const LAST_NAME_IS_REQUIRED = 'Last name is required';
export const RELATED_SUBJECT_REQUIRED = 'Subject is required';
export const MESSAGE_REQUIRED = 'Message is required';
export const OCCASION_REQUIRED = 'Occasion is required';
export const DATE_REQUIRED = 'Date is required';
export const NAME_IS_REQUIRED = 'Name is required';
export const OCCASION_IS_REQUIRED = 'Occasion is required';
export const STATE_IS_REQUIRED = 'State is required';
export const CITY_IS_REQUIRED = 'City is required';
export const ZIP_CODE_IS_REQUIRED = 'Pincode is required';
export const PIN_CODE_MIN_MAX_MESSAGE = 'Pincode must be exactly 6 characters';
export const ADDRESS_IS_REQUIRED = 'Address is required';
export const DATE_OF_BIRTH_IS_REQUIRED = 'Date of birth is required';
export const DESCRIPTION_IS_REQUIRED = 'Description is required';
export const IMAGE_REMOVE_OR_CHANGE = 'Remove or change the image';
