export const ALL_STYLES: any = {
	span1: {
		clipPath: 'polygon(0 92%, 0% 8%, 100% 50%)',
		paddingRight: '100px',
		top: '100px',
		left: '0',
		numberStyle: {
			transform: 'rotate(90deg)',
		},
	},
	span2: {
		clipPath: 'polygon(100% 92%, 0% 50%, 100% 8%)',
		paddingLeft: '100px',
		bottom: '100px',
		right: '0',
		numberStyle: {
			transform: 'rotate(-90deg)',
		},
	},
	span3: {
		clipPath: 'polygon(50% 0%, 8% 100%, 92% 100%)',
		paddingTop: '100px',
		left: '100px',
		bottom: '0',
		numberStyle: {},
	},
	span4: {
		clipPath: 'polygon(50% 100%, 92% 0, 8% 0)',
		paddingBottom: '100px',
		left: '100px',
		top: '0',
		numberStyle: {
			transform: 'rotate(180deg)',
		},
	},
};

export const ALL_STYLE_SM: any = {
	span1: {
		top: '59px',
		paddingRight: '50px',
	},
	span2: {
		bottom: '59px',
		paddingLeft: '50px',
	},
	span3: {
		left: '59px',
		paddingTop: '50px',
	},
	span4: {
		left: '59px',
		paddingBottom: '50px',
	},
};

export const WHEEL_BORDER_ROTATION = [
	'translate(-50%, -50%) rotate(359deg)',
	'translate(-50%, -50%) rotate(89deg)',
	'translate(-50%, -50%) rotate(135deg)',
	'translate(-50%, -50%) rotate(225deg)',
];

export const SPIN_ELEMENT_POSITIONS: Record<number, number> = {
	0: 4,
	1: 0,
	2: 2,
	3: 6,
	4: 1,
	5: 5,
	6: 7,
	7: 3,
};
