import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	getAlbumBannerData,
	getHomeBannerData,
	getSetOfAllBannerData,
} from '../Api/UserListApi';
import { Params } from '../Api/Types';

export type BannerList = {
	loading: boolean;
	homeBannerList: bannerList[];
	setOfAllBannerList: bannerList[];
	albumBannerList: bannerList[];
	tableData: {
		limit: number;
		page: number;
		count: number;
	};
};

export type bannerList = {
	name: string;
	image: string;
	description: string | undefined;
	to: string | undefined;
};

const tableData = {
	limit: 8,
	page: 1,
	count: 0,
};

const initialState: BannerList = {
	homeBannerList: [],
	setOfAllBannerList: [],
	albumBannerList: [],
	loading: false,
	tableData: tableData,
};

export const getAllHomeBanner = createAsyncThunk(
	'/banner2',
	async (params: Params) => {
		try {
			const response = await getHomeBannerData(params);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);
export const getAllSetOfAllBanner = createAsyncThunk(
	'/banner1',
	async (params: Params) => {
		try {
			const response = await getSetOfAllBannerData(params);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);
export const getAllAlbumBanner = createAsyncThunk(
	'/banner',
	async (params: Params) => {
		try {
			const response = await getAlbumBannerData(params);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);

export const bannerSlice = createSlice({
	name: 'banner',
	initialState,
	reducers: {
		setDataLimit: (state, action) => {
			state.tableData.limit = action.payload;
		},
		setPageData: (state, action) => {
			state.tableData.page = action.payload;
		},
		setCountData: (state, action) => {
			state.tableData.page = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllHomeBanner.fulfilled, (state, action) => {
				state.homeBannerList = action?.payload?.rows;

				state.loading = false;
			})
			.addCase(getAllHomeBanner.pending, (state) => {
				state.loading = true;
			})

			.addCase(getAllSetOfAllBanner.fulfilled, (state, action) => {
				state.setOfAllBannerList = action?.payload?.rows;
			})

			.addCase(getAllAlbumBanner.fulfilled, (state, action) => {
				state.albumBannerList = action?.payload?.rows;
			});
	},
});

export const { setDataLimit, setPageData, setCountData } = bannerSlice.actions;

export default bannerSlice.reducer;
