import { postRazorPay } from '../Api/UserListApi';
import { Method } from '../Components/Payment/Payment';

interface PaymentUtils {
	paymentDetails: { amount: number; referralCode: string };
	paymentMethod: Method;
	user_id: string;
	navigateHandler: () => void;
}
export const PaymentUtils = async ({
	paymentDetails,
	paymentMethod,
	user_id,
	navigateHandler,
}: PaymentUtils) => {
	const data = await postRazorPay(paymentDetails);
	const options = {
		key: 'rzp_test_iF35OIBclbaxAW',
		currency: 'INR',
		amount: JSON.parse(data?.data?.amount),
		order_id: data?.data?.order_id,
		name: 'Rupee',
		description: 'Thank you for nothing. Please give us some money',
		image: '/DarkLogomark.png',
		handler: function () {
			navigateHandler();
		},
		prefill: {
			name: data?.data?.name,
			email: data?.data?.email,
			phone_number: data?.data?.contact,
		},
		notes: {
			userId: user_id,
			email: data?.data?.email,
			customer: data?.data?.customer,
			item: data?.data?.item,
			referralCode: paymentDetails?.referralCode,
		},
		config: {
			display: {
				blocks: {
					banks: {
						name: paymentMethod.title,
						instruments: [
							{
								method: paymentMethod.method,
							},
						],
					},
				},
				sequence: ['block.banks'],
				preferences: {
					show_default_blocks: false,
				},
			},
		},
	};
	// options.redirect = false
	const razorpay = (window as any).Razorpay;

	const paymentObject = new razorpay(options);
	paymentObject.open();
};
