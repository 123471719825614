import React, { useEffect, useState } from 'react';
import DeleteCrossIcon from '../../../../../Components/Svg/DeleteCrossIcon';
import ErrorMessage from '../../../../../Components/Typography/ErrorText';
import { USER_IMAGE_MALE } from '../../../../../Configuration/InitialData';
import {
	BORDERCOLOR,
	TEXTCOLOR,
	WHITE,
} from '../../../../../Constant/Color.Constant';
import {
	ACCEPT_FILES_EXTENSSIONS,
	CLASSES,
} from '../../../../../Constant/Constant';
import { setOfFour } from '../../../../../Interface/NewGiftInterface';
import Image from '../../../../../Components/Image/Image';

const UserImageInput = ({
	setSetOfFour,
	setOfFour,
	id,
	errors,
	formEl,
	setIsImageDelete,
	isImageDelete,
	typeEdit,
	edittableData,
	resetAll,
}: any) => {
	const { setValue, clearErrors } = formEl;
	const [uploadedFileJson, setUploadedFileJson] = useState<{
		name: string;
	} | null>(null);

	async function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
		const file = event.target.files && event.target.files[0];
		if (file) {
			setIsImageDelete(false);
			setValue('userImage', URL.createObjectURL(file));
			clearErrors('userImage');
			setUploadedFileJson(file);
			setSetOfFour({
				...setOfFour,
				image: file,
			});
		}
	}

	const filename1 = uploadedFileJson?.name.substring(
		uploadedFileJson?.name.lastIndexOf('/') + 1
	);

	let editFileName: undefined | string = undefined;
	if (uploadedFileJson) {
		const editableNameArr = uploadedFileJson.name.split('/');
		const getNameFromString =
			editableNameArr[editableNameArr.length - 1].split('_');
		editFileName = getNameFromString[getNameFromString.length - 1];
	}

	useEffect(() => {
		if (typeEdit && edittableData?.gift?.image) {
			const imageSource = edittableData.gift.image;

			if (imageSource instanceof Blob || imageSource instanceof File) {
				const imageUrl = URL.createObjectURL(imageSource);
				setUploadedFileJson({ name: imageUrl });

				setSetOfFour((values: any) => ({
					...values,
					image: imageUrl,
				}));
			} else if (typeof imageSource === 'string') {
				setUploadedFileJson({ name: imageSource });

				setSetOfFour((values: any) => ({
					...values,
					image: imageSource,
				}));
			} else {
				console.error('Unsupported image source type:', typeof imageSource);
			}
		}
	}, [typeEdit, edittableData]);

	useEffect(() => {
		if (resetAll) {
			setIsImageDelete(false);
			setUploadedFileJson(null);
			setSetOfFour({
				...setOfFour,
				image: {},
			});
		}
	}, [resetAll]);

	return (
		<div>
			<div className="w-full text-gray-500 lg:mt-3 mt-1 text-base rounded-lg flex flex-col">
				{(Object.keys(setOfFour.image).length !== 0 || !isImageDelete) && (
					<div className="text-lg w-full h-full flex">
						<div
							className="w-full p-2 pr-4 rounded-lg flex justify-between gap-3"
							style={{
								border: `1px solid ${BORDERCOLOR}`,
								background: WHITE,
							}}
						>
							<div className="flex gap-3 items-center w-[calc(100%-36px)]">
								<div className="h-12 w-12 overflow-hidden relative shadow-[rgba(149,157,165,0.2)_0px_8px_24px]">
									<Image
										image={
											filename1
												? typeEdit
													? setOfFour.image
													: typeEdit
													  ? setOfFour.image
													  : URL.createObjectURL(setOfFour.image)
												: USER_IMAGE_MALE
										}
										altName="user-image"
										className="h-full w-full object-cover"
									/>
								</div>
								<div className="w-[calc(100%-60px)]">
									<p
										className="uppercase text-base leading-8 w-full whitespace-nowrap overflow-hidden truncate"
										style={{ color: TEXTCOLOR }}
									>
										{editFileName ?? 'IMG45x24.PNG'}
									</p>
								</div>
							</div>
							<div className="flex items-center">
								<button
									type="button"
									onClick={() => {
										setIsImageDelete(true);
										setValue('imageString', 'something');
										clearErrors('imageString');
										setSetOfFour((values: setOfFour) => ({
											...values,
											image: {},
										}));
									}}
								>
									<DeleteCrossIcon />
								</button>
							</div>
						</div>
					</div>
				)}
				{Object.keys(setOfFour.image).length === 0 && isImageDelete && (
					<button className="w-full h-16 border-2 border-dashed border-gray-200 rounded-lg relative">
						<div className="flex flex-row justify-center gap-5 cursor-pointer">
							{id === CLASSES && (
								<div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
									<p className="capitalize text-center text-lg leading-8">
										Upload image
									</p>
								</div>
							)}

							<div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 opacity-0 w-full h-full">
								<input
									type="file"
									className="h-full w-full cursor-pointer"
									onChange={handleImageChange}
									accept={ACCEPT_FILES_EXTENSSIONS}
								/>
							</div>
						</div>
					</button>
				)}
				{errors?.imageString && (
					<ErrorMessage message={errors?.imageString?.message} />
				)}
			</div>
		</div>
	);
};

export default UserImageInput;
