import React from 'react';

const BackPage = () => {
	return (
		<div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
			>
				<path d="M15 6L9 12L15 18" stroke="#1E1E1E" />
			</svg>
		</div>
	);
};

export default BackPage;
