import React, { useEffect, useState } from 'react';
import CommonButton from '../../../../../Components/Button/CommonButton';
import { getPriceByDenomination } from '../../../../../Api/UserListApi';
import Modal from '../../../../../Components/Model/Modal';
import Information from '../../../../../Components/Svg/Information';
import {
	BLACK,
	GRAY100,
	WHITE,
	WHITE_SMOKE,
} from '../../../../../Constant/Color.Constant';
import { SET_OF_FOUR } from '../../../../../Constant/Constant';
import {
	setOfFour,
	SetOfFourData,
} from '../../../../../Interface/NewGiftInterface';

const AddToBag = ({
	setOfFour,
	setOfFourData,
	id,
	isSubmitValid,
	loading,
	typeEdit,
}: {
	setOfFour: setOfFour;
	setOfFourData?: SetOfFourData;
	id: string | undefined;
	isSubmitValid: boolean;
	loading: boolean;
	typeEdit: string;
}) => {
	const [pricePerDenomination, setPricePerDenomination] = useState([]);
	const [priceInfo, setPriceInfo] = useState<boolean>(false);
	const [totalPrice, setTotalPrice] = useState<number>(0);

	const onClose = () => {
		setPriceInfo(false);
	};
	const fetchData = async () => {
		try {
			const response = await getPriceByDenomination();
			setPricePerDenomination(response?.data?.rows);
		} catch (error) {
			console.error({ error });
		}
	};

	const total = setOfFourData
		? Object.values(setOfFourData).reduce(
				(acc, item: any) => acc + item?.price,
				0
		  )
		: 0;

	useEffect(() => {
		fetchData();
	}, []);

	const temp: any = pricePerDenomination?.filter((element: any) =>
		setOfFour.denomination.includes(element?.denomination.toString())
	);
	const priceArray = temp?.map((item: any) => item.price);

	useEffect(() => {
		setTotalPrice(
			id === SET_OF_FOUR
				? (total as unknown as number)
				: priceArray?.reduce((a: number, b: number) => a + b, 0)
		);
	}, [priceArray]);

	return (
		<div
			className="flex gap-4 items-center lg:min-h-[120px] min-h-[68px] py-3 lg:px-8 sm:flex-row justify-between"
			style={{ background: WHITE_SMOKE }}
		>
			<div className="flex gap-7 sm:w-full w-auto">
				<div className="w-full">
					<div
						className="text-base leading-5 flex xl:flex-col lg:gap-1 justify-between w-full items-center sm:items-start flex-col"
						style={{ color: GRAY100 }}
					>
						<p className="font-medium text-sm leading-5">TOTAL PRICE</p>
						<div className="flex sm:gap-2 gap-1 items-center w-full lg:w-auto">
							{totalPrice ? (
								<>
									<div>
										<div
											className="cursor-pointer"
											onClick={() => setPriceInfo(!priceInfo)}
										>
											<Information />
										</div>
										{priceInfo && (
											<Modal
												title="Price Info"
												buttonName="Submit Order"
												onClose={onClose}
											>
												<div className="w-[300px]">
													{temp?.length > 0 &&
														temp.map((item: any, index: number) => {
															return (
																<div
																	key={index}
																	className="border-b p-1 flex items-center justify-center"
																>
																	<div>
																		Note - {item?.denomination} Rupee Note
																	</div>
																	<div>Price - {item?.price}</div>
																</div>
															);
														})}
												</div>
											</Modal>
										)}
									</div>
									<span
										className="font-bold sm:text-2xl text-base leading-6"
										style={{ color: BLACK }}
									>
										<span>&#8377;{totalPrice}/-</span>
									</span>
								</>
							) : (
								<span>
									<b>---</b>
								</span>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="max-w-max sm:w-full flex lg:justify-end">
				<CommonButton
					height="45px"
					type="submit"
					label={typeEdit ? 'EDIT GIFT' : 'ADD TO BAG'}
					backgroundColor={isSubmitValid ? BLACK : '#828282'}
					loading={loading}
				></CommonButton>
				<div
					id="drawer-right-example"
					className="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full"
					tabIndex={-1}
					aria-labelledby="drawer-right-label"
					style={{
						background: WHITE,
					}}
				></div>
			</div>
		</div>
	);
};

export default AddToBag;
