import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';

// window.dataLayer.push({
//   event: "customEvent1",
//   dlVariable1: "value1",
// });

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<HelmetProvider>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</HelmetProvider>
);

reportWebVitals();
