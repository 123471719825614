import CommonButton from '../../../Components/Button/CommonButton';
import SEO from '../../../Components/Helmet/SEO';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../Constant/Routes.Constant';
import { BLACK, BORDERCOLOR, WHITE } from '../../../Constant/Color.Constant';
import useMobileMediaQuery from '../../../utils/useMobileMediaQuery';
import {
	EXCLUSIVE_NOTE_COLLECTION,
	HOME,
} from '../../../Constant/Seo.Constant';
import Image from '../../../Components/Image/Image';

const OrdinaryGifts = () => {
	const navigate = useNavigate();
	const screen = useMobileMediaQuery();
	const { pathname } = useLocation();

	const handleClickNewGift = () => {
		navigate(`${ROUTES.NEW_GIFT_LAYOUTS}`);
	};
	return (
		<>
			{pathname === ROUTES.EXCLUSIVE ? (
				<SEO title="Exclusives" description={EXCLUSIVE_NOTE_COLLECTION} />
			) : (
				<SEO title="Home" description={HOME} />
			)}
			<div>
				<div className="w-full flex flex-col lg:flex-row lg:min-h-[348px] xlg:h-[348px]">
					<div className="lg:w-2/4 md:w-full sm:w-full xs:w-full xlg:h-full lg:h-auto h-[200px]">
						<div className="relative h-full">
							<Image
								image="/Images/HomeBackGround.png"
								altName="album 1"
								className="h-full w-full object-cover"
							/>
							<div
								className="absolute top-0 left-0 h-full w-full"
								style={{ background: '#00000050' }}
							></div>
						</div>
					</div>
					<div
						className="lg:w-2/4 md:w-full sm:w-full xs:w-full lg:py-16 lg:pl-8 lg:pr-24 xs:py-8 xs:px-4 md:py-16 md:pl-24 md:pr-24 flex flex-col sm:gap-8 xs:gap-6"
						style={{ background: BLACK }}
					>
						<div className="flex flex-col gap-3" style={{ color: WHITE }}>
							<p className="font-bold sm:text-4xl text-2xl sm:leading-[44px] leading-9 text-start lg:w-full w-full">
								BEYOND ORDINARY GIFTS
							</p>
							<p
								className="font-normal sm:text-lg text-sm lg:text-start md:text-start lg:max-w-[592px] w-full sm:leading-7 leading-6"
								style={{ color: BORDERCOLOR }}
							>
								Unlock cherished moments with Rupi&apos;s personalized currency
								notes. Each note weaves a unique story, making it the perfect
								gift for your loved ones.
							</p>
						</div>
						<CommonButton
							onClick={handleClickNewGift}
							className="rounded-lg"
							backgroundColor="white"
							width="max-content"
							fontSize={screen.isMobile ? '14px' : '16px'}
							padding={screen.isMobile ? '8px 16px' : '12px 32px'}
							label="NEW GIFT"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default OrdinaryGifts;
