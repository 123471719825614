import React, { useState } from 'react';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { TEXTCOLOR, BORDERCOLOR } from '../../Constant/Color.Constant';
import Eye from '../../Components/Svg/Eye';
import HideEye from '../../Components/Svg/HideEye';

type TextFieldProps<T extends FieldValues> = {
	size?: 'small' | 'medium';
	name: Path<T>;
	register: UseFormRegister<T>;
	type?: 'text' | 'password' | 'textarea' | 'email' | 'number';
	placeholder?: string;
	className?: string;
	disabled?: boolean;
	rows?: number;
	padding?: string;
};

type RestProps = {
	[key: string]: unknown;
};

type Props<T extends FieldValues> = TextFieldProps<T> & RestProps;

export const CustomTextField = <T extends FieldValues>({
	name,
	register,
	size = 'small',
	type = 'text',
	placeholder = '',
	className = '',
	disabled = false,
	rows = 4,
	...rest
}: Props<T>) => {
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};
	const { onChange } = register(name);

	const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const inputValue = e.target.value;
		if (inputValue.length > 500) {
			const trimmedValue = inputValue.slice(0, 500);
			e.target.value = trimmedValue;
		} else {
			e.target.value = inputValue.trimStart();
		}
		onChange(e);
	};
	return (
		<div className="relative flex">
			{type !== 'textarea' ? (
				<input
					type={
						type === 'password' ? (showPassword ? 'text' : 'password') : type
					}
					className={`bg-white border rounded-lg  sm:p-4 p-3 w-full ${
						size === 'small' ? 'text-sm' : 'text-base'
					} ${className} placeholder-${TEXTCOLOR} focus:border-${BORDERCOLOR} focus:ring-${BORDERCOLOR} ${
						disabled ? 'text-gray-500' : 'text-black'
					}`}
					placeholder={placeholder}
					{...register(name)}
					onChange={(e) => {
						e.target.value = e.target.value.trimStart();
						onChange(e);
					}}
					{...rest}
					disabled={disabled}
				/>
			) : (
				<textarea
					className={`bg-white border resize-none rounded-lg p-4 w-full ${
						size === 'small' ? 'text-sm' : 'text-base'
					} ${className} placeholder-${TEXTCOLOR} focus:border-${BORDERCOLOR} focus:ring-${BORDERCOLOR} ${
						disabled ? 'text-gray-500' : 'text-black'
					}`}
					placeholder={placeholder}
					{...register(name)}
					onChange={handleTextareaChange}
					{...rest}
					disabled={disabled}
					rows={rows}
				/>
			)}
			{type === 'password' && (
				<button
					type="button"
					className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer h-[26px] w-[26px] flex justify-center items-center"
					onClick={togglePasswordVisibility}
				>
					{showPassword ? <Eye /> : <HideEye />}
				</button>
			)}
		</div>
	);
};
