import React from 'react';
import Correct from '../../../Components/Svg/Correct';
import { GRAY900, TEXTCOLORGRAY } from '../../../Constant/Color.Constant';
import { StackItem } from '../../../Interface/Index';

const StackItems = ({ stackItems }: { stackItems: StackItem[] }) => {
	return (
		<div className="grid lg:grid-cols-2 grid-cols-1 xs:grid-cols-1 md:grid-cols-2 col-span-2 sm:gap-8 gap-7">
			{stackItems.map((item) => (
				<div key={item.id}>
					<div className="flex flex-col">
						<div className=" flex flex-row  justify-start sm:gap-x-3 gap-x-2 w-full h-full">
							<div className=" flex justify-start items-start">
								<div className="w-6 h-6 flex justify-center items-center">
									<Correct />
								</div>
							</div>
							<div className="flex flex-col sm:gap-y-2 gap-y-[4px] w-full">
								<div className="flex items-center">
									<p
										className="text-base leading-6 font-semibold uppercase"
										style={{ color: GRAY900 }}
									>
										{item.heading}
									</p>
								</div>
								<div>
									<p
										className="text-base font-normal w-full"
										style={{ color: TEXTCOLORGRAY }}
									>
										{item.content}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default StackItems;
