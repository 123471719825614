import React from 'react';
import { BORDERCOLOR } from '../../Constant/Color.Constant';

const RightColloection = () => {
	return (
		<div>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="#d1d4db"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M6.41685 18.3334L14.7502 10.0001L6.41685 1.66675L5.25018 2.85425L12.396 10.0001L5.25018 17.1459L6.41685 18.3334Z"
					fill={BORDERCOLOR}
				/>
			</svg>
		</div>
	);
};

export default RightColloection;
