import {
	ChangeEvent,
	LegacyRef,
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import CommonLabel from '../../../../Components/InputLable/lable';
import { BLACK, BORDERCOLOR, WHITE } from '../../../../Constant/Color.Constant';
import FileUploadIcon from '../../../../Components/Svg/FileUploadIcon';
import { ACCEPT_FILES_EXTENSSIONS } from '../../../../Constant/Constant';
import { useDraggable } from 'react-use-draggable-scroll';
import Frame from '../../../../Components/Svg/Frame';
import TickSquare from '../../../../Components/Svg/TickSquare';
import {
	removeBackgroundImage,
	setBackgroundImage,
} from '../../../../store/CartImageSlice';
import { useAppDispatch } from '../../../../store/store';
import { findIndex } from 'lodash';
import {
	BackgroundImageJson,
	BgData,
	GiftData,
	SelectedFrameBackground,
	setOfFour,
} from '../../../../Interface/NewGiftInterface';
import { SetState } from '../../../../Interface/Index';
import useScreenSize from '../../../../utils/hooks/UseScreenSIze';

const BackGround = ({
	setSetOfFour,
	backGroundImages,
	setBackGroundImages,
	backGround,
	bgData,
	setSelectedFrameBackground,
	typeEdit,
	editData,
	resetAll,
}: {
	setSetOfFour: SetState<setOfFour>;
	backGroundImages: BackgroundImageJson[];
	setBackGroundImages: SetState<BackgroundImageJson[]>;
	backGround: BackgroundImageJson[];
	bgData: BgData;
	setSelectedFrameBackground: SetState<SelectedFrameBackground>;
	typeEdit: string;
	editData: GiftData;
	resetAll: boolean;
}) => {
	const [outline, setOutline] = useState<number>();
	const dispatch = useAppDispatch();

	const ref: MutableRefObject<HTMLElement | null> = useRef(null);
	const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);

	useEffect(() => {
		setBackGroundImages([...bgData, ...backGround] as BackgroundImageJson[]);
	}, [bgData, backGround]);

	const handleBackgroundClick = async (
		item: BackgroundImageJson,
		index: number
	) => {
		setSelectedFrameBackground(item.image);
		let getSelectedImageString: string = '';
		if (item?.key === 'custom') {
			const selectedImage = item.image;
			setSetOfFour((values: setOfFour) => ({
				...values,
				imageFileJson: selectedImage,
				isCustom: true,
			}));
		} else {
			getSelectedImageString = item?.id;
			setSetOfFour((values: setOfFour) => ({
				...values,
				SelectBackGround: {
					...values.SelectBackGround,
					id: getSelectedImageString,
				},
				isCustom: false,
			}));
		}
		setOutline(index);
	};

	const handleFiles = async (event: ChangeEvent<HTMLInputElement>) => {
		const selectedFiles = [...(event.target.files as any)];
		const promises = Array.from(selectedFiles).map((file) => {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					resolve(reader.result);
				};
				reader.readAsDataURL(file);
			});
		});
		Promise.all(promises).then((dataURLs) => {
			const objectJson = dataURLs.map((e: any) => ({
				image: e,
				key: 'custom',
			}));
			for (let i = 0; i < objectJson.length; i++) {
				dispatch(setBackgroundImage(objectJson[i]));
			}
		});
		setOutline(0);
	};

	const handleDeleteUserBackground = (index: number) => {
		dispatch(removeBackgroundImage(index));
	};

	useEffect(() => {
		if (typeEdit) {
			if (!editData?.gift?.backgroundImage) {
				const selectedBGIndex = findIndex(backGroundImages, {
					id: editData?.gift?.bgImageId,
				});
				setOutline(selectedBGIndex);
			}
		} else {
			setOutline(0);
		}
	}, [editData, backGroundImages]);

	useEffect(() => {
		if (resetAll) {
			setOutline(0);
			setSelectedFrameBackground(backGroundImages[0]?.image);
		}
	}, [resetAll]);

	const screenDetails = useScreenSize();
	const isLGscreen = screenDetails.isExistScreen.lg;

	return (
		<div>
			<CommonLabel labelText="Select Background" required />
			<div className="w-full">
				<div className="flex w-full">
					<div className="pr-3 lg:mt-3 mt-1">
						<div
							className="lg:min-w-[64px] min-w-[48px] lg:w-16 lg:h-16 w-12 h-12 border-2 rounded-lg flex items-center justify-center relative overflow-hidden hover:bg-gray-100 cursor-pointer"
							style={{ borderColor: BORDERCOLOR, background: WHITE }}
						>
							<FileUploadIcon size={isLGscreen ? '24' : '32'} />
							<input
								type="file"
								className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleFiles(e)}
								accept={ACCEPT_FILES_EXTENSSIONS}
								multiple
							/>
						</div>
					</div>
					<div
						className="flex lg:gap-5 gap-4 lg:pl-3 pl-1 w-[calc(100%-76px)] overflow-auto lg:pt-3 pt-1"
						{...events}
						ref={ref as LegacyRef<HTMLDivElement>}
					>
						{backGroundImages?.map(
							(item: BackgroundImageJson, index: number) => (
								<div
									key={index}
									onClick={() => handleBackgroundClick(item, index)}
									style={{
										border:
											outline === index
												? `4px solid ${BLACK}`
												: `1px solid ${BORDERCOLOR}`,
									}}
									className="rounded-lg flex items-center justify-center gap-3 border border-gray-300 lg:min-w-[64px] lg:w-16 lg:h-16 min-w-[48px] w-12 h-12 relative cursor-pointer"
								>
									<div
										className="rounded-md w-full h-full !bg-no-repeat !bg-cover !bg-center"
										style={{
											background: `url('${item.image}')`,
											backgroundRepeat: 'no-repeat',
											backgroundSize: 'cover',
											backgroundPosition: 'center',
										}}
									></div>
									{item.key && (
										<div
											className="absolute z-10 -top-2.5 -right-2.5 w-5 h-5 rounded-full flex items-center justify-center cursor-pointer"
											onClick={() => handleDeleteUserBackground(index)}
											style={{
												background: WHITE,
											}}
										>
											<Frame />
										</div>
									)}
									{outline === index && (
										<div className="absolute">
											<TickSquare size={isLGscreen ? '24' : '32'} />
										</div>
									)}
								</div>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BackGround;
