import { useEffect, useState } from 'react';
import CommonButton from '../Button/CommonButton';
import Remove from '../Svg/Remove';
import { deleteAddToCart, getAddToCart } from '../../Api/UserListApi';
import {
	BLACK,
	BORDERCOLOR,
	BRIGHT_GRAY,
	GRAY,
	GRAY100,
	QUICK_SILVER,
	WHITE,
} from '../../Constant/Color.Constant';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Constant/Routes.Constant';
import { DrawerProps, Gift, SetOfFourProductEl } from '../../Interface/Index';
import { PiShoppingCart } from 'react-icons/pi';
import moment from 'moment';
import { useSelector } from 'react-redux';
import BackPage from '../Svg/BackPage';
import { PRODUCT_IMAGES } from '../../Configuration/InitialData';
import { getAllAddToCart } from '../../store/CartSlice';
import { useAppDispatch } from '../../store/store';
import Edit from '../Svg/Edit';
import {
	ALL_GIFTS,
	EDIT,
	MULTIPLE,
	SETS,
	SINGLE,
} from '../../Constant/Constant';
import _ from 'lodash';
import { ErrorToaster, SuccessToaster } from '../../helpers/CustomToaster';
import Image from '../Image/Image';
import ItemsBatch from '../Ui/ItemsBatch';
import Tooltip from '../Tooltip/Tooltip';

const Drawer = ({ toggleDrawer, isOpen }: DrawerProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [data, setData] = useState<Gift[]>([]);
	const [loading, setLoading] = useState(false);
	const [isSkeleton, setIsSkeleton] = useState(true);
	const userName = useSelector(
		(state: { user: { user: { name: string } } }) => state?.user?.user?.name
	);

	const fetchFrameDetail = async () => {
		setLoading(true);
		try {
			const { data } = await getAddToCart();
			setData(data?.data?.rows);
		} catch (error) {
			console.error('Error fetching add to cart:', error);
		}
		setLoading(false);
	};

	const deleteHandler = async (id: string) => {
		try {
			const params = {
				id: JSON.stringify([id]),
			};
			const { error, message } = await deleteAddToCart(params);
			if (error) {
				ErrorToaster(message);
			} else {
				SuccessToaster(message);
				dispatch(getAllAddToCart());
			}
		} catch (error) {
			console.error('Error fetching Products:', error);
		}
		fetchFrameDetail();
	};

	useEffect(() => {
		fetchFrameDetail();
	}, [isOpen]);

	const totalPrice =
		data &&
		data.map((e) => {
			return !e?.product ? e?.gift?.price : e?.product?.price;
		});

	const totalPriceSum = totalPrice?.reduce((acc, price) => acc + price, 0);

	const submitHandler = () => {
		userName === ''
			? navigate(ROUTES.LOGIN)
			: data?.length !== 0 && navigate(ROUTES.SHOPPING_BAG);
		toggleDrawer();
	};

	const handleBackToShopping = () => {
		navigate(ROUTES.NEW_GIFT_LAYOUTS);
		toggleDrawer();
	};

	const handleEditProduct = (id: any) => {
		const navigateRoute =
			id?.gift?.type === SINGLE || id?.gift?.type === MULTIPLE
				? ROUTES.NEW_GIFT_CLASSIC
				: id.gift.type === SETS && ROUTES.NEW_GIFT_SET_OF_FOUR;
		navigate(`${navigateRoute}?type=${EDIT}`, {
			state: { id, type: EDIT },
		});
		toggleDrawer();
	};

	return (
		<div className="relative z-[4000000]">
			{isOpen && (
				<div className="fixed inset-0 overflow-hidden z-50">
					<button
						className="fixed inset-0 opacity-50 w-full h-full cursor-default"
						onClick={toggleDrawer}
						aria-label="Close"
						style={{
							background: BLACK,
						}}
					></button>
					<div
						className="fixed inset-y-0 right-0 sm:w-[435px] w-full"
						style={{
							background: WHITE,
						}}
					>
						<div role="presentation">
							<div className="flex p-6">
								<div
									className="w-6 h-6 flex justify-center items-center"
									onClick={toggleDrawer}
								>
									<BackPage />
								</div>
								<div className="text-base w-full flex ml-2 items-center text-center font-normal uppercase gap-2">
									<span>YOUR BAG</span>
									<ItemsBatch data={data} />
								</div>
							</div>
							<div className="w-full border"></div>
							<div className="h-[calc(100vh-74px)]">
								<div className=" overflow-scroll h-[calc(100%-96px)] flex flex-col">
									{_.isEmpty(data) && (
										<div className="w-full flex flex-col justify-center items-center gap-4">
											<p className="text-3xl mt-4 font-semibold">
												Your Cart is Empty
											</p>
											<p
												className="underline cursor-pointer underline-offset-2"
												onClick={handleBackToShopping}
											>
												BACK TO SHOPPING
											</p>
											<PiShoppingCart
												style={{ color: BLACK, fontSize: '50px' }}
											/>
										</div>
									)}
									{data?.map((e, index) => {
										return (
											<div
												className="w-full flex sm:px-6 px-4 py-4 h-24 relative border-b"
												style={{ borderColor: BORDERCOLOR }}
												key={index}
											>
												<div
													className="w-16 h-16 relative rounded-lg overflow-hidden"
													style={{
														background: `linear-gradient(to right bottom, ${BRIGHT_GRAY}, ${QUICK_SILVER})`,
													}}
												>
													{isSkeleton && (
														<div className="animate-pulse bg-gradient-to-r from-gray-300 to-gray-200 w-full h-full absolute top-0 left-0"></div>
													)}
													<Image
														image={
															!e?.product
																? e?.gift?.dataUrl
																: e?.product?.images[0]
														}
														className="w-full h-full object-cover"
														onLoad={() => setIsSkeleton(false)}
														style={{
															objectFit: ALL_GIFTS.includes(e?.gift?.type)
																? 'contain'
																: 'cover',
														}}
													/>
												</div>
												<div className="flex justify-between w-[calc(100%-64px)]">
													<div className="ml-3">
														<p
															className="text-base capitalize font-medium"
															style={{ color: GRAY100 }}
														>
															{!e?.product
																? PRODUCT_IMAGES[e.gift?.type.toUpperCase()]
																		?.name
																: e.product?.name}
														</p>
														<p className="text-sm" style={{ color: GRAY100 }}>
															<span>
																<span>Currency:</span>
																<span>&nbsp;</span>
															</span>
															<span>
																{!e?.product
																	? e.gift?.denomination
																			?.map((str) => +str)
																			.join(' , ') ||
																	  e.gift?.sets
																			?.map(
																				(item: SetOfFourProductEl) =>
																					item.denomination
																			)
																			.join(' , ')
																	: e?.product?.denomination}
															</span>
														</p>
														{e.gift?.type !== SETS && (
															<p className="text-sm" style={{ color: GRAY100 }}>
																<span>{!e?.product && 'Date: '}</span>
																<span>
																	{!e?.product &&
																		moment(e.gift?.date).format('DD/MM/YYYY')}
																</span>
															</p>
														)}
													</div>
													<div>
														<div className="flex w-full">
															<div className="ml-auto flex justify-between items-center gap-2">
																{(e.gift?.type === SINGLE ||
																	e.gift?.type === MULTIPLE ||
																	e.gift?.type === SETS) && (
																	<Tooltip text="Edit">
																		<button
																			onClick={() => handleEditProduct(e)}
																		>
																			<Edit width="20px" />
																		</button>
																	</Tooltip>
																)}
																<Tooltip text="Remove">
																	<button onClick={() => deleteHandler(e.id)}>
																		<Remove width="20" />
																	</button>
																</Tooltip>
															</div>
														</div>
														<div
															className="text-base font-bold flex justify-end items-end w-full h-[calc(64px-20px)]"
															style={{ color: GRAY100 }}
														>
															<span>Price:</span>
															<span>
																<span>
																	{!e?.product
																		? e.gift?.price
																		: e?.product?.price}
																</span>
																<span>/-</span>
															</span>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>

								<div className="flex sm:px-6 px-4 py-4">
									<CommonButton
										type="submit"
										loading={loading}
										label={
											data?.length === 0 || data === undefined
												? 'CHECKOUT'
												: `₹${totalPriceSum
														.toFixed(2)
														.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} • CHECKOUT`
										}
										width="100%"
										height="64px"
										backgroundColor={
											data?.length === 0 || data === undefined ? GRAY : BLACK
										}
										onClick={submitHandler}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Drawer;
