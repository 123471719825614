import React from 'react';
import { ModalProps } from '../../Interface/Index';
import Index from '../NoteForSale/NoteForSell';
import classNames from 'classnames';

const Modal: React.FC<ModalProps> = ({
	onClose,
	children,
	className,
	modalClasses,
	notForSale, // isOpen,
}: ModalProps) => {
	// if (isOpen) return null;
	const baseClassName = 'bg-white rounded-lg ';
	const additionalClasses = notForSale ? 'flex flex-col gap-6' : ' ';

	return (
		<>
			<div className="fixed inset-0 z-[10000000] flex justify-center items-center">
				<div
					className="fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity"
					onClick={onClose}
				/>
				<div
					className={classNames(
						'flex justify-center items-center fixed top-2/4 left-2/4 -translate-x-2/4 sm:px-4 nd:px-1 px-0 -translate-y-2/4 z-[10000002]',
						modalClasses
					)}
					onClick={(e) => e.stopPropagation()}
				>
					<div
						className={classNames(
							baseClassName,
							additionalClasses,
							className,
							'w-full mx-4'
						)}
					>
						{notForSale && (
							<div className="xs:max-w-[343px] lg:max-w-[720px] w-full">
								<Index className="rounded-t-lg" />
							</div>
						)}
						<div className="sm:flex sm:items-start flex-col xs:p-0 h-full">
							{children}
						</div>
						{notForSale && (
							<div className="xs:max-w-[343px] lg:max-w-[720px] w-full">
								<Index className="rounded-b-lg" />
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Modal;
