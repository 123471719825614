import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ExclusiveCard from '../../../Components/ExclusiveCard/ExclusiveCard';
import { getAllExclusiveNote } from '../../../store/ExclusiveSlice';
import CommonButton from '../../../Components/Button/CommonButton';
import OrdinaryGifts from '../OrdinaryGifts/OrdinaryGifts';
import SEO from '../../../Components/Helmet/SEO';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { ROUTES } from '../../../Constant/Routes.Constant';
import { EXCLUSIVE_NOTE_COLLECTION } from '../../../Constant/Seo.Constant';
import NoteForSell from '../../../Components/NoteForSale/NoteForSell';
import ExclusiveNoteSlider from './Components/ExclusiveSlider';
import { ExclusiveTitile } from '../../../Constant/Constant';
import { BLACK, GRAY10, WHITE } from '../../../Constant/Color.Constant';

export type ExclusiveNotesData = {
	name: string;
	images: string[];
	description?: string;
	to?: string;
	id?: string;
};

const ExclusiveNotes = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [dataLimit, setDataLimit] = useState(8);

	const exclusiveNotesData = useAppSelector(
		(state) => state?.exclusive?.exclusiveList
	);

	const handleNavigate = () => {
		navigate(ROUTES.EXCLUSIVE);
	};

	const handleLoadMore = () => {
		const newLimit = dataLimit + 8;
		const maxLimit = exclusiveNotesData?.length;

		if (newLimit <= maxLimit) {
			setDataLimit(newLimit);
		} else {
			setDataLimit(maxLimit);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				await dispatch(getAllExclusiveNote({ forSell: false }));
			} catch (error) {
				console.error('Error fetching limited edition data:', error);
			}
		};

		fetchData();
	}, [dispatch]);

	const showLoadMoreButton = dataLimit < exclusiveNotesData?.length;

	return (
		<>
			<SEO title="Exclusive Product" description={EXCLUSIVE_NOTE_COLLECTION} />
			<>
				<div
					className="flex justify-center items-center max-w-[2500px] mx-auto flex-col"
					style={{ backgroundColor: WHITE }}
				>
					{location.pathname === ROUTES.EXCLUSIVE && (
						<>
							<ExclusiveNoteSlider />
							<NoteForSell />
						</>
					)}
					{exclusiveNotesData?.length === 0 ? (
						<div className="flex justify-center w-full h-[300px]">
							<div className="flex justify-center items-center xs:p-5 lg:p-20 sm:p-5 md:p-20 flex-col gap-4">
								<p className="text-3xl mt-4 font-semibold">
									NO EXCLUSIVE DATA FOUND
								</p>
								<p className="underline cursor-pointer underline-offset-2">
									BACK TO THE HOME
								</p>
							</div>
						</div>
					) : (
						<div className="flex flex-col w-full">
							<div className="flex flex-col justify-center xlg:px-24 lg:px-12 xs:px-4 sm:py-24 xs:py-8 sm:gap-12 gap-6">
								<div className="flex justify-center items-center flex-col sm:gap-4 gap-2 w-full ">
									<p
										className="font-bold sm:text-[32px] text-2xl sm:leading-10 leading-8 uppercase sm:max-w-[1184px] max-w-[343px] text-center"
										style={{ color: BLACK }}
									>
										{ExclusiveTitile.title}
									</p>

									<p
										className="sm:text-xl sm:leading-8 font-medium leading-6 text-sm text-center sm:max-w-[1184px] max-w-[343px]"
										style={{ color: GRAY10 }}
									>
										{ExclusiveTitile.description}
									</p>
								</div>

								<div className="flex justify-center">
									<div
										className={`xs:gap-4 lg:gap-8 sm:gap-4 grid ${
											exclusiveNotesData?.length === 3
												? 'sm:grid-cols-2 md:grid-cols-3'
												: exclusiveNotesData?.length === 2
												  ? 'xs:grid-cols-2 md:grid-cols-2'
												  : exclusiveNotesData?.length === 1
												    ? 'xs:grid-cols-1'
												    : 'xs:grid-cols-1 nd:grid-cols-2 sm:grid-cols-2 xlg:grid-cols-4 md:grid-cols-3'
										}`}
									>
										{exclusiveNotesData
											?.slice(0, dataLimit)
											?.map((collection: ExclusiveNotesData, index: number) => (
												<ExclusiveCard
													key={index}
													title={collection.name}
													image={collection.images[0]}
													description={collection.description}
													to={ROUTES.EXCLUSIVE}
													id={collection.id}
													collectionID={collection?.id}
												/>
											))}
									</div>
								</div>
								<div>
									{location.pathname === ROUTES.EXCLUSIVE &&
										showLoadMoreButton && (
											<div className="w-full flex justify-center">
												<CommonButton
													padding="12px 32px"
													label="Load More"
													fontSize="16px"
													onClick={() => handleLoadMore()}
												/>
											</div>
										)}

									<>
										{location.pathname === ROUTES.HOME_PAGE && (
											<div className="w-full flex justify-center font-semibold">
												<CommonButton
													padding="12px 32px"
													label="VIEW ALL"
													fontSize="16px"
													onClick={() => handleNavigate()}
												/>
											</div>
										)}
									</>
								</div>
							</div>
							{location.pathname === ROUTES.EXCLUSIVE && (
								<div className="w-full">
									<NoteForSell />
									<OrdinaryGifts />
								</div>
							)}
						</div>
					)}
				</div>
			</>
		</>
	);
};

export default ExclusiveNotes;
