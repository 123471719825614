import CommonHeader from '../Header/CommonHeader';
import { paymentMethods } from '../../Constant/Constant';
import RightColloection from '../Svg/RightColloection';
import { PaymentUtils } from '../../utils/paymentUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { resetCartCount } from '../../store/CartSlice';
import { ROUTES } from '../../Constant/Routes.Constant';
export interface Method {
	method: string;
	title: string;
}
const Payment = ({
	setPaymentMethod,
	grandTotal,
	couponCode,
}: {
	setPaymentMethod: React.Dispatch<React.SetStateAction<boolean>>;
	grandTotal: number;
	couponCode: string;
}) => {
	const user = useSelector(
		(state: { user: { user: { id: string } } }) => state?.user?.user
	);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const navigateHandler = () => {
		navigate(ROUTES.PROFILE, { state: 'MyOrders' });
		dispatch(resetCartCount());
	};
	const handleClick = async (method: Method) => {
		const data = {
			amount: grandTotal,
			referralCode: couponCode,
		};
		await PaymentUtils({
			paymentDetails: data,
			paymentMethod: method,
			user_id: user.id,
			navigateHandler,
		});
		setPaymentMethod(false);
	};

	return (
		<div className=" w-full ">
			<div>
				<CommonHeader
					title="Payment Method"
					className="uppercase text-lg font-medium leading-8"
				/>
			</div>
			<div className="w-full mt-4">
				<div className="rounded-xl bg-slate-100 w-full flex-col">
					{paymentMethods.map((item) => (
						<div
							key={item.id}
							className="accordion-item cursor-pointer text-black"
							onClick={() => handleClick(item.method)}
						>
							<div className="flex p-5">
								<div className="w-full">{item.summary}</div>
								<RightColloection />
								<div>{item.details}</div>
							</div>
							<div className="border border-t mx-[22px]"></div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Payment;
