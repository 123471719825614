import { createSlice } from '@reduxjs/toolkit';

interface ModalCountState {
	modalOpenCount: number;
}

const initialState: ModalCountState = {
	modalOpenCount: 0,
};

const modalCountSlice = createSlice({
	name: 'modalCount',
	initialState,
	reducers: {
		incrementModalCount: (state) => {
			state.modalOpenCount += 1;
		},
		resetModalCount: (state) => {
			state.modalOpenCount = 0;
		},
	},
});

export const { incrementModalCount, resetModalCount } = modalCountSlice.actions;

export default modalCountSlice.reducer;
