import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { BGGRAY, GRAY100 } from '../../../Constant/Color.Constant';
import { useDraggable } from 'react-use-draggable-scroll';
import { getOfferList } from '../../../Api/UserListApi';

interface Offer {
	id: string;
	couponCode: string;
	discription: string;
}
interface OfferCardProps {
	onSelectOffer: (couponCode: string) => void;
	isCouponApplied: boolean;
	setSelectedOffer: React.Dispatch<React.SetStateAction<string>>;
	selectedOffer: string;
}

const OfferCard: React.FC<OfferCardProps> = ({
	onSelectOffer,
	isCouponApplied,
	setSelectedOffer,
	selectedOffer,
}) => {
	const [offersList, setOffersList] = useState<Offer[]>([]);
	const ref = useRef<HTMLDivElement | null>(null);
	const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);

	const handleOfferClick = (couponCode: string) => {
		setSelectedOffer((prevSelectedOffer: string) =>
			prevSelectedOffer === couponCode ? '' : couponCode
		);
		onSelectOffer(couponCode);
	};

	useEffect(() => {
		const fetchOffersFromApi = async () => {
			try {
				const params = {};
				const data = await getOfferList(params);
				setOffersList(data?.data?.DiscountList);
			} catch (error) {
				console.error('Error fetching offers:', error);
			}
		};
		fetchOffersFromApi();
	}, []);

	return (
		<div
			className="flex gap-4 overflow-x-scroll max-w-full w-full cursor-pointer"
			{...events}
			ref={ref}
		>
			{offersList?.map((offer) => {
				return (
					<div
						key={offer.couponCode}
						style={{
							border:
								selectedOffer === offer.couponCode
									? '2px solid black'
									: '2px solid transparent',
						}}
						className="min-w-[200px] rounded-[4px]"
						onClick={() =>
							!isCouponApplied && handleOfferClick(offer.couponCode)
						}
					>
						<div
							className="px-3 py-2 h-full w-full rounded-[4px]"
							style={{
								backgroundColor: BGGRAY,
								opacity: !isCouponApplied
									? '1'
									: selectedOffer === offer.couponCode
									  ? '1'
									  : '0.5',
								cursor: !isCouponApplied
									? 'pointer'
									: selectedOffer === offer.couponCode
									  ? 'pointer'
									  : 'not-allowed',
							}}
						>
							<div className="flex gap-1 flex-col">
								<p className="md:text-base text-sm md:leading-6 leading-5 font-bold">
									{offer.couponCode}
								</p>
								<p
									className="text-sm leading-5 font-normal max-w-[176px]"
									style={{ color: GRAY100 }}
								>
									{offer.discription}
								</p>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default OfferCard;
