import CommonHeader from '../../../Components/Header/CommonHeader';
import { ProductOverviewProps } from '../../../Interface/Index';
import React, { useEffect, useState } from 'react';
import { cancelPayment, getMyOrder } from '../../../Api/UserListApi';
import SEO from '../../../Components/Helmet/SEO';
import moment from 'moment';
import {
	BGGRAY,
	BLACK,
	BLACK100,
	GRAY100,
	GREEN,
	ORANGE,
	ROSE,
	TEXTCOLOR,
	TEXTCOLORGRAY,
	WHITE,
	WHITE10,
} from '../../../Constant/Color.Constant';
import { MY_ORDERS } from '../../../Constant/Seo.Constant';
import { PiShoppingCart } from 'react-icons/pi';
import Modal from '../../../Components/Model/Modal';
import TrackingSystem from './components/TrakingSystem';
import { PRODUCT_IMAGES } from '../../../Configuration/InitialData';
import { ErrorToaster, SuccessToaster } from '../../../helpers/CustomToaster';
import Image from '../../../Components/Image/Image';

const MyOrders: React.FC<ProductOverviewProps> = () => {
	const [orders, setOrders] = useState<any>([]);
	const [isTrackingModalOpen, setTrackingModalOpen] = useState(false);
	const openTrackingModal = () => {
		setTrackingModalOpen(true);
	};

	useEffect(() => {
		async function fetchOrders() {
			try {
				const response = await getMyOrder();
				const data = response.data.rows;

				if (Array.isArray(data)) {
					setOrders(data);
				} else {
					setOrders([]);
				}
			} catch (error) {
				console.error('Error fetching orders:', error);
				setOrders([]);
			}
		}
		fetchOrders();
	}, []);

	const cancelOrder = async (paymentId: string) => {
		const { error, message } = await cancelPayment({
			orderId: paymentId,
		});
		error ? ErrorToaster(message) : SuccessToaster(message);
	};
	const closeTrackingModal = () => {
		setTrackingModalOpen(false);
	};
	return (
		<>
			<SEO title="My Orders" description={MY_ORDERS} />
			<div className="flex flex-col w-full lg:items-center gap-8">
				<div className="lg:w-[80%] md:w-full sm:w-full xs:w-full gap-4 flex flex-col">
					<div className="hidden lg:block">
						<CommonHeader title="MY ORDERS" />
					</div>
					<div
						className="hidden lg:block w-full"
						style={{ borderBottom: `1px solid ${WHITE10}` }}
					></div>
					<div className="flex flex-col gap-8 xs:w-full">
						{orders.length === 0 ? (
							<>
								<div className="w-full flex flex-col justify-center items-center gap-4 border h-[300px]">
									<p className="text-3xl mt-4 font-semibold">
										Your Orders is Empty
									</p>

									<PiShoppingCart style={{ color: BLACK, fontSize: '50px' }} />
								</div>
							</>
						) : (
							<>
								{orders?.map((order: any, orderIndex: number) => (
									<div
										key={order.id}
										className="flex items-center justify-center w-full"
									>
										<div className="w-full">
											<div
												className="flex flex-col p-4 rounded-xl w-full gap-4"
												style={{ backgroundColor: BGGRAY }}
											>
												<div className="flex justify-between items-center">
													<p
														className="font-medium lg:text-lg xs:text-sm"
														style={{ color: TEXTCOLORGRAY }}
													>
														Order ID: {order.id.substring(0, 5)}
													</p>

													<button
														className="font-medium px-3 py-1 rounded xs:text-xs xs:leading-4 lg:text-base lg:leading-6 capitalize"
														style={{
															backgroundColor: WHITE,
															color:
																order.status === 'Shipped'
																	? ORANGE
																	: order.status === 'pending'
																	  ? ROSE
																	  : GREEN,
														}}
													>
														{order.status}
													</button>
												</div>

												<hr className="border-dashed border-t-3 border-gray-300" />
												{order?.gift.map((gift: any, giftIndex: number) => (
													<div
														key={`gift-${orderIndex}-${giftIndex}`}
														className="flex lg:flex-row items-start"
													>
														<div
															className="h-[88px] w-[88px] rounded-lg"
															style={{
																background:
																	'linear-gradient(to right bottom, #eaebec, #a4a5a7)',
															}}
														>
															<Image
																image={gift?.dataUrl}
																altName="frame"
																className="h-full w-full object-cover"
															/>
														</div>
														<div className="w-[calc(100%-88px)]">
															<div className="flex flex-col pl-3 gap-3">
																<div className="flex flex-col">
																	<p
																		className="lg:text-lg md:text-lg sm:text-base xs:text-sm font-medium capitalize"
																		style={{ color: BLACK100 }}
																	>
																		{
																			PRODUCT_IMAGES[gift?.type.toUpperCase()]
																				?.name
																		}
																	</p>
																	<div className="flex gap-2 w-full">
																		<p
																			className="font-normal xs:text-xs lg:text-sm"
																			style={{ color: GRAY100 }}
																		>
																			Currency :
																			{gift?.denomination
																				?.map((str: string) => +str)
																				.join(' , ') ||
																				gift?.sets
																					?.map(
																						(item: {
																							date: string;
																							denomination: string[];
																							image: string;
																							name: string;
																						}) => item.denomination
																					)
																					.join(' , ')}
																		</p>

																		{gift?.type !== 'sets' && (
																			<p
																				className="font-normal xs:text-xs lg:text-sm"
																				style={{ color: GRAY100 }}
																			>
																				Date:{' '}
																				{moment(gift.date).format('DD-MM-YYYY')}
																			</p>
																		)}
																	</div>
																</div>
																<div className="flex items-center w-full">
																	<p
																		className="font-medium xs:text-base lg:text-lg"
																		style={{ color: TEXTCOLOR }}
																	>
																		<span>₹</span> {gift.price}
																	</p>
																</div>
															</div>
														</div>
													</div>
												))}
												{order?.product.map(
													(product: any, productIndex: number) => (
														<div
															key={`gift-${orderIndex}-${productIndex}`}
															className="flex lg:flex-row items-start"
														>
															<div
																className="md:h-[88px] md:w-[88px] h-20 xs:w-20 rounded-lg overflow-hidden"
																style={{
																	background:
																		'linear-gradient(to right bottom, #eaebec, #a4a5a7)',
																}}
															>
																<Image
																	image={product?.images[0]}
																	className="h-full w-full object-cover"
																	altName="frame"
																/>
															</div>
															<div className="w-[calc(100%-88px)]">
																<div className="flex flex-col pl-3">
																	<p
																		className="lg:text-lg md:text-lg sm:text-base xs:text-sm font-medium"
																		style={{ color: BLACK100 }}
																	>
																		{product.name}
																	</p>
																	<div className="flex gap-2 w-full">
																		<p
																			className="font-normal xs:text-xs lg:text-sm"
																			style={{ color: GRAY100 }}
																		>
																			Currency :{product?.denomination}
																		</p>
																	</div>
																	<div className="flex items-center w-full">
																		<p className="text-gray-900 font-medium xs:text-base lg:text-lg">
																			<span>₹</span> {product?.price}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													)
												)}
												<div className="flex justify-between items-center w-full">
													<button
														className="font-semibold cursor-pointer underline text-lg uppercase"
														style={{ color: TEXTCOLOR }}
														onClick={() => cancelOrder(order.id)}
													>
														{order?.status !== 'canceled' && 'Cancel Order'}
													</button>
													<button
														className="font-semibold text-lg underline cursor-pointer uppercase"
														style={{ color: TEXTCOLOR }}
														onClick={openTrackingModal}
													>
														{order?.status !== 'canceled' && 'Track Order'}
													</button>
												</div>
											</div>
										</div>
									</div>
								))}
							</>
						)}
					</div>
					{isTrackingModalOpen && (
						<Modal onClose={() => setTrackingModalOpen(false)}>
							<TrackingSystem onCloseModal={closeTrackingModal} />
						</Modal>
					)}
				</div>
			</div>
		</>
	);
};

export default MyOrders;
