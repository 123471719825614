import React, { useEffect, useRef, useState } from 'react';
import Remove from '../../../../Components/Svg/Remove';

interface Step {
	id: number;
	details: string;
	location: string;
	date: string;
	time: string;
}

interface TrackingSystemProps {
	onCloseModal: () => void;
}

const TrackingSystem: React.FC<TrackingSystemProps> = ({ onCloseModal }) => {
	const steps: Step[] = [
		{
			id: 1,
			details: 'shipment recive at facility',
			location: 'surat_althan_(Gujrat)',
			date: '29-Nov-2023',
			time: '04:00PM',
		},
		{
			id: 2,
			details: 'shipment recive at facility',
			location: 'surat_vesu_(Gujrat)',
			date: '28-Nov-2023',
			time: '05:50PM',
		},
		{
			id: 3,
			details: 'shipment recive at facility',
			location: 'surat_pal_(Gujrat)',
			date: '28-Nov-2023',
			time: '06:00PM',
		},
		{
			id: 4,
			details: 'shipment recive at facility',
			location: 'surat_citylight_(Gujrat)',
			date: '28-Nov-2023',
			time: '07:50PM',
		},
		{
			id: 5,
			details: 'shipment recive at facility',
			location: 'surat_kamrej_(Gujrat)',
			date: '29-Nov-2023',
			time: '08:50PM',
		},
		{
			id: 6,
			details: 'shipment recive at facility',
			location: 'surat_althan_(Gujrat)',
			date: '30-Nov-2023',
			time: '09:50PM',
		},
		{
			id: 7,
			details: 'shipment recive at facility',
			location: 'surat_althan_(Gujrat)',
			date: '30-Nov-2023',
			time: '09:50PM',
		},
		{
			id: 8,
			details: 'trip arrived',
			location: 'surat_kamrej_(Gujrat)',
			date: '29-Nov-2023',
			time: '08:50PM',
		},
		{
			id: 9,
			details: 'bag recive at facility',
			location: 'surat_citylight_(Gujrat)',
			date: '28-Nov-2023',
			time: '07:50PM',
		},
	];
	const [stepperLine, setStepperLine] = useState<number | undefined>();

	const trakerDiv = useRef<HTMLDivElement | null>(null);
	const firstTl = useRef<HTMLDivElement | null>(null);
	const lastTl = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (trakerDiv.current && firstTl.current && lastTl.current) {
			const elementHeight = trakerDiv.current.clientHeight;
			const firstTlHeight = firstTl.current.clientHeight;
			const lastTlHeight = lastTl.current.clientHeight;
			setStepperLine(elementHeight - firstTlHeight / 2 - lastTlHeight / 2);
		}
	}, []);

	const truncateText = (text: string, maxLength: number) => {
		return text.length > maxLength
			? text.substring(0, maxLength) + '...'
			: text;
	};

	return (
		<>
			<div className="border-b border-gray-300 h-[50px] w-full flex justify-between px-8 items-center">
				<p className="font-medium">Tracking Details</p>
				<div className="cursor-pointer">
					<Remove onClick={onCloseModal} />
				</div>
			</div>
			<div className="relative w-full lg:w-[500px] py-7">
				<div className="h-[500px] overflow-scroll">
					<div ref={trakerDiv} className="w-full">
						{steps.map((step, index) => (
							<div
								key={index}
								className="flex"
								ref={
									index === 0
										? firstTl
										: index === steps.length - 1
										  ? lastTl
										  : null
								}
							>
								<div className="flex pl-5 w-[calc(100%-205px)] py-2.5">
									<div className="flex flex-col">
										<div className="text-left text-base uppercase">
											{truncateText(step.details, 25)}
										</div>
										<div className="text-left text-sm uppercase text-[gray]">
											{truncateText(step.location, 25)}
										</div>
									</div>
								</div>
								<div className="w-[30px] relative flex justify-center items-center">
									{index === 0 && (
										<div
											className="border-2  border-[green] absolute left-2/4 -translate-x-2/4 top-2/4"
											style={{ height: stepperLine }}
										></div>
									)}
									<div className="relative z-[2] h-4 w-4 rounded-full bg-[green] flex justify-center items-center">
										<div className="h-2.5 w-2.5 rounded-full bg-white"></div>
									</div>
								</div>
								<div className="flex items-center lg:justify-end xs:justify-start w-[150px]">
									<div className="flex flex-col">
										<div className="uppercase">{step.date}</div>
										<div className="uppercase text-[gray]">{step.time}</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default TrackingSystem;
