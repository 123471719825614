import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './Pages/Footer/Footer';
import Navbar from './Pages/Navbar/Navbar';
import './App.css';
import { ROUTES, authRoutes, publicRoutes } from './Constant/Routes.Constant';
import Toast from './Components/Toaster/CommonToaster';
import NotFound from './Pages/NotFound/NotFound';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './Middleware/ProtectedRoutes';
import { HelmetProvider } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { MyProvider } from './Components/Provider/Provider';
import ScrollToTop from './Components/ScrollTop/ScrollTop';
import { useAppDispatch } from './store/store';
import FooterSmall from './Pages/Footer/FooterSmall';
import { getAllAddToCart } from './store/CartSlice';
import { applyStylesToBody, unsetStylesFromBody } from './utils/bodyStyles';
import PageLoader from './Components/Ui/PageLoader';
import _ from 'lodash';
import { WHITE } from './Constant/Color.Constant';

function App() {
	const dispatch = useAppDispatch();
	dispatch(getAllAddToCart());
	useEffect(() => {
		const dataLayer = window.dataLayer || [];
		console.log('Data Layer:', dataLayer);
	}, []);

	return (
		<MyProvider initialBadgeCount={0}>
			<BrowserRouter>
				<ScrollToTop />
				<Toast />
				<HelmetProvider>
					<Routes>
						{publicRoutes.map((e, index: number) => {
							return (
								<Route
									key={index}
									path={e.path}
									element={<Layout path={e.path} component={e.component} />}
								/>
							);
						})}

						{authRoutes.map((e, index: number) => {
							return (
								<Route
									key={index}
									path={e.path}
									element={
										<ProtectedRoute
											Component={
												<Layout path={e.path} component={e.component} />
											}
										/>
									}
								/>
							);
						})}

						<Route path="*" element={<NotFound />} />
					</Routes>
				</HelmetProvider>
			</BrowserRouter>
		</MyProvider>
	);
}
export default App;

const Layout = ({
	path,
	component,
}: {
	path: string;
	component: JSX.Element;
}) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
	const [isRightSidebarOpen, setIsRightSidebarOpen] = useState<boolean>(false);
	const [pageLoader, setPageLoader] = useState(false);

	const toggleDrawer = () => {
		isRightSidebarOpen ? unsetStylesFromBody() : applyStylesToBody();
		setIsRightSidebarOpen(!isRightSidebarOpen);
	};

	const isWebFooter = [
		ROUTES.LOGIN,
		ROUTES.REGISTER,
		ROUTES.SHOPPING_BAG,
		ROUTES.RESET_PASSWORD,
		ROUTES.FORGOT_PASSWORD,
		ROUTES.PROFILE,
	];

	return (
		<div className="relative h-screen">
			<div className={`fixed top-0 w-full z-[400000]`}>
				<Navbar
					isDrawerOpen={isDrawerOpen}
					setIsDrawerOpen={setIsDrawerOpen}
					toggleDrawer={toggleDrawer}
					isRightSidebarOpen={isRightSidebarOpen}
				/>
			</div>
			<div className="relative sm:top-28 top-[72px] h-[calc(100vh-112px)]">
				<div
					className="max-w-[2500px] w-full mx-auto"
					style={{
						background: WHITE,
						minHeight: !_.includes(isWebFooter, path)
							? 'calc(100vh - 361px)'
							: 'calc(100vh - 168px)',
					}}
				>
					{React.cloneElement(component, {
						setIsRightSidebarOpen,
						setPageLoader,
					})}
				</div>
				<div>
					{!_.includes(isWebFooter, path) ? <Footer /> : <FooterSmall />}
				</div>
			</div>
			{pageLoader && <PageLoader />}
		</div>
	);
};
